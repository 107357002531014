<template>
  <base-button-secondary v-if="secondary" @clicked="restart">
    Start a new application
  </base-button-secondary>
  <base-button-primary v-else @clicked="restart">
    Start a new application</base-button-primary
  >
</template>

<script>
import BaseButtonPrimary from './BaseButtonPrimary.vue';
import BaseButtonSecondary from './BaseButtonSecondary.vue';

import { updateApplicationsInProgress } from '../../controllers/user/update';

export default {
  name: 'NewApplicationButton',
  components: { BaseButtonPrimary, BaseButtonSecondary },
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async restart() {
      this.$store.dispatch('navigation/startLoading');
      // this.secondary
      //   ? this.$store.dispatch('navigation/buttonSecondaryInLoadingState', true)
      //   : this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);

      const userId = this.$store.getters['auth/userId'];
      const product = localStorage.getItem('productName');

      if (userId) {
        await updateApplicationsInProgress(userId, null, product);
      }

      localStorage.removeItem('applicationId');
      this.$router.replace('onboard').catch(() => {});
    },
  },
};
</script>
