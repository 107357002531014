<template>
  <base-screen v-if="!isLoading" contentCentered customTransition="fade">
    <template #main>
      <div class="flex-row center">
        <container-sized width="100%" class="waiting-list-container">
          <div class="counter-container">
            <p class="counter text-centered">
              {{ counter }}
            </p>
            <p class="counter-desc text-centered">
              is your queue number
            </p>
          </div>
          <BaseImage image="waiting-list-queue" />
        </container-sized>
      </div>
      <div>
        <div class="p-t-6 p-b-4">
          <h1 class="header-1 text-centered">
            Demand for the Pillar card has been incredible!
          </h1>
        </div>
        <p class="text-centered">
          {{ bumped ? "Thanks for requesting to boost your waitlist position. We will contact you when it's time to continue your application." : "We're doing our best to get everyone a card. As soon as we're ready to get your card set up we will be in touch." }} 
        </p>
      </div>
    </template>

    <template #footer>
      <base-button-primary black :disabled="bumped" @clicked="skipTheQueue">
        {{ bumped ? "We got your request!" : "Bump me up the queue" }}
      </base-button-primary>
    </template>
  </base-screen>
</template>
<script>
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../UI/elements/layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

export default {
    name: 'WaitingListInfo',
    components: {
        ContainerSized,
        BaseScreen,
        BaseImage,
        BaseButtonPrimary,
    },
    props: {
        counter: {
            type: Number,
            required: true,
        },
        bumped: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.isLoading = false;
        });
    },
    methods: {
        skipTheQueue() {
            this.$emit('skipTheQueue');
        },
    },
};
</script>

<style lang="scss">
.waiting-list-container {
    max-width: 400px;
    position: relative;
    min-width: 335px !important;
    min-height: 400px !important;
    z-index: -1;

    .img-wrap {
        align-items: flex-end !important;
        img {
            scale: 1.2 !important;
        }
    }
}
.counter-container {
    position: absolute;
    top: 65.3%;
    left: 29.1%;
    z-index: 50;
    width: 46%;
    transform: rotate(-5.87deg);
    text-align: center !important;
}

.counter {
    width: 128px;
    height: 53px;
    gap: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 40px !important;
    font-weight: 600;
    line-height: 52.8px;
    letter-spacing: -0.04em;
    text-align: center;
    margin: 0 auto;
}
.counter-desc {
    margin-top: 4px;
}
</style>
