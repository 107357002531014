<template>
    <base-screen>
        <template #main>
            <base-title>{{ title }}</base-title>
            <p v-if="product == 'pillarCardNTC'" class="m-b-3">
                This should be your permanent address on arriving in the UK.
            </p>
            <AddressSelect
                key="currentAddressSelect"
                type="current"
                v-bind="$attrs"
                @onAddressSelect="onAddressSelect"
            />
        </template>
        <!-- <template #footer>
      <base-button-secondary @clicked="noUkAddress"
        >I don't have another UK address</base-button-secondary
      >
    </template> -->
    </base-screen>
</template>

<script>
import AddressSelect from '../../application/addressSelect.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
export default {
    name: 'CurrentAddressSelect',
    components: { BaseScreen, AddressSelect, BaseTitle },
    data() {
        return {
            product: null,
        };
    },
    computed: {
        title() {
            return this.product == 'pillarCardNTC'
                ? "What's your UK address?"
                : 'What is your current UK address?';
        },
    },
    created() {
        this.product = localStorage.getItem('productName');
    },

    methods: {
        noUkAddress() {
            this.$emit('noUkAddress');
        },
        onAddressSelect(data) {
            this.$emit('updateModule', 'currentAddressSelect', data);
        },
    },
};
</script>
