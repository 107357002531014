<template>
  <button
    type="button"
    :style="styles"
    :class="[size ? `label-button ${size}` : 'label-button']"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'LinkButton',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    styles: {
      type: Object,
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.label-button {
  cursor: pointer;
  color: $darkGrey;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  padding: 8px;
  background-color: transparent;
  transition: all 0.2s;
  border-radius: 8px;

  &.md {
    font-size: 14px;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.28px;
  }

  &.sm {
    padding: 2px;
    font-size: 14px;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.28px;
  }

  &:hover,
  &:active {
    background: $lightestGrey;
  }
}
</style>
