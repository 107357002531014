import * as Sentry from '@sentry/browser';
import store from "../store";
import { LocalStorage } from "../utils/localStorage";


export default async (to, from, next) => {

    const localStorageData = {
        applicationId: localStorage.getItem('applicationId'),
        productName: localStorage.getItem('productName'),
        uid: localStorage.getItem('uid'),
        webview: localStorage.getItem('webview'),
        token: localStorage.getItem('token'),
        tokenExpiration: localStorage.getItem('tokenExpiration'),
        verificationId: localStorage.getItem('verificationId')
    };

    Sentry.addBreadcrumb({
        category: 'navigation',
        message: `LocalStorage data:`,
        module: 'hybridGuard',
        data: localStorageData,
        level: 'info'
    });


    ///////// AUTH: TRY TO LOG IN ////////////////
    let isAuthenticated = store.getters.isAuthenticated;


    let currentUserId = store.getters.userId || null;

    Sentry.addBreadcrumb({
        category: 'navigation',
        message: `Hybrid guard check started...`,
        module: 'hybridGuard',
        data: {
            route: to.path,
            currentUserId
        },
        level: 'info'
    });

    if (!isAuthenticated) {

        Sentry.addBreadcrumb({
            category: 'navigation',
            module: 'hybridGuard',
            message: 'User is not authenticated',
            level: 'info'
        });


        const token = localStorage.getItem('token');

        if (token) {

            Sentry.addBreadcrumb({
                category: 'navigation',
                module: 'hybridGuard',
                message: 'Token exists. Trying to login...',
                level: 'info'
            });

            const authReq = await store.dispatch('auth/trySignIn');

            if (authReq.status) {
                isAuthenticated = true;
                currentUserId = authReq.data.uid;
            }

        }
    }


    const nextStepStore = store.getters['navigation/nextStep'];

    Sentry.addBreadcrumb({
        category: 'navigation',
        module: 'hybridGuard',
        message: `Next step in the store is ${nextStepStore}`,
        level: 'info'
    });



    if (nextStepStore == 'applicationData') {
        next()
    }

    /////////  USER MODULE ////////////////
    const applicationId = localStorage.getItem('applicationId');

    const webview = localStorage.getItem('webview') == 'true';

    // IF there is no applicationId in localStorage, route can be a userModule and it is webview, check if user has approved product required for a route
    if (!applicationId && to.meta?.userModule && webview) {


        Sentry.addBreadcrumb({
            category: 'navigation',
            message: `User module`,
            module: 'hybridGuard',
            data: {
                module: 'hybridGuard',
                route: to.path
            },
            level: 'info'
        });

        // Check if user has required product in his approved products. 
        // If he does, allow navigation. If not, proceed with ckecks
        if (currentUserId) {
            const userRecord = await store.dispatch('application/getUserRecord', { userId: currentUserId, forceUpdate: true });

            if (userRecord.status) {
                const approvedProducts = userRecord.data.approvedProducts;
                if (approvedProducts.length) {
                    const intersection = approvedProducts.filter(element => {
                        return to.meta.products.includes(element);
                    });

                    if (intersection.length) {
                        Sentry.addBreadcrumb({
                            category: 'navigation',
                            module: 'hybridGuard',
                            message: `User module - checks passed. Allow route`,
                            data: {
                                module: 'hybridGuard',
                                route: to.path
                            },
                            level: 'info'
                        });

                        // localStorage.removeItem('applicationId');
                        // localStorage.removeItem('productName')

                        LocalStorage.remove(['applicationId', 'productName']);
                        store.commit('navigation/UPDATE_PRODUCT', null);
                        next();
                        return false;
                    }
                }
            }
        }

    }

    /////////  APPLICATIONID IN LOCALSTORAGE ////////////////
    if (applicationId) {

        Sentry.addBreadcrumb({
            category: 'navigation',
            module: 'hybridGuard',
            message: `ApplicationId found in localStorage`,
            data: {
                applicationId
            },
            level: 'info'
        });

        const applicationRequest = await store.dispatch('application/getApplicationRecord', { applicationId, forceUpdate: true });

        // If applicationId is invalid or an error occured, delete applicationId. Else - check if application is not expired and belongs to current user
        if (!applicationRequest.status) {
            store.dispatch('error/throwNewError', {
                module: 'hybridGuard',
                errorType: 'apiError',
                comment: `application/getApplicationRecord request failed`,
                context: {
                    applicationId,
                }
            });

            LocalStorage.remove('applicationId');
            // localStorage.removeItem('applicationId');
        } else {

            const applicationData = applicationRequest.data;
            if (!currentUserId) {

                const verificationId = localStorage.getItem('verificationId');
                if (!verificationId) {
                    Sentry.addBreadcrumb({
                        category: 'navigation',
                        module: 'hybridGuard',
                        message: 'VerificationId exists - redirect to /auth',
                        level: 'info'
                    });
                    next({ path: '/auth' });
                    return false;
                } else {

                    Sentry.addBreadcrumb({
                        category: 'navigation',
                        module: 'hybridGuard',
                        message: 'VerificationId exists - redirect to /pin',
                        level: 'info'
                    });
                    next({ path: '/pin' });
                    return false;
                }
            }

            // Check if application belongs to currentUserId
            const userCheck = applicationData.userId == currentUserId;
            // If not - sign out and redirect to /error
            if (!userCheck) {
                store.dispatch('error/throwNewError', {
                    module: 'entryGuard',
                    errorType: 'authError',
                    comment: `Application saved in localStorage belongs to a different user.`,
                    context: {
                        currentUserId,
                        applicationUserId: applicationData.userId,
                        applicationId
                    }
                });
                LocalStorage.remove('applicationId')
                // localStorage.removeItem('applicationId');
                await store.dispatch('auth/logout');

            }


            const applicationStatus = applicationData.applicationStatus;

            // If application is expired, update productName in localStoarge and redirect to /expired route where user can select to create a new application
            if (applicationStatus == 'expired') {

                Sentry.addBreadcrumb({
                    category: 'navigation',
                    module: 'hybridGuard',
                    message: `Application expired`,
                    data: {
                        applicationId
                    },
                    level: 'info'
                });

                LocalStorage.set({ 'productName': applicationData.product })
                // localStorage.setItem('productName', applicationData.product);

                if (to.path == '/expired') {
                    next();
                    return false;
                } else {
                    next({ path: '/expired' });
                    return false;

                }


            }



            // If there is the redirect from dymanic link - like obsuccess and obfail (route has meta - dynamic true), allow navigation
            if (to.meta.dynamic) {
                next();
                return false
            }

            // Check if there is the next step in the store. It is updated every time we call product-config. 
            // If the routes match, allow the navigation. If it doesn't exist or routes mismatch, call product-config again


            if (nextStepStore && (`/${nextStepStore}` == to.path)) {

                next();
                return false
            } else {
                const nextStep = await resumeApplicationStep(applicationId, currentUserId);
                if (to.path == `/${nextStep}`) {

                    next();
                    return false;
                } else {

                    next({ path: nextStep });
                    return false;

                }
            }



        }

    }


    /////////  PRODUCTNAME IN LOCALSTORAGE ////////////////
    const productName = store.state.navigation.productName ?? localStorage.getItem('productName');

    // If there is no product - redirect to error
    if (!productName) {
        store.dispatch('error/throwNewError', {
            module: 'hybridGuard',
            errorType: 'navigationError',
            message: 'Please select a product to start an application',
            comment: `No product passed in the query or found in LocalStorage`,
        });

        next({ path: '/error' });
        return false;
    }

    // If we are at this point, it means that we don't have applicationId but have productName. 
    // So now, if the user is logged in, we check his applicationsInProgress propery

    if (currentUserId) {

        Sentry.addBreadcrumb({
            category: 'navigation',
            module: 'hybridGuard',
            message: `Checking user applications in progress...`,
            data: {
                currentUserId
            },
            level: 'info'
        });


        const userRecord = await store.dispatch('application/getUserRecord', { userId: currentUserId, forceUpdate: true });
        if (userRecord.status) {

            const applicationsInProgress = userRecord.data?.applicationsInProgress;

            if (applicationsInProgress) {
                const applicationId = applicationsInProgress?.[productName];

                if (applicationId) {
                    const applicationRequest = await store.dispatch('application/getApplicationRecord', { applicationId, forceUpdate: true });

                    if (applicationRequest.status) {

                        Sentry.addBreadcrumb({
                            category: 'navigation',
                            module: 'hybridGuard',
                            message: 'User has applications in progress',
                            data: {
                                applicationId
                            },
                            level: 'info'
                        });
                        LocalStorage.set({ applicationId })
                        // localStorage.setItem('applicationId', applicationId);

                        const applicationData = applicationRequest.data;
                        const applicationStatus = applicationData.applicationStatus;


                        // If application is expired, update productName in localStoarge and redirect to /expired route where user can select to create a new application
                        if (applicationStatus == 'expired') {

                            Sentry.addBreadcrumb({
                                category: 'navigation',
                                module: 'hybridGuard',
                                message: `ApplicationInProgress is expired`,
                                data: {
                                    applicationId,
                                },
                                level: 'info'
                            });


                            if (to.path == '/expired') {
                                next();
                                return false;
                            } else {
                                next({ path: '/expired' });
                                return false;

                            }
                        }

                        // If there is the redirect from dymanic link - like obsuccess and obfail (route has meta - dynamic true), allow navigation
                        if (to.meta.dynamic) {
                            next();
                            return false
                        }

                        // Resume an application
                        const nextStep = await resumeApplicationStep(
                            applicationId,
                            currentUserId
                        );
                        if (to.path == `/${nextStep}`) {
                            next();
                            return false;
                        } else {
                            next({ path: nextStep });
                            return false;

                        }
                    }
                }
            }
        }

    }

    const nextStep = await newApplicationStep(productName);
    if (to.path == `/${nextStep}`) {
        next();
        return false;
    } else {
        next({ path: nextStep });
        return false;

    }
};

const resumeApplicationStep = async (applicationId, userId) => {

    Sentry.addBreadcrumb({
        category: 'navigation',
        module: 'hybridGuard',
        message: `resumeApplicationStep()...`,
        module: 'hybridGuard',
        data: {
            applicationId,
            userId
        },
        level: 'info'
    });


    // Get application next step
    const payload = {
        applicationId,
    };

    if (userId) {
        payload.userId = userId;
    }

    const getNextStep = await store.dispatch('navigation/getNextStep', payload);

    // If can't get the next step, redirect to /error
    if (!getNextStep.status) {
        store.dispatch('error/throwNewError', {
            module: 'hybridGuard',
            errorType: 'apiError',
            comment: 'navigation/getNextStep request failed',
            context: payload
        });
        return '/error';
    }

    return getNextStep.data.nextStep;
};

const newApplicationStep = async (productName, userId) => {

    Sentry.addBreadcrumb({
        category: 'navigation',
        module: 'hybridGuard',
        message: `newApplicationStep()...`,
        module: 'hybridGuard',
        data: {
            productName,
            userId
        },
        level: 'info'
    });


    const payload = {
        productName
    };

    if (userId) {
        payload.userId = userId;
    }

    const getNextStep = await store.dispatch('navigation/getNextStep', payload);

    // If can't get the next step, redirect to /error
    if (!getNextStep.status) {
        store.dispatch('error/throwNewError', {
            module: 'hybridGuard',
            errorType: 'apiError',
            comment: 'navigation/getNextStep request failed',
            context: payload
        });
        return '/error';
    }

    return getNextStep.data.nextStep;
};



