import axios from "axios";
import axiosRetry from 'axios-retry';
import { errorResponse, successResponse } from "../services/response";
import { http } from '../../config/axios';
import { apiURL } from '../../config/variables';
import store from "../../store";

const apiUrl = apiURL();

export const getObConsent = async (reqData) => {

    const url = `/openbanking/consent`;

    try {
        const response = await http.post(url, reqData);
        return response;

    } catch (err) {
        return errorResponse(err);
    }

};

export const obCustomerLookup = async (data) => {
    const url = apiUrl + `/openbanking/customer-lookup`;

    try {
        const response = await http.post(url, data);
        return response;

    } catch (err) {
        return errorResponse(err);
    }

};

export const obBankVerification = async (data) => {
    const url = apiUrl + `/openbanking/bank-account-verification`;

    try {
        const response = await http.post(url, data)
        return response;

    } catch (err) {
        return errorResponse(err);
    }

};

export const obAccountsList = async (userId) => {

    const url = `/openbanking/bank-accounts/${userId}`;

    try {
        const response = await http.get(url);
        return response;

    } catch (err) {
        const errResp = errorResponse(err);
        store.dispatch('error/setErrorMessage', errResp.data);
        store.dispatch('error/showErrorScreen');
        return errResp
    }

};

export const getObAttempts = async (userId) => {

    const url = `/openbanking/attempts/${userId}`;

    try {
        const response = await http.get(url);
        const data = response.data.data;
        return successResponse(data);

    } catch (err) {
        const errResp = errorResponse(err);

        store.dispatch('error/setErrorMessage', errResp.data);
        store.dispatch('error/showErrorScreen');
        return errResp
    }

};



export const allData = async (data) => {
    // store.dispatch('setLoadingState', true);

    const url = apiUrl + `/openbanking/all-data`;


    try {
        const response = await http.post(url, data);
        // store.dispatch('setLoadingState', false);
        return response;

    } catch (err) {
        // store.dispatch('setLoadingState', false);
        return err;
    }
};


export const customerProgress = async (data) => {
    const createUrl = apiUrl + `/openbanking/customer-progress`;

    const fullToken = data.token;
    const config = {
        method: 'post',
        url: createUrl,
        headers: {
            authorization: `Bearer ${fullToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: data

    };


    //Create an axios instance to be able to use a unique interceptor
    const instance = axios.create({
        url: createUrl,
        method: 'post',
        headers: {
            authorization: `Bearer ${fullToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'

        },
        data: data
    });




    //Return the success response only if customer progressState is 100
    instance.interceptors.response.use(function (response) {
        const finalStatuses = ['Completed', 'CompletedAddition', 'OpenBankingCancelled', 'IbisSystemError', 'SystemError'];

        const data = response.data.data;
        // If the status is not final and forceRunAllData is false, change the status to 400 to trigger the axiosRetry 
        if (!finalStatuses.includes(data.customerProgress.progressDescription) && !data.forceRunAllData) {
            response.status = 400;
            return Promise.reject(response);
        }

        //If we get a success, but progressState is not Completed or CompletedAddition, we change the status to trigger the axiosRetry 
        // if (response.data.data.progressDescription !== 'Completed' && response.data.data.progressDescription !== 'CompletedAddition') {
        //     response.status = 400;
        //     return Promise.reject(response);
        // }

        // Else - just to return response
        return response;

    }, function (error) {

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        return Promise.reject(error);
    });



    //Retry the request if it failed or progressState is not Completed or CompletedAddition
    axiosRetry(instance, {
        retries: 10, // number of retries
        retryDelay: (retryCount) => {
            return retryCount * 3000; // time interval between retries
        },
        retryCondition: (error) => {

            return error;
        },
    });


    try {
        //Use an axios instance 
        const response = await instance.request(config);

        const data = response.data.data;
        return successResponse(data);

    } catch (err) {
        return errorResponse(err)
    }
};


export const obSummaryDecision = async (reqData) => {

    const url = `/decision-engine/ob-summary-block-engine`;

    try {
        const response = await http.post(url, reqData);
        const data = response.data.data;

        return successResponse(data);

    } catch (err) {
        const errResp = errorResponse(err);
        store.dispatch('error/setErrorMessage', errResp.data);
        store.dispatch('error/showErrorScreen');
        return errResp
    }

};

export const obSummaryBlockSubmitChoice = async (data) => {

    const url = `/openbanking/ob-summary-block`;

    try {
        const response = await http.post(url, data);
        return response;

    } catch (err) {
        const errResp = errorResponse(err);
        store.dispatch('error/setErrorMessage', errResp.data);
        store.dispatch('error/showErrorScreen');
        return errResp
    }

};


export const setOBConsentStepToPassed = async () => {


    const applicationId = localStorage.getItem('applicationId');
    const url = `/openbanking/obconsent-step-passed/${applicationId}`;

    try {
        const response = await http.get(url, { applicationId });
        return successResponse(response);

    } catch (err) {
        const errResp = errorResponse(err);
        store.dispatch('error/setErrorMessage', errResp.data);
        store.dispatch('error/showErrorScreen');
        return errResp
    }

};


