<template>
  <JumioError v-if="error && !isLoading" />
  <!-- <jumio-error @clicked="error = false" v-if="error"></jumio-error> -->
  <JumioSuccess v-else-if="success && !isLoading" />
  <div class="d-flex justify-center align-center fullHeight" v-else>
    <iframe
      :src="url"
      width="100%"
      height="100%"
      allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import JumioError from '../../../screens/jumio/JumioError.vue';
import { generateJumioLink } from '../../../controllers/jumio';
import JumioSuccess from '../../jumio/jumioSuccess.vue';
export default {
  components: { JumioSuccess, JumioError },
  name: 'JumioWeb',
  data() {
    return {
      isLoading: true,
      success: false,
      error: false,
    };
  },

  async mounted() {
    const applicationId = localStorage.getItem('applicationId');

    const reqParams = { applicationId };
    const result = await generateJumioLink(reqParams);

    if (result && result.status == 200) {
      //  Append the url to the iframe
      const url = result.data.data.web.href;
      this.url = url;

      // If user already passed the step before
      if (this.$store.getters['navigation/stepPassedBefore']) {
        this.error = true;
      }
    } else {
      this.$router.push('/error');
      return false;
    }

    this.isLoading = false;

    window.addEventListener(
      'message',
      (event) => {
        // If we get response as the string, we use JSON.parse
        if (event.data && typeof event.data == 'string') {
          const resp = JSON.parse(event.data);

          const status = resp.payload.value;

          // If we get success event
          if (status === 'success') {
            this.success = true;
          }

          //If we get error
          if (status === 'error') {
            this.$router.push('/error');
            return false;
          }

          // If we get response as the object
        } else if (event.data && typeof event.data == 'object') {
          const data = event.data.payload.value;

          if (data === 'success') {
            this.showIframe = false;
            this.success = true;
          }

          if (data === 'error') {
            this.$router.push('/error');
            return false;
          }
        }
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: 0;
}
</style>
