<template>
  <div id="gauaranteeScreen">
    <div>
      <div class="flex-row center truelayer-logo">
        <BaseImage image="directDebitLogo" width="180px" />
      </div>
      <div class="m-t-5 m-b-6">
        <h1 class="header-1 text-centered">The Direct Debit Guarantee</h1>
      </div>
    </div>

    <v-card-text class="guaranteeText mb-4">
      The Guarantee is offered by all banks and building societies that accept
      instructions to pay Direct Debits. If there are any changes to the amount,
      date or frequency of your Direct Debit, GC re Pillar will notify you
      (normally 3 working days) in advance of your account being debited or as
      otherwise agreed. If you request GC re Pillar to collect a payment,
      confirmation of the amount and date will be given to you at the time of
      the request. If an error is made in the payment of your Direct Debit, by
      GC re Pillar or your bank or building society, you are entitled to a full
      and immediate refund of the amount paid from your bank or building
      society. If you receive a refund you are not entitled to, you must pay it
      back when GC re Pillar asks you to. You can cancel a Direct Debit at any
      time by simply contacting your bank or building society. Written
      confirmation may be required. Please also notify us.
    </v-card-text>
  </div>
</template>

<script>
import BaseImage from '../assets/BaseImage.vue';
export default {
  components: { BaseImage },
};
</script>

<style>
.guaranteeText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #152451;
}
</style>
