<template>
  <base-screen>
    <template #main>
      <base-subtitle>
        Authorise retrieval of your credit history from CRIF
        India</base-subtitle
      >
      <p class="m-t-4 m-b-4">
        We need your consent to pull your Indian Credit file.
      </p>
      <div>
        <base-checkbox id="termsAccepted" @onInput="inputChanged" preventChange
          >I accept the
          <span class="clickable bold" @click="openLink('terms')"> Terms</span>
          and
          <span class="clickable bold" @click="openLink('policy')">
            Privacy Policy</span
          >
        </base-checkbox>
        <base-checkbox id="dataAccess" @onInput="inputChanged" preventChange
          >I agree to these
          <span class="clickable bold"
            >Credit Data Access Authorisation Terms</span
          >
        </base-checkbox>
      </div>
    </template>
    <template #footer>
      <base-button-primary @clicked="sendValue" :disabled="!formIsValid">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseCheckbox from '../../inputs/BaseCheckbox.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  components: {
    BaseScreen,
    BaseSubtitle,
    BaseButtonPrimary,
    BaseCheckbox,
  },
  name: 'AuthorizationScreen',
  data() {
    return {
      termsAccepted: false,
      dataAccess: false,
    };
  },

  methods: {
    inputChanged(id, value) {
      if (id == 'termsAccepted') {
        this.termsAccepted = value;
      }

      if (id == 'dataAccess') {
        this.dataAccess = value;
      }
    },

    sendValue() {
      this.$emit('updateForm', 'authorizationScreen');
    },
    openLink(doc) {
      let link = 'https://www.hellopillar.com/terms-of-use';
      if (doc == 'policy') {
        link = 'https://www.hellopillar.com/privacy-policy';
      }

      const webview = localStorage.getItem('webview') == 'true';

      if (webview) {
        window.OpenUrlInWebview.postMessage(link);
      } else {
        window.open(link, '_blank');
      }
    },
  },
  computed: {
    formIsValid() {
      return this.termsAccepted && this.dataAccess;
    },
  },
};
</script>
