<template>
  <v-select
    v-model="selected"
    appendToBody
    :options="options"
    :placeholder="placeholder"
    :searchable="true"
    :clearable="false"
    class="custom-select"
    :disabled="disabled"
    maxHeight="10px"
    @open="setDropdownOpenedState(true)"
    @close="setDropdownOpenedState(false)"
  ></v-select>
</template>

<script>
import VueSelect from 'vue-select';

export default {
  components: {
    'v-select': VueSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dropdownOpened: false,
      selected: null,
    };
  },
  watch: {
    selected(newValue, oldValue) {
      this.$emit('selected', newValue.value);
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.selected = this.defaultValue;
    }

    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside);
    });
  },
  beforeUnmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    setDropdownOpenedState(state) {
      this.dropdownOpened = state;
    },
    handleClickOutside(event) {
      if (this.dropdownOpened) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style>
/* Input - collapsed */
.custom-select .vs__dropdown-toggle {
  background-color: #f7fafa;
  padding: 14px 16px;
  border: none;
  border-radius: 8px;
}

/* Placeholder */
.custom-select .vs__search::placeholder {
  color: #727776 !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.28px;
}

/* Placeholder/selected option */
.custom-select .vs__selected {
  color: #727776;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.28px;
}

/* Arrow */
.custom-select .vs__open-indicator {
  fill: #242424;
}
.vs--disabled .vs__open-indicator {
  fill: #b8ccc9 !important; /* Use your desired color for the disabled SVG */
}

/* Dropdown menu */
.vs__dropdown-menu {
  background-color: white !important;
  margin-top: 4px !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0px 20px 40px -16px rgba(110, 120, 119, 0.16) !important;
  padding: 0 !important;

}

/* Dropdown option */
.vs__dropdown-option {
  padding: 12px 8px !important;
  color: #242424 !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 140% !important;
  letter-spacing: -0.12px !important;
  padding-left: 20px !important;
}

/* Dropdown option - hover, selected, highlight */
.vs__dropdown-option:hover,
.vs__dropdown-option:focus,
.vs__dropdown-option:active,
.vs__dropdown-option--highlight,
.vs__dropdown-option--selected {
  background-color: #f7fafa !important;
  outline: none !important;
}

.vs--disabled {
  pointer-events: none !important;
}

/* Disabled - placeholder */
.vs__search:disabled::placeholder {
  color: #b8ccc9 !important;
}

/* Disabled - prefilled */
.vs--disabled .vs__selected-options .vs__selected {
  color: #b8ccc9 !important;
}

/* Reset any default browser styling */
.custom-select *:focus,
.custom-select *:active,
.custom-select *::selection {
  background-color: #f7fafa;
  outline: none;
  border: none;
  box-shadow: none;
  color: inherit;
}

/* Webkit specific tap highlight */
.custom-select .vs__dropdown-toggle,
.custom-select .vs__search {
  -webkit-tap-highlight-color: #f7fafa;
  tap-highlight-color: #f7fafa;
}

.custom-select .vs__dropdown-option,
.custom-select .vs__search {
  -webkit-tap-highlight-color: #f7fafa;
  tap-highlight-color: #f7fafa;
}

.vs__search, .vs__actions {
  cursor:  pointer !important;
}
</style>
