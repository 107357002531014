<template>
  <base-screen >
    <template #main>
      <base-title>What's your previous UK address?</base-title>
      <AddressSelect
        @onAddressSelect="onAddressSelect"
        v-bind="$attrs"
        key="previousAddressSelect"
        type="previousAddress"
      />

      <div class="non-uk-address_wrap" v-if="product == 'pillarCardIndia'">
        <div class="non-uk-address_row">
          <BaseIcon icon="travel" size="lg" />
          <h2 class="header-2">Recently moved to the UK?</h2>
        </div>
        <base-button-secondary-icon size="md" @clicked="nonUkAdressManual">
          Enter a non-UK address
        </base-button-secondary-icon>
      </div>
    </template>
    <template #footer>
      <base-button-link
        @clicked="noUkAddress"
        size="md"
        class="centered button-link"
        v-if="product !== 'pillarCardIndia'"
      >
        I don't have another UK address
      </base-button-link>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonSecondaryIcon from '../../UI/elements/buttons/BaseButtonSecondaryIcon.vue';
import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';
import AddressSelect from '../../application/addressSelect.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseIcon from '../../assets/BaseIcon.vue';

export default {
  components: {
    BaseScreen,
    AddressSelect,
    BaseTitle,
    BaseButtonLink,
    BaseIcon,
    BaseButtonSecondaryIcon,
  },
  name: 'PreviousAddressSelect',
  created() {
    const product = localStorage.getItem('productName');
    this.product = product;
  },
  methods: {
    noUkAddress() {
      this.$emit('noUkAddress');
    },
    onAddressSelect(data) {
      this.$emit('updateModule', 'previousAddressSelect', data);
    },
    nonUkAdressManual() {
      this.$emit('updateModule', 'nonUkAdressManual');
    },
  },
};
</script>

<style lang="scss" scoped>
.non-uk-address_wrap {
  margin-top: 72px;

  background-color: #f3fefa;
  border-radius: 16px;
  padding: 20px 24px;
}

.non-uk-address_row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.button-link {
  margin: 0 auto;
}
</style>
