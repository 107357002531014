<template>
  <div>
    <h4 class="text-centered">Type of tenancies</h4>
    <div class="flex-row tenancies-types_block">
      <div class="tenancies-types_title">
        <div class="header-2">Sole</div>
      </div>
      <div>
        <p>You pay the full rent of the property yourself</p>
      </div>
    </div>
    <hr class="m-v-4" />
    <div class="flex-row tenancies-types_block">
      <div class="tenancies-types_title">
        <h2 class="header-2">Joint</h2>
      </div>
      <div>
        <p>
          You are part of a shared tenancy and contribute towards the total rent
          of the property
        </p>
      </div>
    </div>
    <base-button-primary class="m-t-6" @clicked="close">
      Okay. Got it!
    </base-button-primary>
  </div>
</template>

<script>
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
export default {
  components: { BaseButtonPrimary },
  name: 'TenanciesTypes',
  methods: {
    close() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.tenancies-types_title {
  width: 64px;
  min-width: 64px;
}

.tenancies-types_block {
  margin-top: 24px;
}

hr {
  border: 1px solid $lightestGrey;
}
</style>
