<template>
  <ConsentBankSelect
    v-if="activeScreen == 'consentBankSelect' && !isLoading"
    key="consentBankSelect"
    :redirectUrl="redirectUrl"
    @updateModule="updateModule"
  />
  <ConsentIntro
    v-else-if="activeScreen == 'consentIntro' && !isLoading && selectedBank"
    key="consentIntro"
    :redirectUrl="redirectUrl"
    :redirectDocId="redirectDocId"
    :selectedBank="selectedBank"
    :phoneNo="phoneNo"
    @updateModule="updateModule"
  />
  <ErrorOB
    v-else-if="activeScreen == 'obErrorIntro' && !isLoading && errorCode"
    :errorCode="errorCode"
    @clicked="tryAgain"
  />
</template>

<script>
import { getTrueLayerConsentUrl } from '../../controllers/truelayer';
import { Firestore } from '../../utils/firestore';
import { LocalStorage } from '../../utils/localStorage';
import ErrorOB from '../screens/error/ErrorOB.vue';
import ConsentBankSelect from '../screens/truelayer/ConsentBankSelect.vue';
import ConsentIntro from '../screens/truelayer/ConsentIntro.vue';

export default {
  name: 'TrueLayerConsent',
  components: { ConsentBankSelect, ConsentIntro, ErrorOB },
  data() {
    return {
      isLoading: false,
      redirectUrl: null,
      selectedBank: null,
      errorCode: null,
      errorIntro: false,
      phoneNo: null,
    };
  },

  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];

      if (!activeScreen) {
        return this.errorIntro ? 'obErrorIntro' : 'consentBankSelect';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
  },

  async mounted() {
    const applicationId = localStorage.getItem('applicationId');
    if (!applicationId) {
      this.$store.dispatch('navigation/hideProgessBar');
    }

    const userId = this.$store.getters['auth/userId'];
    const user = await Firestore.getDoc('Users', userId);
    const phoneNo = user.phoneNo;
    this.phoneNo = phoneNo;

    // const passedBefore = this.$store.getters['navigation/stepPassedBefore'];

    // if (passedBefore) {
    //   const obErrorShowed = localStorage.getItem('obErrorShowed');

    //   if (!obErrorShowed) {
    //     // Get the last log from TrueLayerLogs subcollection
    //     const lastLog = await Firestore.getDocs(
    //       `Users/${userId}/TrueLayerLogs`,
    //       { orderBy: 'createdAtTimestamp', limit: 1 }
    //     );

    //     const lastLogDoc = lastLog[0];

    //     if (lastLogDoc.status === 'error' && lastLogDoc.errorCode) {
    //       this.errorCode = lastLogDoc.errorCode;
    //       this.errorIntro = true;
    //     }
    //   }
    // }

    this.$store.dispatch('navigation/stopLoading');
  },
  methods: {
    updateModule(id, data) {
      switch (id) {
        case 'consentIntro':
          this.redirect();
          break;

        case 'consentBankSelect':
          this.getConsentLink(data);
          break;
      }
    },

    tryAgain() {
      this.$store.dispatch('navigation/updateScreen', 'consentBankSelect');
    },
    async redirect() {
      window.OpenBrowser.postMessage(this.redirectUrl);
    },
    async getConsentLink(bank) {
      this.selectedBank = bank;
      this.redirectUrl = null;

      const userId = this.$store.getters['auth/userId'];
      const verificationId = localStorage.getItem('verificationId');
      const applicationId = localStorage.getItem('applicationId');
      const providerId = bank.provider_id;
      if (!userId || !verificationId || !providerId) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/updateScreen', 'consentIntro');

      const webview = localStorage.getItem('webview') == 'true' ? true : false;

      const payload = {
        userId,
        verificationId,
        providerId,
        webview,
      };

      if (applicationId) {
        payload.applicationId = applicationId;
      }

      const result = await getTrueLayerConsentUrl(payload);

      if (!result.status) {
        return false;
      }

      const redirectUrl = result.data.result;
      const redirectDocId = result.data.redirectDocId;
      
      this.redirectUrl = redirectUrl;
      this.redirectDocId = result.data.redirectDocId;
      LocalStorage.remove('obErrorShowed');
    },
  },
};
</script>
