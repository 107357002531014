<script>
import { getFinalDecisionMocked } from '../../controllers/decision-engine';

export default {
    name: 'ForceApprovalFinalDecisionBlock',

    async created() {
        const userId = this.$store.getters['auth/userId'];
        const applicationId = localStorage.getItem('applicationId');
        const payload = {
            applicationId,
            userId,
        };

        await getFinalDecisionMocked(payload);

        this.$store.dispatch('navigation/modulePassed', {
            event: 'forceApprovalFinalDecision_block_run',
            page_title: 'assessingApplication',
        });
    },
};
</script>

<style></style>
