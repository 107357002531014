<template>
  <ob-fail v-if="error"></ob-fail>
  <div class="columnCenteredWrap" v-else>
    <div class="columnCentered">
      <loading-screen> </loading-screen>
    </div>
  </div>
</template>

<script>
import {
  allData,
  obCustomerLookup,
  customerProgress,
} from '../../../controllers/ob';
import ObFail from '../../ob/OBFailScreen';

export default {
  components: {
    ObFail,
  },
  data() {
    return {
      userModule: false,
      error: false,
      moduleName: 'allData',
    };
  },
  async created() {
    this.$store.dispatch('navigation/moduleLoadingStart');
    this.$store.dispatch(
      'navigation/loadingTextUpdate',
      'Fetching your details...'
    );
    let reqParams;
    //Check if the module is loaded as the part of application or independently
    const applicationId = localStorage.getItem('applicationId');
    const token = localStorage.getItem('token');

    if (!applicationId) {
      const webview = localStorage.getItem('webview');
      if (!webview) {
        this.$store.dispatch(
          'error/setErrorMessage',
          'Something went wrong. Please try again or contact support.'
        );
        this.$store.dispatch('error/showErrorScreen');
        return false;
      }

      let userId = this.$store.getters['auth/userId'];

      if (!userId) {
        const authReq = await this.$store.dispatch('auth/trySignIn');
        if (authReq.status) {
          userId = authReq.data.uid;
        } else {
          this.$router.push('/obfail');
          return false;
        }
      }

      reqParams = {
        userId: userId,
        token: token,
      };
      this.userModule = true;
    } else {
      reqParams = {
        applicationId: applicationId,
        token: token,
      };
    }

    //obCustomerLookup request
    const response = await obCustomerLookup(reqParams);
    //If it is not successful, show an error screen
    if (!response.status || response.status !== 200) {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.error = true;
      return false;
    }
    //Get customerProgress (status 200 will be returned only if request is successful and customerProgress is 100)
    const customerProgressRes = await customerProgress(reqParams);

    if (!customerProgressRes.status) {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.error = true;
      return false;
    }

    const data = customerProgressRes.data;

    // If there is a final status and forceRunAllData is false, show an error
    if (
      (data.customerProgress.progressDescription == 'OpenBankingCancelled' ||
        data.customerProgress.progressDescription == 'IbisSystemError' ||
        data.customerProgress.progressDescription == 'SystemError') &&
      !data.forceRunAllData
    ) {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.error = true;
      return false;
    }

    //Call allData...
    const resp = await allData(reqParams);
    if (resp && resp.status == 200) {
      this.moduleSuccess();
    } else {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.error = true;
      return false;
    }
  },
  methods: {
    async moduleSuccess() {
      const applicationId = localStorage.getItem('applicationId');
      //If we have an applicationId, it is a part of application process and we need to call product-config on success
      if (applicationId) {
        this.$store.dispatch('navigation/modulePassed', {
          event: 'open_banking_consent_successful',
          page_title: this.moduleName,
        });
      } else {
        //If we come from app, we redirect to ebav manually, without calling product-config
        this.$router.push('/openbankingebav').catch(() => {});
      }
    },
  },
  provide() {
    return {
      onPrimaryButtonClick: this.onRetry,
    };
  },
};
</script>

<style></style>
