<template>
  <base-screen v-if="KBAoption || TLoption">
    <template #main>
      <base-modal v-if="showDialog" @closeModal="showDialog = false">
        <QRCodeModal
          :link="redirectUrl"
          :redirectDocId="redirectDocId"
          :phoneNo="phoneNo"
          :selectedBank="selectedBank"
          @sendSms="sendSms"
        />
      </base-modal>
      <TrueLayerInfo terms-under-content :selectedBank="selectedBank" />
    </template>
    <template #footer>
      <base-button-primary
        v-if="isWebview"
        :disabled="!redirectUrl"
        @clicked="selectOption('trueLayer')"
      >
        Connect to {{ bankName }}
      </base-button-primary>

      <a
        v-else-if="TLoption && !isDesktop"
        :href="redirectUrl"
        target="_blank"
        @click="logEvent"
      >
        <base-button-primary :disabled="!redirectUrl">
          Connect to {{ bankName }}
        </base-button-primary>
      </a>

      <base-button-primary
        v-else-if="TLoption && isDesktop"
        :disabled="!redirectUrl"
        @clicked="handleDesktopClick"
      >
        Connect to {{ bankName }}
      </base-button-primary>

      <div v-if="KBAoption" class="centered m-t-4 m-b-4">
        <base-button-link @clicked="selectOption('KBA')">
          Or answer some questions
        </base-button-link>
      </div>
    </template>
  </base-screen>
</template>

<script>
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import TrueLayerInfo from '../../truelayer/TrueLayerInfo.vue';

import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';
import QRCodeModal from '../../truelayer/QRCodeModal.vue';
import BaseModal from '../../modal/BaseModal.vue';
export default {
    name: 'CISOptionsScreen',
    components: {
        BaseScreen,
        BaseButtonPrimary,
        BaseButtonLink,
        TrueLayerInfo,
        QRCodeModal,
        BaseModal,
    },

    props: {
        KBAoption: {
            type: Boolean,
            default: true,
        },
        TLoption: {
            type: Boolean,
            default: true,
        },

        redirectUrl: {
            type: String,
        },
        selectedBank: {
            type: Object,
            required: true,
        },
        redirectDocId: {
            type: String,
            required: true,
        },
        phoneNo: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDesktop: null,
            showDialog: false,
            pills: [
                'Account number & sort code',
                'Full name',
                'Transactions',
                'Balance',
            ],
            infoItems: [
                {
                    icon: 'consent-bank',
                    text: 'Truelayer have the same high security standards as your bank',
                },
                {
                    icon: 'consent-hide-pin',
                    text: "We won't see or ask for your bank password or login information",
                },
                {
                    icon: 'consent-visibility',
                    text: 'We get read-only access to view your account details and transactions',
                },
                {
                    icon: 'consent-bank-cancel',
                    text: 'You can cancel access to sharing your bank account information at any time',
                },
            ],
            modalIsActive: false,
        };
    },

    computed: {
        bankName() {
            return this.selectedBank.display_name;
        },
        isWebview() {
            const webview =
                localStorage.getItem('webview') == 'true' ? true : false;
            return webview;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.checkScreenWidth();
            window.addEventListener('resize', this.checkScreenWidth);
        });

        // Add an event listener for the resize event
    },
    beforeUnmount() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener('resize', this.checkScreenWidth);
    },
    methods: {
        logEvent() {
            logFirebaseEvent('tl_connect_selected');
        },
        handleDesktopClick() {
            this.logEvent();
            this.showDialog = true;
        },
        checkScreenWidth() {
            this.isDesktop = window.innerWidth >= 1024;
        },
        selectOption(option) {
            if (option == 'KBA') {
                this.$store.dispatch('navigation/startLoading');
            } else {
                logFirebaseEvent('tl_connect_selected');
            }
            this.$emit('updateModule', 'CISOptionsScreen', option);
        },

        openLink(type) {
            let link;

            switch (type) {
                case 'terms':
                    link = 'https://truelayer.com/legal/enduser_tos';
                    break;

                case 'policy':
                    link = 'https://truelayer.com/legal/privacy';
                    break;

                default:
                    link = 'https://truelayer.com';
                    break;
            }

            const webview = localStorage.getItem('webview') == 'true';

            if (webview) {
                window.OpenBrowser.postMessage(link);
            } else {
                window.open(link, '_blank');
            }
        },
        sendValue() {
            this.$emit('updateModule', 'consentIntro');
        },
    },
};
</script>

<style lang="scss" scoped>
.truelayer-decsr {
    margin-bottom: 24px;
}

.truelayer-logo {
    position: absolute;
    top: 12px;
    max-width: 315px;
}

.consent-intro_pill-row {
    flex-basis: 100%;
    align-content: center;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8px;
    row-gap: 8px;
}

.consent-intro_pill-icon {
    margin-right: 2px;
}
.consent-intro_pill-item {
    border: 2px solid #f5faf9;

    align-items: center;
    border-radius: 28px;
    width: auto !important;
    padding: 2px 10px;

    span {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: center;
        white-space: nowrap;
    }
}

.consent-intro_info-block {
    margin-top: 20px;
    margin-bottom: 12px;
    // margin-right: 12px;
    // margin-left: 12px;
    padding: 24px;
    background-color: rgba(122, 159, 248, 0.1);
    border-radius: 16px;
}

.consent-intro_info-item {
    padding-left: 10px;
    align-content: center;
    gap: 16px;

    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0;
    }
}

.consent-info_item-icon {
    align-self: center;
}

.consent-info_footer {
    margin-bottom: 100px;
    p {
        font-size: 12px !important;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(78, 85, 84, 0.8);
        padding: 12px;
        padding-top: 0;
        text-align: center;
        margin-bottom: 0;
    }
    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        cursor: pointer;
    }
}
</style>
