import { sendRequest } from "../config/axios/helper";


export const kbaUrls = {
    IDENTITY_VERIFICATION: {
        url: '/kba-luminate/identity-verification',
        type: 'post',
        reqParams: ['applicationId']
    },

    IDENTITY_QUESTIONNAIRE: {
        url: '/kba-luminate/identity-verification-questionnaire',
        type: 'post',
        reqParams: ['applicationId', 'transactionId', 'questionAnswers']
    },
}


export const KBAIdVerification = async params => {
    return await sendRequest(kbaUrls.IDENTITY_VERIFICATION, params)

};

export const KBAQuestionnaire = async params => {
    return await sendRequest(kbaUrls.IDENTITY_QUESTIONNAIRE, params)

};




