<template>
  <div class="m-b-2">
    <h2 class="base-subtitle">
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'BaseSubtitle',
};
</script>

<style lang="scss" scoped>
h2.base-subtitle {
  font-family: Poppins;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;

  color: $darkestGrey !important;
}
</style>
