import { sendRequest } from "../config/axios/helper";


export const obUrls = {
    SUBMIT_OB_SUMMARY_CHOICE: {
        url: '/openbanking/ob-summary-block',
        type: 'post',
        reqParams: ['applicationId', 'selectedChoice']
    },
    GET_BANK_ACCOUNTS_LIST: {
        url: '/openbanking/bank-accounts/',
        type: 'get',
        reqParams: ['userId']
    },
    ADD_NEW_ACCOUNT: {
        url: '/openbanking/add-new-account',
        type: 'post',
        reqParams: ['applicationId', 'userId']
    }
}



export const submitOBSummaryChoice = async params => {
    return await sendRequest(obUrls.SUBMIT_OB_SUMMARY_CHOICE, params);
};


export const bankAccountsList = async params => {

    const reqObj = obUrls.GET_BANK_ACCOUNTS_LIST;

    // Add the url with passed params to request object
    reqObj.url = obUrls.GET_BANK_ACCOUNTS_LIST.url + params?.userId;
    return await sendRequest(reqObj, params);

};


export const addNewAccount = async params => {
    return await sendRequest(obUrls.ADD_NEW_ACCOUNT, params);
}
