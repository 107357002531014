<template>
  <base-screen>
    <template #main>
      <base-title>What's your {{ country }} address?</base-title>
      <BaseInternationalForm
        :countryCode="countryCode"
        :inputsArray="formInputs"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <base-button-primary
        :disabled="!formIsValid"
        @clicked="sendValue(address)"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import screenMixin from '../../../mixins/screenMixin.js';
import { countryAddressFormInputs } from '../../../assets/data/countryAddressFormInputs.js';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseInternationalForm from '../../application/baseInternationalForm.vue';
import countryNames from '../../../json/alpha2codes.json';

export default {
    components: {
        BaseScreen,
        BaseButtonPrimary,
        BaseTitle,
        BaseInternationalForm,
    },
    mixins: [screenMixin({ screenName: 'internationalAddress' })],
    props: {
        countryCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            formInputs: [],
            address: {},
            formIsValid: false,
            countryName: null,
        };
    },
    computed: {
        country() {
            const names = {
                IN: 'Indian',
                US: 'US',
                AU: 'Australian',
            };
            return names[this.countryCode];
        },
    },
    created() {
        this.formInputs = countryAddressFormInputs[this.countryCode]; // fetch form inputs frm external file
    },

    methods: {
        updateForm(address, status) {
            let countryName = this.countryName;

            if (!countryName) {
                countryName = countryNames.find(
                    (el) => el.value === this.countryCode
                ).name;
                this.countryName = countryName;
            }

            // Add country name to the address object
            this.address = {
                ...address,
                country: countryName,
                countryCode: this.countryCode,
            };

            this.formIsValid = status === 'valid';
        },
    },
};
</script>
