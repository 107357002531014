<template>
  <base-screen>
    <template #main>
      <p class="transactions-list_title">
        We think we have identified your rent transactions
      </p>
      <p class="transactions-list_description m-v-4">
        Please select your <strong> current rent payment. </strong>
      </p>
      <TransactionsList
        :items="transactionsList"
        @onSelect="sendValue"
        v-bind="$attrs"
      />
      <!-- <div class="transaction-list_no-transaction">
        <div class="p-v-5 p-h-4">
          <div> -->
      <!-- <div v-if="showManualInputForm">
              <h5 class="text-centered">
                Are you not able to see <br />
                rent payment?
              </h5>
              <base-button-primary
                size="md"
                class="m-t-5"
                @clicked="addTransactionManually"
                >Enter rent info manually
              </base-button-primary>
            </div> -->

      <!-- </div>
        </div>
      </div> -->
    </template>

    <template #footer>
      <div class="centered m-t-4">
        <base-button-link size="md" @clicked="changeAccount"
          >Select another account
        </base-button-link>
      </div>
    </template>
  </base-screen>
</template>

<script>
// import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

import TransactionsList from '../../../components/transactions/list/TransactionsList.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';

export default {
  components: {
    BaseScreen,
    TransactionsList,

    // BaseButtonPrimary,
    BaseButtonLink,
  },
  name: 'TransactionsListScreen',
  props: {
    transactionsList: {
      type: Array,
      required: true,
    },
    selectedBankName: {
      tyoe: String,
      required: true,
    },
    showManualInputForm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sendValue(transactionId) {
      this.$emit('updateForm', 'transactionsList', transactionId);
    },
    changeAccount() {
      this.$emit('changeAccount');
    },
    addTransactionManually() {
      this.$emit('addTransactionManually');
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions-list_title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}

.transactions-list_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
