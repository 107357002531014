<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-subtitle>1. What is your employment status?</base-subtitle>
      <div v-for="option in employmentOptions" :key="option.value">
        <div
          class="base-list-item_container"
          :class="{ active: employmentStatus === option.value }"
          @click="selectEmploymentStatus(option.value)"
        >
          <div class="base-list-item">
            {{ option.label }}
          </div>
          <div class="base-list-icon">
            <BaseIcon
              :icon="
                employmentStatus === option.value
                  ? 'check_green'
                  : 'arrow-right_lightgrey'
              "
              :size="employmentStatus === option.value ? 'sm' : 'md'"
            />
          </div>
        </div>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseSubtitle from '../../layout/BaseSubtitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseIcon from '../../assets/BaseIcon.vue';

const EMPLOYMENT_OPTIONS = [
  { label: 'Employed full-time', value: 'employedFullTime' },
  { label: 'Employed part-time', value: 'employedPartTime' },
  { label: 'Unemployed', value: 'unemployed' },
  { label: 'Disability benefits', value: 'disabilityBenefits' },
  { label: 'Retired', value: 'retired' },
  { label: 'Self-employed', value: 'selfEmployed' },
  { label: 'Benefits', value: 'benefits' },
];

export default {
  name: 'IncomeScreen',
  components: {
    BaseScreen,
    BaseSubtitle,
    BaseIcon,
  },
  data() {
    return {
      employmentOptions: EMPLOYMENT_OPTIONS,
      employmentStatus: null,
      selectedByDefault: null,
    };
  },

  created() {
    const applicationData = this.$store.getters['application/applicationData'];

    if (applicationData) {
      const employmentStatus = applicationData.employmentStatus;
      if (employmentStatus) {
        const selectedOption = EMPLOYMENT_OPTIONS.find(
          (el) => el.value == employmentStatus
        );

        if (selectedOption) {
          this.selectedByDefault = selectedOption;
        }
      }
    }
  },

  methods: {
    selectEmploymentStatus(option) {
      this.$store.dispatch('application/updateApplicationStore', {
        employmentStatus: option,
      });

      this.employmentStatus = option;
      this.$emit('updateForm', 'employmentStatus', this.employmentStatus);
    },
  },
};
</script>
