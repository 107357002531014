<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-3">
        <div class="flex-row center ob-summary-block_image">
          <container-sized width="60%">
            <BaseImage image="fail-illustration" />
          </container-sized>
        </div>

        <h2 class="text-centered">We need more information</h2>
        <div class="m-t-4">
          <p class="base-text text-centered">
            We need you to connect more accounts to be able to assess you for a
            credit limit.
          </p>
        </div>

        <div class="ob-summary-block_accounts" v-if="banksList.length > 0">
          <p class="bolc">Accounts added</p>

          <div class="m-t-4 m-b-4">
            <BaseList :items="banksList" maxHeight="100px" padding="6px" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary @clicked="submitChoice('linkNewAccount')">
        Add another account
      </base-button-primary>
      <base-button-secondary
        class="m-t-2"
        @clicked="submitChoice('noMoreAccounts')"
      >
        I don't have more accounts
      </base-button-secondary>
    </template>
  </base-screen>
</template>

<script>
import BaseList from '../../../components/list/BaseList.vue';
import ContainerSized from '../../../components/layout/ContainerSized.vue';
import BaseImage from '../../../components/assets/BaseImage.vue';
import BaseScreen from '../../../components/layout/BaseScreen.vue';
import BaseButtonPrimary from '../../../components/buttons/BaseButtonPrimary.vue';
import BaseButtonSecondary from '../../../components/buttons/BaseButtonSecondary.vue';

export default {
  name: 'MoreAccountsNeeded',
  components: {
    BaseScreen,
    BaseImage,
    ContainerSized,
    BaseButtonPrimary,
    BaseButtonSecondary,
    BaseList,
  },
  props: {
    banksList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    submitChoice(choice) {
      if (choice == 'noMoreAccounts') {
        this.$store.dispatch('navigation/updateLoaderAnimationType', 'de');
      }
      this.$store.dispatch('navigation/startLoading');
      this.$emit('submitChoice', choice);
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-summary-block_image {
  margin-top: -30px;
}
.ob-summary-block {
  height: 100%;
}
.ob-summary-block_accounts {
  margin-top: 30px !important;
}
</style>
