<template>
  <ErrorKBA v-if="errorScreen" />
  <base-screen v-else-if="!isLoading && activeQuestion">
    <template #main>
      <div class="question-header">
        <p>
          {{ activeQuestion.questionId + questionsPassed }}.
          {{ activeQuestion.questionHeader }}
        </p>
      </div>
      <div class="question-text">
        <p>{{ activeQuestion.questionText }}</p>
      </div>

      <div
        v-for="choice in activeQuestion.choices"
        :class="{ selected: choice.choiceId == activeQuestion.answer }"
        :key="choice.choiceId"
        class="choice-block"
        @click="selectAnswer(choice.choiceId)"
      >
        <p>{{ choice.choiceText }}</p>
        <BaseIcon
          :icon="
            choice.choiceId == activeQuestion.answer
              ? 'arrow-right_black'
              : 'arrow-right_lightgrey'
          "
          size="md"
        />
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
import BaseScreen from '../layout/BaseScreen.vue';
import { KBAQuestionnaire, KBAIdVerification } from '../../controllers/kba.js';
import ErrorKBA from '../screens/error/ErrorKBA.vue';
export default {
  components: { BaseScreen, BaseIcon, ErrorKBA },
  name: 'KBAModule',
  data() {
    return {
      errorScreen: false,
      questionsSet: [],
      questionsSetAnswers: [],
      activeQuestion: null,
      transactionId: null,
      questionsPassed: 0,
    };
  },

  async created() {
    const applicationId = localStorage.getItem('applicationId');

    const identityVerification = await KBAIdVerification({ applicationId });

    if (!identityVerification.status) {
      this.$router.replace('/error');
      return false;
    }

    const data = identityVerification.data;

    await this.proceedKbaResult(data);
    this.$store.dispatch('navigation/stopLoading');
  },

  methods: {
    async proceedKbaResult(data) {
      if (data.status == 'failed') {
        this.errorScreen = true;
        return false;
      }

      if (data.status == 'pass') {
        this.$store.dispatch('navigation/modulePassed', {
          event: 'kba_successful',
          page_title: this.moduleName,
        });
        return false;
      }

      if (data.status == 'challenge') {
        const questions = data.questionnaires;
        questions.map((el) => (el.answer = null));
        this.questionsSet = questions;

        this.transactionId = data.transactionId;
        this.activeQuestion = data.questionnaires[0];
        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
      }
    },

    async selectAnswer(answer) {
      const index = this.questionsSet.findIndex(
        (el) => el.questionId == this.activeQuestion.questionId
      );

      if (index !== -1) {
        this.questionsSet[index].answer = answer;

        if (index + 1 < this.questionsSet.length) {
          this.activeQuestion = this.questionsSet[index + 1];
        } else {
          this.$store.dispatch('navigation/startLoading');
          const answers = this.questionsSet.map((question) => ({
            questionId: question.questionId,
            answer: [question.answer],
          }));

          const applicationId = localStorage.getItem('applicationId');

          const payload = {
            applicationId,
            transactionId: this.transactionId,
            questionAnswers: answers,
          };

          const checkAnswers = await KBAQuestionnaire(payload);

          if (!checkAnswers.status) {
            this.$router.replace('/error');
            return false;
          }

          this.questionsPassed = this.questionsSet.length;
          this.proceedKbaResult(checkAnswers.data);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-header {
  margin-bottom: 24px;

  p {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: -0.32px;
  }
}

.question-text {
  margin-bottom: 32px;
  p {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 22.4px; /* 140% */
    letter-spacing: -0.32px;
  }
}

.choice-block {
  padding: 18px 20px;
  border-radius: 8px;
  border: 2px solid #f6f8f8;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.15s;

  &:hover,
  &:active {
    border-color: $middleGrey;
  }
  &.selected {
    border-color: $darkestGrey;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }
}
</style>
