<template>
    <KBAModule v-if="showKBA" key="KBA" />
    <CISIdVerificationFail v-else-if="showMonitorError" @kbaClick="runKBA" />
    <ErrorOB v-else-if="showError" :errorCode="errorCode" @clicked="tryAgain" />
</template>

<script>
import {
    logFirebaseEvent,
    setAnalyticsUserId,
} from '../../controllers/analytics/firebase';
import {
    trueLayerConsentCompleted,
    trueLayerRawDataPullCompleted,
} from '../../controllers/truelayer';
import { useRoute } from 'vue-router';
import { LocalStorage } from '../../utils/localStorage';
import { db } from '../../utils/firestore';
import { Firestore } from '../../utils/firestore';
import ErrorOB from '../screens/error/ErrorOB.vue';
import { doc, getDoc } from 'firebase/firestore';
import CISIdVerificationFail from '../screens/idVerification/CISIdVerificationFail.vue';
import KBAModule from './KBAModule.vue';

export default {
    name: 'TrueLayerConsentComplete',
    components: { ErrorOB, CISIdVerificationFail, KBAModule },
    data() {
        return {
            showAnimation: false,
            providerId: null,
            showError: false,
            errorCode: null,
            showMonitorError: false,
            showKBA: false,
        };
    },

    async mounted() {
        this.$store.dispatch(
            'navigation/updateLoaderAnimationText',
            'Securely connecting to your bank account. This should only take a moment.'
        );

        const route = useRoute();
        const url = route.fullPath;

        if (url.split('?').length <= 1) {
            this.$router.push('/error');
            return false;
        }

        const queryParams = url.split('?')[1].split('&');
        const extractedData = {};

        queryParams.forEach((param) => {
            const [key, value] = param.split('=');
            extractedData[key] = decodeURIComponent(value);
        });

        const error = extractedData['error'];

        if (error) {
            this.$store.dispatch(
                'error/setErrorMessage',
                "It looks like you haven't completed connecting your bank account. To proceed, please try connecting your account again or contact our support team if you need help."
            );
            this.$store.dispatch(
                'error/setErrorTitle',
                'We could not verify your account'
            );
            this.$store.dispatch('error/setErrorImage', 'fail-illustration');

            logFirebaseEvent('tl_bank_consent_cancelled');

            this.$router.push('/error');
            return false;
        }

        const code = extractedData['code'];
        const stateParams = extractedData['state'].split('&');

        let applicationId = null;
        let verificationId = null;
        let token = null;
        let userId = null;
        let consentId = null;
        let extendConnection = false;

        stateParams.forEach((param) => {
            const [key, value] = param.split('=');

            if (key === 'applicationId') {
                if (value !== 'undefined') {
                    applicationId = value;
                }
            } else if (key === 'providerId') {
                this.providerId = value;
            } else if (key === 'verificationId') {
                verificationId = value;
            } else if (key === 'token') {
                token = value;
            } else if (key === 'userId') {
                userId = value;
            } else if (key === 'consentId') {
                consentId = value;
            } else if (key === 'extendConnection') {
                extendConnection = value;
            }
        });

        logFirebaseEvent('tl_bank_consent_granted');

        let currentUserId = null;

        if (verificationId) {
            localStorage.setItem('verificationId', verificationId);
        }
        if (applicationId) {
            localStorage.setItem('applicationId', applicationId);

            if (applicationId !== 'undefined') {
                const applicationDataReq = await this.$store.dispatch(
                    'application/getApplicationRecord',
                    { applicationId }
                );

                const applicationData = applicationDataReq.data;
                localStorage.setItem('productName', applicationData.product);
            }
        }
        if (token) {
            localStorage.setItem('token', token);
            const timestamp = Date.now() + 1000 * 60 * 55;
            localStorage.setItem('tokenExpiration', timestamp);

            const authReq = await this.$store.dispatch('auth/trySignIn');

            //  If success - rewrite currentUserId
            if (authReq.status) {
                currentUserId = authReq.data.uid;
                localStorage.setItem('token', token);
                // Set tokenExpiration time - current time + 55min
                const timestamp = Date.now() + 1000 * 60 * 55;
                localStorage.setItem('tokenExpiration', timestamp);
                setAnalyticsUserId(currentUserId);
            }
        }

        // Make sure applicationId wasn't overwritten by the previous step
        if (applicationId) {
            localStorage.setItem('applicationId', applicationId);
        }

        const payload = {
            userId,
            consentCode: code,
            consentId,
            applicationId,
        };

        const consentCompletedRequest = await trueLayerConsentCompleted(
            payload
        );

        if (!consentCompletedRequest.status) {
            const friendlyMessage = consentCompletedRequest.friendlyMessage;

            const product = localStorage.getItem('productName');
            if (product == 'pillarMonitor') {
                this.showMonitorError = true;
                this.$store.dispatch('navigation/stopLoading');
                return false;
            }
            if (
                friendlyMessage ==
                'It looks like the information on the accounts you have connected do not match the details in your application.'
            ) {
                this.errorCode = 'no_idVerified_accounts';
                this.showError = true;
                this.$store.dispatch('navigation/stopLoading');
                return false;
            } else if (
                friendlyMessage == 'Please connect your primary current account'
            ) {
                this.errorCode = 'tl_no_transaction_accounts';
                this.showError = true;
                this.$store.dispatch('navigation/stopLoading');
                return false;
            } else {
                this.$router.push('/error');
                return false;
            }
        }

        logFirebaseEvent('tl_bank_consent_success');

        const isApplication = localStorage.getItem('applicationId');

        if (isApplication) {
            this.$store.dispatch('navigation/modulePassed', {
                moduleName: 'trueLayerConsent',
                page_title: 'OBConsentComplete',
            });
        } else {
            if (extendConnection == 'true') {
                window.ReconsentComplete.postMessage('Success');
            } else {
                window.AddedNewBankAccount.postMessage('Success');
            }
        }
    },
    methods: {
        tryAgain() {
            this.$store.dispatch('updateLoaderAnimationType', 'default');
            this.$store.dispatch('navigation/startLoading');
            this.$router.push('/');
        },
        runKBA() {
            this.showKBA = true;
        },
    },
};
</script>
