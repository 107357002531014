<template>
  <ConsentBankSelect
    v-if="activeScreen == 'consentBankSelect'"
    key="consentBankSelect"
    @updateModule="updateModule"
  />
  <CISOptionsScreen
    v-else-if="activeScreen == 'CISOptionsScreen'"
    key="CISOptionsScreen"
    :KBAoption="KBAoption"
    :TLoption="TLoption"
    :redirectUrl="redirectUrl"
    :selectedBank="selectedBank"
    :phoneNo="phoneNo"
    :redirectDocId="redirectDocId"
    @updateModule="updateModule"
  />

  <KBAModule v-else-if="activeScreen == 'KBA'" key="KBA" />
</template>

<script>
import ConsentBankSelect from '../screens/truelayer/ConsentBankSelect.vue';
import { logFirebaseEvent } from '../../controllers/analytics/firebase';
import CISOptionsScreen from '../screens/idVerification/CISOptionsScreen.vue';
import { Firestore } from '../../utils/firestore';
import { getTrueLayerConsentUrl } from '../../controllers/truelayer';
import KBAModule from './KBAModule.vue';

export default {
    components: {
        CISOptionsScreen,
        ConsentBankSelect,
        KBAModule,
    },
    data() {
        return {
            isLoading: true,
            KBAoption: false,
            TLoption: false,
            selectedBank: false,
            redirectUrl: false,
            phoneNo: null,
            redirectDocId: null,
        };
    },
    computed: {
        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];

            if (!activeScreen) {
                return 'consentBankSelect';
            } else {
                return activeScreen;
            }
        },
    },

    async created() {
        const applicationId = localStorage.getItem('applicationId');

        const optionsReq = await this.$store.dispatch(
            'navigation/getNextStep',
            {
                applicationId,
            }
        );

        if (!optionsReq.status) {
            return false;
        }

        // Check if all options are avaliable
        const options = optionsReq.data.options;

        if (options.includes('KBA')) {
            this.KBAoption = true;
        }
        if (options.includes('trueLayer')) {
            this.TLoption = true;
        }

        const userId = this.$store.getters['auth/userId'];
        const user = await Firestore.getDoc('Users', userId);
        const phoneNo = user.phoneNo;
        this.phoneNo = phoneNo;

        this.$store.dispatch('navigation/stopLoading');
        this.isLoading = false;
    },
    methods: {
        updateModule(id, data) {
            switch (id) {
                case 'CISOptionsScreen':
                    logFirebaseEvent(`${data}_chosen`);
                    if (data == 'trueLayer') {
                        this.redirect();
                    }

                    if (data == 'KBA') {
                        this.$store.dispatch('navigation/updateScreen', 'KBA');
                    }
                    break;

                case 'consentBankSelect':
                    logFirebaseEvent('trueLayer_chosen');
                    this.getConsentLink(data);
                    break;
            }
        },

        async redirect() {
            window.OpenBrowser.postMessage(this.redirectUrl);
        },
        async getConsentLink(bank) {
            this.selectedBank = bank;
            this.redirectUrl = null;

            const userId = this.$store.getters['auth/userId'];
            const verificationId = localStorage.getItem('verificationId');
            const applicationId = localStorage.getItem('applicationId');
            const providerId = bank.provider_id;
            if (!userId || !verificationId || !providerId) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/updateScreen', 'CISOptionsScreen');

            const webview =
                localStorage.getItem('webview') == 'true' ? true : false;

            const payload = {
                userId,
                verificationId,
                providerId,
                webview,
            };

            if (applicationId) {
                payload.applicationId = applicationId;
            }

            const result = await getTrueLayerConsentUrl(payload);

            if (!result.status) {
                return false;
            }

            const redirectUrl = result.data.result;
            this.redirectUrl = redirectUrl;
            this.redirectDocId = result.data.redirectDocId;
        },
    },
};
</script>

<style></style>
