<script>
import { trueLayerAIVAffordabilityPolicyRules } from '../../controllers/decision-engine';
export default {
  name: 'TrueLayerAIVAffordabilityPolicyRules',

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    const applicationId = localStorage.getItem('applicationId');
    const userId = this.$store.getters['auth/userId'];

    const payload = {
      userId,
      applicationId,
    };

    const response = await trueLayerAIVAffordabilityPolicyRules(payload);

    if (!response.status) {
      this.$router.push('/error');
      return false;
    }

    this.$store.dispatch('navigation/modulePassed', {
      event: null,
      page_title: 'TrueLayerAIVAffordabilityPolicyRules',
    });
  },
};
</script>
