<script>
import { setOBConsentStepToPassed } from '../../../controllers/ob';

export default {
  data() {
    return {
      loading: true,
      moduleName: 'openBankingConsent',
    };
  },

  async created() {
    this.$store.dispatch('navigation/hideProgessBar');
    this.$store.dispatch('navigation/loadingTextUpdate', null);
    this.$store.dispatch('navigation/moduleLoadingStart');

    const applicationId = localStorage.getItem('applicationId');

    const webview = localStorage.getItem('webview');

    if (!applicationId && webview) {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.$router.push('/allData').catch(() => {});
      return true;
    }

    let userId = this.$store.getters['auth/userId'];

    if (!userId) {
      const authReq = await this.$store.dispatch('auth/trySignIn');
      if (authReq.status) {
        userId = authReq.data.uid;
      } else {
        this.$router.push('/obfail');
        return false;
      }
    }

    const resp = await setOBConsentStepToPassed();

    if (!resp.status) {
      return false;
    }
    this.$store.dispatch('navigation/firstRouteStateCancel');
    localStorage.removeItem('obsuccess');
    this.$store.dispatch('navigation/modulePassed', {
      event: null,
      page_title: this.moduleName,
    });
  },
};
</script>

<style></style>
