<template>
  <BaseIntroScreen
    v-if="activeScreen == 'introScreen'"
    description="To complete your application, please provide a bit of information about your income and expenses."
    title="Employment and Expenditure"
    @updateForm="updateForm"
  />
  <EmploymentStatus
    v-else-if="activeScreen == 'employmentStatus'"
    key="employmentStatus"
    @updateForm="updateForm"
  />
  <CompanyName
    v-else-if="activeScreen == 'companyName'"
    key="companyName"
    @updateForm="updateForm"
  />
  <IncomeScreen
    v-if="activeScreen == 'incomeScreen'"
    key="incomeScreen"
    :index="isEmployed ? 3 : 2"
    :employmentStatus="formDetails.employmentStatus"
    @updateForm="updateForm"
  />
  <Expenses
    v-if="activeScreen == 'expenses'"
    key="expenses"
    :index="isEmployed ? 4 : 3"
    @updateForm="updateForm"
  />

  <FinancialDependents
    v-if="activeScreen == 'financialDependents'"
    key="financialDependents"
    :index="isEmployed ? 5 : 4"
    @updateForm="updateForm"
  />
</template>

<script>
import FinancialDependents from '../screens/expenditure/FinancialDependents.vue';
import IncomeScreen from '../screens/employmentDetails/IncomeScreen.vue';
import CompanyName from '../screens/employmentDetails/CompanyName.vue';
import EmploymentStatus from '../screens/employmentDetails/EmploymentStatus.vue';
import BaseIntroScreen from '../layout/BaseIntroScreen.vue';
import Expenses from '../screens/expenditure/ExpensesForm';

export default {
  name: 'EmploymentAndExpenditure',
  components: {
    EmploymentStatus,
    CompanyName,
    IncomeScreen,
    BaseIntroScreen,
    FinancialDependents,
    Expenses,
  },

  data() {
    return {
      formDetails: {
        annualIncome: null,
        employmentStatus: null,
        employerName: null,
        financialDependents: null,
        expenses: null,
      },
    };
  },

  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];
      if (!activeScreen) {
        return 'introScreen';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
    isEmployed() {
      const employedStatuses = [
        'employedFullTime',
        'employedPartTime',
        'selfEmployed',
      ];

      return employedStatuses.includes(this.formDetails.employmentStatus);
    },
  },
  mounted() {
    this.$store.dispatch('navigation/stopLoading');
  },

  methods: {
    async updateForm(id, value) {
      this.formDetails[id] = value;
      const updatedData = { [id]: value };
      this.$store.dispatch('application/updateApplicationData', updatedData);

      switch (id) {
        case 'introScreen':
          this.$store.dispatch('navigation/updateScreen', 'employmentStatus');
          break;

        case 'employmentStatus':
          return this.isEmployed
            ? this.$store.dispatch('navigation/updateScreen', 'companyName')
            : this.$store.dispatch('navigation/updateScreen', 'incomeScreen');

          break;
        case 'employerName':
          this.$store.dispatch('navigation/updateScreen', 'incomeScreen');
          break;

        case 'annualIncome':
          this.$store.dispatch('navigation/updateScreen', 'expenses');
          break;

        case 'expenses':
          this.$store.dispatch(
            'navigation/updateScreen',
            'financialDependents'
          );
          break;
        case 'financialDependents':
          this.sendForm();
          break;

        default:
          // Handle any other cases or do nothing
          break;
      }
    },

    async sendForm() {
      this.$store.dispatch('navigation/startLoading');
      // this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);
      const data = this.formDetails;
      if (!this.isEmployed) {
        data.employerName = null;
      }

      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data, step: 'employmentAndExpenditure' }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'employment_details_entered',
        page_title: 'employmentAndExpenditure',
      });
    },
  },
};
</script>
