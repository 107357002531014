<template>
  <base-screen contentCentered v-if="link">
    <template #main>
      <div class="flex-row center">
        <div class="bank-connect-img-wrap">
          <BaseImage image="connect-bank-desktop" />
          <div class="bank-logo">
            <BaseImage
              :image="providerId"
              folder="tl-banks"
              :style="{
                transform:
                  providerId !== 'ob-halifax' ? 'scale(0.8)' : 'scale(1)',
              }"
            />
          </div>
        </div>
      </div>

      <div class="m-t-6">
        <h3>Thanks for continuing your Pillar application</h3>
      </div>
      <p class="text-centered m-b-6 m-t-2">
        Click the button below to open the {{ bankName }} app and log in
      </p>
    </template>
    <template #footer>
      <div v-show="bankName">
        <a :href="link" target="_blank">
          <base-button-primary :disabled="!link">
            Connect to {{ bankName }}
          </base-button-primary>
        </a>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../assets/BaseImage.vue';
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import { LIST_OF_BANKS } from '../../assets/data/listOfBanks';
import { getTrueLayerConsentLinkDB } from '../../controllers/truelayer';
import BaseScreen from '../layout/BaseScreen.vue';
export default {
  name: 'ObRedirect',
  components: { BaseScreen, BaseButtonPrimary, BaseImage },
  data() {
    return {
      isLoading: true,
      providerId: null,
      bankName: null,
      link: null,
    };
  },

  async created() {
    this.$store.dispatch('navigation/hideProgessBar');
    this.$store.dispatch('navigation/stopLoading');

    try {
      const providerId = this.$route.query.providerId;
      this.providerId = providerId;

      const selectedBank = LIST_OF_BANKS.find(
        (el) => el.provider_id == providerId
      );

      const bankName = selectedBank.display_name;
      this.bankName = bankName;

      const id = this.$route.query.id;
      const userId = this.$route.query.userId;

      const getRedirectLink = await getTrueLayerConsentLinkDB({
        userId,
        redirectDocId: id,
      });

      if (!getRedirectLink.status) {
        this.$router.push('/error');
        return false;
      }

      const link = getRedirectLink.data.redirectLink;
      this.link = link;
    } catch (error) {
      this.$router.push('/error');
    }
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 26.4px */
  letter-spacing: -0.4px;
  margin: 4px;
}

p {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.14px;
}

.bank-connect-img-wrap {
  position: relative;

  .bank-logo {
    position: absolute;
    height: 47px;
    top: 68px;
    right: 34px;
    width: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
</style>
