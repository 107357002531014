<template>
  <div>
    <div v-if="!showAnimation" class="whiteBg"></div>
    <div class="lottie-animation_wrap centered">
      <div>
        <div
          ref="lottieContainer"
          class="lottie-animation"
          :class="{ 'transition-fade': true, show: showAnimation }"
        ></div>

        <transition name="fade">
          <h5 v-if="showAnimation" class="text-centered consentComplete_title">
            We are processing your payment. Please do not close or refresh the
            page.
          </h5>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { trueLayerGetPayment } from '../../controllers/truelayer';
import lottie from 'lottie-web';
export default {
  name: 'TrueLayerPayment',
  data() {
    return {
      animationFileName: 'defaultBank.json',
      showAnimation: false,
      providerId: null,
    };
  },

  async created() {
    this.$store.dispatch('navigation/hideProgessBar');

    const query = this.$route.query;

    const paymentId = query?.paymentId;
    const token = query?.token;
    const userId = query?.userId;
    const amount = query?.amount;
    const providerId = query?.providerId;

    if (providerId && providerId !== 'undefined') {
      this.providerId = providerId;
    }

    const env =
      process.env.VUE_APP_ENV === 'production' ? 'PRODUCTION' : 'STAGING';

    const baseUrl = process.env[`VUE_APP_WEBVIEW_REDIRECT_BASE_URL_${env}`];
    const redirectUrl = `${baseUrl}/PISComplete?amount=${amount}&userId=${userId}&paymentStatus=`;

    if (!paymentId || !token || !userId) {
      window.location.href = `${redirectUrl}error`;
      return false;
    }

    localStorage.setItem('token', token);

    const payload = {
      userId,
      paymentId,
    };

    let index = 0;

    const checkPaymentStatus = async () => {
      const paymentProgressReq = await trueLayerGetPayment(payload);

      if (!paymentProgressReq) {
        window.location.href = `${redirectUrl}error`;
        return false;
      }

      const paymentStatus = paymentProgressReq.data.paymentStatus;
      const finalStatuses = ['executed', 'settled', 'failed'];

      if (finalStatuses.includes(paymentStatus)) {
        window.location.href = `${redirectUrl}${paymentStatus}`;
      } else {
        index++;

        if (index <= 10) {
          //  If it is not, it means that the payment is processing and  we need to check the step again in 3s
          setTimeout(checkPaymentStatus, 500);
        } else {
          window.location.href = `${redirectUrl}${paymentStatus}`;
        }
      }
    };
    await checkPaymentStatus();
  },
  methods: {
    async loadAnimation() {
      const providerId = this.providerId;

      let animationData;

      try {
        animationData = await import(
         `../../../public/lottie-animation/banks/${providerId}`
        );
      } catch (err) {
        animationData = await import(
          `../../../public/lottie-animation/banks/default.json`
        );

      }

      const container = this.$refs.lottieContainer;

      // Load the animation without autoplay
      const animation = lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: true,
        autoplay: false, // Set autoplay to false
        animationData: animationData.default,
      });

      // Pause the animation immediately
      animation.pause();

      // Delay before playing the animation
      const delay = 500;
      setTimeout(() => {
        this.showAnimation = true;
        animation.play();
      }, delay);
    },
  },
};
</script>

<style lang="scss" scoped>
.lottie-animation {
  width: 100%;
}

.whiteBg {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
}

.lottie-animation_wrap {
  width: 100%;
  height: calc(100% - 56px);
}

.consentComplete_title {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.transition-fade {
  opacity: 0;
  transition: opacity 0.5s;
}

.transition-fade.show {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
