<template>
  <CardApproved
    v-if="cardProductType == 'unsecured' || cardProductType == 'secured'"
    :finalBlockDecision="finalBlockDecision"
    :cardProductType="cardProductType"
    v-bind="$attrs"
  />

  <!-- <CardSecured
    :decision="decision"
    v-if="cardProductType == 'secured'"
    :monthlyFee="monthlyFee"
    v-bind="$attrs"
  />
  <CardUnsecured
    :decision="decision"
    :maxCreditLimitApproved="maxCreditLimitApproved"
    :apr="apr"
    v-else-if="cardProductType == 'unsecured'"
    v-bind="$attrs"
  />
  <CardSecuredUnsecuredDeclined
    v-else-if="cardProductType == 'secured_unsecuredDecline'"
    v-bind="$attrs"
  />
  <CardDeclinedMonitor
    v-else-if="cardProductType == 'decline' && !hasMonitor"
    :applicationCardProduct="applicationCardProduct"
    :declineReason="declineReason"
  />
  <CardDeclinedNoMonitor
    v-else-if="cardProductType == 'decline' && hasMonitor"
    :applicationCardProduct="applicationCardProduct"
    :declineReason="declineReason"
  /> -->
</template>

<script>
import CardApproved from './offerTypes/CardApproved.vue';
// import CardDeclinedNoMonitor from './CardDeclinedNoMonitor.vue';
// import CardDeclinedMonitor from './CardDeclinedMonitor.vue';
// import CardSecured from './CardSecured.vue';
// import CardSecuredUnsecuredDeclined from './CardSecuredUnsecuredDeclined.vue';
// import CardUnsecured from './CardUnsecured.vue';

export default {
  components: {
    // CardSecured,
    // CardUnsecured,
    // CardSecuredUnsecuredDeclined,
    // CardDeclinedMonitor,
    // CardDeclinedNoMonitor,
    CardApproved,
  },
  props: {
    finalBlockDecision: {
      type: String,
      required: true,
    },
    cardProductType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalIsActive: false,
      benefits: [
        {
          icon: 'protection',
          title: 'Purchase protection',
          description: 'Section 75 protection on all purchases above £100.',
        },
        {
          icon: 'statement',
          title: 'Spread the cost of your purchases',
          description:
            '0% APR when paying your statement in full. 34.9% APR if you need more time to pay.',
        },
        {
          icon: 'fees',
          title: 'No fees for spending abroad',
          description:
            'Take your Pillar credit card with you when you travel, and enjoy fee-free usage worldwide.',
        },
        {
          icon: 'score_colored',
          title: 'Improves credit score',
          description:
            'Using your Pillar card responsibly and making timely payments will help improve your credit history.',
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
    // this.$store.dispatch('navigation/hideDesktopBG');
    this.$store.dispatch('navigation/hideProgessBar');
    // this.$store.dispatch('navigation/hideDesktopBG');

    const appBarController = document.querySelector('.app-main_bar');

    if (appBarController) {
      appBarController.style.backgroundColor = 'transparent';
    }
  },
  methods: {
    closeModal() {
      this.modalIsActive = false;
    },
    sendValue() {
      this.$emit('updateModule', 'offerScreen');
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-screen_wrapper {
  position: relative;
  width: 1250px;
  margin: 0 auto;
  height: 635px;
  position: fixed;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  background-color: white;
}
.polygon-bg {
  width: 1280px;
  background-color: #242424;
  margin: 0 auto;
  clip-path: polygon(0 0, 100% 0, 50.2% 100%);
  overflow-x: hidden;
  height: 655px;
  position: fixed;
  top: -58px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.card-container {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;

  background: url('../../assets/img/offer-screen_bg.svg') no-repeat;
  background-size: 360px auto;
  background-position: center top 10px;
  width: 100%;
  height: 100%;
}

.card-row {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  z-index: 8000000;
}

.card {
  width: 208px;
  height: auto;
  z-index: 800000;
}
.fixed-screen_content {
  color: white;
  position: relative;
  z-index: 9;
  height: 100%;
  width: 100%;
}

.fixed-screen-content_bg {
  height: 100%;
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 100;
}

.fixed-screen_content {
  margin: 0 auto;
  padding: 0 20px;
}
.fixed-screen-content_title {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;

  h2,
  span {
    color: #fff !important;
    text-align: center;

    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 140% !important;
    letter-spacing: -0.72px !important;

    &.greenText {
      color: #16e89e !important;
    }
  }

  p {
    color: #fff;
    font-size: 14px;

    font-weight: 400;
    line-height: 160%;
    margin-top: 8px;
  }

  .badge {
    margin-top: 24px;
    display: inline-block;
    padding-right: 16px;
    padding-left: 16px;
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.2);

    color: #fff !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: -0.28px !important;
  }
}

.truelayer-logo {
  margin-top: -50px;
}

.benefits-title {
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 140% !important;
  letter-spacing: -0.4px !important;
  margin-top: 16px;
}
.benefits-button-wrapper {
  margin-top: 24px;
}

.benefits-list {
  p {
    margin: 0;
  }
  margin-bottom: 24px;

  .benefits-list_row {
    width: 100%;

    display: flex;
    padding: 8px;
    gap: 12px;
  }

  .benefit-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: -0.28px;
    margin-bottom: 4px;
  }
}

.benefit-icon {
  width: 48px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fixed-screen-content_title_desc {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.fixedFooter {
  position: fixed;
  height: auto !important;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 600px;
  // max-width: 335px;
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateY(100%);
  box-shadow: 0 -30px 20px 0 rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.fixedFooter_content {
  width: 100%;
  padding-right: 40px;
}
@media (min-width: 905px) {
  .fixedFooter_content {
    padding-right: 64px;
  }
}
.slide-up {
  transform: translateY(0);
}

.footer-slide-enter-active {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.footer-slide-enter {
  transform: translateY(0);
}

.footer-slide-leave-active {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.footer-slide-leave {
  transform: translateY(100%);
}

.fixedFooter_content {
  width: 100%;
}
</style>
