<template>
  <button
    type="button"
    class="button-secondary"
    :class="{ 'btn-active': !atBottom, 'btn-hidden': atBottom }"
    @click="click"
  >
    <div class="btn-row">
      <BaseIcon icon="arrow-down_grey" size="sm" />
      Scroll to the bottom
    </div>
  </button>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';

export default {
  name: 'ScrollDocButton',
  components: {
    BaseIcon,
  },
  data() {
    return {
      atBottom: null,
    };
  },
  watch: {
    atBottom(newVal) {
      this.$emit('scrollPositionChanged', newVal);
    },
  },
  mounted() {
    const container = document.querySelector('.main-content');

    if (container) {
      container.addEventListener('scroll', this.checkScroll);
      this.checkScroll();
    }
  },

  beforeUnmount() {
    const container = document.querySelector('.main-content');
    if (container) {
      container.removeEventListener('scroll', this.checkScroll);
    }
  },

  methods: {
    checkScroll() {
      const container = document.querySelector('.main-content');
      if (container) {
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        const scrollTop = container.scrollTop;
        const maxScrollTop = scrollHeight - clientHeight;

        if (scrollTop !== 0) {
          this.atBottom = scrollTop + 50 >= maxScrollTop;
        } else {
          this.atBottom = false;
        }
      }
    },

    click() {
      const container = document.querySelector('.doc-container');
      if (container) {
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;

        this.scrollPosition = maxScrollTop;

        const lastChild = container.lastElementChild;
        lastChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }

      this.$nextTick(() => {
        this.checkScroll();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  border-radius: 8px;
  background-color: transparent;
  color: $darkestGrey;
  height: 40px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  cursor: pointer !important;
  position: relative;
  z-index: 1000;
  visibility: visible;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.btn-row {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.btn-active {
  opacity: 1;
  transform: translateY(0);
}

.btn-hidden {
  opacity: 0;
  transform: translateY(20%);
}
</style>
