<template>
  <div>
    <div v-if="items.length" class="transaction-list_container">
      <div
        v-for="item of items"
        :key="item.id"
        class="m-b-2 transaction-list_item"
      >
        <TransactionListItem :item="item" v-bind="$attrs" />
      </div>
    </div>
    <TransactionListItem
      :item="{
        description: 'Enter info manually',
        subDescription: 'None of these are my rent transactions',
        noImage: true,
        id: 'enterRentManually',
      }"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import TransactionListItem from './TransactionListItem.vue';

export default {
  name: 'TransactionList',
  components: { TransactionListItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
