<template>
  <CardApprovedDesktop v-if="isDesktop" v-bind="$attrs" />
  <CardApprovedMobile v-else v-bind="$attrs" />
</template>

<script>
import CardApprovedDesktop from './cardApproved/CardApprovedDesktop.vue';
import CardApprovedMobile from './cardApproved/CardApprovedMobile.vue';

export default {
  name: 'CardApproved',
  components: { CardApprovedDesktop, CardApprovedMobile },
  data() {
    return {
      isDesktop: null,
    };
  },
  mounted() {
    this.checkScreenWidth(); // Initial check when component is mounted
    window.addEventListener('resize', this.checkScreenWidth); // Add listener to window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth); // Clean up listener when component is destroyed
  },
  methods: {
    checkScreenWidth() {
      this.isDesktop = window.innerWidth >= 1024;
    },
  },
};
</script>
