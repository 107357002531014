<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="fail-illustration" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <transition
            appear
            enter-active-class="animate__animated animate__fadeIn"
          >
            <h1 class="text-centered header-1">
              {{ header }}
            </h1>
          </transition>
          <div class="m-t-6 m-b-4 grey declined text-centered">
            <div>{{ body }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="actionButton">
        <base-button-primary @clicked="goBack">
          {{ buttonText }}
        </base-button-primary>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../UI/elements/layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'ErrorScreen',
  components: {
    ContainerSized,
    BaseScreen,
    BaseImage,
    BaseButtonPrimary,
  },
  data() {
    return {
      isLoading: true,
      reason: '',
      formattedReason: null,
      product: localStorage.getItem('productName'), 
    };
  },

  computed: {
    header() {
      if(this.product == 'rentReportingAndMonitor') {
        return 'It looks like you already have an account with us';
      }
      return 'Application Declined';
    },
    body () {
      if(this.product == 'rentReportingAndMonitor') {
        return 'Please contact customer support to resolve this.';
      }
      return 'Unfortunately your application does not meet our lending criteria at this time.';
    },
    buttonText() {
      if(this.product == 'rentReportingAndMonitor') {
        return 'Contact customer support';
      }
      return 'Okay, got it';
    },
  },

  async created() {
    this.$store.dispatch('navigation/hideProgessBar');

    const userId = this.$store.getters['auth/userId'];

    const applicationId = localStorage.getItem('applicationId');

    if (!userId || !applicationId) {
      this.$router.push('/error');
      return false;
    }

    this.isLoading = false;
    this.$store.dispatch('navigation/stopLoading');
  },

  methods: {
    goBack() {

      if(this.product == 'rentReportingAndMonitor') {
        window.Intercom('showNewMessage');
        return;
      }
      const webview = localStorage.getItem('webview') == 'true';
      if (webview) {
        window.App.postMessage('Back');
      } else {
        window.location.href = 'https://hellopillar.com/';
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/app.scss';

.decline-reason-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;

  color: $darkGrey;

  a {
    font-weight: 500 !important;
    font-size: 14px !important;
    position: relative;
    text-align: center;
    color: $darkGrey !important;
    text-decoration: none !important;
    cursor: pointer;

    &:after {
      position: absolute;
      height: 2px;
      margin: 0 auto;
      content: '';
      left: 0;
      right: 0;
      background-color: $brand;
      width: 100%;
      left: 0;
      bottom: -3px;
    }
  }
}
</style>
