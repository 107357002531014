import { sendRequest } from "../config/axios/helper";


export const DEUrls = {
    DE_STEPS: {
        url: '/decision-engine/v2/processing-application',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    GET_OB_SUMMARY_DECISION: {
        url: '/decision-engine/ob-summary-block-engine',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    GET_DECLINE_REASON: {
        url: '/decision-engine/v2/decline-reason-block',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    GET_TRANSACTIONS: {
        url: '/decision-engine/de-rent-streams-engine-data',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'accountId']
    },

    // TrueLayer
    CIS_ID_VERIFICATION: {
        url: '/decision-engine/v2/cis-id-verification',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    TL_OBID_VERIFICATION: {
        url: '/decision-engine/v2/ob-id-verification',
        type: 'post',
        reqParams: ['userId']
    },

    TL_OB_SUMMARY_DECISION: {
        url: '/decision-engine/v2/ob-summary-block-decision',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },

    TL_OB_SUMMARY_SUBMIT_CHOICE: {
        url: '/decision-engine/v2/ob-summary-block-submit-choice',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'selectedChoice']
    },
    TL_KYC_PEPS: {
        url: '/decision-engine/v2/kyc-peps-sanctions',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },

    TL_FINAL_DECISION_BLOCK: {
        url: '/decision-engine/v2/final-decision-block',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },

    TL_AIV_AFFORDABILITY_POLICY: {
        url: '/decision-engine/v2/aiv-affordability-policy-rules-block',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    AIV_AFFORDABILITY_POLICY_V2: {
        url: '/decision-engine/v2/aiv-affordability-policy-rules-v2',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },

    FINAL_DECISION_MOCK: {
        url: '/decision-engine/final-decision-block-india',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },

    INDIA_BUREAU_DATA: {
        url: '/decision-engine/indiaBureauData-to-passed',
        type: 'post',
        reqParams: ['applicationId']
    },
}

export const getCISIdVerificationDecision = async params => {
    return await sendRequest(DEUrls.CIS_ID_VERIFICATION, params)
}

export const trueLayerObSummaryDecision = async params => {
    return await sendRequest(DEUrls.TL_OB_SUMMARY_DECISION, params)
};


export const trueLayerObSummarySubmitChoice = async params => {
    return await sendRequest(DEUrls.TL_OB_SUMMARY_SUBMIT_CHOICE, params)
};

export const trueLayerObIdVerificationDecision = async params => {
    return await sendRequest(DEUrls.TL_OBID_VERIFICATION, params)
};


export const trueLayerAIVAffordabilityPolicyRules = async params => {
    return await sendRequest(DEUrls.TL_AIV_AFFORDABILITY_POLICY, params)
};



export const AIVAffordabilityPolicyRulesV2 = async params => {
    return await sendRequest(DEUrls.AIV_AFFORDABILITY_POLICY_V2, params)
};


export const trueLayerKYCPepsSanctions = async params => {
    return await sendRequest(DEUrls.TL_KYC_PEPS, params)
};


export const trueLayerFinalDecisionBlock = async params => {
    return await sendRequest(DEUrls.TL_FINAL_DECISION_BLOCK, params)
};


export const obSummaryDecision = async params => {
    return await sendRequest(DEUrls.GET_OB_SUMMARY_DECISION, params)
};


export const getDeclineReason = async params => {
    return await sendRequest(DEUrls.GET_DECLINE_REASON, params)
}

export const getTransactions = async params => {
    return await sendRequest(DEUrls.GET_TRANSACTIONS, params)
}


export const getFinalDecisionMocked = async params => {
    return await sendRequest(DEUrls.FINAL_DECISION_MOCK, params)
}

export const setIndiaBureauDataToPassed = async params => {
    return await sendRequest(DEUrls.INDIA_BUREAU_DATA, params)
}


export const runprocessingApplication = async params => {
    return await sendRequest(DEUrls.DE_STEPS, params)
}