import { sendRequest } from "../config/axios/helper";

export const userUrls = {
    INITIATE_REDIRECT: {
        url: '/pillar-rails/initiate-redirect',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    RETRIEVE_CREDIT_FILE: {
        url: '/pillar-rails/retrieve-credit-file',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    
    }
}

export const initiatePillarRailsRedirect = async params => {
    return await sendRequest(userUrls.INITIATE_REDIRECT, params);
};

export const retrieveCreditFile = async params => {
    return await sendRequest(userUrls.RETRIEVE_CREDIT_FILE, params);
}