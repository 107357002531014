<template>
  <PersonalDetails
    v-if="activeScreen == 'personalDetails'"
    key="personalDetails"
    @updateModule="updateModule"
  />
  <CurrentAddressSelect
    v-else-if="activeScreen == 'currentAddressSelect'"
    key="currentAddressSelect"
    @showManualAddressForm="showManualAddressForm('current')"
    @updateModule="updateModule"
  />
  <CurrentAddressManual
    v-else-if="activeScreen == 'currentAddressManual'"
    key="currentAddressManual"
    @updateModule="updateModule"
  />
  <CurrentAddressDuration
    v-else-if="activeScreen == 'currentAddressDuration'"
    key="currentAddressDuration"
    @updateModule="updateModule"
  />
  <PreviousAddressSelect
    v-else-if="activeScreen == 'previousAddressSelect'"
    key="previousAddressSelect"
    type="previous"
    @noUkAddress="noUkAddress"
    @showManualAddressForm="showManualAddressForm('previous')"
    @updateModule="updateModule"
  />
  <PreviousAddressManual
    v-else-if="activeScreen == 'previousAddressManual'"
    key="previousAddressManual"
    @updateModule="updateModule"
  />
  <InternationalAddressPrevious
    v-else-if="activeScreen == 'internationalAddressPrevious'"
    key="internationalAddressPrevious"
    @updateModule="updateModule"
  />
  <AddressSummary
    v-else-if="activeScreen == 'addressSummary'"
    key="addressSummary"
    :currentAddress="currentAddress"
    :previousAddress="previousAddress"
    @editAddress="editAddress"
    @removeAddress="removeAddress"
    @submitApplication="submitApplication"
  />
</template>

<script>
import InternationalAddressPrevious from '../screens/applicationData/InternationalAddressPrevious.vue';
import AddressSummary from '../screens/applicationData/AddressSummary.vue';
import PreviousAddressManual from '../screens/applicationData/PreviousAddressManual.vue';
import PreviousAddressSelect from '../screens/applicationData/PreviousAddressSelect.vue';
import CurrentAddressDuration from '../screens/applicationData/CurrentAddressDuration.vue';
import CurrentAddressManual from '../screens/applicationData/CurrentAddressManual.vue';
import CurrentAddressSelect from '../screens/applicationData/CurrentAddressSelect.vue';
import PersonalDetails from '../screens/applicationData/PersonalDetails.vue';

import { logFirebaseEvent } from '../../controllers/analytics/firebase.js';
export default {
  name: 'ApplicationData',
  components: {
    PersonalDetails,
    CurrentAddressSelect,
    CurrentAddressManual,
    CurrentAddressDuration,
    PreviousAddressSelect,
    PreviousAddressManual,
    AddressSummary,
    InternationalAddressPrevious,
  },
  data() {
    return {
      isLoading: false,
      personalDetails: null,
      currentAddress: null,
      previousAddress: null,
      currentAddressDuration: false,
      editingAddress: false,
      initialLoading: true,
    };
  },

  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];

      if (!activeScreen) {
        if (this.$store.getters['navigation/stepPassedBefore']) {
          return 'currentAddressSelect';
        }

        return 'personalDetails';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
  },

  created() {
    const product = localStorage.getItem('productName');
    const event = sessionStorage.getItem('app_event');

    if (!event) {
      sessionStorage.setItem('app_event', product);
      logFirebaseEvent('application_started', { product });
    } else {
      if (event != product) {
        sessionStorage.setItem('app_event', product);
        logFirebaseEvent('application_started', { product });
      }
    }

    this.$store.dispatch('navigation/stopLoading');
  },

  methods: {
    updateModule(id, data) {
      switch (id) {
        case 'personalDetails':
          this.personalDetails = data;
          this.$store.dispatch(
            'navigation/updateScreen',
            'currentAddressSelect'
          );
          break;

        case 'currentAddressSelect':
          this.currentAddress = data;
          this.updateStore(data, 'current');
          this.$store.dispatch(
            'navigation/updateScreen',
            'currentAddressDuration'
          );
          break;
        case 'currentAddressManual':
          this.currentAddress = data;
          this.$store.dispatch(
            'navigation/updateScreen',
            'currentAddressDuration'
          );
          if (this.currentAddressDuration) {
            // this.editingAddress = false;
            this.$store.dispatch('navigation/updateScreen', 'addressSummary');
          } else {
            this.$store.dispatch(
              'navigation/updateScreen',
              'currentAddressDuration'
            );
          }
          break;
        case 'addressDuration':
          this.currentAddressDuration = data;
          if (data == 'moreThanYear') {
            this.$store.dispatch('navigation/updateScreen', 'addressSummary');
          } else {
            this.$store.dispatch(
              'navigation/updateScreen',
              'previousAddressSelect'
            );
          }
          break;

        case 'previousAddressSelect':
          if (this.isDuplicate(data)) {
            this.previousAddress = null;
          } else {
            this.previousAddress = data;
            this.updateStore(data, 'previous');
          }
          this.$store.dispatch('navigation/updateScreen', 'addressSummary');
          break;

        case 'previousAddressManual':
          if (this.isDuplicate(data)) {
            this.previousAddress = null;
          } else {
            this.previousAddress = data;
            this.updateStore(data, 'previous');
          }
          this.$store.dispatch('navigation/updateScreen', 'addressSummary');
          break;

        case 'nonUkAdressManual':
          this.$store.dispatch(
            'navigation/updateScreen',
            'internationalAddressPrevious'
          );
          break;
        case 'internationalAddressPrevious':
          data.country = 'India';
          this.previousAddress = data;
          this.$store.dispatch('navigation/updateScreen', 'addressSummary');
          break;
      }
    },

    isDuplicate(submittedPrevious) {
      let currentAddress = `${this.currentAddress?.buildingNo}${this.currentAddress?.subBuilding}${this.currentAddress?.buildingName}${this.currentAddress?.postCode}`;
      currentAddress = currentAddress.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

      let previousAddress = `${submittedPrevious?.buildingNo}${submittedPrevious?.subBuilding}${submittedPrevious?.buildingName}${submittedPrevious?.postCode}`;
      previousAddress = previousAddress.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

      return currentAddress === previousAddress;
    },

    showManualAddressForm(type) {
      if (type == 'current') {
        this.$store.dispatch('navigation/updateScreen', 'currentAddressManual');
      } else {
        this.$store.dispatch(
          'navigation/updateScreen',
          'previousAddressManual'
        );
      }
    },

    noUkAddress() {
      this.$store.dispatch('navigation/updateScreen', 'addressSummary');
    },

    editAddress(type) {
      this.showManualAddressForm(type);
    },
    removeAddress() {
      this.previousAddress = null;
    },

    async submitApplication() {
      this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
      this.$store.dispatch('navigation/startLoading');

      if (this.$store.getters['navigation/stepPassedBefore']) {
        const data = {
          address: {
            current: this.currentAddress,
          },
        };

        if (this.previousAddress) {
          data.address.previous = this.previousAddress;
        }

        const updateApplicationReq = await this.$store.dispatch(
          'application/updateApplication',
          { data, step: 'applicationData' }
        );

        if (!updateApplicationReq.status) {
          this.$router.push('/error');
          return false;
        }

        this.$store.dispatch('navigation/modulePassed', {
          event: 'address_updated',
          page_title: 'applicationData',
        });

        return false;
      }

      const product = localStorage.getItem('productName');
      const webview = localStorage.getItem('webview') == 'true' ? true : false;

      const applicationData = {
        ...this.personalDetails,
        address: {
          current: this.currentAddress,
        },
        userAgent: navigator.userAgent,
        product,
        webview,
      };

      if (
        this.previousAddress &&
        this.previousAddress.country == 'United Kingdom'
      ) {
        applicationData.address.previous = this.previousAddress;
      }

      const userId = this.$store.getters['auth/userId'];

      if (userId) {
        applicationData.userId = userId;
      }


      // Append leadInfo to applicationData if it exists
      const leadInfo = localStorage.getItem('leadInfo');

      if (leadInfo) {
        applicationData.leadInfo = JSON.parse(leadInfo);
      }

      const applicationResponse = await this.$store.dispatch(
        'application/createApplication',
        applicationData
      );

      if (!applicationResponse.status) {
        this.$store.dispatch('navigation/stopLoading');
        this.$store.dispatch('error/updatePopupErrorStatus', {
          status: true,
          message: applicationResponse.friendlyMessage,
        });
        this.$store.dispatch('navigation/updateScreen', 'personalDetails');

        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'application_created',
        page_title: 'applicationData',
      });
    },

    updateStore(data, type) {
      let updatedData = data;

      const keys = Object.keys(updatedData);

      let newObject = {};

      for (let key of keys) {
        newObject[`${type}${key}`] = updatedData[key];
      }

      this.$store.dispatch('application/updateApplicationStore', newObject);
    },
  },
};
</script>
