<script>
import { experianCrossCore } from '../../controllers/experian';
export default {
  name: 'ExperianCrossCore',
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    const applicationId = localStorage.getItem('applicationId');

    const payload = {
      applicationId,
    };

    const response = await experianCrossCore(payload);

    if (!response.status) {
      this.$router.push('/error');
      return false;
    }

    this.$store.dispatch('navigation/modulePassed', {
      event: null,
      page_title: 'crossCore',
    });
  },
};
</script>
