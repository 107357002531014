<script>
import { jumioToPassed } from '../../controllers/jumio';
export default {
  name: 'jumioSuccess',
  data() {
    return {
      moduleName: 'jumio',
    };
  },
  async mounted() {
    this.$store.dispatch('navigation/moduleLoadingStart');
    this.$store.dispatch('navigation/loadingTextUpdate', null);

    const applicationId = localStorage.getItem('applicationId');

    // Set Jumio to passed
    await jumioToPassed(applicationId);

    this.$store.dispatch('navigation/modulePassed', {
      event: 'jumio_successful',
      page_title: this.moduleName,
    });

    this.$store.dispatch('navigation/moduleLoadingFinish');
  },
};
</script>

<style></style>
