<template>
  <base-screen v-if="errorCode" contentCentered desktopNoBG>
    <template #main>
      <div class="flex-row center">
        <container-sized width="65%">
          <BaseImage :image="errorImage" />
        </container-sized>
      </div>
      <div class="title-block">
        <h1 class="header-1 text-centered">
          {{ title }}
        </h1>
        <div class="m-t-6 m-b-4 text-centered">
          <p class="text-centered" v-html="description"></p>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="errorCode !== 'timeout'">
        <base-button-primary black class="m-b-2" @clicked="tryAgain">
          Connect another account
        </base-button-primary>
        <div
          v-if="errorCode == 'no_idVerified_accounts'"
          class="m-t-2 flex-row center"
        >
          <base-button-link @click="openChat">
            Chat with Customer Support
          </base-button-link>
        </div>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';
import { LocalStorage } from '../../../utils/localStorage';
import { trueLayerObSummarySubmitChoice } from '../../../controllers/decision-engine';

export default {
  name: 'ErrorOB',
  components: {
    BaseScreen,
    ContainerSized,
    BaseImage,
    BaseButtonPrimary,
    BaseButtonLink,
    BaseScreen,
    ContainerSized,
    BaseImage,
    BaseButtonPrimary,
  },
  props: {
    errorCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    errorImage() {
      switch (this.errorCode) {
        case 'timeout':
          return 'fail-timeout';
        default:
          return 'fail-illustration';
      }
    },
    description() {
      switch (this.errorCode) {
        case 'tl_no_accounts':
        case 'tl_no_transaction_accounts':
          return 'For Pillar to run smoothly we require a connection to your primary current account(s) (the account where your salary is paid into and the one you use for your daily transactions). Please connect your primary current account';

        case 'no_idVerified_accounts':
          return 'It looks like the information on the accounts you have connected do not match the details in your application. If you believe this to be an error, please reach out to our customer care team. Alternatively, connect another account.';
        case 'timeout':
          return "Leave the rest to us. Enjoy your day, and we'll be back in touch soon to put the finishing touches on your application.";
        case 'de_no_transactions':
          return 'It looks like the accounts you have connected do not provide us with enough information to make a decision on your application. <br> <br> Please connect your primary current account (the account where your salary is paid into and the one you use for your daily transactions)';
        default:
          return '';
      }
    },

    title() {
      switch (this.errorCode) {
        case 'tl_no_accounts':
        case 'tl_no_transaction_accounts':
          return 'Did you link your primary current account?';

        case 'no_idVerified_accounts':
          return "Something doesn't look quite right";

        case 'timeout':
          return "It's taking a little longer than usual";
        case 'de_no_transactions':
          return 'We found issues in your application';
        default:
          return '';
      }
    },
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
  },

  methods: {
    async tryAgain() {
      if (this.errorCode == 'de_no_transactions') {
        this.$store.dispatch('navigation/startLoading');

        const applicationId = localStorage.getItem('applicationId');
        const userId = this.$store.getters['auth/userId'];

        const data = {
          userId,
          applicationId,
          selectedChoice: 'linkNewAccount',
        };

        const res = await trueLayerObSummarySubmitChoice(data);

        if (!res.status) {
          this.$router.push('/error');
          return false;
        }

        this.$store.dispatch('navigation/modulePassed', {
          event: 'open_banking_summary_block_run',
          page_title: 'openBankingSummaryBlock',
        });
      } else {
        this.$emit('clicked');
      }
    },
    openChat() {
      window.Intercom('showNewMessage');
    },
  },
};
</script>

<style lang="scss" scoped>
.title-block {
  margin-top: 56px;
}
</style>
