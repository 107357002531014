<template>
  <div>
    <jumio-error
      v-if="showRetryScreen"
      @clicked="hideRetryScreen"
    ></jumio-error>
    <div v-else class="full-height">
      <base-screen contentCentered>
        <template #main>
          <div class="p-h-5">
            <div class="flex-row center">
              <container-sized width="75%">
                <BaseImage image="Id_confirmation_illustration" />
              </container-sized>
            </div>
            <div class="m-t-6">
              <h3 class="text-centered">Let's take some photos</h3>
              <p class="text-centered grey m-t-4 m-b-4">
                We'll need to match a photo of you to an ID document.
              </p>
              <div class="jumio_intro_list">
                <base-list-item
                  :icon="item.icon"
                  :title="item.title"
                  v-for="(item, index) in listItems"
                  :key="item.icon"
                  :animationDelay="!index ? 0.3 : 0.3 + index * 0.1"
                  :customStyle="listItemStyles"
                >
                  <p>
                    {{ item.description }}
                  </p>
                </base-list-item>
              </div>
            </div>
          </div>
        </template>

        <template #footer>
          <base-button-primary @clicked="start" black class="mb-3 mt-2">
            Okay, I'm ready
          </base-button-primary>
          <div class="jumio_info">
            <p class="m-t-1">
              Your ID will be kept secure and handled according to our
              <span class="clickable bold" @click="openLink('policy')">
                Privacy Policy
              </span>
            </p>
          </div>
        </template>
      </base-screen>
    </div>
  </div>
</template>

<script>
import ContainerSized from '../../components/UI/elements/layout/ContainerSized.vue';
import BaseButtonPrimary from '../../components/buttons/BaseButtonPrimary.vue';
import BaseListItem from '../../components/list/ListItem.vue';
import BaseImage from '../../components/assets/BaseImage.vue';
import BaseScreen from '../../components/layout/BaseScreen.vue';
import JumioError from './JumioError.vue';

export default {
  components: {
    BaseScreen,
    BaseImage,
    BaseListItem,
    BaseButtonPrimary,
    ContainerSized,
    JumioError,
  },
  data() {
    return {
      listItems: [
        {
          icon: 'id_doc_passport',
          title:
            "You'll need a passport, driving license or ID card to complete this process",
        },
        {
          icon: 'id_doc',
          title:
            'Make sure your face is not obstructed and that the image is not blurry',
        },
        {
          icon: 'settings',
          title: 'Avoid taking pictures under bright lights to avoid glare',
        },
      ],
      showRetryScreen: false,
      showDialog: false,
      listItemStyles: {
        fontSize: '12px !important',
        fontWeight: '600',
        lineHeight: '16px',
      },
    };
  },

  created() {
    const jumioPassedBefore =
      this.$store.getters['navigation/stepPassedBefore'];

    if (jumioPassedBefore) {
      this.showRetryScreen = true;
    }
  },

  methods: {
    start() {
      this.$emit('clicked');
    },
    hideRetryScreen() {
      this.showRetryScreen = false;
    },
    openLink(doc) {
      let link = 'https://www.hellopillar.com/terms-of-use';
      if (doc == 'policy') {
        link = 'https://www.hellopillar.com/privacy-policy';
      }

      const webview = localStorage.getItem('webview') == 'true';

      if (webview) {
        window.OpenUrlInWebview.postMessage(link);
      } else {
        window.open(link, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.jumio_intro_list {
  margin-top: 27px;
  margin-bottom: 36px;
}

.clickable {
  position: relative;
  cursor: pointer;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: center;

  &:after {
    position: absolute;
    height: 2px;
    margin: 0 auto;
    content: '';
    left: 0;
    right: 0;
    background-color: $brand;
    width: 100%;
    left: 0;
    bottom: -3px;
  }
}

.jumio_info {
  // max-width: 250px;
  margin: 0 auto;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}
</style>
