<template>
  <PaymentSuccess v-if="isRRProduct" />
  <base-screen
    v-else-if="!isLoading"
    contentCentered
    desktopNoBG
    customTransition="fade"
  >
    <template #main>
      <div class="flex-row center image">
        <BaseImage image="referred-illustration" size="70%" />
      </div>
      <div class="m-t-6">
        <h1 class="header-1 text-centered">
          Hang tight!
        </h1>
      </div>
      <div v-if="device.type == 'desktop'">
        <p class="text-centered">
          A few manual checks are needed. It should be fast but can
          take up to 2 days during busy periods. We will keep you
          updated by email.
        </p>
        <p class="m-t-2 text-centered m-b-4">
          In the meantime, we invite you to monitor your credit score
          and report within the Pillar app, it’s free!
        </p>
        <p class="bold m-t-2 text-centered m-b-4">
          Use the QR-code link to go to the app from your smartphone.
        </p>
      </div>
      <div v-else class="text-description">
        <p class="m-b-2 m-t-4">
          A few manual checks are needed. It should be fast but can
          take up to 2 days during busy periods. We will keep you
          updated by email.
        </p>
        <p>
          In the meantime, we invite you to monitor your credit score
          and report within the Pillar app, it’s free!
        </p>
      </div>
      <div v-if="device.type == 'desktop'">
        <div class="flex-row center">
          <container-sized width="170px">
            <div>
              <qrcode-vue :value="link" size="170" level="H" />
            </div>
          </container-sized>
        </div>
        <p class="backlinkText text-centered" @click="goBack">
          Go back to home
        </p>
      </div>
    </template>
    <template #footer>
      <base-button-primary v-if="webview" @clicked="backToApp">
        Check out my credit scores
      </base-button-primary>

      <div v-else-if="device.type !== 'desktop'" @click="storeRedirect">
        <BaseImage
          width="200px"
          :image="device.os == 'iOS' ? 'app-store' : 'play-store'"
        />
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import PaymentSuccess from '../payment/PaymentSuccess.vue';
import QrcodeVue from 'qrcode.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import { detectDevice } from '../../../controllers/services/helpers';
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

export default {
    name: 'ApplicationReferred',
    components: {
        BaseScreen,
        ContainerSized,
        BaseImage,
        QrcodeVue,
        PaymentSuccess,
        BaseButtonPrimary,
        BaseImage,
    },
    data() {
        return {
            device: { type: null, os: null },
            link: 'https://hellopillar.page.link/app',
            product: null,
            isLoading: true,
            webview: false,
        };
    },

    computed: {
        isRRProduct() {
            const product = localStorage.getItem('productName');

            return (
                product == 'rentReporting' ||
                product == 'rentReportingAndMonitor'
            );
        },
    },

    created() {
        this.$store.dispatch('navigation/hideProgessBar');

        // Send GA event if there ga=true has been passed in the query
        const query = this.$route.query;

        if (query?.GA && query?.GA == 'true') {
            logFirebaseEvent('application_complete');
        }

        const productName = localStorage.getItem('productName');
        if (productName == 'rentReporting') {
            this.product = 'rentReporting';
            return false;
        }

        const webview = localStorage.getItem('webview');

        if (webview == 'true') {
            const product = localStorage.getItem('productName');

            if (product == 'pillarMonitor') {
                window.CISComplete.postMessage('Success');
                return false;
            } else if (
                product == 'pillarCard' ||
                product == 'pillarCardClassic' ||
                product == 'pillarCardBuild'
            ) {
                this.webview = true;
                // this.isLoading = false
                // window.PillarBuildComplete.postMessage('Success');
                // return false;
            }
        } else {
            const deviceInfo = detectDevice();
            this.device = deviceInfo;
        }
        this.$store.dispatch('navigation/stopLoading');

        this.isLoading = false;
    },

    methods: {
        goBack() {
            window.location.href = 'https://hellopillar.com/';
        },
        storeRedirect() {
            window.location.href = this.link;
        },
        backToApp() {
            window.CISComplete.postMessage('Success');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';
.backlinkText {
    margin-top: 48px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: $darkGrey;
    cursor: pointer;
}

.text-description {
    p {
        color: #6e7877;
        text-align: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px; /* 160% */
        letter-spacing: -0.14px;
    }
}

.image {
    margin-bottom: 48px;
}
</style>
