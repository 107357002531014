<template>
  <base-screen>
    <template #main>
      <base-title>What country are you coming from?</base-title>
      <div>
        <BaseSelect
          id="country"
          :options="countryCodes"
          placeholder="Country"
          @selected="onCountryCodeSelect"
        />
        <base-error-message v-if="showErrorMessage" key="error">
          Sorry, this product is not supported in your country yet. 
        </base-error-message>
      </div>
    </template>
    <template #footer>
      <base-button-primary
        :disabled="!formIsValid"
        @clicked="sendValue(countryCode)"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseSelect from '../../inputs/BaseSelect.vue';
import countryCodes from '../../../json/alpha2codes.json';
import screenMixin from '../../../mixins/screenMixin.js';
import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';

export default {
    components: {
        BaseScreen,
        BaseTitle,
        BaseButtonPrimary,
        BaseSelect,
        BaseErrorMessage,
    },
    mixins: [screenMixin({ screenName: 'countrySelect' })],
    data() {
        return {
            countryCodes,
            formIsValid: false,
            countryCode: null,
        };
    },

    computed: {
        showErrorMessage() {
            return !this.formIsValid && this.countryCode;
        },
    },

    methods: {
        onCountryCodeSelect(countryCode) {
            this.countryCode = countryCode;

            // Check if the country is supported
            const countryObj = this.countryCodes.find(
                (country) => country.value === countryCode
            );
            this.formIsValid =
                countryCode && countryObj?.newToCountrySupport === true;
        },
    },
};
</script>
