import * as Sentry from '@sentry/browser';
import store from "../../store/index.js";
import { api } from "./index.js";



// Check if all required aparms passed from the component and if so, make a request. 
// If some params are missing, return an error response

export const sendRequest = async (urlObj, params, authRequest = false) => {

    Sentry.addBreadcrumb({
        category: 'apiRequest',
        message: `${urlObj.url} request...`,
        data: {
            params
        },
        level: 'info'
    });
    const reqParams = urlObj.reqParams;

    const paramKeys = Object.keys(params);
    const check = reqParams.every(v => paramKeys.includes(v));

    if (check) {
        return urlObj.type == 'post' ? await api.post(urlObj.url, params, authRequest) : await api.get(urlObj.url, authRequest);
    } else {

        store.dispatch('error/throwNewError', {
            module: 'sendRequestInterceptor',
            errorType: 'apiError',
            comment: 'Required parameters for the request are missing!',
            context: {
                params
            }
        });

        return {
            status: false,
            message: 'Required parameters for the request are missing!'
        }
    }

}



// Loops through nested data properties and returns the last one
export const extractDataSuccessResponse = (response) => {
    let dataObj = response;


    while (Object.prototype.hasOwnProperty.call(dataObj, 'data')) {
        dataObj = dataObj.data
    }

    Sentry.addBreadcrumb({
        category: 'apiRequest',
        message: 'Got success response',
        data: {
            response
        },
        level: 'info'
    });

    return dataObj

}

