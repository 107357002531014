import { sendRequest } from "../config/axios/helper";


export const commsUrls = {
    SEND_SMS: {
        url: '/communication/sms',
        type: 'post',
        reqParams: ['phoneNumber', 'message']
    }
}



export const sendSms = async params => {
    return await sendRequest(commsUrls.SEND_SMS, params);
};

