<template>
  <base-screen customTransition="fade" docScreen>
    <template #main>
      <DocContainer :data="docData" />
      <DocFooter @updateModule="sendValue" />
    </template>
  </base-screen>
</template>

<script>
import DocFooter from '../../documents/DocFooter.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import DocContainer from '../../documents/DocContainer.vue';

export default {
  name: 'PreContractInformationScreen',
  components: {
    BaseScreen,
    DocContainer,
    DocFooter,
  },

  props: {
    docData: {
      type: String,
      required: true,
    },
    cardProductType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scrollPosition: 0, // Initialize with top position
    };
  },
  created() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  methods: {
    sendValue() {
      this.$emit('updateModule', 'pcci');
    },
  },
};
</script>
