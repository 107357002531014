<template>
  <base-screen>
    <template #main>
      <base-title class="m-b-3" noMargin>Verify your phone number</base-title>
      <p class="">
        Please enter the 6 digit number from the SMS message we just sent you.
      </p>
      <OtpInput
        :otpInputDisabled="otpInputDisabled"
        :timerValue="timerValue"
        v-bind="$attrs"
        ref="OtpInput"
      />
      <div class="resendOtp_container">
        <transition-group name="fade">
          <p v-if="resendDisabled" key="resendDisabled">
            Resend the code in {{ formatTime }}
          </p>
          <p v-else key="resendEnabled">
            <span
              class="clickable bold"
              @click="resendOtpCode"
              :class="{
                disabled: otpInputDisabled == true,
              }"
            >
              Resend the code
            </span>
          </p>
        </transition-group>
      </div>
    </template>
  </base-screen>
</template>

<script>
import OtpInput from '../../inputs/OtpInput.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'OtpScreen',
  components: { BaseScreen, BaseTitle, OtpInput },
  props: {
    otpInputDisabled: {
      type: Boolean,
      default: false,
    },
    timerValue: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      resendDisabled: true,
      currentTimerValue: this.timerValue,
    };
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    startTimer() {
      // Clear any existing timer
      this.currentTimerValue = this.timerValue;
      clearInterval(this.timer);

      this.resendDisabled = true;
      this.timer = setInterval(() => {
        if (this.currentTimerValue > 1) {
          this.currentTimerValue -= 1;
        } else {
          this.resendDisabled = false;

          // this.$emit('allowResend');
          clearInterval(this.timer);
        }
      }, 1000);
    },
    resendOtpCode() {
      this.$emit('resendCode');
    },
  },
  computed: {
    formatTime() {
      const minutes = Math.floor(this.currentTimerValue / 60);
      const seconds = this.currentTimerValue % 60;
      return `0${minutes}:${String(seconds).padStart(2, '0')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.resendOtp_container {
  margin-top: 36px;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
span.disabled {
  opacity: 0.5;
  pointer-events: none !important;
  cursor: none;
}

.fade-enter {
  /* Enter state */
  opacity: 0;
}

.fade-enter-active {
  /* Active enter state */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease;
}

.fade-leave {
  /* Leave state */
  opacity: 1;
  // transform: translateY(0);
}

.fade-leave-active {
  /* Active leave state */

  // transform: translateY(10px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-leave-active {
  position: absolute;
}
</style>
