<template>
  <div>
    <div class="rr-success-anim-block">
      <div class="payment-success_img-block centered" id="rr-success_black">
        <BaseImage image="rr-success_black" />
      </div>
      <div
        class="payment-success_img-block centered"
        id="rr-success_black_home"
      >
        <BaseImage image="rr-success_black_home" />
      </div>
      <div class="payment-success_img-block centered" id="rr-success_blue">
        <BaseImage image="rr-success_blue" />
      </div>
      <div class="payment-success_img-block" id="rr-success_shadow">
        <BaseImage image="rr-success_shadow" />
      </div>
      <div id="success_badge">
        <BaseImage image="success_badge" />
      </div>
    </div>
    <div id="payment-success-container">
      <base-screen contentCentered>
        <template #main>
          <div class="payment-success__content">
            <h3 class="text-centered payment-success__content_title">
              Your rent reporting subscription is set up!
            </h3>
            <div v-if="webview">
              <p>
                We will now be reporting your rent payments and make sure they
                don't go unnoticed.
              </p>
              <p>
                Keep an eye on how it affects your credit scores with Pillar
                Monitor.
              </p>
            </div>
            <div v-if="!webview">
              <div class="m-b-6 text-centered">
                <p v-if="device.type == 'desktop'">
                  Use the QR code to download the Pillar app and watch your
                  biggest expense start to build your credit file.
                </p>
                <p v-else>
                  Download the Pillar app and watch your biggest expense start
                  to build your credit file.
                </p>
              </div>
              <div class="flex-row center">
                <container-sized width="170px">
                  <div v-if="device.type == 'desktop'">
                    <qrcode-vue :value="link" size="170" level="H" />
                  </div>
                  <BaseImage
                    @clicked="storeRedirect"
                    v-else
                    :image="device.os == 'iOS' ? 'app-store' : 'play-store'"
                  />
                </container-sized>
              </div>
              <p
                v-if="device.type == 'desktop'"
                class="backlinkText text-centered"
                @click="goBack"
              >
                Go back to home
              </p>
            </div>
          </div>
        </template>
        <template #footer v-if="webview">
          <base-button-primary @clicked="close"
            >Okay, got it</base-button-primary
          >
        </template>
      </base-screen>
    </div>
  </div>
</template>

<script>
import ContainerSized from '../../layout/ContainerSized.vue';
import gsap from 'gsap';
import BaseImage from '../../assets/BaseImage.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import { detectDevice } from '../../../controllers/services/helpers';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'PaymentSuccess',
  components: {
    BaseImage,
    QrcodeVue,
    ContainerSized,
    BaseScreen,
    BaseButtonPrimary,
  },
  data() {
    return {
      device: { type: null, os: null },
      link: 'https://hellopillar.page.link/app',
      webview: false,
    };
  },
  created() {
    const webview = localStorage.getItem('webview');

    if (webview == 'true') {
      this.webview = true;
    } else {
      const deviceInfo = detectDevice();
      this.device = deviceInfo;
    }
  },

  mounted() {
    var tl = gsap.timeline();

    tl.set('#rr-success_black', { opacity: 0 });
    tl.set('#rr-success_black', { opacity: 0 });
    tl.to('#rr-success_blue', {
      translateX: '-40px',
      duration: 0.7,
      delay: 0.4,
      ease: 'power4.easeOut',
    });
    tl.to(
      '#rr-success_black',
      {
        duration: 0.1,
        opacity: 1,
      },
      '<'
    );
    tl.to(
      '#rr-success_black',
      {
        translateX: '40px',
        duration: 0.7,
        ease: 'power4.easeOut',
      },
      '<'
    );
    tl.to('#rr-success_blue', {
      translateX: '0',
      duration: 0.5,
      opacity: 0,
      delay: 0.3,
      ease: 'power3.easeOut',
    });

    tl.to(
      '#rr-success_black',
      {
        translateX: '0',
        duration: 0.5,
        ease: 'power3.easeOut',
      },
      '<'
    );

    tl.to('#rr-success_shadow', { scale: 3, duration: 0.7 }, '<');
    tl.from('#rr-success_black_home', { opacity: 0, duration: 0.1 }, '<');
    tl.to('#rr-success_black', { opacity: 0, duration: 0.1 }, '<');
    tl.from(
      '#payment-success-container',
      {
        opacity: 0,
        y: '-20px',
        duration: 0.7,
        ease: 'power2.easeOut',
      },
      '<'
    );
    tl.to('#rr-success_shadow', { duration: 0.3, opacity: 0 }, '-=0.3');
    tl.from('#success_badge', { scale: 0, opacity: 0, duration: 0.2 }, '-=0.4');
  },

  methods: {
    goBack() {
      window.location.href = 'https://hellopillar.com/';
    },

    storeRedirect() {
      window.location.href = this.link;
    },
    close() {
      window.RentReportApplicationComplete.postMessage('Complete');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';
.rr-success-anim-block {
  width: 100%;
  position: relative;
}
.payment-success_img-block {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 112px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#success_badge {
  width: 30px;
  //   border: 4px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 180px;
  left: 0;
  right: -70px;
  margin-left: auto;
  margin-right: auto;
}

.payment-success__content {
  padding-top: 220px !important;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: center;
  }
}

.payment-success__content_title {
  margin-bottom: 16px !important;
  margin-top: 16px !important;

  // a {

  // color: rgba(122, 159, 248, 1);
  // text-decoration: underline !important;
  // font-size: 12px !important;
  // font-weight: 500 !important;

  // }
}

.payment-success__link {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
}

.backlinkText {
  margin-top: 72px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: $darkGrey;
  cursor: pointer;
}
</style>
