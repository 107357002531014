<template>
  <base-screen contentCentered desktopNoBG>
    <template #main>
      <div v-if="!showAnimation" class="whiteBg"></div>

      <div class="animation-ob-row">
        <div
          ref="lottieContainerOB"
          class="lottie-animation-ob"
          :class="{ 'transition-fade': true, show: showAnimation }"
        ></div>

        <transition name="fade">
          <h2
            v-if="showAnimation && bureauTitle"
            class="text-centered loader-text header-2"
          >
            Connecting to your
            <br />
            {{ bureauTitle }} credit score
          </h2>
        </transition>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../layout/BaseScreen.vue';
import equifaxAnimationData from '../../../public/lottie-animation/equifax.json';
import experianAnimationData from '../../../public/lottie-animation/experian.json';
import transunionAnimationData from '../../../public/lottie-animation/transunion.json';
import applicationCompleted from '../../../public/lottie-animation/application-complete.json';

import {
  experianConnectScore,
  experianCreateCustomer,
  experianFetchScore,
  experianVerifyCustomer,
} from '../../controllers/experian.js';

import {
  transunionValidateCustomer,
  transunionQueryCustomer,
  transunionGetReport,
} from '../../controllers/transunion.js';
import lottie from 'lottie-web';
import {
  fetchExperianScore,
  fetchTransUnionScore,
} from '../../controllers/cis';

export default {
  name: 'ThreeScores',
  components: { BaseScreen },
  data() {
    return {
      showAnimation: false,
      animationData: equifaxAnimationData,
      firstTimeAnimation: true,
      switchAnimation: false,
      bureau: 'Equifax',
      activeTitle: 'Equifax',
      animationPlayed: ['Equifax'],
    };
  },
  computed: {
    bureauTitle() {
      return this.activeTitle;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadAnimation();

      // If Experian was passed before
      if (this.bureau == 'TransUnion') {
        const transUnionScore = this.fetchTransUnion();

        if (transUnionScore) {
          this.bureau = 'none';
          this.switchAnimation = true;
        }
      } else {
        setTimeout(async () => {
          // Show Equifax for 3s
          this.bureau = 'Experian';
          this.switchAnimation = true;

          const experianScore = await this.fetchExperian();

          if (experianScore) {
            this.bureau = 'TransUnion';
            this.switchAnimation = true;
          }
          const transUnionScore = this.fetchTransUnion();

          if (transUnionScore) {
            this.bureau = 'none';
            this.switchAnimation = true;
          }
        }, 3000);
      }
    });
  },
  created() {
    this.$store.dispatch('navigation/stopLoading');
    const path = this.$route.path;

    if (path == '/transunion') {
      this.animationData = transunionAnimationData;
      this.bureau = 'TransUnion';
      this.activeTitle = 'TransUnion';
      this.animationPlayed.push('Equifax', 'Experian');
    }
  },

  methods: {
    async loadAnimation() {
      const container = this.$refs.lottieContainerOB;

      const animation = lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: this.animationData,
        finalAnimAtionPlayed: false,
      });

      // Pause the animation immediately
      animation.pause();
      this.$store.dispatch('navigation/stopLoading');
      // Delay before playing the animation if it is the first time
      if (this.firstTimeAnimation) {
        const delay = 500;

        setTimeout(() => {
          this.showAnimation = true;
          animation.play();
        }, delay);
      } else {
        this.showAnimation = true;
        animation.play();
      }

      this.firstTimeAnimation = false;

      // Add the event listener to check if we need to switch the animation when loop completes
      animation.addEventListener('loopComplete', () => {
        if (this.switchAnimation) {
          if (this.bureau == 'Experian') {
            this.changeBureau('Experian');
          } else if (this.bureau == 'TransUnion') {
            // If we are on TransUnion but Experian animation haven't played yet, show 1 loop of experian first
            if (
              this.animationPlayed[this.animationPlayed.length - 1] ==
              'Experian'
            ) {
              this.changeBureau('TransUnion');
            } else {
              this.changeBureau('Experian');
            }
          } else if (this.bureau == 'none') {
            // If all requests sent, check what animayion have played and show the skipped animation first
            if (
              this.animationPlayed[this.animationPlayed.length - 1] == 'Equifax'
            ) {
              this.changeBureau('Experian');
            } else if (
              this.animationPlayed[this.animationPlayed.length - 1] ==
              'Experian'
            ) {
              this.changeBureau('TransUnion');
            } else {
              if (!this.finalAnimAtionPlayed) {
                this.activeTitle = null;
                this.animationData = applicationCompleted;
                this.finalAnimAtionPlayed = true;
              } else {
                container.innerHTML = '';
                animation.stop();
                animation.goToAndStop(0);

                this.$store.dispatch('navigation/startLoading');
                this.$store.dispatch('navigation/modulePassed', {
                  event: 'transunion_successful',
                  page_title: 'fetchScores',
                });

                return false;
              }
            }
          }

          container.innerHTML = '';
          animation.stop();
          animation.goToAndStop(0);

          this.loadAnimation();
        }
      });
    },

    changeBureau(bureau) {
      if (bureau == 'Experian') {
        this.animationData = experianAnimationData;
        this.animationPlayed.push('Experian');
        setTimeout(() => {
          this.activeTitle = 'Experian';
        }, 300);
      } else if (bureau == 'TransUnion') {
        this.animationData = transunionAnimationData;
        this.animationPlayed.push('TransUnion');
        setTimeout(() => {
          this.activeTitle = 'TransUnion';
        }, 300);
      }
    },
    async fetchExperian() {
      const applicationId = localStorage.getItem('applicationId');
      const userId = this.$store.getters['auth/userId'];

      const payload = {
        userId,
        applicationId,
      };

      await fetchExperianScore(payload);

      this.switchAnimation = true;
      return true;
    },

    async fetchTransUnion() {
      this.switchAnimation = false;
      const applicationId = localStorage.getItem('applicationId');
      const userId = this.$store.getters['auth/userId'];

      const payload = {
        userId,
        applicationId,
      };

      await fetchTransUnionScore(payload);

      this.switchAnimation = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
// .animation-ob-row {
//   display: flex;
//   justify-content: center;
// }
.lottie-animation-ob {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.whiteBg {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
}

.consentComplete_title {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.transition-fade {
  opacity: 0;
  transition: opacity 0.5s;
}

.transition-fade.show {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
