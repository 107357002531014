<template>
  <OfferScreen
    v-if="activeScreen == 'offerScreen' && !isLoading"
    :decision="finalBlockDecision"
    :cardProductType="cardProductType"
    :applicationCardProduct="applicationCardProduct"
    :declineReason="declineReason"
    :hasMonitor="hasMonitor"
    :maxCreditLimitApproved="maxCreditLimitApproved"
    :apr="apr"
    :monthlyFee="monthlyFee"
    @updateModule="updateModule"
    @hideOfferScreen="hideOfferScreen"
  />

  <AdequateExplanationsScreen
    v-if="activeScreen == 'pre-contract' && !isLoading"
    key="preContractualInfo"
    :docData="preContractHTML"
    :cardProductType="applicationCardProduct"
    @updateModule="updateModule"
  />

  <PCCIScreen
    v-else-if="
      activeScreen == 'pcci' && !isLoading && !applicationCompleted
    "
    key="pcci"
    :cardProductType="applicationCardProduct"
    :docData="pcciHTML"
    @updateModule="updateModule"
  />
  <ApplicationCompleted
    v-else-if="applicationCompleted"
    key="applicationCompleted"
  />
</template>

<script>
import OfferScreen from '../../screens/preContractualInfo/OfferScreen.vue';
import {
    generateUserDocument,
    getDocument,
    setPreContactModuleToPassed,
} from '../../controllers/documents';
import AdequateExplanationsScreen from '../screens/preContractualInfo/AdequateExplanationsScreen.vue';
import PCCIScreen from '../screens/preContractualInfo/PCCIScreen.vue';
// import SelectAgreement from '../screens/preContractualInfo/SelectAgreement.vue';
import { getDeclineReason } from '../../controllers/decision-engine';
import ApplicationCompleted from '../screens/applicationStatus/ApplicationCompleted.vue';
// import { getApplicationRecord } from '../../controllers/application/get';

export default {
    name: 'PreContractualInfo',
    components: {
        // SelectAgreement,
        PCCIScreen,
        AdequateExplanationsScreen,
        OfferScreen,
        ApplicationCompleted,
    },
    data() {
        return {
            isLoading: true,
            finalBlockDecision: null,
            preContractHTML: null,
            pcciHTML: null,
            cardProductType: null,
            hasMonitor: false,
            applicationCardProduct: null,
            declineReason: null,
            maxCreditLimitApproved: null,
            apr: null,
            applicationCompleted: false,
        };
    },
    computed: {
        // docSigned() {
        //   const docsFiltered = this.options.filter((el) => el.signed !== true);
        //   return !docsFiltered.length == 0;
        // },
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];
            if (!activeScreen) {
                return 'offerScreen';
            } else {
                return activeScreen;
            }
        },
        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
    },

    mounted() {
        this.$store.dispatch('navigation/stopLoading');
    },
    async mounted() {
        const applicationId = localStorage.getItem('applicationId');

        if (!applicationId) {
            this.$router.push('/error');
            return false;
        }

        const applRecordReq = await this.$store.dispatch(
            'application/getApplicationRecord',
            { applicationId, forceUpdate: true }
        );

        if (!applRecordReq.status) {
            return false;
        }

        let applicationData = applRecordReq.data;
        const decision = applicationData?.finalDecisionBlock?.overallDecision;

        if (!decision) {
            this.$router.push('/error');
            return false;
        }

        this.finalBlockDecision = decision;

        const cardProductType =
            applicationData?.finalDecisionBlock?.cardProductType;
        const applicationCardProduct = applicationData?.cardProductType;

        const maxCreditLimitApproved =
            applicationData?.finalDecisionBlock?.maxCreditLimitApproved;

        const apr = applicationData?.finalDecisionBlock?.apr;
        const monthlyFee = applicationData?.finalDecisionBlock?.monthlyFee;
        this.cardProductType = cardProductType;
        this.applicationCardProduct = applicationCardProduct;
        this.maxCreditLimitApproved = maxCreditLimitApproved;
        this.apr = apr;
        this.monthlyFee = monthlyFee;

        if (cardProductType == 'decline') {
            const userId = this.$store.getters['auth/userId'];

            const userRecord = await this.$store.dispatch(
                'application/getUserRecord',
                {
                    userId,
                }
            );
            if (userRecord.data?.approvedProducts?.includes('pillarMonitor')) {
                this.hasMonitor = true;
            } else {
                this.hasMonitor = false;
            }
        }
        const userId = this.$store.getters['auth/userId'];

        if (cardProductType == 'decline' || decision == 'decline') {
            const payload = {
                userId,
                applicationId,
            };
            const declineReasonReq = await getDeclineReason(payload);

            if (!declineReasonReq.status) {
                this.$router.push('/error');
                return false;
            }

            const reason = declineReasonReq.data?.description;

            if (!reason) {
                this.$router.push('/error');
                return false;
            }

            this.declineReason = reason;
        }

        if (!userId) {
            this.$router.push('/error');
            return false;
        }

        // Get documents
        const preContractualInfoPayload = {
            userId: userId,
            type: ['pre-contract', 'pcci'],
            applicationId,
        };

        const docDataReq = await getDocument(preContractualInfoPayload);

        if (!docDataReq.status) {
            this.$router.push('/error');
            return false;
        }

        this.preContractHTML = docDataReq.data['pre-contract'];
        this.pcciHTML = docDataReq.data['pcci'];

        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
    },
    methods: {
        // onDocSelect(id) {
        //   this.activeScreen = id;
        // },

        updateModule(id, data) {
            if (id == 'offerScreen') {
                this.$store.dispatch('navigation/updateScreen', 'pre-contract');
            } else if (id == 'pre-contract') {
                this.address = data;
                this.$store.dispatch('navigation/updateScreen', 'pcci');
            } else if (id == 'pcci') {
                this.modulePassed();
            }
        },

        // const selectedIndex = this.options.findIndex((el) => el.clickValue == id);

        // this.options[selectedIndex].signed = true;
        // this.options[selectedIndex].icon = 'check_green';
        // this.$store.dispatch('navigation/updateScreen', 'preContractualInfo');
        // this.activeScreen = 'selectScreen';

        // goBack() {
        //   this.activeScreen = 'selectScreen';
        // },

        async modulePassed() {
            const product = localStorage.getItem('productName');

            if (product == 'pillarCardNTC') {
                this.$store.dispatch('navigation/updateScreen', null);
                this.applicationCompleted = true;
                return false;
            }

            this.$store.dispatch('navigation/startLoading');
            // this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);

            const applicationId = localStorage.getItem('applicationId');

            const userId = this.$store.getters['auth/userId'];

            const payload = {
                userId,
                applicationId,
                type: ['pre-contract', 'pcci'],
            };

            // Generate and save docs in the db
            const preContractExplanationReq = await generateUserDocument(
                payload
            );

            if (!preContractExplanationReq.status) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'pre_contractual_info_complete',
                page_title: 'preContractualInfo',
            });
        },

        hideOfferScreen() {
            this.$store.dispatch(
                'navigation/updateScreen',
                'preContractualInfo'
            );
            // this.activeScreen = 'preContractualInfo';
        },
    },
};
</script>
