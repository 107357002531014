<template>
  <div>
    <div
      class="ob-summary-block_accounts-list"
      :class="bordered ? 'bordered' : null"
    >
      <BaseListItem
        v-for="(item, index) of items"
        :padding="padding"
        :selectedId="selectedId"
        :selectedBank="selectedBank"
        v-bind="$attrs"
        noTextMargin
        :key="index"
        :item="item"
        :index="index"
        :customListStyle="customListStyle"
      />
    </div>
    <slot v-if="withButton"></slot>
  </div>
</template>

<script>
import BaseListItem from './BaseListItem.vue';
export default {
  components: { BaseListItem },
  name: 'BaseList',
  props: {
    maxHeight: {
      type: String,
      default: 'auto',
    },
    bordered: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      required: true,
    },
    selectedId: {
      type: String,
    },
    padding: {
      type: String,
    },
    withButton: {
      type: Boolean,
      default: false,
    },
    customListStyle: {
      type: Object,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    selectedBank: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.ob-summary-block_accounts-list {
  overflow-y: auto;
  transition: all 0.3s;
  max-height: auto;

  @media (min-width: 1024px) {
    max-height: 200px;
  }

  &.bordered {
    border: 1px solid #f5faf9;
    border-radius: 8px;
  }
}
</style>
