<template>
  <base-screen v-if="!isLoading" contentCentered customTransition="fade">
    <template #main>
      <container-sized
        width="100%"
        class="waiting-list-bump-me-up-container"
      >
        <BaseImage image="waiting-list-bump-me-up" />
      </container-sized>
      <div class="p-t-6 p-b-4">
        <h1 class="header-1 text-centered">
          We're reviewing your request to skip the queue
        </h1>
      </div>
      <p class="text-centered">
        Our team is reviewing your application and will notify you of
        any updates as soon as possible
      </p>
    </template>
  </base-screen>
</template>
<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../UI/elements/layout/ContainerSized.vue';

export default {
    name: 'WaitingListRequestConfirmation',
    components: {
        BaseScreen,
        BaseImage,
        ContainerSized,
    },
    props: {
        counter: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.isLoading = false;
        });
    },
    methods: {
        skipTheQueue() {
            this.$emit('skipTheQueue');
        },
    },
};
</script>

<style lang="scss">
.waiting-list-bump-me-up-container {
    margin-left: -45px;
    margin-top: -60px;
    margin-bottom: 20px;
    img {
        scale: 1.25;
    }

    @media (min-width: 600px) {
        margin: 0 !important;
        max-width: 450px;
        img {
            scale: 1;
        }
    }
}
</style>
