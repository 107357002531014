<template>
  <FinancialDependents
    v-if="activeScreen == 'financialDependents'"
    key="financialDependents"
    @updateForm="updateForm"
  />
  <Expenses
    v-if="activeScreen == 'expenses'"
    key="expenses"
    @updateForm="updateForm"
  />
</template>

<script>
import FinancialDependents from '../screens/expenditure/FinancialDependents.vue';
import Expenses from '../screens/expenditure/ExpensesForm';
export default {
  name: 'ExpenditureModule',
  components: { Expenses, FinancialDependents },

  data() {
    return {
      formDetails: {
        financialDependents: null,
        expenses: null,
      },
    };
  },
  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];
      if (!activeScreen) {
        return 'financialDependents';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
  },
  mounted() {
    this.$store.dispatch('navigation/stopLoading');
  },

  methods: {
    async updateForm(id, value) {
      this.formDetails[id] = value;
      const updatedData = { [this.formDetails[id]]: value };
      this.$store.dispatch('application/updateApplicationData', updatedData);

      if (id == 'financialDependents') {
        this.$store.dispatch('navigation/updateScreen', 'expenses');
      } else if (id == 'expenses') {
        await this.sendForm();
      }
    },

    async sendForm() {
      this.$store.dispatch('navigation/startLoading');
      // this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);

      const data = {
        financialDependents: this.formDetails.financialDependents,
        monthlyExpenses: this.formDetails.expenses,
      };

      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data, step: 'expenditure' }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/startLoading');
      this.$store.dispatch('navigation/modulePassed', {
        event: 'expenditure_entered',
        page_title: 'expenditure',
      });
    },
  },
};
</script>
