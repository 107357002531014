<template>
  <base-screen>
    <template #main>
      <base-title>Let's set up your Direct Debit</base-title>
      <p>
        Which bank account you'd like us to take payments from each month.
      </p>
      <div class="m-t-6">
        <div v-for="account in bankAccounts" :key="account.account_id">
          <div
            class="list-item_wrap"
            @click="(event) => (account.disabled ? showWarning(event) : null)"
          >
            <div
              class="list-item_container"
              :class="{
                disabled: account.disabled,
              }"
              @click="selectAccount(account.account_id)"
            >
              <div class="list-image">
                <BaseImage
                  :image="account.provider.provider_id"
                  folder="tl-banks"
                />
              </div>
              <div class="list-title-container">
                <p class="list-title">
                  {{ account.accountName }}
                </p>
                <p class="list-subtitle">
                  {{ account.maskedAccountNumber }}
                </p>
              </div>
              <div class="list-icon">
                <BaseIcon icon="arrow-right_lightgrey" size="md" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <NewAccountButton @clicked="addNewAccount"
      /> -->
    </template>
    <template #footer>
      <div class="direct-debit-row" @click="showDialog = true">
        <div class="direct-debit-logo-container">
          <BaseImage image="direct-debit" width="100px" />
        </div>
        <div class="direct-debit-text">
          <p class="small">
            <span class="grey">Your payments are protected by The</span>
            Direct Debit Guarantee
          </p>
        </div>
      </div>
      <base-modal v-if="showDialog" @closeModal="showDialog = false">
        <DirectDebitGuarantee />
      </base-modal>
    </template>
  </base-screen>
</template>

<script>
import BaseIcon from '../../assets/BaseIcon.vue';
import BaseModal from '../../modal/BaseModal.vue';
// import NewAccountButton from '../../buttons/NewAccountButton.vue';
import DirectDebitGuarantee from '../../directDebitSetup/directDebitGuarantee.vue';
import BaseImage from '../../assets/BaseImage.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'ChooseBankAccount',
  components: {
    BaseTitle,
    BaseScreen,
    BaseImage,
    DirectDebitGuarantee,
    BaseModal,
    BaseIcon,
  },
  props: {
    bankAccounts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
    };
  },

  methods: {
    selectAccount(id) {
      this.$emit('updateForm', 'chooseBankAccount', id);
    },

    showWarning(event) {
      event.stopPropagation();
      this.$store.dispatch('error/updatePopupErrorStatus', {
        status: true,
        message: 'This account is not available',
        type: 'warning',
      });
    },
  },
};
</script>
