<template>
  <button
    type="button"
    :style="styles"
    :class="[
      size ? `button-secondary ${size}` : 'button-secondary',
      isLoading ? 'loading' : '',
    ]"
    @click="click"
  >
    <div class="button_row">
      <slot></slot>
      <BaseIcon icon="arrow-right" size="sm" />
    </div>
  </button>
</template>

<script>
import BaseIcon from '../../../assets/BaseIcon.vue';

export default {
  components: { BaseIcon },
  name: 'BaseButtonSecondaryIcon',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    styles: {
      type: Object,
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
  computed: {
    getHeight() {
      switch (this.size) {
        case 'lg':
          return '60px';

        case 'md':
          return '48px';

        case 'sm':
          return '36px';

        default:
          return '60px';
      }
    },
    isLoading() {
      return this.$store.getters['navigation/buttonSecondaryInLoadingState'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/app.scss';

.button-secondary {
  border-radius: 8px;
  background-color: white;
  color: $darkestGrey;
  height: 60px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  transition: 0.3s;
  padding-right: 20px;
  padding-left: 20px;
  border: none;

  &.md {
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
  }

  &.sm {
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  &:hover,
  &:active {
    border: 1px solid $black;
    color: $black;
  }
}
.loader-container {
  display: flex; /* use flexbox to center the loading indicator */
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loading-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: white;
  animation: spin 1s linear infinite;
  z-index: 3;
}

.button_row {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
