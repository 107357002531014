<template>
  <PANScreen
    v-if="activeScreen == 'PANScreen'"
    key="PANScreen"
    @updateForm="updateForm"
  />
  <AuthorizationScreen
    v-if="activeScreen == 'authorizationScreen'"
    key="authorizationScreen"
    @updateForm="updateForm"
  />
  <CRIFAuth
    v-if="activeScreen == 'CRIFAuth'"
    key="CRIFAuth"
    :timerValue="120"
    @updateForm="updateForm"
  />
  <CreditHistory
    v-if="activeScreen == 'creditHistory'"
    key="creditHistory"
    @updateForm="updateForm"
  />
  <DataTransferConfirmation
    v-if="activeScreen == 'dataTransferConfirmation'"
    key="dataTransferConfirmation"
    @updateForm="updateForm"
  />
  <DataTransferConfirmed
    v-if="activeScreen == 'dataTransferConfirmed'"
    key="dataTransferConfirmed"
    @updateForm="updateForm"
  />
</template>

<script>
import DataTransferConfirmation from '../screens/IndiaBureauData/DataTransferConfirmation.vue';
import CreditHistory from '../screens/IndiaBureauData/CreditHistory.vue';
import AuthorizationScreen from '../screens/IndiaBureauData/AuthorizationScreen.vue';

import PANScreen from '../screens/IndiaBureauData/PANScreen.vue';
import CRIFAuth from '../screens/IndiaBureauData/CRIFAuth.vue';
import DataTransferConfirmed from '../screens/IndiaBureauData/DataTransferConfirmed.vue';
import { setIndiaBureauDataToPassed } from '../../controllers/decision-engine';

export default {
    name: 'IndiaBureauData',
    components: {
        PANScreen,
        AuthorizationScreen,
        CRIFAuth,
        CreditHistory,
        DataTransferConfirmation,
        DataTransferConfirmed,
    },

    data() {
        return {
            formDetails: {
                annualIncome: null,
                employmentStatus: null,
                employerName: null,
            },
        };
    },

    computed: {
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];
            if (!activeScreen) {
                return 'PANScreen';
            } else {
                return activeScreen;
            }
        },
        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
    },

    mounted() {
        this.$store.dispatch('navigation/stopLoading');
    },
    methods: {
        async updateForm(id) {
            if (id == 'PANScreen') {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    'authorizationScreen'
                );
            } else if (id == 'authorizationScreen') {
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationText',
                    'Working out if we can use your Indian Credit Score to support your application...'
                );
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationType',
                    'indiaLoader'
                );
                this.$store.dispatch('navigation/startLoading');
                setTimeout(() => {
                  this.$store.dispatch('navigation/updateScreen', 'CRIFAuth');
                }, 4000);
                
            } else if (id == 'CRIFAuth') {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    'creditHistory'
                );
            } else if (id == 'creditHistory') {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    'dataTransferConfirmation'
                );
            } else if (id == 'dataTransferConfirmation') {
              this.$store.dispatch(
                    'navigation/updateLoaderAnimationText',
                    'Connecting you with your Indian Credit Score...'
                );
                this.$store.dispatch(
                    'navigation/updateLoaderAnimationType',
                    'indiaLoader'
                );
                this.$store.dispatch('navigation/startLoading');
                setTimeout(() => {
                  this.$store.dispatch('navigation/updateScreen', 'dataTransferConfirmed');
                }, 4000);
            } else if (id == 'dataTransferConfirmed') {
                this.sendForm();
            }
        },

        async sendForm() {
            const applicationId = localStorage.getItem('applicationId');
            const payload = { applicationId };

            const setStepToPassed = await setIndiaBureauDataToPassed(payload);
            if (!setStepToPassed.status) {
                this.$router.oush('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'indiaBureauData',
                page_title: 'indiaBureauData',
            });
        },
    },
};
</script>
