
export default function ({ defaultScreen }) {
    return {
        computed: {
            activeScreen () {
                const activeScreen = this.$store.getters['navigation/activeScreen'];
                return activeScreen || defaultScreen;
            },
            animationMode () {
                return this.$store.getters['navigation/animationMode'];
            },
        },
        methods: {
            navigateToNextScreen (screen) {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    screen
                );
            },
            stopLoading () {
                this.$store.dispatch('navigation/stopLoading');
            },
            startLoading () {
                this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
                this.$store.dispatch('navigation/startLoading');
            },
            modulePassed (event) {
                this.$store.dispatch('navigation/modulePassed', event || null);
            }
        },

    };
}
