<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="fail-illustration" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <h3 class="text-centered">Sorry, we couldn't verify your identity</h3>
          <p class="text-centered grey m-t-4 m-b-4">
            We couldn't verify the last ID document you submitted. Please try
            again.
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <base-button-primary @clicked="restart" black class="mb-3 mt-2">
        Try again
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../components/buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../components/layout/BaseScreen.vue';
import ContainerSized from '../../components/UI/elements/layout/ContainerSized.vue';
import BaseImage from '../../components/assets/BaseImage.vue';

export default {
  components: {
    BaseImage,
    ContainerSized,
    BaseScreen,
    BaseButtonPrimary,
  },

  created() {
    this.$store.dispatch('navigation/firstRouteStateCancel');
    this.$store.dispatch('navigation/hideProgessBar');

    localStorage.removeItem('obsuccess');
    // this.$store.dispatch('error/showErrorScreen');
  },

  methods: {
    restart() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-family: Poppins;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: -0.02em !important;
  text-align: center !important;
}
.infoBlock {
  background-color: rgba(122, 159, 248, 0.1);

  padding: 16px;
  border-radius: 8px;

  p {
    //styleName: Headers/Sm-Header;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    .small {
      text-align: center !important;
    }
  }
}

p {
  //styleName: Headers/Sm-Header
  &.small {
    text-align: center !important;
  }
}
</style>
