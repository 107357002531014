<template>
  <base-screen>
    <template #main>
      <base-title class="m-b-3">
        Please enter your phone number
      </base-title>
      <div
        class="phoneInput_wrap"
        :class="{
          disabled: phoneInputDisabled == true,
        }"
      >
        <PhoneInput @onInput="inputChanged" />
      </div>
    </template>
    <template #footer>
      <base-button-primary
        class="m-t-6"
        :disabled="phoneInputDisabled || !isValid"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import PhoneInput from '../../inputs/PhoneInput.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
export default {
    name: 'PhoneInputScreen',
    components: { BaseScreen, BaseTitle, PhoneInput, BaseButtonPrimary },
    props: {
        phoneInputDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            phoneNo: null,
            isValid: false,
        };
    },
    methods: {
        inputChanged(id, value, status) {
            if (value) {
                this.phoneNo = value;
                this.isValid = status == 'valid';
            }
        },

        sendValue() {
            const formattedPhoneNo = this.phoneNo.replace(/\s/g, '');
            this.$emit('updatePhoneNumber', formattedPhoneNo);
        },
    },
};
</script>

<style>
.phoneInput_wrap.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
