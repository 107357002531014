import { createRouter, createWebHistory } from 'vue-router';
import ApplicationData from '../components/modules/ApplicationData.vue';
import PhoneAuth from '../components/modules/PhoneAuth.vue';
import PinAuth from '../components/modules/PinAuth.vue';
import equifaxCISSearch from '../components/modules/EquifaxCISSearch.vue';
import KBAModule from '../components/modules/KBAModule.vue';
import jumio from '../components/modules/JumioModule.vue';
import openBankingConsent from '../components/modules/openBankingConsent/OpenBankingConsent.vue';
import obConsentLink from '../components/modules/openBankingConsent/obConsentLink.vue';
import employmentDetails from '../components/modules/EmploymentDetails.vue';
import expenditure from '../components/modules/ExpenditureModule.vue';
import OpenBankingEbav from '../components/modules/openBankingEBAV/OpenBankingEBAV.vue';
import DirectDebitSetup from '../components/modules/DirectDebitSetup.vue';
import PreContractualInfo from '../components/modules/PreContractualInfo.vue';
import CreditCardAgreement from '../components/modules/CreditCardAgreement.vue';
import ComplyAdvantageSearch from '../components/modules/ComplyAdvantageSearch.vue';
import ExperianDelphiSearchSoft from '../components/modules/ExperianDelphiSearchSoft.vue';
// import CreditPolicyResult from '../components/modules/creditPolicyResult/Index.vue';
import FinalDecisionBlock from '../components/modules/FinalDecisionBlock.vue';
import AddressConfirmation from '../components/modules/AddressConfirmation.vue';
import RentReportingTransaction from '../components/modules/RentReportingTransaction.vue';
import Payment from '../components/modules/StripePayment.vue';
import TrueLayerConsent from '../components/modules/TrueLayerConsent.vue';
import TrueLayerConsentComplete from '../components/modules/TrueLayerConsentComplete.vue';
import TrueLayerRawDataRetrieved from '../components/modules/TrueLayerRawDataRetrieved.vue';
import TrueLayerKYCPepsSanctions from '../components/modules/TrueLayerKYCPepsSanctions.vue';
// import FetchScores from '../components/UI/screens/fetchScores.vue';
import ApplicationReferred from '../components/screens/applicationStatus/ApplicationReferred.vue';
import PausedScreen from '../components/screens/paused/PausedScreen.vue';
import BlockApplications from '../components/screens/blocked/BlockedScreen.vue';
import DecisionDeclineScreen from '../components/screens/preContractualInfo/DecisionDeclineScreen.vue';
import ExperianCrossCore from '../components/modules/ExperianCrossCore.vue';
import OBFail from '../components/ob/OBFailScreen';
import JumioCallback from '../components/modules/jumioCallback/JumioCallback.vue';
import AllData from '../components/modules/allData/AllData.vue';
import hybridGuard from './hybrid-guard';
import entryGuard from './entry-guard';
import consentRedirectGuard from './consent-redirect-guard';
import ErrorScreen from '../components/screens/error/ErrorScreen.vue';
import ApplicationExpired from '../components/screens/applicationStatus/ApplicationExpired.vue';
import ApplicationCompleted from '../components/screens/applicationStatus/ApplicationCompleted.vue';
import TrueLayerPayment from '../components/modules/TrueLayerPayment.vue';
import ThreeScores from '../components/modules/ThreeScores.vue';
import CISDataPull from '../components/modules/CISDataPull.vue';
// import ApplicationDeclined from '../components/screens/applicationStatus/ApplicationDeclined.vue';
import DeclineScreen from '../components/UI/screens/declineScreen.vue';

//TrueLayer modules
import IdVerification from '../components/modules/IdVerification.vue';
import TrueLayerCISIdCheck from '../components/modules/TrueLayerCISIdCheck.vue';
import AccountScoreAnalyticsRetrieved from '../components/modules/AccountScoreAnalyticsRetrieved.vue';
import TrueLayerOBSummaryBlock from '../components/modules/TrueLayerOBSummaryBlock.vue';
import TrueLayerOpenBankingIdCheck from '../components/modules/TrueLayerOpenBankingIdCheck.vue';
import TrueLayerAIVAffordabilityPolicyRules from '../components/modules/TrueLayerAIVAffordabilityPolicyRules.vue';
import AIVAffordabilityPolicyRulesV2 from '../components/modules/AIVAffordabilityPolicyRulesV2.vue';
import ComplyAdvantageCrossCore from '../components/modules/ComplyAdvantageCrossCore.vue';
//pillarCardIndia
import IndiaBureauData from '../components/modules/IndiaBureauData.vue';
import ForceApprovalFinalDecisionBlock from '../components/modules/ForceApprovalFinalDecisionBlock.vue';
import ProcessingApplication from '../components/modules/ProcessingApplication.vue';
import FirstObNameMatch from '../components/modules/FirstObNameMatch.vue';
import ObRedirect from '../components/modules/ObRedirect.vue';
import EmploymentAndExpenditure from '../components/modules/EmploymentAndExpenditure.vue';

import ApplicationDataNTC from '../components/modules/ApplicationDataNTC.vue';
import ForeignDataCapture from '../components/modules/ForeignDataCapture.vue';
import ForeignCreditReportPull from '../components/modules/ForeignCreditReportPull.vue';
import ForeignCreditReportPullSuccess from '../components/screens/foreignCreditReportPull/ForeignCreditReportPullSuccess.vue';
import ForeignCreditReportPullFail from '../components/screens/foreignCreditReportPull/ForeignCreditReportPullFail.vue';
import EmploymentAndExpenditureNTC from '../components/modules/EmploymentAndExpenditureNTC.vue';
import Jubio from '../components/modules/JubioModule.vue';
import UKAddress from '../components/modules/UKAddress.vue';

import ApplicationWaitingList from '../components/screens/applicationStatus/ApplicationWaitingList.vue';

const routes = [

    // Entry points 
    // app
    {
        path: '/entry',
        name: 'entry',
        beforeEnter: entryGuard,
    },
    // web
    {
        path: '/onboard',
        name: 'onboard',
        beforeEnter: entryGuard,
    },

    // Error screens
    {
        path: '/error',
        component: ErrorScreen,
    },
    {
        path: '/offer-error',
        component: DecisionDeclineScreen,
    },
    {
        path: '/blocked',
        component: BlockApplications,
    },
    {
        path: '/paused',
        component: PausedScreen,
    },

    // Application status screens
    {
        path: '/expired',
        component: ApplicationExpired,
        beforeEnter: hybridGuard
    },
    {
        path: '/pending',
        component: ApplicationReferred,
        beforeEnter: hybridGuard,
    },
    {
        path: '/declined',
        component: DeclineScreen,
        beforeEnter: hybridGuard,
    },
    {
        path: '/complete',
        component: ApplicationCompleted,
        beforeEnter: hybridGuard,
    },
    {
        path: '/waitingList',
        component: ApplicationWaitingList,
        beforeEnter: hybridGuard,
    },

    // Auth modules
    {
        path: '/auth',
        name: 'phoneAuth',
        component: PhoneAuth,
    },
    {
        path: '/pin',
        name: 'pinAuth',
        component: PinAuth,
    },

    // Modules
    {
        path: '/applicationData',
        name: 'applicationData',
        component: ApplicationData,
        beforeEnter: hybridGuard,
    },

    {
        path: '/equifaxCISSearch',
        component: equifaxCISSearch,
        beforeEnter: hybridGuard,
    },
    {
        path: '/KBA',
        component: KBAModule,
        beforeEnter: hybridGuard,
    },
    {
        path: '/jumio',
        component: jumio,
        beforeEnter: hybridGuard,
    },
    {
        path: '/jumioCallback',
        component: JumioCallback,
        beforeEnter: hybridGuard,
    },
    {
        path: '/CISIdVerification',
        component: IdVerification,
        beforeEnter: hybridGuard,
    },

    {
        path: '/employmentAndExpenditure',
        component: EmploymentAndExpenditure,
        beforeEnter: hybridGuard,

    },
    {
        path: '/employmentDetails',
        component: employmentDetails,
        beforeEnter: hybridGuard,
    },
    {
        path: '/expenditure',
        component: expenditure,
        beforeEnter: hybridGuard,
    },
    {
        path: '/obSummaryBlock',
        component: TrueLayerOBSummaryBlock,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/precontractualInfo',
        component: PreContractualInfo,
        beforeEnter: hybridGuard,
    },
    {
        path: '/creditCardAgreement',
        component: CreditCardAgreement,
        beforeEnter: hybridGuard,
    },
    {
        path: '/complyAdvantageSearch',
        component: ComplyAdvantageSearch,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/experianDelphiSearchSoft',
        component: ExperianDelphiSearchSoft,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/experianDelphiSearchHard',
        // component: ExperianDelphiSearchHard,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    // {
    //     path: '/policyRulesAndCreditCutOffBlock',
    //     component: CreditPolicyResult,
    //     beforeEnter: hybridGuard
    // },

    {
        path: '/AIVAffordabilityPolicyRules',
        component: TrueLayerAIVAffordabilityPolicyRules,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/AIVAffordabilityPolicyRulesV2',
        component: AIVAffordabilityPolicyRulesV2,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/complyAdvantageCrossCore',
        component: ComplyAdvantageCrossCore,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/KYCPepsSanctions',
        component: TrueLayerKYCPepsSanctions,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/openBankingCISIdCheck',
        component: TrueLayerCISIdCheck,
        beforeEnter: hybridGuard,
    },

    {
        path: '/openBankingIdCheck',
        component: TrueLayerOpenBankingIdCheck,
        beforeEnter: hybridGuard,
    },

    {
        path: '/CISDataPull',
        component: CISDataPull,
        beforeEnter: hybridGuard,
    },

    {
        path: '/finalDecisionBlock',
        component: FinalDecisionBlock,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/processingApplication',
        component: ProcessingApplication,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/experian',
        component: ThreeScores,
        beforeEnter: hybridGuard,
    },
    {
        path: '/transunion',
        component: ThreeScores,
        beforeEnter: hybridGuard,
    },
    {
        path: '/experianCrossCore',
        component: ExperianCrossCore,
        beforeEnter: hybridGuard,
    },

    // TrueLayer
    {
        path: '/trueLayerConsent',
        component: TrueLayerConsent,
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/obconsentcomplete',
        component: TrueLayerConsentComplete,
        beforeEnter: consentRedirectGuard,
        meta: { requiresBankAnimation: true }
    },
    {
        path: '/obredirect',
        component: ObRedirect,
    },

    {
        path: '/trueLayerRawDataRetrieved',
        component: TrueLayerRawDataRetrieved,
        beforeEnter: hybridGuard,
        meta: { requiresBankAnimation: true }

    },

    {
        path: '/firstObNameMatch',
        component: FirstObNameMatch,
        beforeEnter: hybridGuard,

    },
    {
        path: '/accountScoreAnalyticsRetrieved',
        component: AccountScoreAnalyticsRetrieved,
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    // User modules
    {
        path: '/openBankingConsent',
        component: obConsentLink,
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/obsuccess',
        component: openBankingConsent,
        beforeEnter: hybridGuard,
        meta: { dynamic: true, userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/allData',
        component: AllData,
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/obfail',
        component: OBFail,
        beforeEnter: hybridGuard,
        meta: { dynamic: true, userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/openbankingebav',
        component: OpenBankingEbav,
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/directDebitSetup',
        component: DirectDebitSetup,
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarCard'] },
    },

    // Rent Reporting
    {
        path: '/addressConfirmation',
        component: AddressConfirmation,
        beforeEnter: hybridGuard,
    },
    {
        path: '/rentReportingTransaction',
        component: RentReportingTransaction,
        beforeEnter: hybridGuard,
    },

    {
        path: '/payment',
        component: Payment,
        beforeEnter: hybridGuard,
    },

    {
        path: '/truelayerpayment',
        component: TrueLayerPayment,
        meta: { requiresBankAnimation: true }

    },

    // TrueLayer

    // pillarCardIndia

    {
        path: '/indiaBureauData',
        component: IndiaBureauData,
        beforeEnter: hybridGuard,

    },
    {
        path: '/assessingApplication',
        component: ForceApprovalFinalDecisionBlock,
        beforeEnter: hybridGuard,

    },

    {
        path: '/fetchScores',
        component: ThreeScores,
        beforeEnter: hybridGuard,
    },

    // International journey

    {
        path: '/applicationDataNTC',
        name: 'applicationDataNTC',
        component: ApplicationDataNTC,
        beforeEnter: hybridGuard,
    },
    {
        path: '/foreignDataCapture',
        component: ForeignDataCapture,
        beforeEnter: hybridGuard,
    },
    {
        path: '/foreignCreditReportPull',
        component: ForeignCreditReportPull,
        beforeEnter: hybridGuard,
    },

    {
        path: '/foreign-credit-report-pull',
        children: [
            {
                path: 'success',
                name: 'foreignCreditReportPullSuccess',
                component: ForeignCreditReportPullSuccess,
            },
            {
                path: 'fail',
                name: 'foreignCreditReportPullFail',
                component: ForeignCreditReportPullFail,
            }
        ]
    },
    {
        path: '/employmentAndExpenditureNTC',
        component: EmploymentAndExpenditureNTC,
        beforeEnter: hybridGuard,

    },
    {
        path: '/foreignDataCapture',
        component: ForeignDataCapture,
        beforeEnter: hybridGuard,
    },
    {
        path: '/jubio',
        component: Jubio,
        beforeEnter: hybridGuard,
    },
    {
        path: '/UKAddress',
        component: UKAddress,
        beforeEnter: hybridGuard,
    },
    {
        path: '/',
        redirect: '/onboard'
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/onboard'
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router; // Change this line to export default
