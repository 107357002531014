import { sendRequest } from "../config/axios/helper";


export const cisUrls = {
    CHECK_CIS_MATCH: {
        url: '/cis/equifax',
        type: 'post',
        reqParams: ['applicationId']
    },
    RESET_APPLICATION_DATA_MODULE: {
        url: '/application/step/update',
        type: 'post',
        reqParams: ['applicationId', 'userId']
    },
    FETCH_ALL_SCORES: {
        url: '/cis/fetch-all-scores',
        type: 'post',
        reqParams: ['applicationId', 'userId']
    },
    FETCH_EXPERIAN: {
        url: '/cis/experian-initial-fetch',
        type: 'post',
        reqParams: ['applicationId', 'userId']
    },
    FETCH_TRANSUNION: {
        url: '/cis/transunion-initial-fetch',
        type: 'post',
        reqParams: ['applicationId', 'userId']
    }
}



export const checkForCISMatch = async params => {
    return await sendRequest(cisUrls.CHECK_CIS_MATCH, params)

};


export const resetApplicationDataModule = async params => {
    return await sendRequest(cisUrls.RESET_APPLICATION_DATA_MODULE, params)

};

export const fetchAllScores = async params => {
    return await sendRequest(cisUrls.FETCH_ALL_SCORES, params)

};


export const fetchExperianScore = async params => {
    return await sendRequest(cisUrls.FETCH_EXPERIAN, params)

};


export const fetchTransUnionScore = async params => {
    return await sendRequest(cisUrls.FETCH_TRANSUNION, params)

};