<template>
  <div class="warning-card">
    <div class="centered m-b-2">
      <BaseIcon icon="warning_yellow" />
    </div>
    <h3 class="text-centered header-2 title">
      Want to change your rental address?
    </h3>
    <div class="warning-card_content">
      <div>
        <p>
          Choosing a different address associated with your rent payments will
          change the address for your overall Pillar account too.
        </p>
      </div>
      <div class="m-t-3">
        <p>
          Try to keep it consistent and make sure you update it across all your
          accounts like banks, mobile phone contracts, etc.
        </p>
      </div>
    </div>
    <div class="warning-card_buttons">
      <base-button-primary
        @clicked="$emit('changeAddress')"
        data-testid="change-address-button"
      >
        Change my rental address
      </base-button-primary>
      <base-button-secondary
        class="m-t-2 cancel-button"
        @clicked="$emit('cancel')"
        data-testid="cancel-address-button"
      >
        Cancel
      </base-button-secondary>
    </div>
  </div>
</template>

<script>
import BaseButtonSecondary from '../../buttons/BaseButtonSecondary.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseIcon from '../../assets/BaseIcon.vue';

export default {
  name: 'UpdateWarning',
  components: { BaseIcon, BaseButtonPrimary, BaseButtonSecondary },
  methods: {},
};
</script>

<style lang="scss" scoped>
.warning-card_content {
  margin-top: 25px;
  p {
    text-align: center !important;
  }
}

.warning-card_buttons {
  margin-top: 32px;
}

.title {
  font-size: 20px
}
</style>
