<template>
  <PinInputScreen
    v-if="!isLoading && activeScreen == 'confirmExistingPin'"
    key="confirmExistingPin"
    pinType="confirmExistingPin"
    @onConfirmPin="confirmPin"
    :pinInputDisabled="pinInputDisabled"
    ref="PinScreenExistingPin"
  />
  <PinInputScreen
    v-else-if="!isLoading && activeScreen == 'createPinFirst'"
    key="createPinFirst"
    pinType="createPinFirst"
    @onConfirmPin="confirmPin"
    :pinInputDisabled="pinInputDisabled"
  />
  <PinInputScreen
    v-else-if="!isLoading && activeScreen == 'createPinSecond'"
    key="createPinSecond"
    pinType="createPinSecond"
    @onConfirmPin="confirmPin"
    :pinInputDisabled="pinInputDisabled"
    ref="PinScreenPinSecond"
  />
</template>

<script>
import { checkUserHasPin } from '../../controllers/user';
import PinInputScreen from '../screens/pinAuth/PinInputScreen.vue';
import { submitPin } from '../../controllers/auth.js';
import { LocalStorage } from '../../utils/localStorage';

export default {
  name: 'PinAuth',
  components: {
    PinInputScreen,
  },
  data() {
    return {
      isLoading: true,
      pinInputDisabled: false,
      verificationId: null,
      pinExists: false,
      firstPin: null,
    };
  },

  async created() {
    this.$store.dispatch('navigation/hideProgessBar');

    const applicationId = localStorage.getItem('applicationId');
    const applicationDataReq = await this.$store.dispatch(
      'application/getApplicationRecord',
      { applicationId }
    );

    if (!applicationDataReq.status) {
      this.$router.push('/error');
      return false;
    }
    const applicationData = applicationDataReq.data;

    const verificationId = localStorage.getItem('verificationId');
    if (!verificationId) {
      this.$router.push('/auth');
      return false;
    }
    this.verificationId = verificationId;

    const userId = applicationData.userId;
    const payload = { userId };
    const checkPinReq = await checkUserHasPin(payload);

    if (!checkPinReq.status) {
      this.$router.push('/error');
      return false;
    }

    const userHasPin = checkPinReq.data == 'foundpin';
    this.pinExists = userHasPin;
    this.isLoading = false;

    this.$store.dispatch('navigation/stopLoading');
  },

  computed: {
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];
      if (!activeScreen && this.pinExists) {
        return 'confirmExistingPin';
      } else if (!activeScreen && !this.pinExists) {
        return 'createPinFirst';
      } else {
        return activeScreen;
      }
    },
  },

  methods: {
    confirmPin(type, pin) {
      switch (type) {
        case 'confirmExistingPin':
          // this.pinInputDisabled = true;
          this.sendPin(pin);
          break;

        case 'createPinFirst':
          this.firstPin = pin;
          this.$store.dispatch('navigation/updateScreen', 'createPinSecond');
          break;
        case 'createPinSecond':
          if (this.firstPin !== pin) {
            if (
              this.$refs.PinScreenPinSecond &&
              this.$refs.PinScreenPinSecond.$refs.PinInput
            ) {
              this.$refs.PinScreenPinSecond.$refs.PinInput.clearPin();
            }

            this.$store.dispatch('error/updatePopupErrorStatus', {
              status: true,
              message: 'PIN does not match',
            });
            return false;
          }
          // this.pinInputDisabled = true;
          this.sendPin(pin);
          break;
      }
    },

    async sendPin(pin) {
      this.$store.dispatch('navigation/startLoading');

      // remove focus
      document.activeElement.blur();
      const applicationId = localStorage.getItem('applicationId');

      const payload = {
        phoneVerificationId: this.verificationId,
        pin,
        applicationId,
      };
      const submitPinReq = await submitPin(payload);

      // Bad request
      if (!submitPinReq.status) {
        const code = submitPinReq.statusCode;

        if (code == 403) {
          this.$store.dispatch(
            'error/setErrorMessage',
            submitPinReq.friendlyMessage
          );
          this.$router.push('/paused');
          return false;
        }

        this.$store.dispatch('navigation/stopLoading');
        this.$store.dispatch('error/updatePopupErrorStatus', {
          status: true,
          message: submitPinReq.friendlyMessage,
        });
        // this.pinInputDisabled = false;

        if (this.pinExists) {
          this.$refs.PinScreenExistingPin.$refs.PinInput.clearPin();
          return false;
        } else {
          this.$refs.PinScreenPinSecond.$refs.PinInput.clearPin();
          return false;
        }
      }

      // Success

      const token = submitPinReq.data.customToken;

      const loginReq = await this.$store.dispatch('auth/signIn', { token });

      if (!loginReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'pin_entered_successfully',
        page_title: 'pin',
      });
    },
  },
};
</script>

<style>
.isLoading {
  justify-content: left;
}
</style>
