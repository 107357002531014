<template>
    <KBAModule v-if="showKBA" key="KBA" />
    <CISIdVerificationFail v-else-if="!isLoading" @kbaClick="runKBA" />
</template>

<script>
import { getCISIdVerificationDecision } from '../../controllers/decision-engine.js';
import CISIdVerificationFail from '../screens/idVerification/CISIdVerificationFail.vue';
import KBAModule from './KBAModule.vue';

export default {
    name: 'TrueLayerCISIdCheck',
    components: { CISIdVerificationFail, KBAModule },
    data() {
        return {
            isLoading: true,
            showKBA: false,
        };
    },

    async created() {
        const applicationId = localStorage.getItem('applicationId');
        const userId = this.$store.getters['auth/userId'];

        const payload = {
            userId,
            applicationId,
        };

        const response = await getCISIdVerificationDecision(payload);

        if (!response.status) {
            this.$router.push('/error');
            return false;
        }

        const decision = response.data?.decision;

        if (decision == 'fail') {
            this.isLoading = false;
            this.$store.dispatch('navigation/stopLoading');
            return false;
        }

        this.$store.dispatch('navigation/modulePassed', {
            event: 'open_banking_cis_id_check_complete',
            page_title: this.moduleName,
        });
    },
    methods: {
        tryAgain() {
            this.$store.dispatch('updateLoaderAnimationType', 'default');
            this.$store.dispatch('navigation/startLoading');
            this.$router.push('/');
        },
        runKBA() {
            this.showKBA = true;
        },
    },
};
</script>

<style></style>
