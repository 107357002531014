<template>
  <base-screen contentCentered desktopNoBG>
    <template #main>
      <div class="flex-row center">
        <container-sized width="65%">
          <BaseImage image="check-illustration" />
        </container-sized>
      </div>
      <div class="m-t-7">
        <h3 class="text-centered">
          We're experiencing some technical difficulties
        </h3>
        <div class="m-t-6 m-b-4">
          <p class="text-centered grey text-centered">
            This doesn't happen very often, please check back later.
          </p>
        </div>
      </div>
    </template>
    <template #footer></template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  components: { BaseScreen, ContainerSized, BaseImage },
  name: 'PausedScreen',
  data() {
    return {
      bgImg: null,
      isLoading: true,
    };
  },

  mounted() {
    this.$store.dispatch('navigation/stopLoading');
  },
};
</script>
