import { Authorization } from '../../utils/authorization';
import { extractDataSuccessResponse } from './helper';
import { getAppCheckToken } from '../../main.js';
import * as Sentry from '@sentry/vue';

// Add bearer token or secret key to request header
const addTokenInterceptor = async (config) => {
  const token = Authorization.get.customToken('token') ?? localStorage.getItem('token');

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  const publicKey = process.env.VUE_APP_PUBLIC_KEY; //TODO: Switch to public key
  config.headers.public_key = publicKey;


  if (config.authRequest) {
    // Initialize app check and append the token to the request
    try {
      const appCheckToken = await getAppCheckToken();
      config.headers['X-Firebase-AppCheck'] = appCheckToken;

    } catch (error) {
      const applicationId = localStorage.getItem('applicationId');
      const uid = localStorage.getItem('uid');

      Sentry.withScope((scope) => {
        scope.setContext('Failed to execute reCAPTCHA and App check', { applicationId, userId: uid });
        Sentry.captureException(error);
      });
    }
  }



  return config;
};

// reject interceptor
const rejectErrorInterceptor = error => Promise.reject(error);



// success response interceptor. Searches for nested data and returns an object 
// {
//   "status": true,
//     "data": [
//       {
//         "accountHolder": "Aisha Pine",
//         "supportsDirectDebit": true,
//         "bankName": "Revolut"
//       }
//     ]
// }

const successResponseInterceptor = response => {
  const data = extractDataSuccessResponse(response);

  return {
    status: true,
    data
  }

}


// error response interceptor. Returns an object:  
// {
//   "status": false,
//   "statusCode": 400,
//   "message": "Request must have both applicationId and userId",
//   "friendlyMessage": "An unknown error occurred, please try again or contact support"
// }


const errorResponseInterceptor = error => {


  let friendlyMessage = 'An unknown error occurred, please try again or contact support.';

  const errorData = {
    status: false,
    statusCode: error.response.status,
    message: error.response.data?.meta?.message,
    friendlyMessage
  }

  if (error.response.data?.friendlyMessage) {
    errorData.friendlyMessage = error.response.data?.friendlyMessage
  }

  if (error.response.data?.meta?.friendlyMessage) {
    errorData.friendlyMessage = error.response.data?.meta?.friendlyMessage;
  }



  return errorData;

}


/**
 * @function refreshTokenInterceptor
 * @param {*} error -> axios error
 * @returns {Promise}
 * This intercepts 401 responses and basically gets a new token,
 * then uses that new token for all requests that came during, the process
 * of getting a new token.
 */




export { addTokenInterceptor, rejectErrorInterceptor, successResponseInterceptor, errorResponseInterceptor };