<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-title>
        Please enter your debit card details to
        <strong>set up your payment.</strong>
      </base-title>
      <div class="card_details_form">
        <div
          class="card_details_element card_number_element"
          :class="cardNumberError ? 'card_details_error' : null"
          @click="setFocus('cardNumberElement')"
        >
          <div id="card_details_icon">
            <container-sized
              width="36px"
              class="card_details_icon_block"
            >
              <BaseIcon icon="card" />
            </container-sized>
          </div>
          <transition
            appear
            mode="out-in"
            enter-active-class="animate__animated animate__fadeInUp animate__faster"
            leave-active-class="animate__animated animate__fadeOutDown animate__faster"
          >
            <div v-if="cardBrand" id="card_details_brand">
              <container-sized
                :width="cardBrand == 'amex' ? '42px' : '36px'"
                class="card_details_icon_block"
              >
                <BaseImage :image="cardBrand" />
              </container-sized>
            </div>
          </transition>
          <label
            class="card_number"
            :class="cardNumberError ? 'card_details_error' : null"
          >
            Card Number
          </label>

          <div id="card-number-element" ref="cardNumberElement"></div>
        </div>
        <base-error-message
          v-if="cardNumberError"
          key="cardNumberError"
        >
          {{ cardNumberError }}
        </base-error-message>

        <div class="flex-row m-t-4 card_details_row">
          <div
            class="card-element card_details_element"
            :class="cardExpiryError ? 'card_details_error' : null"
            @click="setFocus('cardExpiryElement')"
          >
            <label>Expiry Date</label>
            <div
              id="card-expiry-element"
              ref="cardExpiryElement"
            ></div>
          </div>
          <div
            class="card-element card_details_element"
            :class="cardCvcError ? 'card_details_error' : null"
            @click="setFocus('cardCvcElement')"
          >
            <label>CVC</label>
            <div id="card-cvc-element" ref="cardCvcElement"></div>
          </div>
        </div>
        <base-error-message
          v-if="cardExpiryError"
          key="cardExpiryError"
        >
          {{ cardExpiryError }}
        </base-error-message>
        <base-error-message v-if="cardCvcError" key="cardCvcError">
          {{ cardCvcError }}
        </base-error-message>
      </div>
    </template>
    <template #footer>
      <base-button-primary
        id="Continue"
        :disabled="!formIsValid"
        @clicked="onSubmit"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
import { loadStripe } from '@stripe/stripe-js';
import { stripePlans, stripePublicKey } from '../../../config/variables.js';
import {
    getSubscriptionById,
    rentReportingPayment,
    checkSubscriptionStatus,
} from '../../../controllers/rent-reporting';

export default {
    name: 'CardDeatils',
    components: {
        BaseIcon,
        ContainerSized,
        BaseImage,
        BaseErrorMessage,
        BaseScreen,
        BaseButtonPrimary,
        BaseTitle,
    },
    props: {
        selectedPlan: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            stripe: null,
            cardNumberElement: null,
            cardExpiryElement: null,
            cardCvcElement: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
        };
    },

    computed: {
        // Get card brand if it is avaliable
        cardBrand() {
            if (this.cardNumber) {
                return this.cardNumber.brand !== 'unknown'
                    ? this.cardNumber.brand
                    : null;
            }

            return null;
        },

        cardNumberError() {
            return this.cardNumber?.error
                ? this.cardNumber?.error.message
                : null;
        },
        cardExpiryError() {
            return this.cardExpiry?.error
                ? this.cardExpiry?.error.message
                : null;
        },
        cardCvcError() {
            return this.cardCvc?.error ? this.cardCvc?.error.message : null;
        },

        formIsValid() {
            return (
                this.cardNumber?.validationStatus == 'valid' &&
                this.cardExpiry?.validationStatus == 'valid' &&
                this.cardCvc?.validationStatus == 'valid'
            );
        },
    },
    async mounted() {
        // Load Stripe

        // TODO: move to env
        this.stripe = await loadStripe(stripePublicKey());

        // Create and mount Stripe elements
        var elements = this.stripe.elements();

        const style = {
            base: {
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '500',
                letterSpacing: '-0.01em',
                color: '#242424',

                '::placeholder': {
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: '500',
                    letterSpacing: '-0.01em',
                    color: '#A6BDBA',
                },
            },
            invalid: {
                color: '#242424',
                iconColor: '#EC5A7D',
            },
        };

        // Create Stripe elements
        this.cardNumberElement = elements.create('cardNumber', { style });
        this.cardExpiryElement = elements.create('cardExpiry', { style });
        this.cardCvcElement = elements.create('cardCvc', { style });

        // Mount Stripe elements
        this.cardNumberElement.mount('#card-number-element');
        this.cardExpiryElement.mount('#card-expiry-element');
        this.cardCvcElement.mount('#card-cvc-element');

        // Sign up for Stripe change event
        this.cardNumberElement.on('change', this.onInputChange);
        this.cardExpiryElement.on('change', this.onInputChange);
        this.cardCvcElement.on('change', this.onInputChange);

        this.$nextTick(() => {
            setTimeout(() => {
                this.cardNumberElement.focus();
            }, 200);
        });
    },

    methods: {
        setFocus(elementName) {
            if (elementName === 'cardNumberElement') {
                this.cardNumberElement.focus();
            } else if (elementName === 'cardExpiryElement') {
                this.cardExpiryElement.focus();
            } else if (elementName === 'cardCvcElement') {
                this.cardCvcElement.focus();
            }
        },

        onInputChange(event) {
            this.$store.dispatch('error/hideErrorSnackbar');
            const valid = event.complete && !event.empty && !event.error;

            this[event.elementType] = event;
            this[event.elementType].validationStatus = valid
                ? 'valid'
                : 'invalid';

            if (event.complete) {
                switch (event.elementType) {
                    case 'cardNumber':
                        this.cardExpiryElement.focus();
                        break;
                    case 'cardExpiry':
                        this.cardCvcElement.focus();
                        break;
                    default:
                        break;
                }
            }
        },

        async onSubmit() {
            this.$store.dispatch('error/hideErrorSnackbar');
            this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
            this.$store.dispatch('navigation/startLoading');

            try {
                const response = await this.stripe.createToken(
                    this.cardNumberElement
                );
                if (!response.token) {
                    this.$router.push('/error');
                    return false;
                }

                const userId = this.$store.getters['auth/userId'];
                if (!userId) {
                    this.$router.push('/error');
                    return false;
                }

                const applicationId = localStorage.getItem('applicationId');
                const payload = {
                    userId,
                    applicationId,
                    source: response.token.id,
                    product: 'rentReporting',
                    planId: this.selectedPlan,
                };

                const paymentRequest = await rentReportingPayment(payload);
                if (!paymentRequest.status) {
                    this.$store.dispatch('navigation/stopLoading');
                    this.$store.dispatch('error/updatePopupErrorStatus', {
                        status: true,
                        message: paymentRequest.friendlyMessage,
                    });
                    return;
                }

                if (!paymentRequest.data.clientSecret) {
                  // Payment is made automatically
                  this.$store.dispatch('navigation/modulePassed', {
                        event: 'rr_payment_made',
                        page_title: 'rentReportingPayment',
                    });

                    return;
                }

                // Confirm card payment
                const clientSecret = paymentRequest.data.clientSecret;
                const subscriptionId = paymentRequest.data.subscription;

                const result = await this.stripe.confirmCardPayment(
                    clientSecret
                );

                if (result.error) {
                    this.$store.dispatch('navigation/stopLoading');
                    this.$store.dispatch('error/updatePopupErrorStatus', {
                        status: true,
                        message:
                            result.error?.message ||
                            'Card payment confirmation failed',
                    });
                    return;
                }

                const payloadNew = {
                    userId,
                    subscriptionId,
                    applicationId,
                };

                await this.checkSubscriptionRecursive(payloadNew);
            } catch (error) {
                this.$store.dispatch('navigation/stopLoading');
                this.$store.dispatch('error/updatePopupErrorStatus', {
                    status: true,
                    message:
                        'An unknown error occurred, please try to refresh the page or contact support.',
                });
            }
        },

        async checkSubscriptionRecursive(payload, retryCount = 0) {
            try {
                const getSubscription = await checkSubscriptionStatus(payload);

                if (!getSubscription.status) {
                    this.$store.dispatch('navigation/stopLoading');
                    this.$store.dispatch('error/updatePopupErrorStatus', {
                        status: true,
                        message:
                            'An unknown error occurred, please try to refresh the page or contact support.',
                    });
                    return;
                }

                if (getSubscription.data.subscriptionStatus !== 'active') {
                    if (retryCount < 10) {
                        await new Promise((resolve) =>
                            setTimeout(resolve, 1000)
                        ); // 1 second delay
                        await this.checkSubscriptionRecursive(
                            payload,
                            retryCount + 1
                        );
                    } else {
                        this.$router.push('/error');
                        return false;
                    }
                } else {
                    this.$store.dispatch('navigation/modulePassed', {
                        event: 'rr_payment_made',
                        page_title: 'rentReportingPayment',
                    });
                }
            } catch (error) {
                this.$store.dispatch('navigation/stopLoading');
                this.$store.dispatch('error/updatePopupErrorStatus', {
                    status: true,
                    message:
                        'An unknown error occurred, please try to refresh the page or contact support.',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';

.card_details_element {
    border: 1px solid $lightGrey;
    border-radius: 8px;
    padding: 12px;
    transition: all 0.2s;
    &.card_details_error {
        border: 1px solid $critical;
    }
}

.card_number_element {
    position: relative;
}

label {
    color: $darkGrey;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: -0.01em;
    text-align: left;

    &.card_details_error {
        color: $critical;
    }
}

.card_details_icon_block {
    position: absolute;
    top: 20px;
    right: 10px;
}

#card_details_brand {
    position: absolute;
    top: 0px;
    right: 3px;
}

#card_details_icon {
    position: absolute;
    top: -4px;
    left: 55px;
}

.card_details_row {
    gap: 8px;

    div {
        width: 50%;
    }
}

#card-number-element {
    padding-left: 40px;
}

.card_number {
    padding-left: 40px;
}
</style>
