<template>
  <IncomeScreen
    key="incomeScreen"
    v-if="activeScreen == 'incomeScreen'"
    @updateForm="updateForm"
  />
  <EmploymentStatus
    key="employmentStatus"
    v-else-if="activeScreen == 'employmentStatus'"
    @updateForm="updateForm"
  />
  <CompanyName
    key="companyName"
    v-else-if="activeScreen == 'companyName'"
    @updateForm="updateForm"
  />
</template>

<script>
import IncomeScreen from '../screens/employmentDetails/IncomeScreen.vue';
import CompanyName from '../screens/employmentDetails/CompanyName.vue';
import EmploymentStatus from '../screens/employmentDetails/EmploymentStatus.vue';

export default {
  name: 'EmploymentDetails',
  components: { EmploymentStatus, CompanyName, IncomeScreen },

  data() {
    return {
      formDetails: {
        annualIncome: null,
        employmentStatus: null,
        employerName: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch('navigation/stopLoading');
  },
  methods: {
    async updateForm(id, value) {
      this.formDetails[id] = value;
      const updatedData = { [this.formDetails[id]]: value };
      this.$store.dispatch('application/updateApplicationData', updatedData);

      if (id == 'annualIncome') {
        this.$store.dispatch('navigation/updateScreen', 'employmentStatus');
      } else if (id == 'employmentStatus') {
        const employed = [
          'employedFullTime',
          'employedPartTime',
          'selfEmployed',
        ];

        if (employed.includes(value)) {
          this.$store.dispatch('navigation/updateScreen', 'companyName');
        } else {
          await this.sendForm();
        }
      } else if (id == 'employerName') {
        await this.sendForm();
      }
    },

    async sendForm() {
      this.$store.dispatch('navigation/startLoading');
      // this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);
      const data = this.formDetails;

      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data, step: 'employmentDetails' }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'employment_details_entered',
        page_title: 'employmentDetails',
      });
    },
  },

  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];
      if (!activeScreen) {
        return 'incomeScreen';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
  },
};
</script>
