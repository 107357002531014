<template>
    <ErrorOB v-if="showError" :errorCode="errorCode" @clicked="tryAgain" />
</template>

<script>
import * as Sentry from '@sentry/browser';
import ErrorOB from '../screens/error/ErrorOB.vue';
import { Firestore } from '../../utils/firestore';
import { forceManualRoute } from '../../controllers/rent-reporting';

export default {
    name: 'AccountScoreAnalyticsRetrieved',
    components: {
        ErrorOB,
    },
    data() {
        return {
            showError: false,
            errorCode: null,
            unsubscribeApp: null,
            unsubscribeConsents: null,
            timeoutId: null,
            pollingTimeoutId: null,
            checkAttempts: 0,
            maxAttempts: 20,
        };
    },
    mounted() {
        this.subscribeToDocs();

        // Wait for 7 seconds before starting manual polling
        this.timeoutId = setTimeout(() => {
            this.startPolling();
        }, 7000); // 7 seconds delay
    },
    beforeUnmount() {
        this.cleanup();
    },
    methods: {
        async subscribeToDocs() {
            this.$store.dispatch('navigation/updateLoaderAnimationType', 'de');
            const userId = this.$store.getters['auth/userId'];
            const applicationId = localStorage.getItem('applicationId');

            // Subscribe to Applications document
            this.unsubscribeApp = await Firestore.subscribeToDoc(
                `Applications`,
                applicationId,
                (doc) => {
                    if (
                        doc &&
                        doc.steps &&
                        doc.steps.accountScoreAnalyticsRetrieved
                    ) {
                        const stepStatus =
                            doc.steps.accountScoreAnalyticsRetrieved.status;
                        if (stepStatus === 'passed') {
                            this.cleanup();
                            this.$store.dispatch('navigation/modulePassed', {
                                event: null,
                                page_title: 'AccountScoreAnalyticsRetrieved',
                            });
                        }
                    }
                }
            );

            // Set a 30 seconds timeout to handle overall process timeout
            this.pollingTimeoutId = setTimeout(() => {
                Sentry.withScope((scope) => {
                    scope.setContext(
                        'Timeout error in AccountScoreAnalyticsRetrieved',
                        {
                            applicationId,
                            userId,
                        }
                    );
                    Sentry.captureException(new Error('Timeout error'));
                });
                this.cleanup(); // Clean up subscriptions and clearTimeout

                const product = localStorage.getItem('productName');
                if (product === 'rentReportingAndMonitor') {
                    this.forceManualRoute(userId, applicationId);
                } else {
                    this.$store.dispatch('navigation/stopLoading');
                    this.errorCode = 'timeout';
                    this.showError = true;
                }
            }, 30000);
        },

        async forceManualRoute(userId, applicationId) {
            
            const req = await forceManualRoute({
                userId,
                applicationId,
            });

            if (req.status) {
                this.$store.dispatch('navigation/modulePassed', {
                    event: null,
                    page_title: 'AccountScoreAnalyticsRetrieved',
                });
            } else {
                this.showError = true;
            }
        },
        async startPolling() {
            // Begin the manual polling process
            await this.checkNextStep();
        },

        async checkNextStep() {
            try {
                if (this.checkAttempts >= this.maxAttempts) {
                    this.$store.dispatch('navigation/stopLoading');
                    this.errorCode = 'timeout';
                    this.showError = true;
                }

                const applicationId = localStorage.getItem('applicationId');
                this.checkAttempts++; // Increment the counter

                const nextStepReq = await this.$store.dispatch(
                    'navigation/getNextStep',
                    { applicationId }
                );
                const nextStep = nextStepReq.data.nextStep;

                if (nextStep !== 'accountScoreAnalyticsRetrieved') {
                    clearTimeout(this.pollingTimeoutId); // Clear the polling timeout as we've successfully moved on
                    this.cleanup(); // Clean up before navigating
                    this.$store.dispatch('navigation/modulePassed', {
                        event: null,
                        page_title: 'AccountScoreAnalyticsRetrieved',
                    });
                } else {
                    // If it is not, check the step again in 2 seconds
                    setTimeout(() => this.checkNextStep(), 2000);
                }
            } catch (error) {
                this.showErrorScreen('error');
            }
        },

        showErrorScreen() {
            this.cleanup();
            this.$store.dispatch('navigation/stopLoading');
            this.errorCode = 'timeout';
            this.showError = true;
        },

        cleanup() {
            if (
                this.unsubscribeApp &&
                typeof this.unsubscribeApp === 'function'
            ) {
                this.unsubscribeApp();
            }
            clearTimeout(this.timeoutId);
            clearTimeout(this.pollingTimeoutId);
        },

        tryAgain() {
            this.$store.dispatch('navigation/startLoading');
            this.$router.go();
        },
    },
};
</script>
