<template>
  <div>
    <p class="pillar-heading m-t-7">All Banks</p>
    <v-text-field
      class="bankSearchInput rounded-pillar"
      rounded
      hide-details="auto"
      id="bankSelected"
      @input="() => searchInput()"
      v-model="searchText"
      placeholder="Search for your bank"
      shouldAutofocus
    >
      <template v-slot:append>
        <v-icon
          v-if="searchText != ''"
          class="pa-1 mt-1"
          style="cursor: pointer"
          color="#6E7877"
          @click="clearSearchText"
        >
          close
        </v-icon>
        <v-icon class="pa-1 mt-1" color="#6E7877" v-if="searchText == ''">
          search
        </v-icon>
      </template>
    </v-text-field>

    <standard-list
      borderless
      dense
      :key="1"
      :data-index="1"
      :items="filteredItems"
      gutter
      arrow
      images
      @chosen="selectBank"
    ></standard-list>

    <NoResults v-if="!filteredItems.length && searchText != ''" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
      banks: [
        {
          text: 'Account Score Test Bank',
          code: 'astestbank',
          show: false,
        },
        {
          text: 'Allied Irish Bank',
          code: 'alliedirishbank',
          show: true,
        },
        {
          text: 'Allied Irish Business',
          code: 'alliedirishbusiness',
          show: false,
        },
        {
          text: 'Amazon Mastercard',
          code: 'amazonmastercard',
          show: false,
        },
        {
          text: 'Aqua',
          code: 'aqua',
          show: false,
        },
        {
          text: 'B Bank',
          code: 'bbank',
          show: false,
        },
        {
          text: 'Bank Of Scotland',
          code: 'bos',
          show: true,
        },
        {
          text: 'Bank Of Scotland Business',
          code: 'bankofscotlandbusiness',
          show: false,
        },
        {
          text: 'Bank Of Scotland Commerical',
          code: 'bankofscotlandcommercial',
          show: false,
        },
        {
          text: 'Bank of Ireland',
          code: 'boi',
          show: true,
        },
        {
          text: 'Bank of Ireland Business',
          code: 'boibusiness',
          show: false,
        },
        {
          text: 'Barclays',
          code: 'barclays',
          show: true,
          popular: true,
        },
        {
          text: 'Barclays Business',
          code: 'barclaysbusiness',
          show: false,
        },
        {
          text: 'Barclays Corporate',
          code: 'barclayscorporate',
          show: false,
        },
        {
          text: 'Barclaycard',
          code: 'barclaycard',
          show: false,
        },
        {
          text: 'Capital One',
          code: 'capitalone',
          show: false,
        },
        {
          text: 'Cashplus',
          code: 'cashplus',
          show: true,
        },
        {
          text: 'Cater Allen',
          code: 'caterallen',
          show: true,
        },
        {
          text: 'Clydesdale Bank',
          code: 'clydesdale',
          show: true,
        },
        {
          text: 'Coutts',
          code: 'Coutts',
          show: true,
        },
        {
          text: 'Cumberland',
          code: 'cumberland',
          show: true,
        },
        {
          text: 'Danske',
          code: 'danske',
          show: true,
        },
        {
          text: 'Danske Bank Business',
          code: 'danskebankbusiness',
          show: false,
        },

        {
          text: 'First Direct',
          code: 'firstdirect',
          show: true,
        },
        {
          text: 'First Trust Bank',
          code: 'firsttrustbank',
          show: true,
        },
        {
          text: 'Fluid',
          code: 'fluid',
          show: false,
        },
        {
          text: 'Halifax',
          code: 'halifax',
          show: true,
          popular: true,
        },
        {
          text: 'HSBC',
          code: 'hsbc',
          show: true,
          popular: true,
        },
        {
          text: 'HSBC Business',
          code: 'hsbcbusiness',
          show: false,
        },
        {
          text: 'HSBC Kinetic',
          code: 'hsbckinetic',
          show: false,
        },
        {
          text: 'Lloyds',
          code: 'lloyds',
          show: true,
          popular: true,
        },
        {
          text: 'Lloyds Business Banking',
          code: 'lloydsbusinessbanking',
          show: false,
        },
        {
          text: 'Lloyds Commercial',
          code: 'lloydscommercial',
          show: false,
        },
        {
          text: 'Marbels',
          code: 'marbels',
          show: false,
        },
        {
          text: 'MBNA',
          code: 'mbna',
          show: false,
        },
        {
          text: 'Mettle',
          code: 'mettle',
          show: false,
        },
        {
          text: 'M&S Bank',
          code: 'marksandspencer',
          show: true,
        },
        {
          text: 'Metro Bank',
          code: 'metro',
          show: false,
        },
        {
          text: 'Monzo',
          code: 'monzo',
          show: true,
          popular: true,
        },
        {
          text: 'Nationwide Building Society',
          code: 'nationwidebuildingsociety',
          show: true,
          popular: true,
        },
        {
          text: 'Natwest',
          code: 'natwestbank',
          show: true,
          popular: true,
        },
        {
          text: 'Natwest Business',
          code: 'natwestbusiness',
          show: false,
        },
        {
          text: 'Opus',
          code: 'opus',
          show: false,
        },
        {
          text: 'RBS',
          code: 'rbs',
          show: true,
        },
        {
          text: 'RBS Business',
          code: 'rbsbusiness',
          show: false,
        },
        {
          text: 'Revolut',
          code: 'revolut',
          show: true,
        },
        {
          text: 'Santander',
          code: 'santander',
          show: true,
          popular: true,
        },
        {
          text: 'Sainsburys Bank',
          code: 'sainsburysbank',
          show: true,
        },
        {
          text: 'Starling',
          code: 'starling',
          show: true,
        },
        {
          text: 'Tesco Bank',
          code: 'tesco',
          show: false,
        },
        {
          text: 'Tide',
          code: 'tide',
          show: false,
        },
        {
          text: 'TSB',
          code: 'tsb',
          show: true,
          popular: true,
        },
        {
          text: 'TUI',
          code: 'tui',
          show: false,
        },
        {
          text: 'Ulster Bank',
          code: 'ulsterbank',
          show: true,
        },
        {
          text: 'Ulster Business',
          code: 'ulsterbusiness',
          show: false,
        },
        {
          text: 'Vanquis Bank',
          code: 'vanquis',
          show: false,
        },
        {
          text: 'Virgin Money',
          code: 'virginmoney',
          show: false,
        },
        {
          text: 'Wallis',
          code: 'wallis',
          show: false,
        },
        {
          text: 'Yorkshire Bank',
          code: 'yorkshirebank',
          show: true,
        },
      ],
    };
  },
  async created() {
    if (process.env.VUE_APP_ENV != 'production') {
      this.banks[0].show = true;
    }
  },
  methods: {
    selectBank(bank) {
      this.$emit('selectBank', bank.code);
    },
    searchInput() {
      this.$emit('searchInput', this.searchText);
    },
    clearSearchText() {
      this.searchText = '';
      this.searchInput();
    },
  },
  computed: {
    filteredItems() {
      if (this.searchText != '') {
        return this.banks.filter(
          (x) =>
            x.text
              .replace(/\s/g, '')
              .toLowerCase()
              .startsWith(this.searchText.replace(/\s/g, '').toLowerCase()) &&
            x.show == true
        );
      } else {
        return this.banks.filter((x) => x.show == true);
      }
    },
  },
};
</script>

<style lang="scss">
.bankSearchInput {
  background-color: #f5faf9;
  padding: 7px 0px;
  .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin-top: 0px !important;
      }

      .v-text-field__slot {
        padding-bottom: 0px !important;
        input {
          padding: 0px !important;
        }
      }
    }
  }
}
</style>
