<template>
  <EquifaxCISFail v-if="errorScreen" @addNewAddress="addNewAddress" />
</template>

<script>
import {
  checkForCISMatch,
  resetApplicationDataModule,
} from '../../controllers/cis.js';

import EquifaxCISFail from '../screens/equifaxCISSearch/EquifaxCISFail.vue';

export default {
  components: { EquifaxCISFail },
  name: 'EquifaxCisSearch',
  data() {
    return {
      errorScreen: false,
      allowRetry: false,
    };
  },
  async created() {
    const applicationId = localStorage.getItem('applicationId');
    const resp = await checkForCISMatch({ applicationId });

    if (!resp.status) {
      this.$router.push('/error');
      return false;
    }

    const response = resp.data;

    if (response.singleMatch == false) {
      this.errorScreen = true;
      this.$store.dispatch('navigation/stopLoading');
      return false;
    }

    this.$store.dispatch('navigation/modulePassed', {
      event: 'equifax_search_successful',
      page_title: 'EquifaxCisSearch',
    });
  },

  methods: {
    async addNewAddress() {
      this.$store.dispatch('navigation/startLoading');
      // this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);
      const userId = this.$store.getters['auth/userId'];
      const applicationId = localStorage.getItem('applicationId');

      if (!userId || !applicationId) {
        this.$router.push('/error');
        return false;
      }

      const updateReq = await resetApplicationDataModule({
        userId,
        applicationId,
      });

      if (!updateReq.status) {
        this.$router.push('/error');
        return false;
      }

      // Refresh to get the next module
      this.$router.go();
    },
  },
};
</script>
