<template>
  <div>
    <div v-if="!showAnimation" class="whiteBg"></div>
    <div class="lottie-animation_wrap centered">
      <div>
        <div
          ref="lottieContainer"
          class="lottie-animation"
          :class="{ 'transition-fade': true, show: showAnimation }"
        ></div>

        <transition name="fade">
          <h5 v-if="showAnimation" class="text-centered consentComplete_title">
            Connecting you with your Indian Credit Score...
          </h5>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '../../../../public/lottie-animation/india-loader.json';

export default {
  data() {
    return {
      showAnimation: false,
    };
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
    const container = this.$refs.lottieContainer;

    // Load the animation without autoplay
    const animation = lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: true,
      autoplay: false, // Set autoplay to false
      animationData: animationData,
    });

    // Pause the animation immediately
    animation.pause();

    // Delay before playing the animation
    const delay = 500; // Set the desired delay in milliseconds
    setTimeout(() => {
      this.showAnimation = true;
      animation.play();
      setTimeout(() => {
        this.$emit('updateForm', 'dataTransferConfirmed');
      }, 4000);
    }, delay);
  },
};
</script>

<style scoped>
.lottie-animation {
  width: 100%;
}

.whiteBg {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
}

.lottie-animation_wrap {
  width: 100%;
  height: calc(100% - 56px);
}

.consentComplete_title {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.transition-fade {
  opacity: 0;
  transition: opacity 0.5s;
}

.transition-fade.show {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
