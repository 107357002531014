import { sendRequest } from "../config/axios/helper";


export const experianUrls = {
    CREATE_CUSTOMER: {
        url: '/experian/create-customer',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    VERIFY_CUSTOMER: {
        url: '/experian/verify-customer',
        type: 'post',
        reqParams: ['userId', 'experianPartyId']
    },
    CONNECT_SCORE: {
        url: '/experian/connect-credit-score',
        type: 'post',
        reqParams: ['userId']
    },
    FETCH_SCORE: {
        url: '/experian/fetch-credit-score/',
        type: 'get',
        reqParams: ['userId']
    },
    CROSS_CORE: {
        url: '/experian/create-cross-core-submit',
        type: 'post',
        reqParams: ['applicationId']
    },
    DELPHI_SEARCH: {
        url: '/experian/create-customer-search',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'searchType']
    }


}



export const experianCreateCustomer = async params => {
    return await sendRequest(experianUrls.CREATE_CUSTOMER, params);
};


export const experianVerifyCustomer = async params => {
    return await sendRequest(experianUrls.VERIFY_CUSTOMER, params);
};

export const experianConnectScore = async params => {
    return await sendRequest(experianUrls.CONNECT_SCORE, params);
};

export const experianFetchScore = async params => {

    const reqObj = experianUrls.FETCH_SCORE;
    reqObj.url = experianUrls.FETCH_SCORE.url + params?.userId
    return await sendRequest(reqObj, params);

};


export const experianCrossCore = async params => {
    return await sendRequest(experianUrls.CROSS_CORE, params);
};



export const experianDelphiSearch = async params => {
    return await sendRequest(experianUrls.DELPHI_SEARCH, params);
};
