<template>
  <transition name="fade" appear>
    <base-screen contentCentered>
      <template #main>
        <base-modal v-if="modalIsActive" @closeModal="modalIsActive = false">
          <div class="card-benefits-wrap">
            <p>
              What you get with
              <br />
              <strong>{{ cardProductName }}</strong>
            </p>
            <div class="benefits-list">
              <div
                class="benefits-row"
                v-for="item in selectedBenefits"
                :key="item.icon"
              >
                <div class="benefits-icon">
                  <BaseIcon :icon="item.icon" size="38" />
                </div>
                <div class="benefits-text">
                  <p class="benefits-title">{{ item.title }}</p>
                  <p class="benefits-description">{{ item.description }}</p>
                </div>
              </div>
            </div>

            <base-button-primary @clicked="sendValue">
              Complete my application
            </base-button-primary>
          </div>
        </base-modal>
        <div class="card-screen-container">
          <div class="card-screen-content">
            <div class="card-screen-title">
              <h2>
                You're
                <span v-if="decision == 'refer'">conditionally</span>
                approved
                <br />
                for a {{ cardProductName }}
              </h2>

              <div class="card-screen-desc">
                <p v-if="cardProductType == 'unsecured'">
                  Credit limit of £{{ maxCreditLimitApproved }}.
                  <br />
                  
                  <br />
                  {{ apr }}% APR.
                </p>
                <p v-else>
                  Our secured card has the benefits of a traditional credit
                  card, and also helps you build your credit history
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-screen-footer m-t-6">
          <base-button-primary @clicked="sendValue">
            Complete my application
          </base-button-primary>

          <div class="m-b-3 m-t-5">
            <base-button-link @clicked="modalIsActive = true">
              Learn more
            </base-button-link>
          </div>
        </div>
      </template>
    </base-screen>
  </transition>
</template>

<script>
import BaseIcon from '../../../../components/assets/BaseIcon.vue';
import BaseButtonPrimary from '../../../../components/buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../../../components/layout/BaseScreen.vue';
import BaseModal from '../../../../components/modal/BaseModal.vue';
import BaseButtonLink from '../../../../components/UI/elements/buttons/BaseButtonLink.vue';

export default {
  components: {
    BaseScreen,
    BaseModal,
    BaseButtonLink,
    BaseIcon,
    BaseButtonPrimary,
  },
  name: 'CardApprovedDesktop',

  props: {
    cardProductType: {
      type: String,
      required: true,
    },
    decision: {
      type: String,
      required: true,
    },
    monthlyFee: {
      type: Number,
      required: true,
    },
    apr: {
      type: Number,
      required: true,
    },
    maxCreditLimitApproved: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalIsActive: false,
      imageLoaded: false,
    };
  },
  created() {
    this.$store.dispatch('navigation/setCustomDesktopBG', 'card-desktop');
  },
  unmounted() {
    this.$store.dispatch('navigation/setCustomDesktopBG', null);
  },
  computed: {
    cardProductName() {
      return this.cardProductType == 'unsecured'
        ? 'Pillar Credit Card '
        : 'Credit Builder Card';
    },
    benefitsUnsecured() {
      return [
        {
          icon: 'transactions',
          title: 'Spread the cost of your purchases ',
          description: `0% APR when paying your statement in full. ${this.apr}% APR if you need more time to pay.`,
        },
        {
          icon: 'no-fees',
          title: 'No fees for spending abroad',
          description:
            'Take your Pillar credit card with you when you travel, and enjoy fee-free usage worldwide.',
        },
        {
          icon: 'scores',
          title: 'Improves your credit score',
          description:
            'Using your Pillar card responsibly and making timely payments will help improve your credit history.',
        },
        {
          icon: 'purchase',
          title: 'Purchase protection',
          description:
            'Section 75 protection on all card purchases above £100.',
        },
      ];
    },
    benefitsSecured() {
      return [
        {
          icon: 'scores',
          title: 'Improves your credit score',
          description:
            'Using your Pillar card responsibly and making timely payments will help improve your credit history.',
        },
        {
          icon: 'no-fees',
          title: 'No fees for spending abroad',
          description:
            'Take your Pillar credit card with you when you travel, and enjoy fee-free usage worldwide.',
        },
        {
          icon: 'purchase',
          title: 'Purchase protection',
          description:
            'Section 75 protection on all card purchases above £100.',
        },
      ];
    },
    selectedBenefits() {
      return this.cardProductType === 'unsecured'
        ? this.benefitsUnsecured
        : this.benefitsSecured;
    },
    desktopBGImageUpdated() {
      return this.$store.getters['navigation/desktopBGImageUpdated'];
    },
  },

  methods: {
    sendValue() {
      this.$emit('updateModule', 'offerScreen');
    },
    preloadImage() {
      const imgUrl = require('@/assets/backgrounds/card_mobile.svg');
      const img = new Image();
      img.src = imgUrl;

      img.onload = () => {
        this.$nextTick(() => {
          this.$store.dispatch('navigation/setCustomDesktopBG', 'card-desktop');
          this.imageLoaded = true;
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.white-overlay {
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}
.card-benefits-wrap {
  p {
    text-align: center !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    letter-spacing: -0.4px !important;
    margin-top: -20px;
  }
}

.benefits-list {
  p {
    margin: 0;
  }
  margin-bottom: 24px;

  .benefits-row {
    width: 100%;
    display: flex;
    padding: 8px;
    gap: 12px;
    justify-content: left;

    .benefits-text {
      .benefits-title {
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 19.6px !important;
        letter-spacing: -0.28px !important;
        margin-bottom: 4px !important;
        text-align: left !important;
      }
      .benefits-description {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 140% !important;
        letter-spacing: -0.4px !important;
        margin: 0;
        text-align: left !important;
      }
    }
  }
}

.card-screen-container,
.card-screen-footer {
  max-width: 350px;
}

.card-screen-title {
  h2,
  span {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 132% !important; /* 31.68px */
    letter-spacing: -0.72px !important;
  }
}
</style>
