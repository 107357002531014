<script>
import { initiatePillarRailsRedirect } from '../../controllers/pillarRails.js';

export default {
    name: 'ForeignCreditReportPull',
    async created() {
        this.$store.dispatch('navigation/startLoading');
        await this.initiatePillarRailsRedirect();
    },
    methods: {
        async initiatePillarRailsRedirect() {
            const applicationId = localStorage.getItem('applicationId');
            const applicationData = await this.$store.dispatch(
                'application/getApplicationRecord',
                {
                    applicationId,
                }
            );

            const countryCode = applicationData.data.countryCode;

            let country;
            if (countryCode === 'IN') {
                country = 'India';
            }

            this.$store.dispatch('navigation/changeLoaderOpacity', 1);
            this.$store.dispatch(
                'navigation/updateLoaderAnimationText',
                `Redirecting you to our secure portal to pull your credit report from ${country}`
            );
            this.$store.dispatch(
                'navigation/updateLoaderAnimationType',
                'indiaLoader'
            );
            const userId = this.$store.getters['auth/userId'];
            const req = await initiatePillarRailsRedirect({
                applicationId,
                userId,
            });

            if (!req.status) {
                this.$router.push('/error');
                return false;
            }

            const redirectLink = req.data.url;

            setTimeout(() => {
                window.location.href = redirectLink;
            }, 2000);
        },
    },
};
</script>
