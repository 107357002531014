<template>
  <div
    :style="{
      width: getSize,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
  },

  computed: {
    getSize() {
      return this.width;
    },
  },
};
</script>

<style></style>
