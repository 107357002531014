<template>
  <div
    v-if="activeScreen !== 'startScreen'"
    class="back-button"
    @click="goBack"
  >
    <BaseIcon icon="go-back" :isButton="true" />
  </div>
  <div v-if="activeScreen == 'smsScreen'">
    <div class="flex-row center">
      <BaseImage image="send-sms" width="100px" />
    </div>
    <div class="m-t-6">
      <h3>We sent a link to your phone</h3>
    </div>
    <p class="text-centered m-b-6">
      We have sent an sms to you {{ maskedPhone }}. Tap on the link to securely
      connect your {{ provider }} account.
    </p>

    <div
      class="centered m-b-7 resend-sms-button"
      :style="{ opacity: smsButtonDisabled ? 0 : 1 }"
    >
      <base-button-grey :disabled="smsButtonDisabled" @clicked="onSendSmsClick">
        Didn't receive the SMS?
      </base-button-grey>
    </div>
  </div>
  <div v-else-if="activeScreen == 'qrCodeScreen'" class="qr-code-wrap">
    <div class="centered">
      <div class="option-image">
        <qrcode-vue :value="qrCodeLink" :size="180" level="H" />
      </div>
    </div>
    <div class="m-t-7">
      <h3>Scan the QR-code</h3>
    </div>
  </div>
  <div v-else>
    <div class="flex-row center">
      <div class="bank-connect-img-wrap">
        <BaseImage image="connect-bank-desktop" />
        <div class="bank-logo">
          <BaseImage
            :image="providerId"
            folder="tl-banks"
            :style="{
              transform:
                providerId !== 'ob-halifax' ? 'scale(0.8)' : 'scale(1)',
            }"
          />
        </div>
      </div>
    </div>

    <div class="m-t-6">
      <h3>Link your {{ provider }} account</h3>
    </div>
    <p class="text-centered m-b-6">
      For a more seamless experience, please select how you would like to
      continue your application on your mobile
    </p>

    <div class="option-container" @click="onQrCodeClick">
      <div class="option-row">
        <div class="option-image">
          <qrcode-vue :value="qrCodeLink" :size="100" level="H" />
        </div>
        <div>
          <p>Scan a QR-code to open the {{ provider }} app</p>
        </div>
      </div>
    </div>
    <div class="option-container" @click="onSendSmsClick">
      <div class="option-row">
        <div class="option-image">
          <BaseImage image="send-sms" />
        </div>
        <div>
          <p>Receive an SMS with a link to resume your application</p>
        </div>
      </div>
    </div>

    <div class="centered link-button-container">
      <a :href="link" target="_blank">
        <base-button-link size="sm">
          Or continue in the browser
        </base-button-link>
      </a>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
import BaseImage from '../assets/BaseImage.vue';
import QrcodeVue from 'qrcode.vue';
import BaseButtonLink from '../UI/elements/buttons/BaseButtonLink.vue';
import BaseButtonGrey from '../buttons/BaseButtonGrey.vue';
import { sendSms } from '../../controllers/comms';

export default {
  name: 'QRCodeModal',
  components: {
    BaseImage,
    QrcodeVue,
    BaseButtonLink,
    BaseButtonGrey,
    BaseIcon,
  },

  props: {
    link: {
      type: String,
      required: true,
    },
    redirectDocId: {
      type: String,
      required: true,
    },
    phoneNo: {
      type: String,
      required: true,
    },
    selectedBank: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeScreen: 'startScreen',
      smsButtonDisabled: false,
    };
  },

  computed: {
    maskedPhone() {
      return this.phoneNo.replace(/.(?=.{4})/g, '*');
    },
    provider() {
      return this.selectedBank.display_name;
    },
    providerId() {
      return this.selectedBank.provider_id;
    },
    qrCodeLink() {
      const baseUrl = window.location.origin;
      const userId = this.$store.getters['auth/userId'];
      return `${baseUrl}/obredirect?id=${this.redirectDocId}&providerId=${this.providerId}&userId=${userId}`;
    },
  },

  methods: {
    goBack() {
      this.activeScreen = 'startScreen';
    },
    onQrCodeClick() {
      this.activeScreen = 'qrCodeScreen';
    },
    async onSendSmsClick() {
      const applicationId = localStorage.getItem('applicationId');

      const message = `Complete your application with Pillar by linking your  ${this.provider} account using the following link: ${this.qrCodeLink}`;

      const payload = {
        phoneNumber: this.phoneNo,
        message,
        applicationId,
      };
      const sendMessageReq = await sendSms(payload);

      if (!sendMessageReq.status) {
        this.$store.dispatch('error/updatePopupErrorStatus', {
          status: true,
          message: sendMessageReq.friendlyMessage,
        });
        return false;
      }

      if (this.activeScreen !== 'smsScreen') {
        this.activeScreen = 'smsScreen';
      } else {
        this.smsButtonDisabled = true;

        // Enable button after 2 minutes
        setTimeout(() => {
          this.smsButtonDisabled = false;
        }, 120000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 26.4px */
  letter-spacing: -0.4px;
  margin: 4px;
}

.back-button {
  position: absolute;
  left: 18px;
  top: 20px;
}

.option-container {
  border-radius: 16px;
  border: 2px solid rgba(166, 189, 186, 0.1);
  padding: 20px 24px;
  margin-bottom: 4px;
  transition: background-color 0.1s;
  cursor: pointer;
  &:hover {
    background-color: rgba(166, 189, 186, 0.1);
  }
}
.option-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  .option-image {
    width: 100px;
  }

  p {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.14px;
  }
}

.qr-code-wrap {
  margin-top: 12px;
  margin-bottom: 36px;
}

.bank-connect-img-wrap {
  position: relative;

  .bank-logo {
    position: absolute;
    height: 47px;
    top: 68px;
    right: 34px;
    width: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
.resend-sms-button {
  transition: opacity 0.3;
}

.link-button-container {
  margin-bottom: 36px;
  margin-top: 24px;
}
</style>
