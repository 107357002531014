<template>
  <base-screen>
    <template #main>
      <base-subtitle>
        {{ index }}. What is your annual income before tax?
      </base-subtitle>
      <base-description>
        {{ description }}
      </base-description>
      <div class="input-wrap">
        <BaseInput
          prependSymbol="£"
          :item="item"
          autofocus
          @onInput="inputChanged"
        />
      </div>
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="item.validationStatus !== 'valid'"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

import BaseDescription from '../../layout/BaseDescription.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'IncomeScreen',
  components: {
    BaseScreen,
    BaseSubtitle,
    BaseInput,
    BaseDescription,
    BaseButtonPrimary,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    employmentStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: {
        id: 'annualIncome',
        value: '',
        type: 'number',
        subtype: 'whole',
        label: '',
        validationRequired: true,
        validationStatus: 'pending',
      },
    };
  },
  computed: {
    description() {
      let description = '';
      switch (this.employmentStatus) {
        case 'employedFullTime':
        case 'employedPartTime':
          description =
            'Your total annual salary including any regular bonuses, overtime or commission earned';
          break;

        case 'unemployed':
        case 'disabilityBenefits':
          description =
            'Please include any benefits or other income you receive';
          break;
        case 'retired':
          description =
            'Please include any pension payments or other income you receive';
          break;
        case 'selfEmployed':
          description =
            'Please include any salary, dividends or other income you receive';
          break;
        case 'benefits':
          description =
            'Please include any benefits or other income you receive';
          break;
      }

      return description;
    },
  },

  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateForm', this.item.id, this.item.value);
    },
  },
};
</script>
