<template>
    <base-screen v-if="!isLoading" contentCentered desktopNoBG>
        <template #main>
            <div class="p-h-5">
                <div class="flex-row center">
                    <container-sized width="75%">
                        <BaseImage image="fail-illustration" />
                    </container-sized>
                </div>
                <div class="m-t-6">
                    <h3 class="text-centered">
                        We could not verify your details
                    </h3>
                    <p class="text-centered grey m-t-4 m-b-4">
                        Please connect a different bank account, or answer
                        questions about your credit report.
                    </p>
                </div>
            </div>
        </template>

        <template #footer>
            <base-button-primary @clicked="retry">
                Try again
            </base-button-primary>
            <base-button-secondary @clicked="kbaClick" class="m-t-2">
                Answer some questions
            </base-button-secondary>
            <!-- <div class="m-t-2">
        <base-button-secondary @clicked="restart('KBA')">
          Answer some questions
        </base-button-secondary>
      </div> -->
        </template>
    </base-screen>
</template>

<script>
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseButtonSecondary from '../../buttons/BaseButtonSecondary.vue';
import BaseImage from '../../assets/BaseImage.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';

export default {
    name: 'CISIdVerificationFail',
    components: {
        BaseScreen,
        BaseImage,
        BaseButtonPrimary,
        ContainerSized,
        BaseButtonSecondary,
    },
    data() {
        return {
            address: null,
            isLoading: true,
            allowReset: true,
        };
    },
    async created() {
        this.$store.dispatch('navigation/hideProgessBar');
        this.isLoading = false;
    },

    methods: {
        async retry() {
            await logFirebaseEvent('ob_failed_open_banking_chosen');
            this.$store.dispatch('navigation/startLoading');
            this.$router.push('/');
        },
        async kbaClick() {
            await logFirebaseEvent('ob_failed_kba_chosen');
            this.$emit('kbaClick');
        },
        async restart(option) {
            this.isLoading - true;
            const event =
                option == 'KBA'
                    ? 'ob_failed_kba_chosen'
                    : 'ob_failed_open_banking_chosen';
            await logFirebaseEvent(event);

            const applicationId = localStorage.getItem('applicationId');
            //   const webview = localStorage.getItem('webview');

            //   if (webview && !applicationId) {
            //     this.$router.push('/openBankingConsent');
            //     return false;
            //   }

            const nextStepReq = await this.$store.dispatch(
                'navigation/getNextStep',
                {
                    applicationId,
                    productName: null,
                    userId: null,
                    selectedOption: option,
                }
            );

            const nextStep = nextStepReq.data.nextStep;

            if (this.$router.currentRoute.path == `/${nextStep}`) {
                this.$router.go();
            } else {
                this.$router.replace(`/${nextStep}`).catch(() => {});
            }
        },
    },
};
</script>
