import { sendRequest } from "../config/axios/helper";


export const userUrls = {
    PHONE_LOOKUP: {
        url: '/auth/phone/lookup',
        type: 'post',
        reqParams: ['phoneNo']
    },
    INITIATE_VERIFICATION: {
        url: '/auth/phoneVerification/initiate',
        type: 'post',
        reqParams: ['userId']
    },
    SUBMIT_OTP: {
        url: '/auth/phoneVerification/submit',
        type: 'post',
        reqParams: ['phoneVerificationId', 'verificationCode', 'applicationId']
    },
    RESEND_OTP: {
        url: '/auth/token/resend-verification',
        type: 'post',
        reqParams: ['verificationId']
    },
    SUBMIT_PIN: {
        url: '/auth/token/generate',
        type: 'post',
        reqParams: ['phoneVerificationId', 'pin', 'applicationId']
    }
}



export const phoneLookup = async params => {
    return await sendRequest(userUrls.PHONE_LOOKUP, params, { authRequest: true });
};

export const initiateVerification = async params => {
    return await sendRequest(userUrls.INITIATE_VERIFICATION, params, { authRequest: true });
};

export const submitOTP = async params => {
    return await sendRequest(userUrls.SUBMIT_OTP, params, { authRequest: true });
};

export const resendOTP = async params => {
    return await sendRequest(userUrls.RESEND_OTP, params, { authRequest: true });
};

export const submitPin = async params => {
    return await sendRequest(userUrls.SUBMIT_PIN, params, { authRequest: true });
};