<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-subtitle>
        {{ index }}. How many people depend on you financially?
      </base-subtitle>
      <base-description>
        This might include children, spouses, relatives or friends.
      </base-description>
      <BaseInput :item="item" autofocus @onInput="inputChanged" />
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="item.validationStatus !== 'valid'"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
      <p class="small grey text-centered m-t-4">
        By clicking on the 'Continue' button above, you confirm that you are not currently in arrears on any essential bills or credit products.
      </p>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseDescription from '../../layout/BaseDescription.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
import BaseInput from '../../inputs/BaseInput.vue';

export default {
  name: 'FinancialDependents',
  components: {
    BaseScreen,
    BaseSubtitle,
    BaseButtonPrimary,
    BaseDescription,
    BaseInput,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      item: {
        id: 'financialDependents',
        value: '',
        type: 'number',
        subtype: 'financialDependents',
        label: '',
        validationRequired: true,
        validationStatus: 'pending',
      },
    };
  },

  computed: {
    isValid() {
      return !(this.financialDependents === null);
    },
  },
  created() {
    const applicationData = this.$store.getters['application/applicationData'];

    if (applicationData) {
      const financialDependents = applicationData.financialDependents;
      if (financialDependents || financialDependents == 0) {
        this.selectedByDefault = financialDependents;
      }
    }
  },
  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateForm', this.item.id, +this.item.value);
    },
  },
};
</script>
