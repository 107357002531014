import { sendRequest } from "../config/axios/helper";


export const DDUrls = {
    DIRECT_DEBIT_SETUP: {
        url: '/truelayer/direct-debit-setup',
        type: 'post',
        reqParams: ['userId', 'repaymentAmountSetting', 'accountId']
    }
}



export const directDebitSetup = async params => {
    return await sendRequest(DDUrls.DIRECT_DEBIT_SETUP, params)

};


