<template>
  <div class="flex-row transaction-list-item_row" @click="sendValue">
    <div v-if="!item.noImage" class="transaction-list-item_logo">
      <img :src="item.image ? item.image : defaultImgSrc" />
    </div>
    <div class="transaction-list-item_title">
      <h3 class="p-b-1 header-3">
        {{ item.description }}
      </h3>
      <p v-if="item.subDescription">
        {{ item.subDescription }}
      </p>

      <p class="grey small">
        <span v-if="item.minAmount">£{{ item.minAmount }}</span>
        <span v-if="item.maxAmount"> to £{{ item.maxAmount }}</span>

        <span v-if="item.frequency">
          <span v-if="item.minAmount || item.maxAmount" class="dot">
            &#183;
          </span>
          {{ item.frequency }}
        </span>
      </p>
    </div>

    <div class="transaction-list-item_icon__select">
      <BaseIcon icon="arrow-right" size="sm" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '../../assets/BaseIcon.vue';
import RRDefault from '../../../assets/img/rr-default.svg';

export default {
  name: 'TransactionListItem',
  components: { BaseIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultImgSrc: RRDefault,
    };
  },

  methods: {
    sendValue() {
      if (this.item.id == 'enterRentManually') {
        // this.$store.commit('navigation/UPDATE_ANIMATION_MODE', 'normal');
        return this.$emit('addTransactionManually');
      }

      this.$emit('onSelect', this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';
.transaction-list-item_row {
  gap: 10px;
  align-items: center;
  padding: 16px 35px 16px 20px;
  background-color: $lightestGrey;
  border-radius: 16px;
  cursor: pointer;
}
.transaction-list-item_logo {
  padding: 8px;
  width: 46px;
  background-color: white;
  border-radius: 12px;

  img {
    width: 100%;
  }
}
.transaction-list-item_title {
  width: 180px;
}

.transaction-list-item_icon__select {
  margin-left: auto;
}
</style>
