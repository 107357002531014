import { sendRequest } from "../config/axios/helper";

export const DocsUrl = {
    GET_DOCUMENTS: {
        url: '/documents/get-document',
        type: 'post',
        reqParams: ['userId', 'type', 'applicationId']
    },
    GENERATE_USER_DOCUMENT: {
        url: '/documents/generate/doc',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'type']
    },
    SET_PRE_CONTARCT_INFO_TO_PASSED: {
        url: '/documents/set-pre-contract-info-to-passed',
        type: 'post',
        reqParams: ['applicationId']
    },
    UPLOAD_DOCUMENT: {
        url: '/documents/upload',
        type: 'post',
        reqParams: []
    }
};

export const getDocument = async params => {
    return await sendRequest(DocsUrl.GET_DOCUMENTS, params);
};

export const generateUserDocument = async params => {
    return await sendRequest(DocsUrl.GENERATE_USER_DOCUMENT, params);
};

export const setPreContactModuleToPassed = async params => {
    return await sendRequest(DocsUrl.SET_PRE_CONTARCT_INFO_TO_PASSED, params);
};

export const uploadDocument = async params => {
    return await sendRequest(DocsUrl.UPLOAD_DOCUMENT, params);
};