import { sendRequest } from "../config/axios/helper";

export const rentReportingPaymentUrl = {
    STRIPE_PAY: {
        url: '/rent-reporting/pay',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'source', 'product', 'planId']
    },
    GET_SUBSCRIPTION: {
        url: '/rent-reporting/subscription/',
        type: 'get',
        reqParams: ['userId']
    },
    GET_SUBSCRIPTION_PLANS: {
        url: '/rent-reporting/plans',
        type: 'post',
        reqParams: ['applicationId']
    },
    CHECK_SUBSCRIPTION_STATUS: {
        url: '/rent-reporting/payment/check',
        type: 'post',
        reqParams: ['applicationId', 'userId', 'subscriptionId']
    },
    FORCE_MANUAL_ROUTE: {
        url: '/rent-reporting/application/force-manual-route',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    }

}

export const rentReportingPayment = async params => {
    return await sendRequest(rentReportingPaymentUrl.STRIPE_PAY, params)

};

export const getSubscriptionById = async params => {

    const reqObj = rentReportingPaymentUrl.GET_SUBSCRIPTION;

    // Add the url with passed params to request object
    reqObj.url = rentReportingPaymentUrl.GET_SUBSCRIPTION.url + params?.userId + '/' + params?.subscriptionId;
    return await sendRequest(reqObj, params);

};

export const getRentReportingPLans = async params => {
    return await sendRequest(rentReportingPaymentUrl.GET_SUBSCRIPTION_PLANS, params)

};

export const checkSubscriptionStatus = async params => {
    return await sendRequest(rentReportingPaymentUrl.CHECK_SUBSCRIPTION_STATUS, params)
}

export const forceManualRoute = async params => {
    return await sendRequest(rentReportingPaymentUrl.FORCE_MANUAL_ROUTE, params)
}