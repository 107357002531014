<template>
  <div class="banks-list_search-box" v-if="searchItems">
    <input
      type="text"
      placeholder="Search for your bank"
      class="input"
      @input="(event) => searchTextUpdate(event.target.value)"
      @blur="sendGAEvent"
    />
    <div class="banks-list_search-icon">
      <BaseIcon icon="search" size="24" />
    </div>
    <div class="m-t-2">
      <BaseList
        :selectedBank="selectedBank"
        padding="16px"
        bordered
        full-width
        max-height="auto"
        :items="searchItems"
        :withButton="false"
        @onItemSelect="onItemSelect"
      />
    </div>
  </div>
</template>

<script>
import BaseList from '../list/BaseList.vue';
import BaseIcon from '../assets/BaseIcon.vue';
import { LIST_OF_BANKS } from '../../assets/data/listOfBanks.js';
import { logFirebaseEvent } from '../../controllers/analytics/firebase';

export default {
  name: 'AllBanksList',
  components: { BaseIcon, BaseList },
  props: {
    selectedBank: {
      type: Object,
    },
  },
  data() {
    return {
      banks: LIST_OF_BANKS,
      banksFormatted: [],
      searchText: null,
      searchItems: [],
    };
  },
  created() {
    const initialBanks = LIST_OF_BANKS;
    const updatedBanks = [];

    initialBanks.map((el) => {
      const newObj = {
        id: el.provider_id,
        text: el.display_name,
        clickValue: el.provider_id,
        avatarImage: el.provider_id,
        folder: 'tl-banks',
        disabled: false,
      };

      updatedBanks.push(newObj);
    });

    this.banksFormatted = updatedBanks;
    this.searchItems = updatedBanks;
  },

  methods: {
    searchTextUpdate(value) {
      this.searchText = value;
      this.$emit('onSearchUpdate', value);

      const filtered = this.banksFormatted.filter((el) =>
        el.text.toLowerCase().startsWith(value.toLowerCase())
      );

      this.searchItems = filtered;
    },

    sendGAEvent() {
      if (this.searchText) {
        logFirebaseEvent('tl_bank_search', {
          input: this.searchText,
        });
      }
    },

    onItemSelect(bankId) {
      const selectedBank = this.banks.find((el) => el.provider_id == bankId);

      this.$emit('onBankSelect', selectedBank);
    },
  },
};
</script>

<style lang="scss" scoped>
.banks-list_search-box {
  position: relative;
}
.input {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  outline: none;
  transition: 0.3s;
  padding: 19px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: $darkestGrey;
  border: 1px solid $lightestGrey;

  &.filled {
    border: 1px solid $darkestGrey;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  &:disabled {
    border-color: $lightestGrey;
  }
}
.input::placeholder {
  color: $middleGrey !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 58px white inset !important;
}

.banks-list_search-icon {
  position: absolute;
  top: 14px;
  right: 5px;
}
</style>
