<template>
    <div>
        <div class="manualAddressForm__group">
            <div
                v-for="(item, index) of formInputs.filter(
                    (el) => el.inputGroup == 'addressBuilding'
                )"
                :key="'group' + index"
                class="manualAddressForm_item"
            >
                <BaseInput :item="item" @onInput="inputChanged" />
            </div>
            <base-error-message v-if="customError" key="error">
                {{ customError }}
            </base-error-message>
        </div>
        <div
            v-for="(item, index) of formInputs.filter(
                (el) => el.inputGroup !== 'addressBuilding'
            )"
            :key="index"
            class="manualAddressForm_item"
        >
            <BaseInput :item="item" @onInput="inputChanged" />
        </div>
        <div>
            <BaseSelect
                :options="countryCodes"
                disabled
                placeholder="Country"
                :defaultValue="countrySelectedByDefault"
                @selected="selectCountryCode"
            />
        </div>
        <base-error-message v-if="customBuldingError" key="error">
            {{ customBuldingError }}
        </base-error-message>
    </div>
</template>

<script>
import BaseErrorMessage from '../inputs/BaseErrorMessage.vue';
import BaseInput from '../inputs/BaseInput.vue';
import countryCodes from '../../json/alpha2codes.json';
import BaseSelect from '../inputs/BaseSelect.vue';

import { findAddress } from '../../controllers/address-finder.js';

export default {
    name: 'ManualAddressForm',
    components: { BaseInput, BaseErrorMessage, BaseSelect },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            countryCodes,
            countrySelectedByDefault: {
                label: 'United Kingdom',
                value: 'GBR',
                flag: 'https://flagcdn.com/gb.svg',
                active: true,
            },
            formInputs: [
                {
                    id: this.type + 'subBuilding',
                    value: '',
                    type: 'text',
                    label: 'Flat / Apartment Number',
                    validationRequired: false,
                    inputGroup: 'addressBuilding',
                },
                {
                    id: this.type + 'buildingName',
                    value: '',
                    type: 'text',
                    label: 'Building Name',
                    validationRequired: false,
                    inputGroup: 'addressBuilding',
                },
                {
                    id: this.type + 'buildingNo',
                    value: '',
                    type: 'text',
                    label: 'Building Number',
                    validationRequired: false,
                    inputGroup: 'addressBuilding',
                },
                {
                    id: this.type + 'streetName',
                    value: '',
                    type: 'text',
                    label: 'Street',
                    validationRequired: false,
                    inputGroup: 'addressBuilding',
                },
                {
                    id: this.type + 'city',
                    value: '',
                    type: 'text',
                    label: 'City*',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
                {
                    id: this.type + 'postCode',
                    value: '',
                    type: 'postcode',
                    label: 'Postcode*',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
            ],
            addressObj: {
                subBuilding: '',
                buildingName: '',
                buildingNo: '',
                streetName: '',
                city: '',
                postCode: '',
                country: 'United Kingdom',
            },
            country: null,
            customError: null,
            customBuldingError: null,
        };
    },

    methods: {
        async inputChanged(id, value, status) {
            //   this.tenancyStartError = null;
            const index = this.formInputs.findIndex((el) => el.id == id);

            const addressGroupField = this.formInputs[index]?.inputGroup;

            if (addressGroupField) {
                this.customError = null;
                this.customBuldingError = null;
            }

            this.formInputs[index].value = value;
            this.formInputs[index].validationStatus = status;
            const formattedId = id.replace(this.type, '');
            this.addressObj[formattedId] = value;
            this.formIsValid();
        },

        selectCountryCode(option) {
            this.country = option.value;
        },

        async formIsValid() {
            const addressObj = this.addressObj;
            let invalidInputs = [];
            invalidInputs = this.formInputs.filter(
                (el) =>
                    el.validationRequired == true &&
                    el.validationStatus !== 'valid'
            );

            if (invalidInputs.length) {
                this.$emit('updateAddress', addressObj, 'invalid');
                return false;
            }

            if (!addressObj.buildingName && !addressObj.buildingNo) {
                this.customBuldingError =
                    '*Building Name or Building Number is required';
                this.$emit('updateAddress', addressObj, 'invalid');
                return false;
            }

            if (
                !addressObj.subBuilding &&
                !addressObj.buildingName &&
                !addressObj.buildingNo &&
                !addressObj.streetName
            ) {
                this.customError =
                    '*Apartment name, building name, number or street is required';
                this.$emit('updateAddress', addressObj, 'invalid');
                return false;
            }

            this.$emit('updateAddress', addressObj, 'valid');
        },
    },
};
</script>

<style lang="scss" scoped>
.manualAddressForm_item {
    margin-bottom: 8px;
}

// .manualAddressForm__group {
//   margin-bottom: 32px;
// }
</style>
