<template>
    <WaitingListRequestConfirmation v-if="bumpMeUpScreen" />
    <WaitingListInfo
        v-else
        :counter="formattedCounter"
        :bumped="bumped"
        @skipTheQueue="skipTheQueue"
    />
</template>

<script>
import {
    getQueueCounter,
    skipTheQueue,
} from '../../../controllers/application';
import WaitingListInfo from './WaitingListInfo.vue';
import WaitingListRequestConfirmation from './WaitingListRequestConfirmation.vue';
import { Firestore } from '../../../utils/firestore';

export default {
    name: 'ApplicationWaitingList',
    components: {
        WaitingListInfo,
        WaitingListRequestConfirmation,
    },

    data() {
        return {
            counter: 0,
            targetCounter: null,
            bumpMeUpScreen: false,
            bumped: false,
        };
    },
    computed: {
        formattedCounter() {
            return new Intl.NumberFormat('en-UK').format(this.counter);
        },
    },
    async created() {
        this.$store.dispatch('navigation/hideProgessBar');
        const userId = this.$store.getters['auth/userId'];

        const user = await Firestore.getDoc('Users', userId);

        if (user.flags && user.flags.includes('cardWaitlistBumped')) {
            this.bumped = true;
        }

        const response = await getQueueCounter({ userId });

        if (!response.status) {
            this.$router.push('/error');
            return false;
        }

        const counter = response.data.queueCounter + 1;
        this.targetCounter = counter;
        this.$store.dispatch('navigation/stopLoading');
        this.animateCounter();
    },
    methods: {
        animateCounter() {
            const distance = this.targetCounter - this.counter;
            let step;
            let delay;

            if (distance > 5000) {
                step = Math.ceil(distance / 2);
                delay = 1;
            } else if (distance > 1000) {
                step = Math.ceil(distance / 5);
                delay = 3;
            } else if (distance > 100) {
                step = Math.ceil(distance / 10);
                delay = 5;
            } else if (distance > 10) {
                step = Math.ceil(distance / 10);
                delay = 7;
            } else {
                step = 1;
                delay = 10;
            }

            if (this.counter < this.targetCounter) {
                this.counter = Math.min(
                    this.counter + step,
                    this.targetCounter
                );
                setTimeout(this.animateCounter, delay);
            }
        },
        async skipTheQueue() {
            const userId = this.$store.getters['auth/userId'];

            this.bumpMeUpScreen = true;
            await skipTheQueue({
                userId,
            });
            return true;
        },
    },
};
</script>
