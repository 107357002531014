<template>
  <base-screen>
    <template #main>
      <base-title class="m-b-3">
        Who do you bank with?
      </base-title>
      <p>
        {{ description }}
      </p>
      <div
        key="popularBanks"
        :class="{ 'roll-down': !searchText, 'roll-up': searchText }"
      >
        <div class="m-t-4">
          <div class="m-t-4">
            <PopularBanksGrid
              :selectedBank="selectedBank"
              @onBankSelect="onBankSelect"
            />
          </div>
        </div>
      </div>

      <div class="m-t-6">
        <p class="bold grey uppercase">
          more banks
        </p>
        <div class="m-t-4">
          <AllBanksList
            :selectedBank="selectedBank"
            @onBankSelect="onBankSelect"
            @onSearchUpdate="onSearchUpdate"
          />
        </div>
      </div>
    </template>
  </base-screen>
</template>

<script>
import AllBanksList from '../../truelayer/AllBanksList.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import PopularBanksGrid from '../../truelayer/PopularBanksGrid.vue';
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';

export default {
    name: 'ConsentBankSelect',
    components: {
        BaseScreen,
        BaseTitle,
        PopularBanksGrid,
        AllBanksList,
    },
    props: {
        redirectUrl: {
            type: String,
        },
    },

    data() {
        return {
            searchText: null,
            isLoading: true,
            selectedBank: null,
        };
    },
    computed: {
        popularBanksVisible() {
            return false;
        },
        description() {
            const product = localStorage.getItem('productName');

            if (product == 'rentReportingAndMonitor') {
                return 'Please choose the bank you use to pay your rent with';
            } else {
                return 'Please choose the bank with your primary account (the account where you receive your salary or use for your day-to-day spending).';
            }
        },
    },

    methods: {
        onBankSelect(bank) {
            this.selectedBank = bank;
            logFirebaseEvent('tl_bank_selected', {
                bank: bank.provider_id,
            });

            this.$emit('updateModule', 'consentBankSelect', bank);
        },
        onSearchUpdate(value) {
            this.searchText = value;
        },
    },
};
</script>

<style>
.roll-down {
    max-height: 500px;
    transition: max-height 0.2s ease-out;
}

.roll-up {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
}
</style>
