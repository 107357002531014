<template>
  <base-screen>
    <template #main>
      <base-title class="m-b-3" noMargin>{{ getTitle }}</base-title>
      <p class="grey">{{ getSubtitle }}</p>
      <PinInput
        :pinType="pinType"
        @confirmPin="sendPin"
        ref="PinInput"
        :pinInputDisabled="pinInputDisabled"
        v-bind="$attrs"
      />
    </template>
  </base-screen>
</template>

<script>
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import PinInput from '../../inputs/PinInput.vue';

export default {
  name: 'PinInputScreen',
  components: { BaseScreen, BaseTitle, PinInput },
  props: {
    pinType: {
      type: String,
      required: true,
    },
    pinInputDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getTitle() {
      let title;

      switch (this.pinType) {
        case 'confirmExistingPin':
          title = 'Enter your PIN';
          break;
        case 'createPinFirst':
          title = 'Create your PIN';
          break;
        case 'createPinSecond':
          title = 'Confirm your PIN';
          break;
      }
      return title;
    },

    getSubtitle() {
      let subTitle;
      switch (this.pinType) {
        case 'confirmExistingPin':
          subTitle =
            'Welcome back! Please confirm your PIN to access your account.';
          break;
        case 'createPinFirst':
          subTitle = 'Please create a 4 digit PIN to protect access to the Pillar app.';
          break;
        case 'createPinSecond':
          subTitle = 'Please re-enter your chosen PIN.';
          break;
      }
      return subTitle;
    },
  },

  methods: {
    sendPin(pin) {
      this.$emit('onConfirmPin', this.pinType, pin);
    },
  },
};
</script>
