<template>
  <base-screen v-if="!isLoading" customTransition="fade" docScreen>
    <template #main>
      <DocContainer :data="docData" />
      <SignatureFooter @updateModule="updateModule" :fullName="fullName" />
    </template>
  </base-screen>
</template>
<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import SignatureFooter from '../../creditCardAgreement/signatureInput/SignatureFooter.vue';
import DocContainer from '../../documents/DocContainer.vue';

export default {
  name: 'CreditCardAgreementScreen',

  components: {
    BaseScreen,
    SignatureFooter,
    DocContainer,
  },

  props: {
    docData: {
      type: String,
      required: true,
    },
    cardProductType: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },

  methods: {
    updateModule(signatureName) {
      this.$emit('updateModule', 'creditCardAgreementData', signatureName);
    },
  },
};
</script>
