<template>
  <div class="upload-container">
    <label :for="id" class="upload-label">
      {{ selectedFilePath && inputActive ? selectedFilePath : title }}
      <input
        :id="id"
        name="file"
        type="file"
        :data-type="id"
        @change="handleFileChange"
      />
      <BaseIcon icon="upload" />
    </label>
  </div>
  <base-error-message v-if="uploadError && inputActive">
    {{ uploadError }}
  </base-error-message>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
import BaseErrorMessage from './BaseErrorMessage.vue';

export default {
    name: 'FileUploadInput',
    components: { BaseIcon, BaseErrorMessage },
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: 'Upload file',
        },
        selectedInput: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedFile: null,
            selectedFilePath: '',
            uploadError: null,
            validFileTypes: [
                'image/png',
                'image/jpeg',
                'image/jpg',
                'application/pdf',
            ],
        };
    },

    computed: {
        inputActive() {
            if (!this.selectedInput) return true;
            return this.selectedInput === this.id;
        },
    },

    watch: {
        selectedInput(newVal) {
            if (newVal !== this.id) {
                this.selectedFile = null;
                this.selectedFilePath = '';
            }
        },
    },
    methods: {
        handleFileChange(event) {
            const file = event.target.files[0];

            if (file && this.validFileTypes.includes(file.type)) {
                this.uploadError = null;
                this.selectedFile = file;
                this.selectedFilePath = file.name;
            } else {
                this.uploadError =
                    'Invalid file type. Please select a PNG, JPEG, JPG, or PDF file.';
                this.selectedFile = null;
                this.selectedFilePath = null;
                event.target.value = null;
            }

            this.$emit('onFileChange', event);
        },
    },
};
</script>

<style lang="scss">
.upload-container {
    display: flex;
    align-items: center;
}

.upload-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 20px;
    align-items: center;
    border-radius: 16px;
    border: 2px solid #edf5f5;
    margin-bottom: 8px;
    transition: all 0.2s;
    cursor: pointer;

    &.active {
        border: 2px solid #242424;
        background: #242424;

        .base-list-item {
            color: white;
        }
    }

    &:hover {
        border: 2px solid #242424;
    }
}

.upload-label input {
    display: none;
}

.upload-icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    stroke: #000;
}
</style>
