<template>
  <JumioIntro v-if="jumioIntro" @clicked="continueJumio" />
  <JumioWeb v-else />
</template>

<script>
import JumioIntro from '../../screens/jumio/JumioIntro.vue';
import JumioWeb from '../screens/jumio/JumioWeb.vue';
export default {
  components: { JumioIntro, JumioWeb },
  data() {
    return {
      jumioIntro: true,
    };
  },

  methods: {
    continueJumio() {
      const webview = localStorage.getItem('webview');
      const applicationId = localStorage.getItem('applicationId');

      if (webview == 'true') {
        window.JumioStart.postMessage(applicationId);
      } else {
        this.jumioIntro = false;
      }
    },
  },
};
</script>
