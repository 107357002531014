<template>
  <button
    type="button"
    :class="size ? `button-link ${size}` : 'button-link'"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButtonLink',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
  computed: {
    getHeight() {
      switch (this.size) {
        case 'lg':
          return '60px';

        case 'md':
          return '48px';

        case 'sm':
          return '36px';

        default:
          return '60px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/app.scss';

.button-link {
  border: none;
  color: #727776;
  border-radius: 8px;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: center;
  background-color: transparent;
  padding: 16px;
  transition: 0.3s;
  cursor: pointer;

  text-align: center;

  /* Headers/H6 */

  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: -0.28px;

  &.md {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
  }

  &.sm {
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  &:hover,
  &:active {
    background-color: none;

    // border: 1px solid $black;
    // color: $black;
  }
}
</style>
