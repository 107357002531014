<template>
  <base-screen>
    <template #main>
      <base-title> How long have you lived at this address? </base-title>
      <AddressDuration v-bind="$attrs" />
    </template>
  </base-screen>
</template>
<script>
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import AddressDuration from '../../application/addressDuration.vue';

export default {
  components: { AddressDuration, BaseScreen, BaseTitle },
  name: 'CurrentAddressDuration',
};
</script>
