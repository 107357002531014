<template>
  <CreditCardAgreementScreen
    v-if="!isLoading"
    key="creditCardAgreementData"
    :docData="creditCardAgreementData"
    :cardProductType="cardProductType"
    :fullName="appFullName"
    @updateModule="updateModule"
  />
</template>

<script>
import CreditCardAgreementScreen from '../screens/creditCardAgreement/CreditCardAgreementScreen.vue';
import { getDocument } from '../../controllers/documents';
import { generateUserDocument } from '../../controllers/documents.js';

export default {
  name: 'CreditCardAgreement',
  components: {
    CreditCardAgreementScreen,
  },
  data() {
    return {
      isLoading: true,
      creditCardAgreementData: null,
      cardProductType: null,
    };
  },

  async created() {
    let applicationData = this.$store.getters['application/applicationData'];
    const applicationId = localStorage.getItem('applicationId');
    const userId = this.$store.getters['auth/userId'];

    if (!userId || !applicationData) {
      this.$router.push('/error');
      return false;
    }

    const firstName = applicationData.firstName;
    const lastName = applicationData.lastName;
    this.appFullName = firstName + ' ' + lastName;
    this.cardProductType = applicationData.cardProductType;

    // Get documents
    const docPayload = {
      userId: userId,
      type: 'credit-agreement',
      applicationId,
    };

    let docDataReq = await getDocument(docPayload);
    if (!docDataReq.status) {
      this.$router.push('/error');
      return false;
    }

    this.creditCardAgreementData = docDataReq.data['credit-agreement'];
    this.isLoading = false;
    this.$store.dispatch('navigation/stopLoading');
  },
  methods: {
    async updateModule(id, data) {
      // Check if the input is the same as name in application
      const check = this.appFullName.toLowerCase() == data.toLowerCase().trim();

      if (!check) {
        setTimeout(() => {
          this.$store.dispatch('error/updatePopupErrorStatus', {
            status: true,
            message: `Please enter your name - ${this.appFullName} to continue.`,
          });
        }, 100);
        return false;
      }
      this.$store.dispatch('navigation/startLoading');

      const userId = this.$store.getters['auth/userId'];
      if (!userId) {
        this.$router.push('/error');
        return false;
      }

      const applicationId = localStorage.getItem('applicationId');

      const payload = {
        userId,
        applicationId,
        type: 'credit-agreement',
      };
      const generateAgreement = await generateUserDocument(payload);

      if (!generateAgreement.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'credit_agreement_signed',
        page_title: 'creditCardAgreement',
      });
    },
  },
};
</script>
