<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-title>How much would you like to pay each month?</base-title>
      <!-- Render the first item separately -->
      <div
        v-if="firstOption"
        class="list-item_container m-t-8 m-b-8"
        @click="sendValue(firstOption.value)"
      >
        <div class="list-title-container">
          <h3 class="header-3">
            {{ firstOption.title }}
          </h3>
          <p class="grey small">
            {{ firstOption.description }}
          </p>
        </div>
        <div class="list-icon">
          <BaseIcon icon="arrow-right_lightgrey" size="md" />
        </div>
      </div>

      <div class="other-options-container">
        other options
      </div>
      <!-- Render the rest of the items starting from index 1 -->
      <div v-for="option in remainingOptions" :key="option.value">
        <div class="list-item_container" @click="sendValue(option.value)">
          <div class="list-title-container">
            <h3 class="header-3">
              {{ option.title }}
            </h3>
            <p class="grey small">
              {{ option.description }}
            </p>
          </div>
          <div class="list-icon">
            <BaseIcon icon="arrow-right_lightgrey" size="md" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="direct-debit-row"
        :style="{ cursor: 'pointer' }"
        @click="showDialog = true"
      >
        <div class="direct-debit-logo-container">
          <BaseImage image="direct-debit" width="100px" />
        </div>
        <div class="direct-debit-text">
          <p class="small">
            <span class="grey">Your payments are protected by The</span>
            Direct Debit Guarantee
          </p>
        </div>
      </div>
      <base-modal v-if="showDialog" escape @closeModal="showDialog = false">
        <DirectDebitGuarantee />
      </base-modal>
    </template>
  </base-screen>
</template>

<script>
import BaseIcon from '../../assets/BaseIcon.vue';
import DirectDebitGuarantee from '../../directDebitSetup/directDebitGuarantee.vue';
import BaseImage from '../../assets/BaseImage.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseModal from '../../modal/BaseModal.vue';

export default {
  name: 'RepaymentAmountSetup',
  components: {
    BaseTitle,
    BaseScreen,
    BaseImage,
    DirectDebitGuarantee,
    BaseModal,
    BaseIcon,
  },
  props: {
    repaymentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    firstOption() {
      return this.repaymentOptions[0];
    },
    remainingOptions() {
      return this.repaymentOptions.slice(1);
    },
  },

  methods: {
    sendValue(option) {
      this.$emit('updateForm', 'repaymentSettings', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.other-options-container {
  margin-top: 32px;
  margin-bottom: 8px;
  padding: 8px 20px;
  color: #727776;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
</style>
