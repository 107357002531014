<template>
    <transition :name="animationMode" appear>
        <div
            class="base-screen-container"
            :class="{
                'has-vertical-scroll': hasVerticalScroll,
                'content-centered': contentIsCentered,
                'full-width': fullWidth,
            }"
        >
            <div ref="slotContentContainer" class="slotContentContainer">
                <slot name="main" />
            </div>

            <div v-if="setupDone">
                <Teleport to=".footer">
                    <div ref="slotFooterContainer" class="base-screen-footer">
                        <div class="base-screen-footer-content">
                            <slot name="footer" />
                        </div>
                    </div>
                </Teleport>
            </div>
        </div>
    </transition>
</template>

<script>
import {
    ref,
    computed,
    onMounted,
    onBeforeUnmount,
    nextTick,
    onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useDeviceType } from '@/utils/useDeviceType.js';

export default {
    props: {
        contentCentered: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        desktopNoBG: {
            type: Boolean,
            default: false,
        },
        customTransition: {
            type: String,
            required: false,
        },
        docScreen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const store = useStore();

        const setupDone = ref(false);
        const slotContentContainer = ref(null);
        const slotFooterContainer = ref(null);
        const hasVerticalScroll = ref(false);
        const contentIsCentered = ref(false);

        const headerIsVisible = computed(() => {
            return store.getters['navigation/headerIsVisible'];
        });

        const animationMode = computed(() => {
            if (props.customTransition) {
                return props.customTransition;
            }

            return store.getters['navigation/animationMode'] == 'normal'
                ? 'entry'
                : 'reverse';
        });

        const { isDesktop } = useDeviceType();

        onMounted(() => {
            store.commit('navigation/HIDE_DESKTOP_BG', props.desktopNoBG);

            nextTick().then(() => {
                // Scroll to the top
                const container = document.querySelector('.main-content');
                if (container) {
                    container.scrollTop = 0;
                }
                onResize();
                // setTimeout(() => {
                //   onResize();
                // }, 310);
            });

            // Add a listener for a window resize
            window.addEventListener('resize', onResize);
            setupDone.value = true;            
        });

        // Remove resize listener before unmount
        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize);
        });

        onUnmounted(() => {
            store.commit('navigation/SET_ANIMATION_MODE', 'normal');
        });
        function onResize() {
            if (slotContentContainer.value) {
                // Get content height
                let totalContentHeight = 0;
                if (slotContentContainer.value) {
                    totalContentHeight =
                        slotContentContainer.value.clientHeight;
                }

                // Get footer height and dispatch it to the store
                let footerTotalHeight = 0;

                if (slotFooterContainer.value) {
                    // Footer always has padding 32 and 20 which means that there is no content
                    footerTotalHeight =
                        slotFooterContainer.value.offsetHeight == 52
                            ? 0
                            : slotFooterContainer.value.offsetHeight;

                    store.commit(
                        'navigation/SET_FOOTER_HEIGHT',
                        footerTotalHeight
                    );
                }

                // Check if header is visible
                const headerVisible = headerIsVisible.value;
                const headerHeight = headerVisible ? 56 : 0;

                // Check total content height
                const totalOffsetHeight =
                    totalContentHeight + footerTotalHeight + headerHeight + 20;

                // Check if there is vertical scroll
                const hasScroll =
                    totalOffsetHeight > window.innerHeight || props.docScreen;

                // Set hasVerticalScroll and contentCentered to the store
                store.commit('navigation/SET_VERTICAL_SCROLL', hasScroll);

                // Check if content is centered
                const setContentIsCentered =
                    (props.contentCentered || isDesktop.value) && !hasScroll;
                contentIsCentered.value = setContentIsCentered;
                store.commit(
                    'navigation/SET_CONTENT_CENTERED',
                    setContentIsCentered
                );
            }
        }

        return {
            slotContentContainer,
            slotFooterContainer,
            hasVerticalScroll,
            onResize,
            headerIsVisible,
            contentIsCentered,
            animationMode,
            setupDone
        };
    },
};
</script>

<style lang="scss">
.base-screen-container {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    max-width: $max-content-width !important;
    align-content: center;

    &.no-bg {
        background-color: transparent;
    }

    &.full-width {
        padding: 0 !important;
        max-width: 100%;
        max-width: 100% !important;
    }

    &.base-screen-content-centered {
        justify-content: center;
        padding-top: 0;
    }

    &.content-centered {
        justify-content: center;
    }
    @media (min-width: 600px) {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.base-screen-footer {
    max-width: $max-content-width !important;
    margin: 0 auto;
}

.base-screen-footer-content {
    padding: 20px;
    padding-bottom: 32px;

    @media (min-width: 600px) {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.slotContentContainer {
    height: auto;
}
</style>
