<template>
    <base-screen>
        <template #main>
            <base-title>What's your UK address?</base-title>
            <p class="m-b-3">
                This should be your permanent address on arriving in the UK.
            </p>
        </template>
        <template #footer>
            <base-button-primary @clicked="onAddressSelect" class="m-b-2">
                I have a fixed UK Address
            </base-button-primary>
            <base-button-secondary @clicked="noUkAddress">
                I don't have a fixed UK
            </base-button-secondary>
        </template>
    </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseButtonSecondary from '../../buttons/BaseButtonSecondary.vue';

export default {
    name: 'UKAddressExists',
    components: {
        BaseScreen,
        BaseTitle,
        BaseButtonSecondary,
        BaseButtonPrimary,
    },
    data() {
        return {
            product: null,
        };
    },

    created() {
        this.product = localStorage.getItem('productName');
    },

    methods: {
        noUkAddress() {
            this.$emit('noUkAddress');
        },
        onAddressSelect(data) {
            this.$emit('updateModule', 'UKAddressExists', true);
        },
    },
};
</script>
