<template>
  <choose-bank @selectBank="selectBank"></choose-bank>
</template>

<script>
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';
import { getObConsent } from '../../../controllers/ob';
import ChooseBank from '../../verification/chooseBank.vue';

export default {
  components: { ChooseBank },
  data() {
    return {
      redirectUrl: null,
      webview: false,
    };
  },

  async created() {
    this.$store.dispatch('navigation/loadingTextUpdate', null);
    this.$store.dispatch('navigation/moduleLoadingStart');

    const applicationId = localStorage.getItem('applicationId');
    const token = localStorage.getItem('token');
    const webview = localStorage.getItem('webview');
    let reqParams;

    let userId = this.$store.getters['auth/userId'];
    if (!userId) {
      const token = localStorage.getItem('token');

      if (token) {
        const authReq = await this.$store.dispatch('auth/trySignIn');
        //  If success - rewrite currentUserId
        if (authReq.status) {
          userId = authReq.data.uid;
        }
      }
    }

    // If we still don't have user after trying to sign in, redirect to error
    if (!userId) {
      this.$router.push('/obfail');
      return false;
    }

    if (!applicationId) {
      reqParams = {
        userId: userId,
        token: token,
      };

      this.userModule = true;
    } else {
      //Use applicationId for sending request
      reqParams = {
        applicationId: applicationId,
        token: token,
      };
    }

    if (webview == 'true') {
      reqParams.webview = 'true';
      this.webview = true;
    }

    // Generate the AccountScore link (their hosted web page) for OBConsent
    const resp = await getObConsent(reqParams);

    //If request is successful and we get a redirect url, we show choose-bank screen
    if (resp && resp.status == 200) {
      const url = resp.data.data;
      this.redirectUrl = url;
    } else {
      this.$store.dispatch(
        'error/setErrorMessage',
        'Something went wrong. Please try again or contact support.'
      );
      this.$store.dispatch('error/showErrorScreen');
      return false;
    }

    this.$store.dispatch('navigation/moduleLoadingFinish');
  },

  methods: {
    async selectBank(bankcode) {
      const fullUrl = `${this.redirectUrl}${bankcode}`;
      localStorage.setItem('obsuccess', 'true');
      const webview = localStorage.getItem('webview');

      logFirebaseEvent('open_banking_bank_chosen', { bankcode });

      if (webview) {
        const link = `/consent.html?${fullUrl}`;
        window.OpenAccountScore.postMessage(link);
      } else {
        window.location.href = `/consent.html?${fullUrl}`;
      }
    },
  },
};
</script>

<style></style>
