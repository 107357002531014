<template>
  <div>
    <p class="pillar-heading">Popular Banks</p>
    <v-row style="height: 200px" justify="space-between" class="mb-8 px-2">
      <v-col v-for="(item, index) of items" :key="index" class="col-4 pa-1">
        <v-list class="pa-0 m-t-2">
          <v-list-item
            class="pa-4 bankCard"
            flat
            @click="chooseBank(item.code)"
            @touchend="chooseBank(item.code)"
          >
            <v-img :src="getImgUrl(item.text)" contain aspect-ratio="1" />
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { detectDevice } from '../../../controllers/services/helpers';
export default {
  name: 'PopularAccountsGrid',
  data() {
    return {
      items: [
        {
          text: 'Barclays',
          code: 'barclays',
          show: true,
          popular: true,
        },
        {
          text: 'Lloyds Bank',
          code: 'lloyds',
          show: true,
          popular: true,
        },
        {
          text: 'Halifax',
          code: 'halifax',
          show: true,
          popular: true,
        },
        {
          text: 'Natwest',
          code: 'natwestbank',
          show: true,
          popular: true,
        },
        {
          text: 'Santander',
          code: 'santander',
          show: true,
          popular: true,
        },
        {
          text: 'Nationwide Building Society',
          code: 'nationwidebuildingsociety',
          show: true,
          popular: true,
        },
        {
          text: 'Monzo',
          code: 'monzo',
          show: true,
          popular: true,
        },
        {
          text: 'HSBC',
          code: 'hsbc',
          show: true,
          popular: true,
        },
        {
          text: 'TSB',
          code: 'tsb',
          show: true,
          popular: true,
        },
      ],
    };
  },
  computed: {
    eventType() {
      const deviceInfo = detectDevice();

      if (deviceInfo?.os == 'iOS') {
        return 'touchend';
      }

      return 'click';
    },
  },
  methods: {
    getImgUrl(name) {
      const elName = _.camelCase(name);
      try {
        return require(`@/assets/banks/popular/${elName}.png`);
      } catch (err) {
        return false;
      }
    },
    chooseBank(code) {
      this.$emit('selectBank', code);
    },
  },
};
</script>

<style>
.bankCard {
  background: #f5faf9;
  border: 1px solid #ffffff;
  border-radius: 12px;
  min-height: 100%;
}

.pillar-heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
</style>
