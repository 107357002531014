<template>
  <div class="addressSelect_wrap">
    <BaseInput :item="address" @onInput="onChange" />

    <div class="address-button">
      <LabelButton
        v-if="product == 'pillarCardNTC' && !showAddressList"
        class="m-t-3"
        size="md"
        @clicked="noUkAddress"
      >
        I haven't sorted my UK address yet
      </LabelButton>
      <LabelButton
        v-else
        class="m-t-3"
        size="md"
        @clicked="showManualAddressForm"
      >
        Can't find your address?
      </LabelButton>
    </div>
    <div v-if="showAddressList" class="addressSelect_list m-t-1">
      <BaseList
        bordered
        fullWidth
        maxHeight="250px"
        :items="searchItems"
        :customListStyle="{
          padding: '12px 16px',
          borderBottom: ' 1px solid #F5FAF9',
        }"
        @onItemSelect="onItemSelect"
      />
    </div>
  </div>
</template>

<script>
import {
    findAddress,
    retrieveAddress,
} from '../../controllers/address-finder.js';
import { logFirebaseEvent } from '../../controllers/analytics/firebase.js';
import LabelButton from '../buttons/LabelButton.vue';
import BaseInput from '../inputs/BaseInput.vue';
import BaseList from '../list/BaseList.vue';
export default {
    name: 'AddressSelect',
    components: { BaseInput, BaseList, LabelButton },
    props: {
        type: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            address: {
                id: `searchAddress${this.type}`,
                value: '',
                label: 'Address or Postcode',
            },
            searchItems: [],
            searchText: '',
            addressType: null,
            product: null,
        };
    },
    computed: {
        showAddressList() {
            return (
                this.searchText &&
                this.searchItems &&
                this.searchItems.length > 0
            );
        },
    },
    created() {
        this.product = localStorage.getItem('productName');
    },

    methods: {
        onChange(id, value) {
            this.searchText = value;
            this.searchForAddress(value);
        },

        showManualAddressForm() {
            this.$emit('showManualAddressForm');
        },
        async searchForAddress(value, id) {
            if (value) {
                const response = await findAddress(value, id);

                if (response.status) {
                    let filteredResults = [];
                    response.data.map((el) => {
                        const newObj = {
                            id: el.Id,
                            type: el.Type,
                            text: el.Text,
                            textDescription: el.Description,
                            clickValue: el.Id,
                            disabled: false,
                        };

                        filteredResults.push(newObj);
                    });

                    const exactMatch = filteredResults.find(
                        (x) =>
                            x?.text.split(' ').join('').toLowerCase() ==
                            this.searchText.split(' ').join('').toLowerCase()
                    );

                    if (exactMatch && exactMatch.type == 'Postcode') {
                        if (exactMatch.textDescription.includes('Addresses')) {
                            this.onItemSelect(exactMatch.id, exactMatch);
                        } else {
                            this.searchItems = [exactMatch];
                        }
                    } else {
                        this.searchItems = filteredResults.sort((a, b) =>
                            ('' + a.text).localeCompare(b.text, undefined, {
                                numeric: true,
                            })
                        );
                    }
                }
            }
        },
        async onItemSelect(id, addressObj) {
            let address = this.searchItems.find((el) => el.id == id);

            if (addressObj) {
                (id = addressObj.id), (address = addressObj);
            }

            if (address.type === 'Address') {
                this.searchItems = [];
                const response = await retrieveAddress(id);
                this.$emit('onAddressSelect', response.data);
            } else if (
                address.type === 'Postcode' ||
                address.type === 'Street' ||
                address.type == 'BuildingName' ||
                address.type == 'BuildingNumber'
            ) {
                logFirebaseEvent('address_pre_selected');
                this.searchForAddress(this.searchText, id);
            }
        },
        noUkAddress() {
            this.$emit('noUkAddress');
        },
    },
};
</script>

<style lang="scss" scoped>
.address-button {
    position: absolute;
}
.addressSelect_wrap {
    position: relative;
    height: 250px;
}

.addressSelect_link {
    position: absolute;
    top: 80px;
    left: 10px;
    cursor: pointer;
    h6 {
        color: $darkGrey !important;
    }
}

.addressSelect_list {
    z-index: 1000;
    position: relative;
    background-color: white;
    z-index: 100;
}
</style>
