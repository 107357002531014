<template>
  <PaymentPlan
    v-if="activeScreen == 'paymentPlan' && !isLoading"
    key="paymentPlan"
    :plans="plans"
    @updateForm="updateForm"
  />
  <CardDetails
    v-else-if="activeScreen == 'cardDetails'"
    key="cardDetails"
    :selectedPlan="selectedPlan"
    @updateForm="updateForm"
  />
</template>

<script>
import CardDetails from '../screens/payment/CardDetails.vue';
import PaymentPlan from '../screens/payment/PaymentPlan.vue';
import { Firestore } from '../../utils/firestore';
import { getRentReportingPLans } from '../../controllers/rent-reporting';

export default {
    name: 'StripePayment',
    components: {
        PaymentPlan,
        CardDetails,
    },
    data() {
        return {
            selectedPlan: null,
            plans: null,
            isLoading: true,
            withTrial: false
        };
    },

    computed: {
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];
            if (!activeScreen) {
                return 'paymentPlan';
            } else {
                return activeScreen;
            }
        },
        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
    },

    async mounted() {
        const applicationId = localStorage.getItem('applicationId');
        const userId = this.$store.getters['auth/userId'];

        const plansReq = await getRentReportingPLans({ applicationId, userId });

        if (!plansReq.status) {
            this.$store.dispatch('navigation/stopLoading');
            this.$router.push('/error');
            return false;
        }

        this.plans = plansReq.data;

        this.selectedPlan = this.plans.annual;
        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
    },
    methods: {
        updateForm(id, data) {
            if (id == 'paymentPlan') {
                this.selectedPlan = data;
                this.$store.dispatch('navigation/updateScreen', 'cardDetails');
            }
        },
    },
};
</script>
