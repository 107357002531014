import DeviceDetector from 'device-detector-js';

export const detectDevice = () => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    if (device.device.type !== 'desktop') {
        return {
            type: device.device.type,
            os: device.os.name
        }
    } else {
        return {
            type: 'desktop'
        }
    }
};