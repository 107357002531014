<template>
    <component
        :is="componentMap[activeScreen]"
        :key="activeScreen"
        v-bind="currentComponentProps"
        @updateModule="updateModule"
        @noUkAddress="noUkAddress"
    />
</template>

<script>
import CurrentAddressSelect from '../screens/applicationData/CurrentAddressSelect.vue';
import AddressVerification from '../screens/UKAddress/AddressVerification.vue';
import {
    updateApplicationRecord,
    getApplicationRecord,
} from '../../controllers/application.js';
import moduleMixin from '../../mixins/moduleMixin.js';
import { uploadDocument } from '../../controllers/documents.js';
import UKAddressExists from '../screens/applicationData/UKAddressExists.vue';

export default {
    name: 'UKAddress',
    components: {
        CurrentAddressSelect,
        AddressVerification,
    },
    mixins: [moduleMixin({ defaultScreen: 'ukAddressExists' })],
    data() {
        return {
            formDetails: {
                currentAddressSelect: null,
                addressVerification: null,
            },
        };
    },

    computed: {
        componentMap() {
            return {
                currentAddressSelect: CurrentAddressSelect,
                addressVerification: AddressVerification,
                ukAddressExists: UKAddressExists,
            };
        },
        currentComponentProps() {
            switch (this.activeScreen) {
                case 'addressVerification':
                    return {
                        currentAddress: this.formDetails.currentAddressSelect,
                    };
                default:
                    return {};
            }
        },
    },
    mounted() {
        this.stopLoading();
    },

    methods: {
        async noUkAddress() {
            this.$store.dispatch('navigation/startLoading');

            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const applicationRecord = await getApplicationRecord({
                applicationId,
            });

            const payload = {
                applicationId,
                userId,
                data: {
                    address: {
                        ...applicationRecord.data.address,
                        current: null,
                    },
                },
                stepToPassed: 'UKAddress',
            };

            const req = await updateApplicationRecord(payload);
            if (!req.status) {
                this.$router.push('/error');
                return false;
            }

            this.modulePassed({
                event: 'UK_address_provided',
                page_title: 'UKAddress',
            });
        },

        updateModule(id, data) {
            // Set the data
            this.formDetails[id] = data;

            if (id === 'addressVerification') {
                return this.submitForm();
            }

            // Navigate to the next screen
            const screenTransitions = {
                currentAddressSelect: 'addressVerification',
                UKAddressExists: 'currentAddressSelect',
            };

            const nextScreen = screenTransitions[id];
            if (nextScreen) {
                this.navigateToNextScreen(nextScreen);
            }
        },

        async submitForm() {
            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const applicationRecord = await getApplicationRecord({
                applicationId,
            });

            const address = {
                ...applicationRecord.data.address,
                current: this.formDetails.currentAddressSelect,
            };

            const payload = {
                userId,
                applicationId,
                stepToPassed: 'UKAddress',
                fileInfo: this.formDetails.addressVerification,
                applicationData: {
                    address,
                },
            };

            const resp = await uploadDocument(payload);
            if (!resp.status) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'UK_address_provided',
                page_title: 'UKAddress',
            });
        },
    },
};
</script>
