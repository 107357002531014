<template>
  <div
    class="flex-row list-item-row"
    :class="[
      {
        selected:
          selectedBank?.provider_id == item.id &&
          activeScreen == 'consentBankSelect',
        disabled: item?.disabled,
      },
    ]"
    :style="{
      opacity: item.disabled ? '0.4' : '1',
      paddingTop: padding ? padding : 'auto',
      paddingBottom: padding ? padding : 'auto',
      ...customListStyle,
    }"
    @click="onClick"
  >
    <div v-if="item?.inputValue">
      <input
        type="radio"
        :checked="isSelected"
        @click="onInputSelect"
        name="selectedBank"
        :value="item.inputValue"
        :disabled="item.disabled"
      />
    </div>
    <div
      v-if="item.avatarIcon || item.avatarImage"
      class="avatar"
      :style="{
        animationDelay: index ? `${index * 0.2}s` : null,
      }"
      @click="onInputSelect"
    >
      <BaseIcon :icon="item.avatarIcon" v-if="item.avatarIcon" size="18" />
      <BaseImage
        v-if="item.avatarImage"
        :image="item.avatarImage"
        :format="item.avatarImageFormat"
        :folder="item.folder"
      />
    </div>
    <div
      class="list-item_text"
      :style="{ marginLeft: noTextMargin ? '0' : 'auto' }"
      @click="onInputSelect"
    >
      <div>
        {{ item.text }}
      </div>
      <div v-if="item.textDescription" class="m-t-1 list-item_descr">
        {{ item.textDescription }}
      </div>
    </div>
    <div class="list-item_icon">
      <BaseIcon
        :icon="item.icon"
        size="14"
        v-if="item.icon && (!item.inputValue || isSelected)"
      />
    </div>
  </div>
</template>

<script>
import BaseImage from '../assets/BaseImage.vue';
import BaseIcon from '../assets/BaseIcon.vue';

export default {
  name: 'BaseListItem',

  components: { BaseIcon, BaseImage },
  props: {
    item: { type: Object, required: true },
    index: {
      type: Number,
    },
    selectedId: {
      type: String,
    },
    padding: {
      type: String,
    },
    noTextMargin: {
      type: Boolean,
      default: false,
    },
    customListStyle: {
      type: Object,
    },
    selectedBank: {
      type: Object,
    },
  },

  computed: {
    isSelected() {
      return this.item.inputValue == this.selectedId;
    },

    activeScreen() {
      return this.$store.getters['navigation/activeScreen'];
    },
  },
  methods: {
    onInputSelect() {
      if (!this.item?.inputValue || this.item.disabled) {
        return false;
      }
      this.$emit('onItemSelect', this.item.inputValue);
    },

    onClick() {
      if (!this.item.clickValue) {
        return false;
      }

      this.$emit('onItemSelect', this.item.clickValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-row {
  padding-left: 8px;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s;
  &.selected {
    border: 2px solid #16e89e;
    border-radius: 8px;
    background-color: white;
  }
}

.avatar {
  width: 28px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item_text {
  flex-grow: 1;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.list-item_descr {
  color: $darkGrey;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
}

.list-item_icon {
  margin-left: auto;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
