<template>
  <base-screen contentCentered customTransition="fade">
    <template #main>
      <div class="content">
        <div class="image">
          <BaseImage image="intro-expenditure" size="200" />
        </div>

        <h1 class="header-1 text-centered m-v-6">
          Tell us about your
          <b>{{ title }}</b>
        </h1>
        <p class="text-centered">
          {{ description }}
        </p>
      </div>
    </template>
    <template #footer>
      <base-button-primary black @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import BaseImage from '../assets/BaseImage.vue';
import BaseScreen from './BaseScreen.vue';
export default {
  name: 'BaseIntroScreen',
  components: { BaseScreen, BaseImage, BaseButtonPrimary },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  methods: {
    sendValue() {
      this.$emit('updateForm', 'introScreen');
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 24px 24px 24px;
}
.image {
  display: flex;
  justify-content: center;
}

.header-1 {
  font-weight: 400;
}
</style>
