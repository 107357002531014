<template>
  <div
    class="flex-row list-item-row p-v-2"
    :style="{
      animationDelay: animationDelay ? `${animationDelay}s` : null,
    }"
  >
    <div>
      <slot name="icon">
        <BaseIcon v-if="icon" :icon="icon" size="36" />
      </slot>
    </div>
    <div class="list-item-text">
      <h3 class="m-t-1 header-3" v-if="title" :style="customStyle">
        {{ title }}
      </h3>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
export default {
  name: 'BaseListItem',
  components: { BaseIcon },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    animationDelay: {
      type: Number,
    },
    customStyle: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.list-item-row {
  gap: 12px;
  align-items: center;
}

.list-item-text {
  h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 0;
    color: $darkGrey;
  }
}
</style>
