<template>
  <div class="agreement doc-container" v-if="data">
    <div v-html="data"></div>
    <div class="last-item"></div>
  </div>
</template>

<script>
export default {
  name: 'DocContainer',
  props: {
    data: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.last-item {
  height: 70px;
}
</style>
