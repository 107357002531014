<template>
  <div class="pin-code-input">
    <input
      v-for="(field, index) in pinFields"
      :value="field.value"
      type="password"
      pattern="[0-9]*"
      inputmode="numeric"
      :key="index"
      :id="field.id"
      class="pin-field"
      maxlength="1"
      autocomplete="off"
      @focus="onFocus(index, $event)"
      @input="onInput(index, $event)"
      @paste="onPaste"
      @keydown.backspace="onBackspace(index, $event)"
      :autofocus="index === 0"
      :disabled="pinInputDisabled"
      ref="pinFields"
    />
  </div>
</template>

<script>
export default {
  name: 'pinInput',
  props: {
    pinInputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pinFields: [
        { id: 'pin-1', value: '' },
        { id: 'pin-2', value: '' },
        { id: 'pin-3', value: '' },
        { id: 'pin-4', value: '' },
      ],
    };
  },
  mounted() {
    // Set autofocus to the first element
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.pinFields[0].focus();
      }, 300);
    });
  },

  methods: {
    clearPin() {
      this.pinFields.map((field) => (field.value = ''));
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.pinFields[0].focus();
        }, 200);
      });
    },
    onFocus(index, event) {
      event.target.select();
    },
    onInput(index, event) {
      const input = event.target;
      const value = input.value;

      // Remove non-numeric characters from the input value
      const numericValue = value.replace(/[^0-9]/g, '');

      // Only update the value if it's a valid numeric value
      if (numericValue !== value) {
        input.value = numericValue;
      }

      // Store the entered value in the corresponding pinFields item
      this.pinFields[index].value = numericValue;

      // Autofocus on next one
      const nextFieldIndex = index + 1;
      if (numericValue.length === 1 && nextFieldIndex < this.pinFields.length) {
        this.$nextTick(() => {
          this.$refs.pinFields[nextFieldIndex].focus();
        });
      }

      // Check if all input fields are filled
      const isAllInputsFilled = this.pinFields.every(
        (field) => field.value && field.value !== ''
      );

      // If true, emit the sending event
      if (isAllInputsFilled) {
        this.sendpin();
      }
    },
    onPaste(event) {
      const pasteData = event.clipboardData.getData('text');
      const pinValues = pasteData.split('').slice(0, this.pinFields.length);

      // Update the values of pinFields
      pinValues.forEach((value, index) => {
        this.pinFields[index].value = value;
        this.$refs.pinFields[index].value = value;
      });

      // Focus on the next empty input field (if any)
      const nextEmptyIndex = pinValues.length;

      if (nextEmptyIndex < this.pinFields.length) {
        this.$nextTick(() => {
          this.$refs.pinFields[nextEmptyIndex].focus();
        });
      }
    },
    onBackspace(index, event) {
      event.preventDefault();

      // Remove the input
      event.target.value = '';
      this.pinFields[index].value = '';

      // Autofocus the previous one
      const previousFieldIndex = index - 1;
      if (previousFieldIndex >= 0) {
        this.$refs.pinFields[previousFieldIndex].focus();
      }
    },

    sendpin() {
      const pinCode = this.pinFields.map((field) => field.value).join('');
      this.$emit('confirmPin', pinCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-code-input {
  display: flex;
  gap: 8px;
  margin-top: 30px;
}

.pin-field {
  width: 30px;
  height: 30px;

  border: 1px solid rgba(213, 231, 226, 1);
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  height: 58px;
  width: 46px;
  box-sizing: border-box;
}

.pin-field:focus {
  border-color: rgba(36, 36, 36, 1);
  outline: none;
}

.pin-field::selection {
  color: inherit;
  background-color: transparent !important;
}
</style>
