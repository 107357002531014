<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="fail-illustration" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <h1 class="header-1 text-centered">
            We could not verify your details
          </h1>
          <p
            class="text-centered grey m-t-4 m-b-4"
            v-if="getProduct == 'pillarMonitor'"
          >
            Please connect a different bank account, or answer questions about
            your credit report.
          </p>
          <p class="text-centered grey m-t-4 m-b-4" v-else>
            Something went wrong. Please try again later or contact support.
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="actionButton">
        <base-button-primary @clicked="restart('openBanking')">
          Connect a different account
        </base-button-primary>
        <div class="m-t-2">
          <base-button-secondary
            @clicked="restart('KBA')"
            v-if="getProduct == 'pillarMonitor'"
          >
            Answer some questions
          </base-button-secondary>
        </div>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../assets/BaseImage.vue';
import BaseScreen from '../layout/BaseScreen.vue';
import { mapActions } from 'vuex';
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import BaseButtonSecondary from '../buttons/BaseButtonSecondary.vue';
import ContainerSized from '../UI/elements/layout/ContainerSized.vue';
import { logFirebaseEvent } from '../../controllers/analytics/firebase';

export default {
  components: {
    ContainerSized,

    BaseButtonPrimary,
    BaseButtonSecondary,
    BaseScreen,
    BaseImage,
  },
  name: 'OBFailScreen',

  data() {
    return {};
  },

  async created() {
    localStorage.removeItem('obsuccess');
    this.$store.dispatch('navigation/firstRouteStateCancel');
    this.$store.dispatch('navigation/hideProgessBar');

    // const userId = this.$store.getters['auth/userId'];
    // const resp = await getObAttempts(userId);

    // if (!resp.status) {
    //   return false;
    // }

    // this.obattempts = resp.data;
    // this.$store.dispatch(
    //   'setErrorMessage',
    //   "Sorry we couldn't connect to your bank account. Please try again or contact support"
    // );
    localStorage.removeItem('obsuccess');
    // this.$store.dispatch('error/showErrorScreen');
  },

  methods: {
    ...mapActions(['resetStore']),
    async restart(option) {
      this.$store.dispatch('navigation/requestStart');
      const event =
        option == 'KBA'
          ? 'ob_failed_kba_chosen'
          : 'ob_failed_open_banking_chosen';
      await logFirebaseEvent(event);

      const applicationId = localStorage.getItem('applicationId');
      const webview = localStorage.getItem('webview');

      if (webview && !applicationId) {
        this.$router.push('/openBankingConsent');
        return false;
      }

      const nextStepReq = await this.$store.dispatch('navigation/getNextStep', {
        applicationId,
        productName: null,
        userId: null,
        selectedOption: option,
      });
      const nextStep = nextStepReq.data.nextStep;

      if (this.$router.currentRoute.path == `/${nextStep}`) {
        this.$router.go();
      } else {
        this.$router.replace(`/${nextStep}`).catch(() => {});
      }
    },

    callIntercom() {
      window.Intercom('showNewMessage');
    },
  },

  computed: {
    getProduct() {
      return localStorage.getItem('productName');
    },
  },
};
</script>

<style lang="scss" scoped>
.infoBlock {
  background-color: #e5f6ff;
  padding: 16px;
  border-radius: 8px;

  p {
    //styleName: Headers/Sm-Header;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
</style>
