<template>
  <base-screen noLeaveAnimation fixedFooter>
    <template #main>
      <div class="flex-row center">
        <BaseImage image="india-uk-flags" width="240px" />
      </div>
      <div class="transfer-confrimation_text">
        <h4>Transfer my credit file to the UK</h4>
        <p>
          Transfer my Indian credit report to support my financial application
          in UK.
        </p>
        <p>
          By transferring my data to the UK I acknowledge that the data privacy
          laws in the UK differ to the laws in India.
        </p>
      </div>
      <!-- <div class="button_wrapper">
        <base-button-primary @clicked="sendValue">
          Transfer my credit history to UK
        </base-button-primary>
      </div> -->

      <!-- <div>
        <p class="text">
          By clicking on the 'Transfer my credit history to UK' button above, I
          understand that I am sending my CRIF India credit history to Pillar
          for processing according to its Privacy Policy and Terms of Service. I
          acknowledge that UK has different data privacy laws, which may not be
          as protective as laws in India. I understand that Pillar will disclose
          my credit history to Pillar in its preferred format for the purpose of
          supporting my application.
        </p>
      </div> -->
    </template>
    <template #footer>
      <base-button-primary @clicked="sendValue">
        Transfer my credit history to UK
      </base-button-primary>
      <div class="centered m-t-4">
        <base-button-link size="md">I prefer not to transfer</base-button-link>
      </div>

      <div class="footer-text">
        <p>
          By clicking on the 'Transfer my credit history to UK', I understand
          that I am sending my CRIF India credit history to Pillar for
          processing according to its <span>Privacy Policy</span> and
          <span>Terms of Service.</span>
        </p>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
// import BaseButtonLink from '../../UI/elements/buttons/BaseButtonLink.vue';
import BaseImage from '../../assets/BaseImage.vue';
// import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'ConsentIntro',
  components: {
    BaseScreen,
    BaseButtonPrimary,
    BaseImage,
    BaseButtonLink,
    // BaseButtonLink, BaseButtonPrimary, BaseButtonLink
  },

  methods: {
    sendValue() {
      this.$emit('updateForm', 'dataTransferConfirmation');
    },
  },
};
</script>

<style lang="scss" scoped>
.button_wrapper {
  width: 300px;
  margin: 0 auto;
}

.text {
  margin-top: 24px;
  color: #a6bdba;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.transfer-confrimation_text {
  margin-top: 20px;
  background-color: #f3fefa;
  border-radius: 16px;
  padding: 20px 36px;

  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 16px !important;
  }
}

.footer-text {
  margin-top: 26px;
  p {
    color: #727776;
    font-weight: 500;
    font-size: 12px;

    span {
      color: black;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
</style>
