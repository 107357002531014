<template>
    <component
        :is="componentMap[activeScreen]"
        v-if="countryCode"
        :key="activeScreen"
        v-bind="currentComponentProps"
        @updateModule="updateModule"
        @showAlternativeForm="showAlternativeForm"
    />
</template>

<script>
import InternationalAddress from '../screens/applicationData/InternationalAddress.vue';
import IdentificationMain from '../screens/foreignDataCapture/IdentificationMain.vue';
import IdentificationAlternative from '../screens/foreignDataCapture/IdentificationAlternative.vue';
import moduleMixin from '../../mixins/moduleMixin.js';
import {
    getApplicationRecord,
    updateApplicationRecord,
} from '../../controllers/application.js';

export default {
    name: 'ForeignDataCapture',
    components: {
        InternationalAddress,
        IdentificationMain,
        IdentificationAlternative,
    },
    mixins: [moduleMixin({ defaultScreen: 'internationalAddress' })],
    data() {
        return {
            internationalAddress: null,
            countryCode: null,
        };
    },

    computed: {
        componentMap() {
            return {
                internationalAddress: InternationalAddress,
                identificationMain: IdentificationMain,
                identificationAlternative: IdentificationAlternative,
            };
        },

        currentComponentProps() {
            switch (this.activeScreen) {
                case 'internationalAddress':
                case 'identificationMain':
                case 'identificationAlternative':
                    return { countryCode: this.countryCode };
                default:
                    return {};
            }
        },
    },

    async created() {
        // Get country code from the application record
        const applicationId = localStorage.getItem('applicationId');
        const applicationData = await this.$store.dispatch(
            'application/getApplicationRecord',
            { applicationId }
        );

        const countryCode = applicationData?.data?.countryCode;
        if (!countryCode) {
            this.$router.push('/error');
            return false;
        }

        this.countryCode = countryCode;
        this.stopLoading();
    },

    methods: {
        updateModule(id, data) {
            // Set the data
            switch (id) {
                case 'internationalAddress':
                    this.internationalAddress = data;
                    break;
                case 'identificationMain':
                case 'identificationAlternative':
                    this.idDetails = data;
                    this.submitData();
                    break;
            }

            // Navigate to the next screen
            const screenTransitions = {
                internationalAddress: 'identificationMain',
            };

            const nextScreen = screenTransitions[id];
            if (nextScreen) {
                this.navigateToNextScreen(nextScreen);
            }
        },

        showAlternativeForm() {
            this.navigateToNextScreen('identificationAlternative');
        },
        async submitData() {
            this.startLoading();

            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const applicationRecord = await getApplicationRecord({
                applicationId,
            });

            const payload = {
                applicationId,
                userId,
                data: {
                    address: {
                        ...applicationRecord.data.address,
                        international: this.internationalAddress,
                    },
                    identification: {
                        ...this.idDetails,
                        countryCode: this.countryCode,
                    },
                },
                stepToPassed: 'foreignDataCapture',
            };

            const req = await updateApplicationRecord(payload);
            if (!req.status) {
                this.$router.push('/error');
                return false;
            }

            this.modulePassed({
                event: 'foreign_data_captured',
                page_title: 'foreignDataCapture',
            });
        },
    },
};
</script>
