<template>
  <base-screen>
    <template #main>
      <base-subtitle>2. What company do you work for?</base-subtitle>
      <div class="input-wrap">
        <BaseInput :item="item" autofocus @onInput="inputChanged" />
      </div>
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="item.validationStatus !== 'valid'"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  name: 'CompanyName',
  components: {
    BaseScreen,
    BaseInput,
    BaseSubtitle,
    BaseButtonPrimary,
  },

  data() {
    return {
      item: {
        id: 'employerName',
        value: '',
        type: 'text',
        label: '',
        validationRequired: true,
        validationStatus: 'pending',
      },
    };
  },
  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateForm', this.item.id, this.item.value);
    },
  },
};
</script>

<style>
.input-wrap {
  position: relative;
}
</style>
