<template>
    <base-screen noLeaveAnimation>
        <template #main>
            <base-subtitle>
                {{ index }}. What is your employment start date?
            </base-subtitle>
            <div class="personal-details-form_row flex-row m-t-2">
                <div
                    v-for="(item, index) of formInputs.filter(
                        (el) => el.inputGroup == 'employmentStartDate'
                    )"
                    :key="'group2' + index"
                >
                    <BaseInput
                        :ref="item.id"
                        :item="item"
                        noErrorMessage
                        :inputRef="'input' + item.id"
                        @onInput="inputChanged"
                        @focused="focusedInput"
                    />
                </div>
            </div>
            <base-error-message v-if="employmentStartDateError">
                {{ employmentStartDateError }}
            </base-error-message>
        </template>
        <template #footer>
            <base-button-primary
                black
                :disabled="!formIsValid"
                @clicked="sendValue"
            >
                Continue
            </base-button-primary>
        </template>
    </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import moment from 'moment';
import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';

export default {
    name: 'EmploymentStartDate',
    components: {
        BaseScreen,
        BaseSubtitle,
        BaseButtonPrimary,
        BaseInput,
        BaseErrorMessage,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            employmentStartDateError: null,
            employmentStartDate: null,
            formInputs: [
                {
                    id: 'dateEmploymentStart',
                    value: '',
                    type: 'number',
                    subtype: 'day',
                    label: 'Day',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'employmentStartDate',
                },

                {
                    id: 'monthEmploymentStart',
                    value: '',
                    type: 'number',
                    subtype: 'month',
                    label: 'Month',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'employmentStartDate',
                },

                {
                    id: 'yearEmploymentStart',
                    value: '',
                    type: 'number',
                    subtype: 'year',
                    label: 'Year',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'employmentStartDate',
                },
            ],
        };
    },

    computed: {
        formIsValid() {
            const validInputs = this.formInputs.filter(
                (el) => el.validationStatus !== 'valid'
            );
            return (
                validInputs.length == 0 &&
                this.employmentStartDate &&
                !this.employmentStartDateError
            );
        },
    },
    methods: {
        inputChanged(id, value, status) {
            this.employmentStartDateError = null;
            const index = this.formInputs.findIndex((el) => el.id == id);

            this.formInputs[index].value = value;
            this.formInputs[index].validationStatus = status;

            this.validateEmploymentStartDate();
        },

        sendValue() {
            this.$emit(
                'updateForm',
                'employmentStartDate',
                this.employmentStartDate
            );
        },
        validateEmploymentStartDate() {
            this.employmentStartDate = '';
            const dateInput = this.formInputs.find(
                (el) => el.id == 'dateEmploymentStart'
            );
            const monthInput = this.formInputs.find(
                (el) => el.id == 'monthEmploymentStart'
            );
            const yearInput = this.formInputs.find(
                (el) => el.id == 'yearEmploymentStart'
            );

            if (
                dateInput.value.length &&
                monthInput.value.length &&
                yearInput.value.length == 4
            ) {
                const fieldsValid =
                    dateInput.validationStatus == 'valid' &&
                    monthInput.validationStatus == 'valid' &&
                    yearInput.validationStatus == 'valid';

                if (!fieldsValid) {
                    this.employmentStartDateError =
                        '*Please enter a valid date.';
                    return false;
                }

                const fullDate = `${dateInput.value}/${monthInput.value}/${yearInput.value}`;
                const dateIsValid = moment(
                    fullDate,
                    'DD/MM/YYYY',
                    true
                ).isValid();

                if (!dateIsValid) {
                    this.employmentStartDateError =
                        '*Please enter a valid date.';
                    return false;
                }

                // const date = moment(fullDate, 'DD/MM/YYYY', true);
                // const now = moment();

                // // Check if the date is in the future
                // if (date.isBefore(now)) {
                //     this.employmentStartDateError =
                //         '*The date must be in the future';
                //     return false;
                // } else {
                // }

                this.employmentStartDateError = null;
                this.employmentStartDate = fullDate;

                return true;
            }
        },
    },
};
</script>
