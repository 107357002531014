<template>
  <OtpScreen
    key="otpScreen"
    v-if="activeScreen == 'otpScreen'"
    :timerValue="122"
    :otpInputDisabled="otpInputDisabled"
    @confirmOTP="confirmOTP"
    @resendCode="resendCode"
    ref="OtpScreen"
  />
  <PhoneInputScreen
    v-else-if="activeScreen == 'phoneInputScreen'"
    :phoneInputDisabled="phoneInputDisabled"
    @updatePhoneNumber="updatePhoneNumber"
    key="phoneInputScreen"
  />
</template>

<script>
import OtpScreen from '../screens/phoneAuth/OtpScreen.vue';

import {
  phoneLookup,
  initiateVerification,
  submitOTP,
  resendOTP,
} from '../../controllers/auth.js';
import PhoneInputScreen from '../screens/phoneAuth/PhoneInputScreen.vue';

export default {
  components: {
    OtpScreen,
    PhoneInputScreen,
  },
  data() {
    return {
      isLoading: true,
      phoneNo: null,
      userId: null,
      otpInputDisabled: false,
      phoneInputDisabled: false,
      verificationId: null,
      activeScreen: null,
      modalIsActive: false,
      consentAndComms: {
        marketing: true,
        privacyPolicy: false,
        termsOfUse: false,
      },
    };
  },

  async mounted() {
    this.$store.dispatch('navigation/hideProgessBar');

    let applicationData = this.$store.getters['application/applicationData'];

    if (!applicationData) {
      const applicationId = localStorage.getItem('applicationId');

      applicationData = await this.$store.dispatch(
        'application/getApplicationRecord',
        { applicationId }
      );

      if (!applicationData.status) {
        this.$router.push('/error');
        return false;
      }
      applicationData = applicationData.data;
    }

    const phoneNo = applicationData.phoneNo;

    if (phoneNo) {
      this.phoneNo = phoneNo;

      await this.phoneConfirmation(phoneNo);
      this.activeScreen = 'otpScreen';
    } else {
      this.activeScreen = 'phoneInputScreen';
      this.$store.dispatch('navigation/stopLoading');
      this.isLoading = false;
    }

    // this.isLoading = false;
  },

  methods: {
    async updatePhoneNumber(phoneNo) {
      this.phoneInputDisabled = true;
      this.$store.dispatch('navigation/buttonPrimaryInLoadingState', true);

      // Update application record with phoneNo
      const data = { phoneNo };
      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }
      const verificationId = await this.phoneConfirmation(phoneNo);

      if (verificationId) {
        this.activeScreen = 'otpScreen';
      }
    },

    async phoneConfirmation(phoneNo) {
      const lookupReqBody = { phoneNo };

      //Step 1: Phone Number Lookup
      const lookupReq = await phoneLookup(lookupReqBody);

      if (!lookupReq.status) {
        this.$router.push('/error');
        return false;
      }

      const userId = lookupReq.data;
      this.userId = userId;

      // //Step 2: Initiate Verification
      const verificationReqBody = { userId };
      const verificationReq = await initiateVerification(verificationReqBody);

      if (!verificationReq.status) {
        this.$store.dispatch(
          'error/setErrorMessage',
          verificationReq.friendlyMessage
        );

        this.phoneInputDisabled = false;
        this.$router.push('/error');
        return false;
      }

      const verificationId = verificationReq.data;

      this.verificationId = verificationId;
      this.$store.dispatch('navigation/stopLoading');
      this.isLoading = false;
      return verificationId;
    },

    disableInput() {
      this.otpInputDisabled = true;
    },

    async resendCode() {
      this.disableInput();
      const verificationId = this.verificationId;
      const payload = { verificationId };
      const req = await resendOTP(payload);

      if (!req.status) {
        this.$store.dispatch('error/updatePopupErrorStatus', {
          status: true,
          message: req.friendlyMessage,
        });
      }

      this.otpInputDisabled = false;
      this.$refs.OtpScreen.startTimer();
    },

    async confirmOTP(otp) {
      // Remove focus
      document.activeElement.blur();
      this.$store.dispatch('navigation/startLoading');
      // this.otpInputDisabled = true;

      const applicationId = localStorage.getItem('applicationId');

      const payload = {
        phoneVerificationId: this.verificationId,
        verificationCode: otp,
        applicationId: applicationId,
      };

      const req = await submitOTP(payload);
      if (!req.status) {
        this.$store.dispatch('navigation/stopLoading');
        // this.otpInputDisabled = false;

        // Show error notification

        this.$store.dispatch('error/updatePopupErrorStatus', {
          status: true,
          message: req.friendlyMessage,
        });

        // Clear otp
        this.$refs.OtpScreen.$refs.OtpInput.clearOtp();
        return false;
      }
      // this.isLoading = true;

      const data = {
        userId: this.userId,
      };

      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }

      localStorage.setItem('verificationId', this.verificationId);

      this.$store.dispatch('navigation/modulePassed', {
        event: 'phone_otp_successful',
        page_title: 'auth',
      });
    },
  },
};
</script>

<style></style>
