<template>
  <div class="m-t-6 p-h-5">
    <div class="slider-manual-input">
      <div key="input" class="input-wrap">
        <div class="currency-symbol">
          £
        </div>
        <input
          class="input"
          :class="{
            filled: sliderValue,
            noLabel: true,
            prependSymbol: true,
            hasErrors: error,
          }"
          :value="sliderValue"
          pattern="'\\d*'"
          type="number"
          subType="tenancyContribution"
          @input="(event) => onChange(event.target.value)"
          @blur="onBlur"
        />
      </div>
    </div>
    <base-error-message v-if="error">
      {{ error }}
    </base-error-message>
    <div v-if="max > min" class="m-h-4">
      <div ref="sliderRange" class="slider_container">
        <!-- <div class="slider-container_tooltip" :style="{ right: tooltipPosition }">
      <p class="slider-container_tooltip_text">£{{ sliderValue }}</p>
    </div> -->
      </div>
      <div class="input_slider_wrap">
        <input
          type="range"
          :min="0"
          :max="max"
          class="slider"
          :value="sliderValueComputed"
          :style="sliderBackgroundStyle"
          @input="(event) => onChange(event.target.value, event)"
        />
      </div>

      <div class="slider_container_red">
        <div>£{{ 0 }}</div>
        <div>£{{ max }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseErrorMessage from '../inputs/BaseErrorMessage.vue';
export default {
    name: 'SliderWithLegend',
    components: {
        BaseErrorMessage,
    },
    props: {
        min: {
            type: Number,
            default: 1,
        },
        max: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            sliderValue: this.max,
            error: null,
        };
    },

    computed: {
        tooltipPosition() {
            const width = this.$refs.sliderRange?.clientWidth;
            const percent = (this.sliderValue * 100) / this.max;

            let pixelPerPercent = width - (width * percent) / 100;
            return pixelPerPercent + 'px';
        },
        sliderValueComputed() {
            return Math.max(Number(this.sliderValue), this.min);
        },
        sliderBackgroundStyle() {
            const thumbPercentage =
                ((this.sliderValueComputed - 0) / (this.max - 0)) * 100;
            return {
                background: `
        linear-gradient(to right, 
        #7599EB 0%, 
        #7599EB ${thumbPercentage}%, 
        #D5E7E2 ${thumbPercentage}%, 
        #D5E7E2 100%)`,
            };
        },
    },
    mounted() {
        this.$emit('sliderValueUpdate', this.sliderValue);
    },

    methods: {
        onBlur() {
            // console.log('onBlur');
            // if (
            //   this.sliderValue == '' ||
            //   this.sliderValue < this.min ||
            //   this.sliderValue == 0
            // ) {
            //   this.sliderValue = this.min;
            // } else if (this.sliderValue > this.max) {
            //   this.sliderValue = this.max;
            // }
        },
        onChange(value, event) {
          // Event from the input field
            if (event && value < this.min) {
                this.sliderValue = this.min;
                event.target.value = this.min;
                this.$emit('sliderValueUpdate', this.min);
                return false;
            }

            // Event from the slider
            this.sliderValue = value;
            this.$emit('sliderValueUpdate', value);
            this.error =
                value < this.min || value > this.max
                    ? '*Please enter a valid amount'
                    : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.slider_container {
    margin-top: 22px;
}

.slider_container {
    position: relative;
    left: 0;
}
.slider {
    width: 100%;
    background: $blue;
    height: 4px;
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: white;
    cursor: pointer;
    border: 4px solid $darkestGrey;
    border-radius: 8px;
    outline: 8px solid rgba(122, 159, 248, 0.1);
}

.slider-container_tooltip {
    background-color: black;

    position: absolute;
    top: -48px;
    right: 0;
    margin-right: -10px;
    border-radius: 8px;
}

.slider-container_tooltip::after {
    content: ' ';
    position: absolute;
    top: 95%; /* At the bottom of the tooltip */
    left: 90%;
    margin-left: -15px;
    border-width: 7px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.slider-container_tooltip_text {
    color: white;
    margin-bottom: 0;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
}

.slider_container_red {
    display: flex;
    justify-content: space-between;

    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: center;
}
.slider_container {
    position: relative;
    background: white;
}

.input-wrap {
    position: relative;
    left: 0;
}

.input {
    width: 100%;
    border: 1px solid $lightGrey;
    height: 58px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    outline: none;
    transition: 0.3s;
    padding: 19px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $darkestGrey;

    &.filled {
        border: 1px solid $darkestGrey;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        padding-bottom: 5px;
    }

    &.hasErrors {
        border: 1px solid $critical;
    }
    &:disabled {
        border-color: $lightestGrey;
    }
    &.noLabel {
        padding: 0px 16px;
    }

    &.prependSymbol {
        padding-left: 35px !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 58px white inset !important;
}

.currency-symbol {
    position: absolute;
    top: 19px;
    left: 19px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $middleGrey;
}
.slider-manual-input {
    padding-right: 52px;
}
</style>
