<template>
    <div>
        <div
            v-for="(item, index) of formInputs.filter((el) => !el.inputGroup)"
            :key="index"
            class="m-b-2"
        >
            <BaseInput
                :item="item"
                :inputRef="'input' + item.id"
                @onInput="inputChanged"
                @focused="focusedInput"
            />
        </div>

        <div class="m-t-6">
            <h2 class="header-2">Date of Birth*</h2>
            <div class="personal-details-form_row flex-row m-t-2">
                <div
                    v-for="(item, index) of formInputs.filter(
                        (el) => el.inputGroup == 'dateOfBirth'
                    )"
                    :key="'group' + index"
                >
                    <BaseInput
                        :ref="item.id"
                        :item="item"
                        noErrorMessage
                        :inputRef="'input' + item.id"
                        @onInput="inputChanged"
                        @focused="focusedInput"
                    />
                </div>
            </div>
            <base-error-message v-if="dofError">
                {{ dofError }}
            </base-error-message>
        </div>

        <div class="m-t-6">
            <h2 class="header-2">Phone Number*</h2>
            <div class="m-t-2">
                <PhoneInput
                    :userPhoneNumber="userPhoneNumber"
                    @onInput="inputChanged"
                />
            </div>
        </div>

        <div v-if="newToCountryFlow" class="m-t-6">
            <h2 class="header-2">When are you arriving in the UK?*</h2>
            <div class="personal-details-form_row flex-row m-t-2">
                <div
                    v-for="(item, index) of formInputs.filter(
                        (el) => el.inputGroup == 'dateOfArriving'
                    )"
                    :key="'group2' + index"
                >
                    <BaseInput
                        :ref="item.id"
                        :item="item"
                        noErrorMessage
                        :inputRef="'input' + item.id"
                        @onInput="inputChanged"
                        @focused="focusedInput"
                    />
                </div>
            </div>
            <base-error-message v-if="arrivalDateError">
                {{ arrivalDateError }}
            </base-error-message>
        </div>

        <div class="m-t-6 p-l-1">
            <div>
                <base-checkbox
                    id="updatesAccepted"
                    preventChange
                    checkedByDefault
                    :toggle="toggleMarketing"
                    @onInput="inputChanged"
                >
                    <span @click="toggleMarketing = !toggleMarketing">
                        Receive updates about similar Pillar products and
                        services.
                    </span>

                    <Popper hover="true" :style="myStyles">
                        <span class="clickable bold" data-cy="termsLink">
                            Terms apply.
                        </span>
                        <template #content>
                            We'll provide updates by email, text messages,
                            social media and push notifications.
                            <br />
                            <br />
                            You can opt-out anytime.
                            <br />
                            See our Privacy Policy for more details.
                        </template>
                    </Popper>
                </base-checkbox>
            </div>
            <div>
                <base-checkbox
                    id="termsAccepted"
                    @onInput="inputChanged"
                    preventChange
                    :toggle="toggle"
                >
                    <span class="inner-click" @click="toggle = !toggle">
                        I accept the
                    </span>
                    <span
                        class="clickable bold"
                        @click="openLink('terms', $event)"
                    >
                        Terms
                    </span>
                    <span class="inner-click" @click="toggle = !toggle">
                        and
                    </span>
                    <span
                        class="clickable bold"
                        @click="openLink('policy', $event)"
                    >
                        Privacy Policy.
                    </span>
                </base-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import BaseErrorMessage from '../inputs/BaseErrorMessage.vue';
import BaseCheckbox from '../inputs/BaseCheckbox.vue';
import PhoneInput from '../inputs/PhoneInput.vue';
import Popper from 'vue3-popper';

import BaseInput from '../inputs/BaseInput.vue';
import moment from 'moment';
export default {
    name: 'PersonalDetailsForm',
    components: {
        BaseInput,
        PhoneInput,
        BaseCheckbox,
        BaseErrorMessage,
        Popper,
    },

    data() {
        return {
            myStyles: {
                '--popper-theme-background-color': '#333333',
                '--popper-theme-background-color-hover': '#333333',
                '--popper-theme-text-color': '#ffffff',
                '--popper-theme-border-width': '0px',
                '--popper-theme-border-style': 'solid',
                '--popper-theme-border-radius': '8px',
                '--popper-theme-padding': '16px',
                '--popper-theme-box-shadow':
                    '0 6px 30px -6px rgba(0, 0, 0, 0.25)',
            },
            formInputs: [
                {
                    id: 'firstName',
                    value: '',
                    type: 'text',
                    label: 'First Name*',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
                {
                    id: 'lastName',
                    value: '',
                    type: 'text',
                    label: 'Last Name*',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
                {
                    id: 'emailAddress',
                    value: '',
                    type: 'email',
                    label: 'Email Address*',
                    validationRequired: true,
                    validationStatus: 'pending',
                },

                {
                    id: 'date',
                    value: '',
                    type: 'number',
                    subtype: 'day',
                    label: 'Day',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfBirth',
                },

                {
                    id: 'month',
                    value: '',
                    type: 'number',
                    subtype: 'month',
                    label: 'Month',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfBirth',
                },

                {
                    id: 'year',
                    value: '',
                    type: 'number',
                    subtype: 'year',
                    label: 'Year',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfBirth',
                },
                {
                    id: 'phoneNo',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'phoneNo',
                },

                {
                    id: 'updatesAccepted',
                    validationRequired: false,
                    validationStatus: 'pending',
                    inputGroup: 'checkboxes',
                },

                {
                    id: 'termsAccepted',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'checkboxes',
                },
            ],
            personalDetailsObj: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                phoneNo: '',
                consentAndComms: {
                    marketing: true,
                    privacyPolicy: true,
                    termsOfUse: true,
                },
            },
            showDialog: false,
            dialogToShow: null,
            dofError: null,
            arrivalDateError: null,
            userPhoneNumber: null,
            currentFocused: null,
            toggle: false,
            toggleMarketing: true,
            newToCountryFlow: false,
        };
    },

    async created() {
        const userId = this.$store.getters['auth/userId'];

        if (userId) {
            const userDataReq = await this.$store.dispatch(
                'application/getUserRecord',
                {
                    userId,
                }
            );
            if (!userDataReq.status) {
                this.$router.push('/error');
                return false;
            }
            const userData = userDataReq.data;

            this.userPhoneNumber = userData.phoneNo;
        }

        const product = localStorage.getItem('productName');

        if (product == 'pillarCardNTC') {
            this.formInputs.push(
                {
                    id: 'dateArrival',
                    value: '',
                    type: 'number',
                    subtype: 'day',
                    label: 'Day',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfArriving',
                },

                {
                    id: 'monthArrival',
                    value: '',
                    type: 'number',
                    subtype: 'month',
                    label: 'Month',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfArriving',
                },

                {
                    id: 'yearArrival',
                    value: '',
                    type: 'number',
                    subtype: 'year',
                    label: 'Year',
                    validationRequired: true,
                    validationStatus: 'pending',
                    inputGroup: 'dateOfArriving',
                }
            );

            this.personalDetailsObj.dateOfArrivalToUK = '';
            this.newToCountryFlow = true;
        }
        // window.addEventListener('keydown', (e) => {
        //   const types = ['date', 'month', 'year'];
        //   if (e.key == 'Tab' && types.includes(this.currentFocused)) {
        //     e.preventDefault()
        //   }
        // });
    },

    methods: {
        focusedInput(ref) {
            const type = ref.split('input')[1];
            this.currentFocused = type;
        },
        inputChanged(id, value, status) {
            const index = this.formInputs.findIndex((el) => el.id == id);

            const groupField = this.formInputs[index]?.inputGroup;

            if (groupField == 'dateOfBirth') {
                this.dofError = null;
                this.validateDOF();
            }

            if (groupField == 'dateOfArriving') {
                this.arrivalDateError = null;
                this.validateArrivalDate();
            }

            this.formInputs[index].value = value;
            this.formInputs[index].validationStatus = status;

            if (id == 'updatesAccepted') {
                if (value == true) {
                    this.personalDetailsObj.consentAndComms.marketing = true;
                } else {
                    this.personalDetailsObj.consentAndComms.marketing = false;
                }
            } else if (id == 'phoneNo') {
                if (value !== undefined) {
                    const phoneNumber = value.replace(/\s/g, '');
                    this.personalDetailsObj.phoneNo = phoneNumber;
                }
            } else if (
                groupField !== 'dateOfBirth' &&
                groupField !== 'dateOfArriving' &&
                (id !== 'phoneNo') & (id !== 'termsAccepted') &&
                id !== 'communication'
            ) {
                this.personalDetailsObj[id] = value;
            }

            // if (id == 'date' && value.length == 2) {
            //   this.$refs.month ? this.$refs.month[0].$refs.inputmonth.focus() : null;
            // }
            // if (id == 'month' && value.length == 2) {
            //   this.$refs.year ? this.$refs.year[0].$refs.inputyear.focus() : null;
            // }

            this.formIsValid();
        },

        formIsValid() {
            const personalDetailsObj = this.personalDetailsObj;

            let invalidInputs = [];
            invalidInputs = this.formInputs.filter(
                (el) =>
                    el.validationRequired == true &&
                    el.validationStatus !== 'valid' &&
                    el.inputGroup !== 'date'
            );

            const DOFCheck = this.validateDOF();
            let arrivalDateCheck = true;

            if (this.newToCountryFlow) {
                arrivalDateCheck = this.validateArrivalDate();
            }

            if (invalidInputs.length || !DOFCheck || !arrivalDateCheck) {
                this.$emit(
                    'updatePersonalDetails',
                    personalDetailsObj,
                    'invalid'
                );
                return false;
            }

            // if () {
            //   this.$emit('updatePersonalDetails', personalDetailsObj, 'invalid');
            //   return false;
            // }
            // if (
            //   !addressObj.subBuilding &&
            //   !addressObj.buildingName &&
            //   !addressObj.buildingNo &&
            //   !addressObj.streetName
            // ) {
            //   this.customError =
            //     'Apartment name, building name, number or street is required';
            //   this.$emit('updatePersonalDetails', addressObj, 'invalid');
            //   return false;
            // }

            this.$emit('updatePersonalDetails', personalDetailsObj, 'valid');
        },
        toggleTooltip(event) {
            event.stopPropagation();
            event.preventDefault();
            this.termsTooltip = !this.termsTooltip;
        },

        validateDOF() {
            this.personalDetailsObj.dateOfBirth = '';
            const dateInput = this.formInputs.find((el) => el.id == 'date');
            const monthInput = this.formInputs.find((el) => el.id == 'month');
            const yearInput = this.formInputs.find((el) => el.id == 'year');

            if (
                dateInput.value.length &&
                monthInput.value.length &&
                yearInput.value.length == 4
            ) {
                const fieldsValid =
                    dateInput.validationStatus == 'valid' &&
                    monthInput.validationStatus == 'valid' &&
                    yearInput.validationStatus == 'valid';

                if (!fieldsValid) {
                    this.dofError = '*Please enter a valid date of birth.';
                    return false;
                }

                const fullDOB = `${dateInput.value}/${monthInput.value}/${yearInput.value}`;

                const dateOfBirthOfValid = moment(
                    fullDOB,
                    'DD/MM/YYYY',
                    true
                ).isValid();

                if (!dateOfBirthOfValid) {
                    this.dofError = '*Please enter a valid date of birth.';
                    return false;
                }

                const getFullYears = () => {
                    const birthDate = moment(fullDOB, 'DD/MM/YYYY', true);
                    const now = moment();
                    const duration = moment.duration(now.diff(birthDate));
                    const fullYears = duration.asYears();

                    return Math.trunc(fullYears);
                };

                const fullYears = getFullYears();
                if (!fullYears || fullYears < 18) {
                    this.dofError =
                        '*You must be over 18 years old to create an account';
                    return false;
                }

                this.dofError = null;
                this.personalDetailsObj.dateOfBirth = fullDOB;
                return true;
            }
        },

        validateArrivalDate() {
            this.personalDetailsObj.dateOfArrivalToUK = '';
            const dateInput = this.formInputs.find(
                (el) => el.id == 'dateArrival'
            );
            const monthInput = this.formInputs.find(
                (el) => el.id == 'monthArrival'
            );
            const yearInput = this.formInputs.find(
                (el) => el.id == 'yearArrival'
            );

            if (
                dateInput.value.length &&
                monthInput.value.length &&
                yearInput.value.length == 4
            ) {
                const fieldsValid =
                    dateInput.validationStatus == 'valid' &&
                    monthInput.validationStatus == 'valid' &&
                    yearInput.validationStatus == 'valid';

                if (!fieldsValid) {
                    this.arrivalDateError = '*Please enter a valid date.';
                    return false;
                }

                const fullDate = `${dateInput.value}/${monthInput.value}/${yearInput.value}`;

                const dateIsValid = moment(
                    fullDate,
                    'DD/MM/YYYY',
                    true
                ).isValid();

                if (!dateIsValid) {
                    this.arrivalDateError = '*Please enter a valid date.';
                    return false;
                }

                const date = moment(fullDate, 'DD/MM/YYYY', true);
                const now = moment();

                // Check if the date is in the future
                // if (date.isBefore(now)) {
                //     this.arrivalDateError = '*The date must be in the future';
                //     return false;
                // } else {
                // }

                this.arrivalDateError = null;
                this.personalDetailsObj.dateOfArrivalToUK = fullDate;
                return true;
            }
        },
        openLink(doc, e) {
            e.stopPropagation();
            e.preventDefault();
            let link = 'https://www.hellopillar.com/terms-of-use';
            if (doc == 'policy') {
                link = 'https://www.hellopillar.com/privacy-policy';
            }

            const webview = localStorage.getItem('webview') == 'true';

            if (webview) {
                window.OpenUrlInWebview.postMessage(link);
            } else {
                window.open(link, '_blank');
            }
        },
    },
};
</script>

<style>
.personal-details-form_row {
    gap: 10px;
}

.popper {
    max-width: 350px !important;
}
</style>
