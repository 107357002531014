<template>
    <div v-if="banks" class="banks-grid_wrapper">
        <div v-for="(item, index) of banks" :key="index">
            <div
                class="banks-grid_item"
                :class="{
                    selected: selectedBank?.display_name == item.display_name,
                }"
                @click="onBankSelect(item)"
            >
                <div
                    class="banks-grid_item_image-wrap"
                    :style="{
                        transform:
                            item.display_name == 'Halifax' ||
                            item.display_name == 'AIB'
                                ? 'scale(0.78)'
                                : 'scale(1)',
                    }"
                >
                    <BaseImage
                        :image="item.provider_id"
                        folder="tl-banks"
                        width="70%"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { POPULAR_BANKS } from '../../assets/data/listOfBanks.js';
import BaseImage from '../assets/BaseImage.vue';

export default {
    name: 'PopularBanksGrid',
    components: { BaseImage },
    props: {
        selectedBank: {
            type: Object,
        },
    },
    data() {
        return {
            banks: POPULAR_BANKS,
        };
    },
    created() {
        this.preloadImages();
    },
    methods: {
        onBankSelect(selectedBank) {
            this.$emit('onBankSelect', selectedBank);
        },
        preloadImages() {
            this.banks.forEach((bank) => {
                let img = new Image();
                img.src = bank.logo_url;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.banks-grid_wrapper {
    gap: 8px;
    display: flex;
    align-items: start;
    width: 100%;
    flex-wrap: wrap;
}

.banks-grid_item {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.2s;
    border-radius: 16px;
    border: 1px solid #edf5f5;

    &.selected {
        border: 2px solid #16e89e;
        background-color: white;
    }
    .banks-grid_item_image-wrap {
        img {
            width: 60px;
        }
    }
}
</style>
