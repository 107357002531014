<template>
  <base-screen>
    <template #main>
      <base-title> {{ title }} </base-title>
      <ManualAddressForm type="current" @updateAddress="updateAddress" />
    </template>
    <template #footer>
      <base-button-primary black :disabled="!formIsValid" @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import ManualAddressForm from '../../application/manualAddressForm.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';

export default {
  name: 'CurrentAddressManual',
  components: { BaseScreen, BaseTitle, BaseButtonPrimary, ManualAddressForm },

  data() {
    return {
      address: null,
      formStatus: 'pending',
      errorMessage: null,
    };
  },

  computed: {
    formIsValid() {
      return this.formStatus == 'valid';
    },
    title() {
      const product = localStorage.getItem('productName');

      if(product === 'rentReportingAndMonitor') {
        return 'Enter new rental address below';
      } else {
        return 'Couldn\'t find your address? Enter it below';
      }
    }
  },
  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateModule', 'currentAddressManual', this.address);
    },

    updateAddress(address, status) {
      this.address = address;
      this.formStatus = status;
    },
  },
};
</script>
