<template>
  <base-screen>
    <template #main>
      <base-title>
        Last few questions about your tenancy
      </base-title>
      <base-modal v-if="showDialog" @closeModal="showDialog = false">
        <TenanciesTypes @cancel="showDialog = false" />
      </base-modal>

      <div>
        <!-- <div class="transaction-details_block_img_wrap">
          <div class="transaction-details_block_img">
            <img :src="item.image ? item.image : defaultImgSrc" />
          </div>
        </div> -->
        <div>
          <!-- <p class="transaction-details_block_title">
            In the last six months, you've paid to
            <strong> {{ item.description }}</strong>
          </p>
          <h4 class="text-centered">£{{ item.amount }} /month</h4> -->

          <div class="transaction-details_tenancy-type">
            <div
              class="transaction-details_tenancy-type_icon"
              @click="showDialog = true"
            >
              <BaseIcon icon="question_blue" size="md" />
            </div>
            <h5 class="m-b-2">
              Please select the type of tenancy you are in.
            </h5>
            <div class="m-t-5 p-v-3 transaction-details_tenancy-type_row flex-row">
              <label class="transaction-details_tenancy-type_item custom-radio" @click="tennancyType = 'sole'">
                <input type="radio" :checked="tennancyType == 'sole'" />
                <span>Sole</span>
              </label>
              <label class="transaction-details_tenancy-type_item custom-radio" @click="tennancyType = 'joint'">
                <input type="radio" :checked="tennancyType == 'joint'" />
                <span>Joint</span>
              </label>
            </div>


            <div
              v-if="tennancyType == 'joint'"
              class="transaction-details_joint m-t-6"
            >
              <transition
                appear
                enter-active-class="animate__animated animate__fadeIn"
              >
                <div>
                  <h2 class="header-2">
                    Please confirm your contribution to this rent payment.
                  </h2>

                  <SliderWithLegend
                    :max="amount"
                    :min="minAmount"
                    @sliderValueUpdate="sliderValueUpdate"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary :disabled="!formIsValid" @clicked="sendValue" > 
        Continue
      </base-button-primary>
      <!-- <div v-if="showBackToTransactionsOption" class="m-t-2">
        <base-button-secondary @clicked="goBack">
          Not the right transaction?
        </base-button-secondary>
      </div> -->
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
// import BaseButtonSecondary from '../../buttons/BaseButtonSecondary.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import SliderWithLegend from '../../slider/SliderWithLegend.vue';
import BaseIcon from '../../assets/BaseIcon.vue';

import TenanciesTypes from '../../transactions/TenanciesTypes.vue';
// import RRDefault from '../../../assets/img/rr-default.svg';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseModal from '../../modal/BaseModal.vue';

export default {
  name: 'TransactionDetails',
  components: {
    BaseIcon,
    BaseButtonPrimary,
    // BaseButtonSecondary,
    SliderWithLegend,
    TenanciesTypes,
    BaseTitle,
    BaseScreen,
    BaseModal,
  },
  props: {
    // item: {
    //   type: Object,
    //   required: true,
    // },
    amount: {
      type: Number,
      required: true,
    },
    showBackToTransactionsOption: {
      type: Boolean,
      default: true,
    },
    generalRecurrenceType: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      tennancyType: 'sole',
      jointTenancyContribution: 0,
      showDialog: false,
      
      // defaultImgSrc: RRDefault,
    };
  },
  computed: {
    minAmount() {
    if (this.generalRecurrenceType.toLowerCase() == 'monthly') {
      return 60;
    } else  if(this.generalRecurrenceType.toLowerCase() == 'fortnightly') {
      return 30;
    } else {
      return 15;
    }
    },
    tooltipPosition() {
      const width = this.$refs.sliderRange?.clientWidth;
      const newPosition = width - (width * this.sliderValue) / 100;
      return Math.round(newPosition) + 'px';
    },
    formIsValid() {
      return this.tennancyType == 'sole' || (this.jointTenancyContribution >= this.minAmount && this.jointTenancyContribution <= this.amount);
    
    },
  },
  methods: {

    changeTennancyType(type) {
      this.tennancyType = type;
    },
    goBack() {
      this.$emit('goBack');
    },

    sliderValueUpdate(value) {
      this.jointTenancyContribution = value;
    },
    sendValue() {
      const data = {
        tenancyType: this.tennancyType,
        // DETransactionId: this.item.id,
      };

      if (this.tennancyType == 'joint') {
        data.jointTenancyContribution = +this.jointTenancyContribution;
      }

      this.$emit('updateForm', 'transactionDetails', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';
.transaction-details_title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}

.transaction-details_block {
  background-color: $lightestGrey;
  min-height: 200px;
  margin-top: 50px;
  border-radius: 24px;
  padding: 69px 16px 16px;
  position: relative;
}

.transaction-details_block_img_wrap {
  position: absolute;
  padding: 12px;
  border-radius: 40%;
  top: -45px;
  left: calc(50% - 54px);
  background-color: white;
  box-shadow: 0px 15px 15px 0px rgba(118, 130, 152, 0.1);
  border: 12px solid rgba(110, 120, 119, 0.2);
}

.transaction-details_block_img {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
}

.transaction-details_block_title_wrap {
  text-align: center;
}

.transaction-details_block_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
}

.transaction-details_tenancy-type {
  // background-color: white;
  // padding: 24px 35px 24px 24px;
  // border-radius: 12px;
  position: relative;
}

.transaction-details_tenancy-type_row {
  gap: 24px;
}

.transaction-details_tenancy-type_item {
  display: flex;
}

.transaction-details_tenancy-type_icon {
  position: absolute;
  top: 10px;
  right: 0;
}

.transaction-details_joint {
  text-align: left;
}
</style>
