<template>
  <base-screen>
    <template #main>
      <base-title>{{ title }}</base-title>
      <BaseInternationalForm
        :countryCode="countryCode"
        :inputsArray="formInputs"
        @updateForm="updateForm"
      />
      <LabelButton v-if="countryCode == 'IN'" @clicked="showAlternativeForm" class="m-t-3" size="md">
        Use a different identifier
      </LabelButton>
    </template>
    <template #footer>
      <base-button-primary
        :disabled="!formIsValid"
        @clicked="sendValue(idDetails)"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import screenMixin from '../../../mixins/screenMixin.js';
import { countryIdNumbersFormInputsMain } from '../../../assets/data/countryIdNumbersFormInputs.js';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseInternationalForm from '../../application/baseInternationalForm.vue';
import LabelButton from '../../buttons/LabelButton.vue';

export default {
    components: {
        BaseScreen,
        BaseInternationalForm,
        BaseButtonPrimary,
        LabelButton,
        BaseTitle,
    },
    mixins: [screenMixin({ screenName: 'identificationMain' })],
    props: {
        countryCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            idDetails: {},
            formIsValid: false,
        };
    },
    computed: {
        title() {
            const titles = {
                IN: "What's your Indian identifier?",
                US: "What's your Social Security Number?",
                AU: "What's your Driving License Number?",
            };
            return titles[this.countryCode] || null;
        },
        formInputs() {
            // Get country specific form inputs
            return countryIdNumbersFormInputsMain[this.countryCode];
        },
    },

    methods: {
      updateForm(idDetails, status) {
            this.idDetails = idDetails;
            this.formIsValid = status === 'valid';
        },
        showAlternativeForm() {
            this.$emit('showAlternativeForm');
        },
    },
};
</script>