<script>
export default {
  data() {
    return {
      moduleName: 'jumioCallback',
    };
  },

  async created() {
    this.$store.dispatch('navigation/loadingTextUpdate', null);
    this.$store.dispatch('navigation/moduleLoadingStart');

    const applicationId = localStorage.getItem('applicationId');

    const checkJumioStatus = async () => {
      // Get next step
      const nextStepReq = await this.$store.dispatch('navigation/getNextStep', {
        applicationId,
      });

      const nextStep = nextStepReq.data.nextStep;

      //If next step is not jumioCallback (we got the jumio callback and step was set to passed), get the next step
      if (nextStep !== 'jumioCallback') {
        this.$store.dispatch('navigation/modulePassed', {
          event: null,
          page_title: this.moduleName,
        });
        return true;
      } else {
        //  If it is not, it means that we are still waiting for callback and we need to check the step again in 5s
        setTimeout(checkJumioStatus, 5000);
      }
    };
    await checkJumioStatus();
  },
};
</script>

<style></style>
