<template>
  <base-screen customTransition="fade" docScreen>
    <template #main>
      <DocContainer :data="docData" />
      <DocFooter @updateModule="sendValue" />
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import DocContainer from '../../documents/DocContainer.vue';
import DocFooter from '../../documents/DocFooter.vue';

export default {
  name: 'AdequateExplanationsScreen',
  components: {
    BaseScreen,
    DocContainer,
    DocFooter,
  },

  props: {
    docData: {
      type: String,
      required: true,
    },
    cardProductType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scrollPosition: 0,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  methods: {
    sendValue() {
      this.$emit('updateModule', 'pre-contract');
    },
  },
};
</script>
