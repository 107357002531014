<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="error-illustration" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <transition
            appear
            enter-active-class="animate__animated animate__fadeIn"
          >
            <h3 class="text-centered">Sorry, your application has expired</h3>
          </transition>
          <!-- <p class="text-centered grey m-t-4">
               Please start a
              </p> -->
        </div>
      </div>
    </template>

    <template #footer>
      <NewApplicationButton />
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../UI/elements/layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import NewApplicationButton from '../../buttons/NewApplicationButton.vue';

export default {
  components: {
    ContainerSized,
    BaseScreen,
    BaseImage,
    NewApplicationButton,
  },
  name: 'ErrorScreen',
  data() {
    return {
      isLoading: true,
    };
  },

  created() {
    this.$store.dispatch('navigation/hideProgessBar');
  },
  mounted() {
    this.isLoading = false;
    this.$store.dispatch('navigation/stopLoading');
  },
  computed: {
    errorMessage() {
      return (
        this.$store.getters?.errorMessage ??
        'An unknown error occurred, please try again or contact support.'
      );
    },
  },
};
</script>

<style>
.actionButton {
  padding: 20px;
}
</style>
