<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-subtitle>{{ address }}</base-subtitle>
      <base-description>
        In order to verify you are staying at {{ address }} we need you
        to complete any one of the following:
      </base-description>
      <FileUploadInput
        id="tenancy-agreement"
        key="tenancy-agreement"
        title="Upload a signed Tenancy Agreement"
        :selectedInput="selectedType"
        @onFileChange="onFileChange($event, 'tenancy-agreement')"
      />
      <FileUploadInput
        id="bank-statement"
        key="bank-statement"
        title="Upload a Bank Statement"
        :selectedInput="selectedType"
        @onFileChange="onFileChange($event, 'bank-statement')"
      />
      <FileUploadInput
        id="utility-bill"
        key="utility-bill"
        title="Upload a Utility Bill"
        :selectedInput="selectedType"
        @onFileChange="onFileChange($event, 'utility-bill')"
      />
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="!selectedFile"
        @clicked="uploadFile"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';
import BaseDescription from '../../layout/BaseDescription.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import FileUploadInput from '../../inputs/FileUploadInput.vue';

export default {
    name: 'AddressVerification',
    components: {
        BaseScreen,
        BaseSubtitle,
        BaseButtonPrimary,
        BaseDescription,
        FileUploadInput,
    },
    props: {
        currentAddress: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedFile: null,
            selectedType: '',
            eventData: null,
        };
    },
    computed: {
        address() {
            // convert address object to string
            const firstLine = `${
                this.currentAddress.subBuilding
                    ? this.currentAddress.subBuilding + ' '
                    : ''
            }${
                this.currentAddress.buildingName
                    ? this.currentAddress.buildingName + ' '
                    : ''
            }${
                this.currentAddress.buildingNo
                    ? this.currentAddress.buildingNo + ' '
                    : ''
            }${this.currentAddress.streetName}, ${this.currentAddress.city}`;

            return firstLine;
        },
    },
    methods: {
        onFileChange(event, type) {
            const file = event.target.files[0];
            this.selectedFile = file;
            this.selectedType = type;
            this.eventData = event;
        },

        async uploadFile() {
            this.$store.dispatch('navigation/startLoading');

            const userId = this.$store.getters['auth/userId'];
            const req = await this.$store.dispatch(
                'application/uploadFileToStorage',
                {
                    eventData: this.eventData,
                    docType: this.selectedType,
                    userId,
                }
            );

            if (!req.status) {
                this.handleError('Something went wrong while uploading the file. Please try again.');
                return;
            }

            const fileInfo = req.data;
            this.$emit(
                'updateModule',
                'addressVerification',
                fileInfo
            );
        },


        handleError(response) {
            this.selectedFile = null;
            this.$store.dispatch('navigation/stopLoading');
            this.$store.dispatch('error/updatePopupErrorStatus', {
                status: true,
                message: response.friendlyMessage,
            });
        },
    },
};
</script>

<style scoped>
.file-path {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
}
</style>
