<template>
  <div :class="['modal', { 'modal--50': desktopBGHidden }]">
    <div class="modal-overlay" :class="{ 'show-overlay': showModal }"></div>
    <div class="modal-content" :class="{ 'show-content': showModal }">
      <div v-if="headerIsVisible" class="modal-header">
        <base-title v-if="title" noMargin>
          {{ title }}
        </base-title>
        <div
          v-if="!noCloseButton"
          :style="{ marginLeft: 'auto' }"
          @click="closeDialog"
        >
          <BaseIcon icon="close" :isButton="true" size="lg" />
        </div>
      </div>
      <div ref="scrollableContent" class="modal-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
import BaseTitle from '../layout/BaseTitle.vue';

export default {
  name: 'BaseBottomSheet',
  components: { BaseTitle, BaseIcon },
  props: {
    title: {
      type: String,
    },
    noCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    headerIsVisible() {
      return this.title || !this.noCloseButton;
    },
    desktopBGHidden() {
      return this.$store.getters['navigation/desktopBGHidden'];
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.scrollableContent) {
        this.$refs.scrollableContent.scrollTop = 0;
      }
      this.showModal = true;
      this.toggleBodyScroll(true); // Enable body scrolling
    });
  },
  unmounted() {
    this.toggleBodyScroll(false); // Disable body scrolling when modal is closed or component is destroyed
  },
  methods: {
    closeDialog() {
      this.showModal = false;
      this.$emit('closeModal');
    },

    toggleBodyScroll(enable) {
      const body = document.body;

      if (enable) {
        body.classList.add('modal-open');
      } else {
        body.classList.remove('modal-open');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body.modal-open {
  overflow: hidden !important;
  max-height: 100vh !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.show-overlay {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  position: fixed;
  bottom: -10px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 10000;
}

.modal-content {
  background-color: white;
  max-width: 600px;
  width: 100%;
  padding: 24px;
  border-radius: 12px 12px 0 0;
  opacity: 0;
  transform: translateY(
    100%
  ); /* Initially push the modal content out of the screen */
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out,
    transform 0.3s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  pointer-events: none;
}

.show-content {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .modal--50 {
    width: 50%;
    right: 0;
    margin-left: auto;
  }
}
</style>
