<script>
export default {
    name: 'ForeignCreditReportPullFail',
    async created() {
        console.log('ForeignCreditReportPullFail created');
        this.$store.dispatch('navigation/startLoading');

        console.log('fail');
        this.$store.dispatch(
            'error/setErrorMessage',
            'Sorry we could not pull your credit report at this time. Please try again later or contact support.'
        );

        this.$router.push('/error');
    },
};
</script>
