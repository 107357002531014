<script>
export default {
  name: 'TrueLayerRawDataRetrieved',

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    const checkNextStep = async () => {
      const applicationId = localStorage.getItem('applicationId');

      // Get next step
      const nextStepReq = await this.$store.dispatch('navigation/getNextStep', {
        applicationId,
      });
      const nextStep = nextStepReq.data.nextStep;

      //If next step is not trueLayerRawDataRetrieved, get the next step
      if (nextStep !== 'trueLayerRawDataRetrieved') {
        this.$store.dispatch('navigation/modulePassed', {
          event: null,
          page_title: 'trueLayerRawDataRetrieved',
        });
        return true;
      } else {
        //  If it is not, it means that we are still waiting for data to pull and we need to check the step again in 2s
        setTimeout(checkNextStep, 2000);
      }
    };
    await checkNextStep();
  },
};
</script>
