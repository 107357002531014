<template>
  <DirectDebitSetupBuild 
    v-if="cardTypeSecured && !isLoading" 
    key="directDebitBuild"
    :bankAccounts="bankAccounts"
    @updateForm="updateForm"
  />
  <ChooseBankAccount
    v-else-if="activeScreen == 'chooseBankAccount' && !isLoading"
    key="chooseBankAccount"
    :bankAccounts="bankAccounts"
    @updateForm="updateForm"
  />
  <RepaymentAmountSetup
    v-else-if="activeScreen == 'repaymentAmountSetup' && !isLoading"
    key="repaymentAmountSetup"
    :repaymentOptions="repaymentOptions"
    @updateForm="updateForm"
  />
  <SelectInvoiceDayOfMonth
    v-else-if="activeScreen == 'selectInvoiceDayOfMonth' && !isLoading"
    key="selectInvoiceDayOfMonth"
    @updateForm="updateForm"
  />
  <DirectDebitConfirmation
    v-else-if="activeScreen == 'directDebitConfirmation' && !isLoading"
    key="directDebitConfirmation"
    :selectedBankAccount="selectedBankAccount"
    :selectedRepaymentOption="selectedRepaymentOption"
    :selectedInvoiceDayOfMonth="selectedInvoiceDayOfMonth"
    @updateForm="updateForm"
  />
</template>

<script>
import DirectDebitConfirmation from '../screens/directDebitSetup/DirectDebitConfirmation.vue';
import RepaymentAmountSetup from '../screens/directDebitSetup/RepaymentAmountSetup.vue';
import ChooseBankAccount from '../screens/directDebitSetup/ChooseBankAccount.vue';
import { directDebitSetup } from '../../controllers/direct-debit.js';
import { getBankAccounts } from '../../controllers/truelayer';
import SelectInvoiceDayOfMonth from '../screens/directDebitSetup/SelectInvoiceDayOfMonth.vue';
import DirectDebitSetupBuild from '../screens/directDebitSetup/DirectDebitSetupBuild.vue';

const REPAYMENT_OPTIONS = [
    {
        title: 'Choose Each Payment',
        description:
            "We'll ask you how much you want to pay as your due date nears. If you don't select an amount, we'll automatically charge you the minimum.",
        value: 'chooseEachTime',
    },
    {
        title: 'Full Statement Balance',
        description:
            'The full amount of money you owe on the credit card following a billing cycle',
        value: 'fullBalance',
    },
    {
        title: 'Minimum Payment',
        description:
            "The lowest amount you can repay to cover what you've borrowed on your card.",
        value: 'minimumPayment',
    },
];

export default {
    name: 'DirectDebitSetup',
    components: {
        ChooseBankAccount,
        RepaymentAmountSetup,
        DirectDebitConfirmation,
        SelectInvoiceDayOfMonth,
        DirectDebitSetupBuild
    },
    data() {
        return {
            isLoading: true,
            bankAccounts: [],
            selectedBankAccount: null,
            repaymentOptions: REPAYMENT_OPTIONS,
            selectedRepaymentOption: null,
            selectedInvoiceDayOfMonth: null,
            cardTypeSecured: false
        };
    },

    computed: {
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];
            if (!activeScreen) {
                return 'chooseBankAccount';
            } else {
                return activeScreen;
            }
        },

        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
    },

    async created() {
        let userId = this.$store.getters['auth/userId'];

        if (!userId) {
            this.$router.push('/error');
            return false;
        }

        // Get cardProductType
        const applicationId = localStorage.getItem('applicationId');
        const applicationData = await this.$store.dispatch(
                'application/getApplicationRecord',
                {
                    applicationId,
                }
            );

         this.cardTypeSecured =  applicationData.data.cardProductType == 'secured';

        // Get connected bank accounts
        let accounts = [];
        const bankAccountsReq = await getBankAccounts({ userId });

        if (!bankAccountsReq.status) {
            this.$router.push('/error');
            return false;
        }

        // If bank accounts are fetched, add new fields and push to accounts list
        if (bankAccountsReq.data.length > 0) {
            const fetchedAccounts = bankAccountsReq.data;

            fetchedAccounts.map((el) => {
                let name = el.provider.display_name;
                if (el.account_type == 'SAVINGS') {
                    name = `${name} SAVINGS`;
                }

                const updatedAccount = {
                    ...el,
                    accountName: name,
                    disabled: !(
                        el.supportsDirectDebit && el?.validRepaymentAccount
                    ),
                    maskedAccountNumber: el?.account_number?.number
                        ? `****${el.account_number.number.slice(-4)}`
                        : null,
                };

                return accounts.push(updatedAccount);
            });
        }

        this.bankAccounts = accounts;
        this.$store.dispatch('navigation/stopLoading');

        // For transition effect
        setTimeout(() => {
            this.isLoading = false;
        }, 200);
    },


    methods: {
        async updateForm(id, value) {
            switch (id) {
                case 'directDebitSetupBuild':
                    const selectedAccountBuild = this.bankAccounts.find(
                        (el) => el.account_id == value
                    );
                    this.selectedBankAccount = selectedAccountBuild;
                    const selectedRepaymentOptionBuild = this.repaymentOptions.find(
                        (el) => el.value == 'chooseEachTime'
                    );
                    this.selectedRepaymentOption = selectedRepaymentOptionBuild;

                    await this.setupDirectDebit();
                    break;
                case 'chooseBankAccount':
                    const selectedAccount = this.bankAccounts.find(
                        (el) => el.account_id == value
                    );
                    this.selectedBankAccount = selectedAccount;

                    this.$store.dispatch(
                        'navigation/updateScreen',
                        'repaymentAmountSetup'
                    );
                    break;

                case 'repaymentSettings':
                    const selectedRepaymentOption = this.repaymentOptions.find(
                        (el) => el.value == value
                    );

                    this.selectedRepaymentOption = selectedRepaymentOption;

                    this.$store.dispatch(
                        'navigation/updateScreen',
                        'selectInvoiceDayOfMonth'
                    );
                    break;
                case 'selectInvoiceDayOfMonth':
                    this.selectedInvoiceDayOfMonth = value;

                    this.$store.dispatch(
                        'navigation/updateScreen',
                        'directDebitConfirmation'
                    );
                    break;
                case 'directDebitConfirmed':
                    await this.setupDirectDebit();
                    break;
                default:
                    break;
            }
        },

        async setupDirectDebit() {
            this.$store.dispatch('navigation/startLoading');
            const userId = this.$store.getters['auth/userId'];

            if (!userId) {
                this.$router.push('/error');
                return false;
            }

            const reqData = {
                userId,
                repaymentAmountSetting: this.selectedRepaymentOption.value,
                accountId: this.selectedBankAccount.account_id,
                invoiceDayOfMonth: this.selectedInvoiceDayOfMonth?.value,
            };

            const applicationId = localStorage.getItem('applicationId');
            if (applicationId) {
                reqData.applicationId = applicationId;
            }

            const DDSetupReq = await directDebitSetup(reqData);

            if (!DDSetupReq.status) {
                this.$router.push('/error');
                return false;
            }

            if (!applicationId) {
                window.DirectDebitSetupComplete.postMessage('Success');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                moduleName: 'directDebitSetup',
            });
        },
    },
};
</script>

<style>
.direct-debit-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding-left: 27px;
    cursor: pointer;
}

.direct-debit-logo-container {
    width: 100px;
}
.direct-debit-text {
    flex: 1;
}
</style>
