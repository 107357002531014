import { sendRequest } from "../config/axios/helper";

export const obUrls = {
    GET_CONSENT_URL: {
        url: '/truelayer/consent-link',
        type: 'post',
        reqParams: ['userId', 'providerId', 'verificationId']
    },
    GET_CONSENT_LINK_DB: {
        url: '/truelayer/get-redirect-link',
        type: 'post',
        reqParams: ['userId', 'redirectDocId']
    },

    CONSENT_COMPLETED: {
        url: 'truelayer/consent',
        type: 'post',
        reqParams: ['userId', 'consentId', 'consentCode']
    },

    RAW_DATA_PULL_STATUS: {
        url: 'truelayer/raw-data-pull-status',
        type: 'post',
        reqParams: ['userId', 'consentId']
    },

    GET_BANK_ACCOUNTS: {
        url: 'truelayer/bank-accounts/',
        type: 'get',
        reqParams: ['userId']
    },

    GET_PAYMENT: {
        url: 'truelayer/payment/get',
        type: 'post',
        reqParams: ['userId', 'paymentId']
    },
    RESET_TL_STEPS: {
        url: 'truelayer/reset-application-steps',
        type: 'post',
        reqParams: ['applicationId']
    }

}

export const getTrueLayerConsentUrl = async params => {
    return await sendRequest(obUrls.GET_CONSENT_URL, params);
};

export const getTrueLayerConsentLinkDB = async params => {
    return await sendRequest(obUrls.GET_CONSENT_LINK_DB, params);
};

export const trueLayerConsentCompleted = async params => {
    return await sendRequest(obUrls.CONSENT_COMPLETED, params);
};

export const trueLayerRawDataPullCompleted = async params => {
    return await sendRequest(obUrls.RAW_DATA_PULL_STATUS, params);
};

export const getBankAccounts = async params => {

    const reqObj = { ...obUrls.GET_BANK_ACCOUNTS };

    // Add the url with passed params to request object
    reqObj.url = obUrls.GET_BANK_ACCOUNTS.url + params?.userId;
    return await sendRequest(reqObj, params);

};

export const trueLayerGetPayment = async params => {
    return await sendRequest(obUrls.GET_PAYMENT, params);
};

export const resetTLSteps = async params => {
    return await sendRequest(obUrls.RESET_TL_STEPS, params);
}