<template>
  <base-screen>
    <template #main>
      <base-title>Tell us a bit about you</base-title>
      <PersonalDetailsForm
        v-bind="$attrs"
        @updatePersonalDetails="updatePersonalDetails"
      />
    </template>
    <template #footer>
      <base-button-primary :disabled="!formIsValid" @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import PersonalDetailsForm from '../../application/personalDetailsForm.vue';
export default {
  name: 'PersonalDetails',
  components: {
    BaseScreen,
    BaseTitle,
    PersonalDetailsForm,
    BaseButtonPrimary,
  },

  data() {
    return {
      details: null,
      formStatus: 'pending',
    };
  },

  computed: {
    formIsValid() {
      return this.formStatus == 'valid';
    },
  },
  methods: {
    updatePersonalDetails(details, status) {
      this.details = details;
      this.formStatus = status;
    },

    sendValue() {
      this.$emit('updateModule', 'personalDetails', this.details);
    },
  },
};
</script>
