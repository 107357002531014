<template>
  <div>
    <div
      class="flex-row address-duration_row"
      @click="sendValue(el.inputValue)"
      v-for="(el, index) in options"
      :key="index"
    >
      <div class="address-duration-item_title">
        <h2 class="header-2">{{ el.text }}</h2>
      </div>

      <div class="transaction-list-item_icon__select">
        <BaseIcon icon="arrow-right" size="sm" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
export default {
  name: 'AddressDuration',
  components: { BaseIcon },
  data() {
    return {
      options: [
        {
          text: 'More than 1 year',
          inputValue: 'moreThanYear',
          icon: 'check_grey',
        },
        {
          text: 'Less than 1 year',
          inputValue: 'lessThanYear',
          icon: 'check_grey',
        },
      ],
    };
  },

  methods: {
    sendValue(value) {
      this.$emit('updateModule', 'addressDuration', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.address-duration_row {
  gap: 10px;
  align-items: center;
  padding: 21px 24px;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.transaction-list-item_icon__select {
  margin-left: auto;
}
</style>
