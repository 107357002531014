<template>
  <div class="phone-input_wrap">
    <vue-tel-input
      :key="updateKey"
      defaultCountry="GB"
      :disabled="disabled"
      autoFormat
      :value="phone"
      :inputOptions="{
        id: 'telinput',
        // placeholder: 'Enter phone number',
        required: true,
      }"
      mode="international"
      validCharactersOnly
      placeholder=""
      :class="{ hasErrors: showError }"
      @open="onFocus"
      @focus="onFocus"
      @input="(number, phoneObject) => updateInputNew(number, phoneObject)"
      @blur="onBlur"
    ></vue-tel-input>
    <base-error-message v-if="showError" key="error">
      {{ validation.errorMessage }}
    </base-error-message>
  </div>
</template>

<script>
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { logFirebaseEvent } from '../../controllers/analytics/firebase';
import BaseErrorMessage from './BaseErrorMessage.vue';
import { VueTelInput } from 'vue3-tel-input';
export default {
  name: 'PhoneInput',
  components: { BaseErrorMessage, VueTelInput },
  props: {
    userPhoneNumber: {
      type: String,
    },
  },
  data() {
    return {
      updateKey: 1,
      phone: '',
      countryDialCode: null,
      countryCode: '',
      validation: {
        status: 'pending',
        errorMessage: '',
      },
      inFocus: false,
      showError: false,
      disabled: false,
      phoneInput: '',
    };
  },

  mounted() {
    this.setMatchedDivWidth();
    window.addEventListener('resize', this.setMatchedDivWidth);

    // Set type tel to input
    this.$nextTick(() => {
      const telInput = document.getElementById('telinput');
      if (telInput) {
        telInput.type = 'tel';
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setMatchedDivWidth);
  },

  async created() {
    const userId = this.$store.getters['auth/userId'];

    if (userId) {
      const userDataReq = await this.$store.dispatch(
        'application/getUserRecord',
        {
          userId,
        }
      );
      if (!userDataReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.phone = userDataReq.data.phoneNo;
      this.updateKey++;

      this.disabled = true;
      return false;
    }

    const applicationData = this.$store.getters['application/applicationData'];

    if (applicationData) {
      const phoneNo = applicationData.phoneNo;
      this.updateKey++;

      if (phoneNo) {
        this.phone = phoneNo;
        this.$emit('onInput', 'phoneNo', phoneNo, 'valid');
      }
    }
  },

  methods: {
    // On input - update value, make a validation
    // on blur - show an error but only if the input touched

    onFocus() {
      this.inFocus = true;
      this.showError = false;
    },
    onBlur() {
      this.inFocus = false;

      if (this.validation.status == 'invalid') {
        this.showError = true;
      }

      // logFirebaseEvent
      if (this.phone?.length >= 2) {
        logFirebaseEvent('_phoneNo', { method: 'blur' });
      }
    },

    updateInputNew(number, phoneObject) {
      if (phoneObject) {
        this.number = number;

        const phoneNumber = phoneObject?.number;
        const valid = phoneObject.valid;

        // If phone number is not valid
        if (!valid) {
          this.validation = {
            status: 'invalid',
            errorMessage: '*Please enter a valid phone number',
          };

          if (phoneNumber != undefined) {
            this.$store.dispatch('application/updateApplicationStore', {
              phoneNo: phoneNumber,
            });
          }

          // Emit event to Personal Details form
          this.$emit('onInput', 'phoneNo', phoneNumber, 'invalid');
          return false;
        }

        // If phone nunber is valid
        this.showError = false;

        this.validation = {
          status: 'valid',
          errorMessage: '',
        };

        // Emit event to Personal Details form

        this.$store.dispatch('application/updateApplicationStore', {
          phoneNo: phoneNumber,
        });
        this.$emit('onInput', 'phoneNo', number, 'valid');
      }
    },

    updateInput(number, phoneObject) {
      this.phone = number;
      if (!phoneObject) {
        return false;
      }
      // const applicationData =
      //   this.$store.getters['application/applicationData'];

      // const phoneNoObj = applicationData?.phoneNoObj;
      // console.log(phoneNoObj);
      // if (!number && phoneNoObj?.countryCode) {
      //   return false;
      // }
      // this.$store.dispatch('application/updateApplicationStore', {
      //   phoneNoObj: phoneObject,
      // });

      // if (phoneObject?.country?.dialCode) {
      //   this.countryCode = phoneObject.country.dialCode;

      //   if (this.phone?.startsWith(`+${this.countryCode}`)) {
      //     this.phone = this.phone.replace(`+${this.countryCode}`, '');
      //   }
      // }

      // Remove country code from input if it is the same as in placeholder
      // console.log('CHECK');
      // console.log(this.phone);
      // console.log(this.countryCode);

      // Get the value and validation status from phoneObject
      const phoneNumber = number;

      const valid = phoneObject.valid;

      // If phone number is not valid
      if (!valid) {
        this.validation = {
          status: 'invalid',
          errorMessage: '*Please enter a valid phone number',
        };

        if (phoneNumber != undefined) {
          this.$store.dispatch('application/updateApplicationStore', {
            phoneNo: phoneNumber,
          });
        }

        // Emit event to Personal Details form
        this.$emit('onInput', 'phoneNo', phoneNumber, 'invalid');
        return false;
      }

      // If phone nunber is valid
      this.showError = false;

      this.validation = {
        status: 'valid',
        errorMessage: '',
      };

      // Emit event to Personal Details form

      this.$store.dispatch('application/updateApplicationStore', {
        phoneNo: phoneNumber,
      });
      this.$emit('onInput', 'phoneNo', number, 'valid');
    },

    setMatchedDivWidth() {
      const inputWrap = document.querySelector('.input-wrap');
      const matchedDiv = document.querySelector('.vti__dropdown-list');
      if (inputWrap && matchedDiv) {
        const inputWrapWidth = inputWrap.getBoundingClientRect().width;

        matchedDiv.style.width = `${inputWrapWidth}px`;
      }
    },
  },
};
</script>

<style lang="scss">
.vue-tel-input {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid #d5e7e2 !important;
  background: transparent !important;
  &:focus-within {
    border-color: #242424 !important;
    box-shadow: none !important;
  }

  &.hasErrors {
    border: 1px solid $critical !important;

    .vti__dropdown {
      input {
        color: $critical !important ;
      }
    }
  }

  &.disabled {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $middleGrey;
    background-color: $lightestGrey !important;
    margin-left: 0 !important;
  }
}

.vue-tel-input.disabled input {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: $middleGrey;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.vti__dropdown {
  background-color: $lightestGrey;
  border-radius: 8px 0 0 8px !important;
  outline: none !important;

  .vti__selection {
    padding: 12px 9px;
    margin: 0;
  }
}
.vti__dropdown-list.above {
  max-width: 600px;
  min-width: 300px;
}

.vti__input {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #242424;
  border-radius: 8px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 58px white inset !important;
}
.vti__dropdown-list {
  margin-top: 4px;
  max-width: 335px;
  min-height: 200px;
  top: 57px !important;
  padding: 0 !important;
  border-radius: 8px;
  border: 1px solid $lightestGrey;
  .vti__dropdown-item {
    padding: 12px 16px;
    border-bottom: 1px solid $lightestGrey;
  }
}

.phone-input_wrap {
  position: relative;
}

.phone-input_country-code {
  position: absolute;
  top: 20px;
  left: 88px;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: $middleGrey;
}

.vti__dropdown-list {
  max-width: $max-content-width;
}
</style>
