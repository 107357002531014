<template>
  <div class="button-container-wrap">
    <div class="scroll-button-container">
      <ScrollDocButton @scrollPositionChanged="handleScrollPositionChanged" />
    </div>
    <div class="button-container">
      <div class="button-container-content">
        <base-button-primary
          :disabled="!isAtBottom"
          class="m-t-4"
          @clicked="updateModule"
        >
          Continue
        </base-button-primary>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import ScrollDocButton from '../buttons/ScrollDocButton.vue';

export default {
  components: { BaseButtonPrimary, ScrollDocButton, BaseButtonPrimary },
  data() {
    return {
      inputName: '',
      inFocus: false,
      disabled: false,
      isAtBottom: null,
    };
  },

  methods: {
    handleScrollPositionChanged(isAtBottom) {
      this.isAtBottom = isAtBottom;
    },
    updateModule() {
      this.$emit('updateModule');
    },
  },
};
</script>

<style scoped lang="scss">
.button-container-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  background: linear-gradient(to bottom, transparent 0%, white 10%);
  left: 0;

  @media (min-width: 1024px) {
    left: 50%;
    width: 50%;
  }
}

.button-container {
  border-radius: 24px 24px 0px 0px;
  background-color: white;
}

.scroll-button-container {
  padding-bottom: 42px;
  padding-top: 36px;
  margin-bottom: -20px;
  background: linear-gradient(to top, rgba(110, 120, 119, 0.16), transparent);
}

.button-container-content {
  max-width: $max-content-width;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 32px;
  padding-top: 32px;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
