<template>
  <CurrentAddressManual
    v-if="activeScreen == 'changeAddressScreen'"
    key="changeAddressScreen"
    @updateModule="updateModule"
  />
  <AddressConfirmationForm
    v-else-if="activeScreen == 'addressConfirmationScreen'"
    key="addressConfirmationScreen"
    :currentAddress="currentAddress"
    @sendForm="sendForm"
  />
</template>

<script>
import AddressConfirmationForm from '../screens/addressConfirmation/AddressConfirmationForm.vue';
import CurrentAddressManual from '../screens/applicationData/CurrentAddressManual.vue';
import { LocalStorage } from '../../utils/localStorage';

export default {
  name: 'AddressConfirmation',
  components: {
    AddressConfirmationForm,
    CurrentAddressManual,
  },
  data() {
    return {
      isLoading: true,
      userData: null,
      currentAddress: null,
    };
  },
  computed: {
    activeScreen() {
      const activeScreen = this.$store.getters['navigation/activeScreen'];
      if (!activeScreen) {
        return 'addressConfirmationScreen';
      } else {
        return activeScreen;
      }
    },
    animationMode() {
      return this.$store.getters['navigation/animationMode'];
    },
  },

  async created() {
    let applicationData = this.$store.getters['application/applicationData'];

    if (!applicationData) {
      const applicationId = LocalStorage.get('applicationId');

      applicationData = await this.$store.dispatch(
        'application/getApplicationRecord',
        { applicationId }
      );

      if (!applicationData.status) {
        this.$router.push('/error');
        return false;
      }
      
    }

    this.currentAddress = applicationData.address?.current;
    this.$store.dispatch('navigation/stopLoading');

    this.isLoading = false;
  },

  methods: {
    async sendForm(data) {
      this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
      this.$store.dispatch('navigation/startLoading');

      const updateApplicationReq = await this.$store.dispatch(
        'application/updateApplication',
        { data, step: 'addressConfirmation' }
      );

      if (!updateApplicationReq.status) {
        this.$router.push('/error');
        return false;
      }

      this.$store.dispatch('navigation/modulePassed', {
        event: 'rr_address_confirmation_passed',
        page_title: 'addressConfirmation',
      });
      this.$store.dispatch('navigation/requestFinish');
    },

    async updateModule(id, payload) {
      if (id == 'currentAddressManual' && payload) {
        this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
        this.$store.dispatch('navigation/startLoading');

        let currentAddress = `${this.currentAddress?.buildingNo}${this.currentAddress?.subBuilding}${this.currentAddress?.buildingName}${this.currentAddress?.postCode}`;
        currentAddress = currentAddress.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

        let newAddress = `${payload?.buildingNo}${payload?.subBuilding}${payload?.buildingName}${payload?.postCode}`;
        newAddress = newAddress.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

        
        const isDuplicate = currentAddress == newAddress;

        if (!isDuplicate) {
          const data = {
          address: {
            current: payload,
            previous: this.currentAddress,
          },
        };


          const updateApplicationReq = await this.$store.dispatch(
            'application/updateApplication',
            { data }
          );
          if (!updateApplicationReq.status) {
            this.$router.push('/error');
            return false;
          }
          this.$store.dispatch('application/updateApplicationData', {
            address: data.address,
          });
          this.currentAddress = data.address.current;
        }

        this.$store.dispatch('navigation/stopLoading');
        this.$store.dispatch(
          'navigation/updateScreen',
          'addressConfirmationScreen'
        );
        
      }
    },
  },
};
</script>
