<template>
  <base-modal
    title=" "
    v-if="showExtendedContent && isDesktop"
    @closeModal="(showExtendedContent = false), (inputName = '')"
  >
    <p class="signature-desc">
      Please, type your full name
      <strong>({{ fullName }})</strong>
      to agree to the terms in the credit agreement.
    </p>
    <div class="input-container-content">
      <div key="input" class="signature-input">
        <input
          ref="inputRefDesktop"
          :value="inputName"
          class="input"
          :class="{
            filled: inFocus || inputName,
            disabled: disabled,
          }"
          @focus="onFocus"
          @blur="onBlur"
          @input="(event) => onInput(event.target.value)"
        />
        <span
          class="label"
          :class="{
            filled: inFocus || inputName,
          }"
        >
          Your Signature*
        </span>
      </div>
      <div class="input-container-content__extended">
        <ul>
          <li>
            By signing the agreement, you accept to enter into a legally binding
            contract with Pillar which will be governed by the terms and
            conditions of the agreement.
          </li>
          <li>
            Pillar will register this credit card on your credit file, which
            will be visible to you and other lenders.
          </li>
        </ul>
      </div>
      <base-button-primary
        :disabled="!inputIsValid"
        class="m-t-4"
        @clicked="updateModule"
      >
        Accept and Sign
      </base-button-primary>
    </div>
  </base-modal>
  <div
    class="signature-input-wrap"
    :class="{
      hidden: showExtendedContent && isDesktop,
    }"
  >
    <div
      class="modal-overlay"
      :class="{ 'show-overlay': showExtendedContent }"
      @click="closeDialog"
    ></div>

    <div class="scroll-button-container" v-if="!showExtendedContent">
      <ScrollDocButton @scrollPositionChanged="handleScrollPositionChanged" />
    </div>
    <div class="signature-input-container">
      <div class="signature-input-container-wrap">
        <div
          class="input-container"
          :class="{
            extended: showExtendedContent,
          }"
        >
          <div class="input-container-header" v-if="showExtendedContent">
            <div class="close-button-wrap" @click="closeDialog">
              <BaseIcon icon="close" :isButton="true" size="lg" />
            </div>
          </div>
          <p class="signature-desc">
            Please, type your full name
            <strong>({{ fullName }})</strong>
            to agree to the terms in the credit agreement.
          </p>
          <div class="input-container-content">
            <div key="input" class="signature-input">
              <input
                ref="inputRef"
                :value="inputName"
                class="input"
                :class="{
                  filled: inFocus || inputName,
                  disabled: disabled,
                }"
                @focus="onFocus"
                @blur="onBlur"
                @input="(event) => onInput(event.target.value)"
              />
              <span
                class="label"
                :class="{
                  filled: inFocus || inputName,
                }"
              >
                Your Signature*
              </span>
            </div>
          </div>
        </div>

        <div
          class="input-container-content__extended"
          v-if="showExtendedContent"
        >
          <ul>
            <li>
              By signing the agreement, you accept to enter into a legally
              binding contract with Pillar which will be governed by the terms
              and conditions of the agreement.
            </li>
            <li>
              Pillar will register this credit card on your credit file, which
              will be visible to you and other lenders.
            </li>
          </ul>
        </div>
        <base-button-primary
          :disabled="!inputIsValid"
          class="m-t-4"
          @clicked="updateModule"
        >
          Accept and Sign
        </base-button-primary>
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from '../../modal/BaseModal.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
import ScrollDocButton from '../../buttons/ScrollDocButton.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

export default {
  components: { BaseButtonPrimary, ScrollDocButton, BaseIcon, BaseModal },
  props: {
    fullName: {
      type: String,
      requited: true,
    },
  },
  data() {
    return {
      inputName: '',
      inFocus: false,
      disabled: false,
      isAtBottom: null,
      showedDialog: false,
      showExtendedContent: false,
      isDesktop: window.innerWidth > 1024,
    };
  },
  computed: {
    inputIsValid() {
      return this.inputName.length >= 2;
    },
  },

  created() {
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 1024;
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 1024;
    });
  },
  methods: {
    handleScrollPositionChanged(isAtBottom) {
      if (isAtBottom && !this.showedDialog) {
        this.showedDialog = true;

        setTimeout(() => {
          this.showExtendedContent = isAtBottom;

          if (isAtBottom) {
            this.onFocus();
          }
        }, 300);
      }

      if (!isAtBottom) {
        this.showedDialog = false;
      }
    },
    onFocus() {
      this.showExtendedContent = true;
      this.inFocus = true;
      this.$nextTick(() => {
        if (this.isDesktop) {
          this.$refs.inputRefDesktop.focus();
        } else {
          this.$refs.inputRef.focus();
        }
      });
    },
    onBlur() {
      this.inFocus = false;
    },
    onInput(value) {
      this.inputName = value;
      this.$emit('onInputUpdate', value);
    },
    closeDialog() {
      this.showExtendedContent = false;
      this.inFocus = false;
    },
    updateModule() {
      this.$emit('updateModule', this.inputName);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: -1;
}
.hidden {
  display: none;
}
.show-overlay {
  opacity: 1;
  pointer-events: auto;
}
.signature-input-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  background: linear-gradient(to bottom, transparent 0%, white 10%);
  left: 0;

  @media (min-width: 1024px) {
    left: 50%;
    width: 50%;
  }
}

.signature-input-container {
  border-radius: 24px 24px 0px 0px;
  background-color: white;
}

.scroll-button-container {
  padding-bottom: 42px;
  padding-top: 36px;
  margin-bottom: -20px;
  background: linear-gradient(to top, rgba(110, 120, 119, 0.16), transparent);
}

.input {
  width: 100%;
  border: 1px solid #d5e7e2;
  height: 58px;
  border-radius: 8px;
  outline: none;
  transition: 0.3s;
  padding: 19px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #242424;
  box-sizing: border-box;

  &.filled {
    border: 1px solid #242424;
    padding-bottom: 5px;
  }

  &:disabled {
    border-color: #242424;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 58px white inset !important;
}

.label {
  position: absolute;
  display: inline-block;
  pointer-events: none;
  left: 17px;
  top: 20px;
  transition: 0.2s ease all;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #b8ccc9;

  &.filled {
    top: 10px;
    font-size: 12px;
    line-height: 12px;
    color: #727776 !important;
  }
}

.signature-input {
  margin-top: 20px;
}

.input-container-content {
  position: relative;
  border-radius: 0 8px 8px 0;
}
.input-container {
  transition: max-height 2s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-height: 150px;
  overflow: hidden;
  z-index: 2000;
}

.input-container.extended {
  max-height: 500px;
}

.input-container-header {
  margin-top: -12px;
  display: flex;
  justify-content: flex-end;

  h4 {
    text-align: center !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.36px;
    margin-top: 16px;
  }
}

.input-container-content__extended {
  ul {
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 1024px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    li {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.12px;
      margin-bottom: 8px;
    }
    li::marker {
      color: $middleGrey;
    }
  }
}
.signature-input-container-wrap {
  max-width: $max-content-width;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 32px;
  padding-top: 32px;
  @media (min-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.signature-desc {
  color: #242424;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}
</style>
