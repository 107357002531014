<template>
    <div class="address-card">
        <div class="flex-row align-center">
            <div class="address-card_content">
                <div class="address-card_title m-b-1">
                    {{ addressType }}
                </div>
                {{ firstLine }}
                <br />
                {{ secondLine }}
                <br />
                {{ postcode }}
                <br />
                {{ country }}
            </div>

            <div
                class="address-card_icon"
                data-testid="edit-address-icon"
                @click="removeAddress"
                v-if="type == 'previous'"
            >
                <BaseIcon icon="bin" isButton />
            </div>
            <div
                class="address-card_icon"
                @click="editAddress"
                data-testid="remove-address-icon"
            >
                <BaseIcon icon="pen" isButton />
            </div>
        </div>
    </div>
</template>

<script>
import BaseIcon from '../../assets/BaseIcon.vue';
export default {
    name: 'AddressCard',
    components: { BaseIcon },
    props: {
        type: {
            type: String,
        },
        address: {
            type: Object,
            required: true,
        },
        customTitle: {
            type: String,
        }
    },

    computed: {
        firstLine() {
            if (this.address.country == 'India') {
                return `${this.address.indiaFlat}, ${this.address.indiaStreet}, ${this.address.indiaLocality}`;
            }
            const firstLine = `${
                this.address.subBuilding ? this.address.subBuilding + ' ' : ''
            }${
                this.address.buildingName ? this.address.buildingName + ' ' : ''
            }${this.address.buildingNo ? this.address.buildingNo + ' ' : ''}${
                this.address.streetName ? this.address.streetName : ''
            }`;
            return firstLine;
        },

        secondLine() {
            if (this.address.country == 'India') {
                return `${this.address.indiaCity}, ${this.address.indiaState}`;
            }

            const secondLine = `${this.address.city}`;

            return secondLine;
        },

        postcode() {
            if (this.address.country == 'India') {
                return `${this.address.indiaPinCode}`;
            } else {
                return `${this.address.postCode}`;
            }
        },
        country() {
            return this.address.country;
        },

        addressType() {
            if (this.customTitle) {
                return this.customTitle;
            }

            return this.type == 'current'
                ? 'Current Address'
                : 'Previous Address';
        },
        // formattedAddress() {
        //   const address = `${
        //     this.address.subBuilding ? this.address.subBuilding + ' ' : ''
        //   }${this.address.buildingName ? this.address.buildingName + ' ' : ''}${
        //     this.address.buildingNo ? this.address.buildingNo + ' ' : ''
        //   }${this.address.streetName ? this.address.streetName : ''}, ${
        //     this.address.city
        //   }, ${this.address.country}, ${this.address.postCode}`;
        //   return address;
        // },
    },
    methods: {
        editAddress() {
            this.$emit('editAddress', this.type);
        },

        removeAddress() {
            this.$emit('removeAddress', this.type);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';

.align-center {
    align-items: center;
}
.address-card_title {
    font-size: 14px !important;

    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: -0.02em !important;
    color: $darkestGrey !important;
    text-align: left;
}
.address-card {
    background-color: $lightestGrey;
    padding: 16px 20px;
    border-radius: 8px;
    align-items: center;
}

.address-card_content {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $darkGrey;
}

.address-card_icon {
    padding: 10px;
    margin-left: auto;
}
</style>
