<template>
  <div>
    <base-modal v-if="modalIsActive && isDesktop" noCloseButton>
      <div class="flex-row center truelayer-logo">
        <div class="text-link" @click="openLink">
          <BaseImage format="png" image="truelayer" width="180px" />
        </div>
      </div>
      <p class="truelayer-decsr m-t-3">
        <b>Truelayer</b>
        is an FCA regulated open banking provider. Truelayer work with
        companies like Pillar to make the most of open banking, which
        means they can use technology to connect with banks and access
        important financial data in real-time.
      </p>

      <p class="truelayer-decsr">
        Using Truelayer's APIs, Pillar can offer their customers an easy
        and safe way to link their bank accounts, see their financial
        information, and manage their money effectively.
      </p>
      <base-button-primary @clicked="modalIsActive = false">
        Okay. Got it
      </base-button-primary>
    </base-modal>
    <base-bottom-sheet v-if="modalIsActive && !isDesktop" noCloseButton>
      <div class="flex-row center truelayer-logo">
        <div class="text-link" @click="openLink">
          <BaseImage format="png" image="truelayer" width="180px" />
        </div>
      </div>
      <p class="truelayer-decsr m-t-3">
        <b>Truelayer</b>
        is an FCA regulated open banking provider. Truelayer work with
        companies like Pillar to make the most of open banking, which
        means they can use technology to connect with banks and access
        important financial data in real-time.
      </p>

      <p class="truelayer-decsr">
        Using Truelayer's APIs, Pillar can offer their customers an easy
        and safe way to link their bank accounts, see their financial
        information, and manage their money effectively.
      </p>
      <base-button-primary @clicked="modalIsActive = false">
        Okay. Got it
      </base-button-primary>
    </base-bottom-sheet>
    <div class="consent-intro_content">
      <div class="flex-row center">
        <div class="image-container">
          <BaseImage image="bank-connect" />
          <div
            class="bank-logo"
            :style="{
              transform:
                bankName == 'Halifax' || bankName == 'TSB'
                  ? 'scale(0.8)'
                  : bankName == 'AIB'
                    ? 'scale(0.7)'
                    : 'scale(0.92)',
            }"
          >
            <BaseImage :image="bankLogo" />
          </div>
        </div>
      </div>
      <h3 class="text-centered text-link m-t-3" @click="showModal = true">
        Connect your
        <br />
        {{ bankName }} account
      </h3>
      <p
        v-if="productName == 'rentReportingAndMonitor'"
        class="text-centered m-t-3 m-b-4 text-link"
        @click="modalIsActive = true"
      >
        For seamless rent payment reporting, please connect your bank
        account with our trusted partner,
        <span class="clickable_no-underline">TrueLayer.</span>
      </p>

      <p
        v-else
        class="text-centered m-t-3 m-b-4 text-link"
        @click="modalIsActive = true"
      >
        To securely verify your
        {{
          productName == 'pillarMonitor'
            ? 'identity'
            : 'income and expenditure'
        }}, please connect your {{ bankName }} account with our trusted
        partner,
        <span class="clickable_no-underline">Truelayer.</span>
      </p>
      <div
        v-if="productName == 'rentReportingAndMonitor'"
        class="flex-row consent-intro_pill-row"
      >
        <div
          v-for="(item, index) of rentPills"
          :key="index"
          class="consent-intro_pill-item flex-row"
        >
          <BaseIcon
            icon="check_light-grey"
            size="sm"
            class="consent-intro_pill-icon"
          />
          <div>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div v-else class="flex-row consent-intro_pill-row">
        <div
          v-for="(item, index) of pills"
          :key="index"
          class="consent-intro_pill-item flex-row"
        >
          <BaseIcon
            icon="check_light-grey"
            size="sm"
            class="consent-intro_pill-icon"
          />
          <div>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="consent-intro_info-block">
        <h2 class="header-2">
          Your data will be safe
        </h2>
        <div class="m-t-3">
          <div
            v-for="(item, index) of infoItems"
            :key="index"
            class="flex-row p-h-1 p-v-1 consent-intro_info-item"
          >
            <div class="consent-info_item-icon">
              <BaseIcon :icon="item.icon" size="28" />
            </div>
            <div>
              <p>
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isDesktop || termsUnderContent"
        class="consent-info_footer"
      >
        <p>
          For more information on how TrueLayer protect your data
          please see their
          <span @click="openLink('terms')">Terms of Service</span>
          and
          <span @click="openLink('policy')">Privacy Policy</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
import BaseImage from '../assets/BaseImage.vue';
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import BaseBottomSheet from '../modal/BaseBottomSheet.vue';
import BaseModal from '../modal/BaseModal.vue';

export default {
    name: 'TrueLayerIntro',
    components: {
        BaseModal,
        BaseImage,
        BaseIcon,
        BaseButtonPrimary,
        BaseBottomSheet,
    },
    props: {
        termsUnderContent: {
            type: Boolean,
            default: false,
        },
        selectedBank: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            pills: [
                'Account Number & Sort Code',
                'Full Name',
                'Transactions',
                'Balance',
            ],
            rentPills: [
                'Account Number & Sort Code',
                'Full Name',
                'Rent Transactions',
                'Balance',
            ],
            infoItems: [
                {
                    icon: 'consent-bank',
                    text: 'TrueLayer have the same high security standards as your bank',
                },
                {
                    icon: 'consent-hide-pin',
                    text: "We won't see or ask for your bank password or login information",
                },
                {
                    icon: 'consent-visibility',
                    text: 'We get read-only access to view your account details and transactions',
                },
                {
                    icon: 'consent-bank-cancel',
                    text: 'You can cancel access to sharing your bank account information at any time',
                },
            ],
            modalIsActive: false,
            isDesktop: null,
        };
    },
    computed: {
        bankName() {
            return this.selectedBank.display_name;
        },
        bankLogo() {
            return this.selectedBank.logo_url;
        },
        productName() {
            const productName = localStorage.getItem('productName');
            return productName;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.checkScreenWidth();
            window.addEventListener('resize', this.checkScreenWidth);
        });
    },
    beforeUnmount() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener('resize', this.checkScreenWidth);
    },
    methods: {
        openLink(type) {
            let link;

            switch (type) {
                case 'terms':
                    link = 'https://truelayer.com/legal/enduser_tos';
                    break;

                case 'policy':
                    link = 'https://truelayer.com/legal/privacy';
                    break;

                default:
                    link = 'https://truelayer.com';
                    break;
            }

            const webview = localStorage.getItem('webview') == 'true';

            if (webview) {
                window.OpenBrowser.postMessage(link);
            } else {
                window.open(link, '_blank');
            }
        },

        checkScreenWidth() {
            this.isDesktop = window.innerWidth >= 1024;
        },
    },
};
</script>

<style lang="scss" scoped>
.truelayer-decsr {
    margin-bottom: 24px;
}

.consent-intro_pill-row {
    flex-basis: 100%;
    align-content: center;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8px;
    row-gap: 8px;
}

.consent-intro_pill-icon {
    margin-right: 2px;
}
.consent-intro_pill-item {
    border: 2px solid #f5faf9;

    align-items: center;
    border-radius: 28px;
    width: auto;
    padding: 2px 10px;

    span {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: center;
        white-space: nowrap;
    }
}

.consent-intro_info-block {
    margin-top: 20px;
    margin-bottom: 12px;
    padding: 24px;
    background-color: rgba(122, 159, 248, 0.1);
    border-radius: 16px;
}

.consent-intro_info-item {
    padding-left: 10px;
    align-content: center;
    gap: 16px;
    margin-top: 12px;

    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0;
    }
}

.consent-info_item-icon {
    align-self: center;
}

.consent-info_footer {
    padding-top: 0px;

    p {
        font-size: 12px !important;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(78, 85, 84, 0.8);
        padding: 12px;
        text-align: center;
        margin-bottom: 0;
    }
    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        cursor: pointer;
    }
}

.image-container {
    width: 120px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
}

.bank-logo {
    width: 51px;
    height: 40px;

    right: 12px;
    top: 44px;
    position: absolute;
    display: flex;
    align-items: center;
}
</style>
