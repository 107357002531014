<template>
  <div class="error-text p-h-4 p-v-2">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.error-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: left;
  color: $critical;
}
</style>
