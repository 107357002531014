<template>
  <button
    type="button"
    class="base-button-grey"
    :disabled="disabled"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButtonGrey',
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button-grey {
  border-radius: 8px;
  background-color: #f7fafa;
  color: #242424;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
  padding: 12px 16px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $lightestGrey;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
