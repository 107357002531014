<template>
  <ErrorOb v-if="errorCode" errorCode="de_no_transactions" />
  <MoreAccountsNeeded
    v-else-if="!isLoading && moreAccountsNeeded"
    :banksList="listItems"
    @submitChoice="submitChoice"
  />
</template>

<script>
import MoreAccountsNeeded from '../screens/obSummaryBlock/MoreAccountsNeeded.vue';
import {
  trueLayerObSummaryDecision,
  trueLayerObSummarySubmitChoice,
} from '../../controllers/decision-engine.js';
import { getBankAccounts } from '../../controllers/truelayer';
import ErrorOb from '../screens/error/ErrorOB.vue';

export default {
  name: 'TrueLayerOBSummaryBlock',
  components: {
    MoreAccountsNeeded,
    ErrorOb,
  },
  data() {
    return {
      isLoading: true,
      moreAccountsNeeded: false,
      listItems: [],
      errorCode: null,
    };
  },

  async created() {
    const userId = this.$store.getters['auth/userId'];
    const applicationId = localStorage.getItem('applicationId');

    if (!userId || !applicationId) {
      this.$router.push('/error');
      return false;
    }

    const decisionReq = await trueLayerObSummaryDecision({
      applicationId,
      userId,
    });

    if (!decisionReq.status) {
      this.$router.push('/error');
      return false;
    }

    const data = decisionReq.data;

    if (data.options?.moreAccountsNeeded) {
      if (
        data.options.errorCodes.length > 0 &&
        data.options.errorCodes.includes('InsufficientData_NoTransactions')
      ) {
        this.errorCode = 'de_no_transactions';
        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
        return true;
      }

      const bankAccountsReq = await getBankAccounts({ userId });

      if (!bankAccountsReq.status) {
        this.$router.push('/error');
        return false;
      }

      const bankAccounts = bankAccountsReq.data;
      const accountsList = [];

      if (bankAccounts.length > 0) {
        bankAccounts.map((el) => {
          let name = el.provider.display_name;
          if (el.account_type == 'SAVINGS') {
            name = `${name} SAVINGS`;
          }

          const accountListItem = {
            text: name,
            avatarImage: el.provider.logo_uri,
            textDescription: el?.account_number?.number
              ? `****${el.account_number.number.slice(-4)}`
              : null,
          };

          return accountsList.push(accountListItem);
        });
      }

      this.listItems = accountsList;
      this.moreAccountsNeeded = true;
      this.$store.dispatch('navigation/stopLoading');
      this.isLoading = false;
    } else {
      // If accountShouldBeAdded is false, we just set the step to passed and go to next step
      // module is passed successfully
      this.$store.dispatch('navigation/modulePassed', {
        event: 'open_banking_summary_block_run',
        page_title: 'openBankingSummaryBlock',
      });
    }
  },

  methods: {
    async submitChoice(choice) {
      //Call the obSummaryBlockSubmitChoice endpoint that contains the logic for making changes to application based on user choice

      const applicationId = localStorage.getItem('applicationId');
      const userId = this.$store.getters['auth/userId'];
      const selectedChoice = choice;

      const data = { userId, applicationId, selectedChoice };

      const res = await trueLayerObSummarySubmitChoice(data);
      //If request was not successful, redirect to an error screen
      if (!res.status) {
        this.$router.push('/error');
        return false;
      }

      // module is passed successfully
      this.$store.dispatch('navigation/modulePassed', {
        event: 'open_banking_summary_block_run',
        page_title: 'openBankingSummaryBlock',
      });
    },
  },
};
</script>
