<template>
  <base-screen>
    <template #main>
      <base-title> {{ getTitle }}</base-title>
      <AddressCard
        v-bind="$attrs"
        key="currentAddress"
        :address="currentAddress"
        type="current"
      />
      <AddressCard
        v-if="previousAddress"
        v-bind="$attrs"
        key="previousAddress"
        class="m-t-2"
        :address="previousAddress"
        type="previous"
      />
    </template>

    <template #footer>
      <base-button-primary @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import AddressCard from '../../applicationData/address/AddressCard.vue';
import BaseTitle from '../../layout/BaseTitle.vue';

export default {
  name: 'AddressSummary',
  components: {
    BaseTitle,
    AddressCard,
    BaseScreen,
    BaseButtonPrimary,
  },
  props: {
    currentAddress: {
      type: Object,
      required: true,
    },
    previousAddress: {
      type: Object,
    },
  },

  data() {
    return {
      previousAddressUpdated: null,
    };
  },
  computed: {
    getTitle() {
      return this.previousAddress
        ? 'Confirm your Addresses'
        : 'Confirm your Address';
    },
  },
  methods: {
    sendValue() {
      this.$emit('submitApplication');
    },
  },
};
</script>
