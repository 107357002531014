<template>
  <base-screen fixedFooter docScreen>
    <template #main>
      <base-title class="m-b-3" noMargin>
        Your CRIF credit file
      </base-title>
      <p class="">
        We have retrieved your Indian credit file from CRIF India. Check
        out your credit file below.
      </p>
      <div class="accordion_wrap">
        <div
          v-for="(item, index) in listItems"
          :key="index + item.title"
        >
          <div class="accordion-item">
            <div class="accordion-title" @click="toggleItem(index)">
              <div class="title-row">
                <BaseIcon :icon="item.icon" size="38" />
                <h5 class="title_header">
                  {{ item.title }}
                </h5>
                <div class="title-icon">
                  <BaseIcon
                    :icon="
                      index == activeIndex
                        ? 'chevron-up_grey'
                        : 'chevron-down_grey'
                    "
                    size="28"
                  />
                </div>
              </div>
            </div>
            <transition name="accordion">
              <div
                v-show="activeIndex === index"
                :ref="`content-${index}`"
                class="accordion-content"
              >
                <div class="accordion-content_item">
                  <div
                    v-for="(
                      contentItem, contentIndex
                    ) in item.content"
                    :key="contentIndex"
                  >
                    <div>
                      <div
                        v-if="contentItem.title"
                        class="accordion-content-item_title m-b-2 m-t-3"
                      >
                        <h2 class="header-2">
                          {{ contentItem.title }}
                        </h2>
                      </div>
                      <div
                        class="accordion-content-item_row"
                      >
                        <div>
                          <span
                            class="accordion-content-item_subtitle"
                          >
                            {{
                              contentItem.item &&
                                contentItem.item
                                  .name
                            }}:
                          </span>
                        </div>
                        <div
                          class="accordion-content-item_value"
                        >
                          {{
                            contentItem.item &&
                              contentItem.item.value
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
export default {
    components: { BaseIcon, BaseScreen, BaseTitle, BaseButtonPrimary },
    data() {
        return {
            activeIndex: null,
            listItems: [
                {
                    title: 'Personal',
                    icon: 'profile',
                    content: [
                        {
                            item: {
                                name: 'First Name',
                                value: 'ASHUTOSH',
                            },
                        },
                        {
                            item: {
                                name: 'Last Name',
                                value: 'BHATT',
                            },
                        },
                        {
                            item: {
                                name: 'Father Name',
                                value: 'ANIL',
                            },
                        },
                        {
                            item: {
                                name: 'Date of Birth',
                                value: 'April 2th, 1982',
                            },
                        },
                        {
                            item: {
                                name: 'Phone Number',
                                value: '9818882078',
                            },
                        },
                        {
                            item: {
                                name: 'Email',
                                value: 'ashutoshbhatt@gmail.com',
                            },
                        },
                        {
                            item: {
                                name: 'PAN',
                                value: 'AKHPB4697H',
                            },
                        },
                        {
                            title: 'Address',
                            item: {
                                name: 'Flat/ApartmentNumber',
                                value: 'A-24',
                            },
                        },
                        {
                            item: {
                                name: 'StreetAddress',
                                value: 'INDRAPRASTHA APPTS',
                            },
                        },
                        {
                            item: {
                                name: 'Village/Locality/Area',
                                value: 'I.P.EXTENSION',
                            },
                        },
                        {
                            item: {
                                name: 'City/Town',
                                value: 'NEW DELHI',
                            },
                        },
                        {
                            item: {
                                name: 'State',
                                value: 'DELHI',
                            },
                        },
                        {
                            item: {
                                name: 'PINCode',
                                value: '110092',
                            },
                        },
                    ],
                },
                {
                    title: 'Credit Score',
                    icon: 'score_grey',
                    content: [
                        {
                            item: {
                                name: 'Credit Score',
                                value: '742',
                            },
                        },
                    ],
                },
                {
                    title: 'Tradelines',
                    icon: 'utilization',
                    content: [
                        {
                            title: 'Auxilo',
                            item: {
                                name: 'Open',
                                value: 'No',
                            },
                        },
                        {
                            item: {
                                name: 'Date Opened',
                                value: 'October 9th, 2016',
                            },
                        },
                        {
                            title: 'Bajaj Finserv',
                            item: {
                                name: 'Open',
                                value: 'Yes',
                            },
                        },
                        {
                            item: {
                                name: 'Date Opened',
                                value: 'January 4th, 2019',
                            },
                        },
                        {
                            title: 'ICICI Incred',
                            item: {
                                name: 'Open',
                                value: 'No',
                            },
                        },
                        {
                            item: {
                                name: 'Date Opened',
                                value: 'May 2nd, 2022',
                            },
                        },
                    ],
                },
                {
                    title: 'Inquiries',
                    icon: 'docs_grey',
                    content: [
                        {
                            title: 'American Express',
                            item: {
                                name: 'Inquiry Date',
                                value: 'October 9th, 2016',
                            },
                        },
                        {
                            title: 'KreditBee',
                            item: {
                                name: 'Inquiry Date',
                                value: 'April 23rd, 2021',
                            },
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        toggleItem(index) {
            this.$nextTick(() => {
                // Close previously active content smoothly before opening the new one
                if (this.activeIndex !== null && this.activeIndex !== index) {
                    const prevContent =
                        this.$refs[`content-${this.activeIndex}`][0];
                    if (prevContent) {
                        prevContent.style.transition =
                            'max-height 0.15s ease-out';
                        prevContent.style.maxHeight = '0';
                    }
                }

                const content = this.$refs[`content-${index}`][0];
                if (!content) {
                    console.error(`Ref content-${index} is not defined.`);
                    return;
                }

                if (this.activeIndex === index) {
                    // Closing the current accordion item
                    content.style.transition = 'max-height 0.05s ease-out';
                    content.style.maxHeight = '0';
                    this.activeIndex = null;
                } else {
                    // Opening the new accordion item
                    this.activeIndex = index;
                    // Apply a small delay to ensure the transition starts smoothly
                    requestAnimationFrame(() => {
                        // Adjust 'max-height' based on the content's natural height
                        content.style.transition = 'max-height 0.15s ease-in';
                        content.style.maxHeight = `${content.scrollHeight}px`;
                    });
                }
            });
        },

        sendValue() {
            this.$emit('updateForm', 'creditHistory');
        },
    },
};
</script>

<style lang="scss" scoped>
.accordion-item {
    border: 1px solid #f6f8f8;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 16px;
}

.accordion-title {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-row {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.title-icon {
    margin-left: auto;
}

.accordion-content-item_row {
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.01em;
    text-align: left;
    align-items: center;
}

.accordion-content-item_title {
    width: 100%;
    display: flex;

    span {
        font-weight: 600;
        font-size: 16px;
    }
}

.title_header {
    font-size: 16px !important;
    font-weight: 600 !important;
}
.accordion-enter-active,
.accordion-leave-active {
    transition: max-height 0.5s ease;
}

.accordion-enter,
.accordion-leave-to {
    max-height: 0;
}

.accordion_wrap {
    padding-bottom: 80px;
}

.accordion-content-item_title {
    h5 {
        font-size: 14px !important;
        font-weight: 600;
    }
}

.accordion-content-item_subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.accordion-content-item_value {
    font-size: 14px;
    font-weight: 600;
}

.accordion-enter-active,
.accordion-leave-active {
    transition: all 0.5s ease;
}

.accordion-enter,
.accordion-leave-to {
    opacity: 0;
}

.active.accordion-content {
    max-height: 200px;
    opacity: 1;
}

.accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
    padding-top: 20px;
    padding-left: 20px;
}
</style>
