<template>
  <ErrorOb v-if="errorCode" errorCode="de_no_transactions" />
  <MoreAccountsNeeded
    v-if="moreAccountsNeeded && !isLoading"
    :banksList="listItems"
    @submitChoice="submitChoice"
  />
</template>

<script>
import MoreAccountsNeeded from '../screens/obSummaryBlock/MoreAccountsNeeded.vue';
import {
  runprocessingApplication,
  trueLayerObSummarySubmitChoice,
} from '../../controllers/decision-engine.js';
import { getBankAccounts } from '../../controllers/truelayer';
import ErrorOb from '../screens/error/ErrorOB.vue';

export default {
  name: 'ProcessingApplication',
  components: { ErrorOb, MoreAccountsNeeded },

  data() {
    return {
      isLoading: true,
      moreAccountsNeeded: false,
      listItems: [],
      errorCode: null,
    };
  },
  async created() {
    this.$store.dispatch('navigation/updateLoaderAnimationType', 'de');

    const applicationId = localStorage.getItem('applicationId');
    const userId = this.$store.getters['auth/userId'];

    const payload = {
      applicationId,
      userId,
    };

    const response = await runprocessingApplication(payload);

    if (!response.status) {
      this.$router.push('/error');
      return false;
    }

    const data = response.data;

    if (data.options?.moreAccountsNeeded) {
      if (
        data.options.errorCodes.length > 0 &&
        data.options.errorCodes.includes('InsufficientData_NoTransactions')
      ) {
        this.errorCode = 'de_no_transactions';
        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
        return true;
      }

      const bankAccountsReq = await getBankAccounts({ userId });

      if (!bankAccountsReq.status) {
        this.$router.push('/error');
        return false;
      }

      const bankAccounts = bankAccountsReq.data;
      const accountsList = [];

      bankAccounts.map((el) => {
        let name = el.provider.display_name;
        if (el.account_type == 'SAVINGS') {
          name = `${name} SAVINGS`;
        }

        const accountListItem = {
          text: name,
          avatarImage: el.provider.logo_uri,
          textDescription: el?.account_number?.number
            ? `****${el.account_number.number.slice(-4)}`
            : null,
          icon: 'check_green',
          folder: 'banks',
        };

        return accountsList.push(accountListItem);
      });

      this.listItems = accountsList;
      this.moreAccountsNeeded = true;
      this.isLoading = false;
      this.$store.dispatch('navigation/stopLoading');
      return false;
    }

    this.$store.dispatch('navigation/modulePassed', {
      event: null,
      page_title: 'processingApplication',
    });
  },

  methods: {
    async submitChoice(choice) {
      this.$store.dispatch('navigation/startLoading');
      //Call the obSummaryBlockSubmitChoice endpoint that contains the logic for making changes to application based on user choice

      const applicationId = localStorage.getItem('applicationId');
      const userId = this.$store.getters['auth/userId'];
      const selectedChoice = choice;

      const data = { userId, applicationId, selectedChoice };

      const res = await trueLayerObSummarySubmitChoice(data);
      //If request was not successful, redirect to an error screen
      if (!res.status) {
        this.$router.push('/error');
        return false;
      }

      this.$router.go();

    },
  },
};
</script>
