import * as Sentry from '@sentry/browser';



export const LocalStorage = {
    get(item) {
        return localStorage.getItem(item)
    },
    set(item) {
        // logLocalStorage();

        if (Array.isArray(item)) {
            for (const obj of item) {
                for (const key in obj) {
                    const value = obj[key];
                    localStorage.setItem(key, value);
                }
            }
        } else {
            for (const key in item) {
                const value = item[key];
                localStorage.setItem(key, value);
            }
        }

        Sentry.addBreadcrumb({
            category: 'localStorage',
            data: {
                action: 'set',
                data: item
            },
            level: 'info'
        });
    },
    remove(item) {
        // logLocalStorage();

        if (Array.isArray(item)) {
            for (const key of item) {
                localStorage.removeItem(key);
            }
        } else {

            if (item == 'all') {
                localStorage.clear();
            }
            localStorage.removeItem(item);
        }

        Sentry.addBreadcrumb({
            category: 'localStorage',
            data: {
                action: 'remove',
                data: item
            },
            level: 'info'
        });
    }


};

// export const logLocalStorage = () => {
//     const applicationId = localStorage.getItem('applicationId');
//     const productName = localStorage.getItem('productName');
//     const uid = localStorage.getItem('uid');
//     const tokenExpiration = localStorage.getItem('tokenExpiration');
//     const verificationId = localStorage.getItem('verificationId');

//     console.log('****** LocalStorage: ', {
//         applicationId,
//         productName,
//         uid,
//         tokenExpiration,
//         verificationId
//     });
// };
