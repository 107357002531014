<script>
import { retrieveCreditFile } from '../../../controllers/pillarRails.js';

export default {
    name: 'ForeignCreditReportPullSuccess',
    async created() {
        this.$store.dispatch(
            'navigation/updateLoaderAnimationType',
            'indiaLoader'
        );
        const applicationId = localStorage.getItem('applicationId');
        const applicationData = await this.$store.dispatch(
            'application/getApplicationRecord',
            {
                applicationId,
            }
        );

        const countryCode = applicationData.data.countryCode;

        let country;
        if (countryCode === 'IN') {
            country = 'India';
        }

        this.$store.dispatch(
            'navigation/updateLoaderAnimationText',
            'Returning you to Pillar to complete your application'
        );
        this.$store.dispatch(
            'navigation/updateLoaderAnimationType',
            'indiaLoader'
        );

        const requestId = this.$route.query.requestId;
        await this.getCreditFile(requestId);
    },
    methods: {
        async getCreditFile(requestId) {
            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const req = await retrieveCreditFile({
                applicationId,
                userId,
                requestId,
            });

            if (!req.status) {
                this.$router.push('/error');
                return false;
            }

            setTimeout(() => {
                this.$store.dispatch('navigation/modulePassed', {
                    event: null,
                    page_title: 'foreignCreditReportPull',
                });
            }, 2000);
        },
    },
};
</script>
