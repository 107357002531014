<template>
  <base-screen v-if="!isLoading" contentCentered desktopNoBG>
    <template #main>
      <div class="flex-row center">
        <container-sized width="65%">
          <BaseImage :image="bgImg" />
        </container-sized>
      </div>
      <div class="m-t-7">
        <h1 class="header-1 text-centered">
          {{ title }}
        </h1>
        <div class="m-t-6 m-b-4">
          <p class="text-centered grey">
            {{ message }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary @clicked="refresh">Try again</base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
// import ErrorKBA from './ErrorKBA.vue';

export default {
  name: 'ErrorScreen',
  components: {
    BaseScreen,
    ContainerSized,
    BaseImage,
    BaseButtonPrimary,
  },
  data() {
    return {
      bgImg: null,
      isLoading: true,
    };
  },

  computed: {
    message() {
      return (
        this.$store.getters['error/errorMessage'] ??
        'Please try again later or contact support.'
      );
    },

    errorType() {
      return this.$store.getters['error/errorType'] ?? null;
    },

    errorDetails() {
      return this.$store.getters['error/errorDetails'] ?? null;
    },

    title() {
      return this.$store.getters['error/errorTitle'] ?? 'Something went wrong';
    },
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
    const errorType = this.$store.getters['error/errorType'] ?? null;

    switch (errorType) {
      case 'navigationError':
        this.bgImg = 'error-illustration';
        break;

      default:
        this.bgImg = 'error-illustration';
        break;
    }

    this.$store.dispatch('navigation/stopLoading');
    this.isLoading = false;
  },

  methods: {
    refresh() {
      this.$store.dispatch('navigation/startLoading');
      this.$router.replace('/onboard');
    },
  },
};
</script>
