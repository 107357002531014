<template>
  <base-screen>
    <template #main>
      <base-title>Please, confirm your Direct Debt details</base-title>
      <div class="card">
        <div class="header-row">
          <h2 class="header-2">Bank account details</h2>
        </div>
        <div class="flex-row row_account-name">
          <div class="logo">
            <BaseImage
              :image="selectedBankAccount.provider.provider_id"
              folder="tl-banks"
            />
          </div>
          <div class="details">
            <h4 class="header-4">Account Name</h4>
            <p class="grey bold m-t-1">
              {{ selectedBankAccount.accountHolder }}
            </p>
          </div>
        </div>

        <div class="flex-row row_account-details">
          <div class="details">
            <h4 class="header-4">Account Number</h4>
            <p class="grey small m-t-1">
              {{ selectedBankAccount.account_number.number }}
            </p>
          </div>
          <div class="details">
            <h4 class="header-4">Sort Code</h4>
            <p class="grey small m-t-1">
              {{ selectedBankAccount.account_number.sort_code }}
            </p>
          </div>
        </div>
      </div>

      <div class="card m-t-4">
        <div class="header-row">
          <h2 class="header-2">Payment Settings</h2>
        </div>
        <div class="flex-row">
          <div class="details">
            <h4 class="header-4">Monthly Repayment Amount</h4>
            <p class="grey small m-t-1">
              {{ selectedRepaymentOption.title }}
            </p>
          </div>
        </div>

        <div class="flex-row m-t-4">
          <div class="details">
            <h4 class="header-4">Date and Frequency</h4>
            <p class="grey small m-t-1">
              {{ selectedInvoiceDayOfMonth.label}}
            </p>
          </div>
        </div>
      </div>

      <div class="m-t-4">
        <div class="card card_info">
          <div class="card-title">
            <BaseIcon icon="info_blue" />
            <h2 class="header-2">Important to know</h2>
          </div>
          <div class="flex-row row_info">
            <div class="dot"></div>
            <p>
              Payments collected under this Direct Debit will appear as Pillar
              on your bank statement.
            </p>
          </div>
          <div class="flex-row row_info">
            <div class="dot"></div>
            <p>
              You may cancel this Direct Debit at any time by contacting Pillar
              support, or your bank.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary @clicked="sendValue">
        Confirm Direct Debit
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseIcon from '../../assets/BaseIcon.vue';
import BaseImage from '../../assets/BaseImage.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
export default {
  name: 'DirectDebitConfirmation',
  components: { BaseTitle, BaseScreen, BaseButtonPrimary, BaseImage, BaseIcon },
  props: {
    selectedBankAccount: {
      type: Object,
      required: true,
    },
    selectedRepaymentOption: {
      type: Object,
      required: true,
    },
    selectedInvoiceDayOfMonth: {
      type: Object,
      required: true,
    },
  },

  methods: {
    sendValue() {
      this.$emit('updateForm', 'directDebitConfirmed');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 16px 20px 20px 20px;
  border-radius: 12px;
  border: 1px solid $lightGrey;

  .header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .row_account-name {
    gap: 16px;
    align-items: center;

    .logo {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #f7fafa;
      border-radius: 16px;
      padding: 2px;
    }
  }

  .row_account-details {
    gap: 8px;
    margin-top: 24px;
  }
}

.row_info {
  gap: 16px;
  margin-left: 8px;
  align-items: baseline;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dot {
  min-width: 4px;
  height: 4px !important;
  width: 4px !important;
  background-color: $middleGrey;
  border-radius: 50%;
}
</style>
