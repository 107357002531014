<template>
    <div class="flex-column">
        <div key="input" class="input-wrap">
            <div v-if="prependSymbol" class="currency-symbol">
                {{ prependSymbol }}
            </div>
            <input
                :id="item.id"
                key="item.id"
                :ref="inputRef || 'baseInput'"
                autocomplete="off"
                class="input"
                :class="{
                    filled: inFocus || value,
                    hasErrors: showError,
                    noLabel: !item.label,
                    prependSymbol: prependSymbol,
                    disabled: disabled,
                }"
                :pattern="item.type == 'number' ? '\\d*' : null"
                :type="item.type"
                :data-cy="item.id"
                required
                :value="value"
                :disabled="disabled"
                @focus="onFocus"
                @blur="onBlur"
                @input="(event) => onInput(event.target.value)"
            />

            <span
                v-if="item.label"
                class="label"
                :class="{
                    filled: inFocus || value,
                    hasErrors: showError,
                }"
            >
                {{ item.label }}
            </span>
        </div>
        <base-error-message v-if="!noErrorMessage && showError" key="error">
            {{ validation.errorMessage }}
        </base-error-message>
    </div>
</template>

<script>
import { logFirebaseEvent } from '../../controllers/analytics/firebase';
import BaseErrorMessage from './BaseErrorMessage.vue';

export default {
    name: 'BaseInput',
    components: { BaseErrorMessage },
    props: {
        item: {
            type: Object,
            required: true,
        },
        noErrorMessage: {
            type: Boolean,
            default: false,
        },
        shouldAutofocus: {
            type: Boolean,
            default: false,
        },
        inputRef: {
            type: String,
        },
        noPrefill: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        prependSymbol: {
            type: String,
            required: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            value: '',
            inFocus: false,
            validation: {
                status: 'pending',
                errorMessage: '',
            },
            showError: false,
        };
    },
    watch: {
        min() {
            this.validationCheck();
        },
    },
    created() {
        if (!this.noPrefill) {
            const applicationData =
                this.$store.getters['application/applicationData'];

            if (applicationData) {
                const value = applicationData[this.item.id];
                if (value) {
                    this.value = value;
                    this.$emit('onInput', this.item.id, this.value, 'valid');
                }
            }
        }
    },
    mounted() {
        if (this.autofocus) {
            setTimeout(() => {
                this.$refs.baseInput.focus();
            }, 300);
        }
    },

    methods: {
        onFocus() {
            this.inFocus = true;
            this.$emit('focused', this.inputRef);
        },
        onBlur() {
            this.inFocus = false;

            if (this.validation.status == 'invalid') {
                this.showError = true;
            }

            // logFirebaseEvent
            if (this.value.length >= 2) {
                const snakeCaseField = this.item.id.replace(
                    /[A-Z]/g,
                    (letter) => `_${letter.toLowerCase()}`
                );
                logFirebaseEvent(`${snakeCaseField}`, { method: 'blur' });
            }

            if (
                (this.item.subtype == 'day' || this.item.subtype == 'month') &&
                this.value.length == 1
            ) {
                const formattedValue = `0${this.value}`;
                this.value = formattedValue;

                this.$store.dispatch('application/updateApplicationStore', {
                    [this.item.id]: formattedValue,
                });
                this.$emit('onInput', this.item.id, formattedValue, 'valid');
            }

            if (
                this.item.subtype == 'whole' &&
                this.value &&
                this.item.id == 'rentPaymentAmount'
            ) {
                const formattedValue = parseInt(this.value, 10);
                this.value = formattedValue;

                this.$store.dispatch('application/updateApplicationStore', {
                    [this.item.id]: formattedValue,
                });
                this.$emit('onInput', this.item.id, formattedValue, 'valid');
            }
        },

        async onInput(value) {
            if (
                this.item.subtype &&
                ['day', 'month', 'year'].includes(this.item.subtype)
            ) {
                if (this.item.subtype == 'year') {
                    value = value.slice(0, 4);
                } else {
                    value = value.slice(0, 2);
                }

                event.target.value = value;
                this.value = value;

                this.validationCheck();

                return false;
            }

            if (this.item.type == 'number') {
                this.value = parseInt(value.replace(/\D/g, ''));
                this.validationCheck();
            } else {
                const trimmedValue = value.trim();
                this.value = trimmedValue;

                // If validation is not required, just pass the value
                if (!this.item.validationRequired) {
                    this.$store.dispatch('application/updateApplicationStore', {
                        [this.item.id]: trimmedValue,
                    });
                    this.$emit('onInput', this.item.id, trimmedValue, 'valid');
                } else {
                    // If validation is required, check if the input is valid
                    this.validationCheck();
                }
            }
        },

        validationCheck() {
            let value = this.value;

            let message;

            switch (this.item.type) {
                case 'text':
                    if (value == '' || value == ' ') {
                        message = '*This field is required';
                    } else if (value.length < 2) {
                        message =
                            '*This field should have at least 2 characters';
                    }
                    break;

                //TODO: change the regex to validation library - validator.js?
                case 'email':
                    {
                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                        if (!regex.test(value)) {
                            message = '*Please enter a valid email';
                        }
                    }
                    break;

                case 'number': {
                    // If we pass subtype
                    if (this.item.subtype) {
                        switch (this.item.subtype) {
                            case 'whole':
                                if (!value || value == '' || value == ' ') {
                                    message = '*This field is required';
                                } else if (
                                    !value ||
                                    value <= 0 ||
                                    value.toString().length > 10 ||
                                    value > 10000000
                                ) {
                                    message = '*Please enter a valid number';
                                }

                                if (message) {
                                    this.showError = true;
                                }
                                break;

                            case 'day':
                                if (+value > 31 || +value < 1) {
                                    message = '*Invalid date';
                                } else if (value.length < 1) {
                                    message = '*Date is required';
                                }

                                break;

                            case 'month':
                                if (+value < 1 || +value > 12) {
                                    message = '*Invalid month';
                                } else if (value.length < 1) {
                                    message = '*Month is required';
                                }

                                break;
                            case 'year':
                                if (+value < 1920) {
                                    message = '*Invalid year';
                                } else if (value.length < 1) {
                                    message = '*Year is required';
                                }
                                break;
                            case 'yearOfBirth':
                                if (+value < 1920) {
                                    message = '*Year of birth is invalid';
                                } else if (
                                    +value >
                                    new Date().getFullYear() - 17
                                ) {
                                    message =
                                        '*You must be over 18 years old to create an account';
                                }
                                break;

                            case 'financialDependents':
                                if (
                                    value !== 0 &&
                                    (value == '' || value == ' ' || !value)
                                ) {
                                    message = '*This field is required';
                                } else if (value < 0) {
                                    message = '*Please enter a valid number';
                                } else if (value > 0 && value % 1 !== 0) {
                                    message = '*Please enter a whole number';
                                } else if (value > 10) {
                                    message =
                                        '*The maximum number of financial dependents allowed is 10';
                                }
                                break;
                            case 'numberMonths':
                                if (value == '' || value == ' ') {
                                    message = '*This field is required';
                                } else if (value < 0) {
                                    message = '*Please enter a valid number';
                                } else if (value % 1 !== 0) {
                                    message = '*Please enter a whole number';
                                } else if (value > 800) {
                                    message = '*Invalid number of months';
                                }
                                break;

                            case 'rentPaymentAmount':
                                const min = this.min || 15;
                                if (value == '' || value == ' ' || !value) {
                                    message = '*This field is required';
                                } else if (value < 0) {
                                    message = '*Please enter a valid number';
                                } else if (value % 1 !== 0) {
                                    message = '*Please enter a whole number';
                                } else if (value > 99999) {
                                    message =
                                        '*The maximum amount allowed is £99999';
                                } else if (value < min) {
                                    message = `*The minimum amount allowed is £${min}`;
                                }

                                if (message) {
                                    this.showError = true;
                                }
                                break;
                        }
                    }
                    break;
                }
                case 'postcode': {
                    const regex =
                        /\b([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})\b/;

                    if (!regex.test(value) || value.length > 8) {
                        message = '*Please enter a valid postcode';
                    }

                    break;
                }
                case 'PANCode': {
                    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

                    if (!regex.test(value)) {
                        message =
                            '*Please enter a valid Permanent Account Number';
                    }
                    break;
                }

                case 'aadhaarCardNumber': {
                    const regex = /^\d{12}$/;

                    // Field optional so we don't show error message if field is empty
                    if (value && !regex.test(value)) {
                        message = '*Please enter a valid Aadhaar Card Number';
                    }
                    break;
                }

                case 'drivingLicenseNumberIN': {
                    const regex = /^[A-Z]{2}[A-Z0-9]{3,14}$/;

                    if (value && !regex.test(value)) {
                        message =
                            '*Please enter a valid Driving License Number';
                    }
                    break;
                }

                case 'NREGACardNumber': {
                    const regex = /^[A-Za-z0-9-]{8,15}$/;

                    if (value && !regex.test(value)) {
                        message =
                            '*NREGA number must be 8 to 15 characters long and can only include letters, numbers, and dashes';
                    }
                    break;
                }

                case 'socialSecurityNumber': {
                    const regex = /^\d{9}$/;

                    if (!regex.test(value)) {
                        message =
                            'Social Security Number must be in the format XXX-XX-XXXX.';
                    }
                    break;
                }

                case 'drivingLicenseNumberAU': {
                    const regex =
                        /^(?=.{9,})[A-Z0-9]{1,10}([- ]?[A-Z0-9]{1,10})*$/;

                    if (!regex.test(value)) {
                        message = 'Please enter a valid Driving License Number';
                    }
                    break;
                }
            }

            if (message) {
                this.validation = {
                    status: 'invalid',
                    errorMessage: message,
                };

                this.$store.dispatch('application/updateApplicationStore', {
                    [this.item.id]: this.value,
                });
                // Emit event to Personal Details form
                this.$emit('onInput', this.item.id, this.value, 'invalid');
                return false;
            }

            // If validation passed
            this.showError = false;
            this.validation = {
                status: 'valid',
                errorMessage: '',
            };
            this.$emit('onInput', this.item.id, this.value, 'valid');
            // this.$emit('onInput', this.item.id, this.value, 'valid');
            this.$store.dispatch('application/updateApplicationStore', {
                [this.item.id]: this.value,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.input-wrap {
    position: relative;
}

.input {
    width: 100%;
    border: 1px solid $lightGrey;
    box-sizing: border-box;
    height: 58px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    outline: none;
    transition: 0.3s;
    padding: 19px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $darkestGrey;

    &.filled {
        border: 1px solid $darkestGrey;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        padding-bottom: 5px;
    }

    &.hasErrors {
        border: 1px solid $critical;
    }
    &:disabled {
        border-color: $lightestGrey;
    }
    &.noLabel {
        padding: 0px 16px;
    }

    &.prependSymbol {
        padding-left: 35px !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 58px white inset !important;
}

.label {
    position: absolute;
    display: inline-block;
    pointer-events: none;
    left: 17px;
    top: 20px;
    transition: 0.2s ease all;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;

    &.filled {
        top: 10px;

        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: -0.01em;
        text-align: left;
        color: $darkGrey !important;
    }
    &.hasErrors {
        color: $critical !important;
    }
}

.currency-symbol {
    position: absolute;
    top: 19px;
    left: 19px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $middleGrey;
}
</style>
