<template>
  <ErrorOB v-if="showError" :errorCode="errorCode" @clicked="tryAgain" />
</template>

<script>
import { ref, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ErrorOB from '../screens/error/ErrorOB.vue';
import { Firestore } from '../../utils/firestore';
import { LocalStorage } from '../../utils/localStorage';

export default {
  name: 'firstObNameMatch',
  components: { ErrorOB },

  setup() {
    const store = useStore();
    const router = useRouter();

    const stepStatus = ref(null);
    const status = ref(null);
    const errorCode = ref(null);
    const showError = ref(false);

    let unsubscribe = null;
    let firstTimeout = null;
    let secondTimeout = null;

    const cleanup = () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
      }
    };

    const applicationId = localStorage.getItem('applicationId');

    // Subscribe to application to check when the firstObNameMatch step is passed
    const subscribeToApplications = async () => {
      unsubscribe = await Firestore.subscribeToDoc(
        `Applications`,
        applicationId,
        (doc) => {
          stepStatus.value = doc.steps.firstObNameMatch.status;

          if (stepStatus.value === 'passed') {
            cleanup();
            store.dispatch('navigation/modulePassed', {
              event: null,
              page_title: 'firstObNameMatch',
            });
          }
        }
      );

      // If in 5s the status is not passed, subscribe to another doc to check if there is an error and get its code
      firstTimeout = setTimeout(subscribeToLogs, 5000);
    };

    const subscribeToLogs = async () => {
      try {
        cleanup();

        const userId = store.getters['auth/userId'];

        // Get the last log from TrueLayerLogs subcollection

        const lastLog = await Firestore.getDocs(
          `Users/${userId}/TrueLayerLogs`,
          { orderBy: 'createdAtTimestamp', limit: 1 }
        );

        const lastLogId = lastLog[0].docId;

        unsubscribe = await Firestore.subscribeToDoc(
          `Users/${userId}/TrueLayerLogs`,
          lastLogId,
          (doc) => {
            if (doc?.status) {
              status.value = doc.status;
            }

            if (status.value === 'error' && doc.errorCode) {
              cleanup();
              store.dispatch('navigation/stopLoading');
              errorCode.value = doc.errorCode;
              LocalStorage.set({ obErrorShowed: true });
              showError.value = true;
              return;
            } else if (
              status.value === 'processing' ||
              status.value === 'complete'
            ) {
              cleanup();
              store.dispatch('navigation/modulePassed', {
                event: null,
                page_title: 'firstObNameMatch',
              });
            }
          }
        );

        secondTimeout = setTimeout(showTimeoutErrorPage, 90000); // If in 1m 30s the status is not passed, show the error page
      } catch (error) {
        router.push('/error');
      }
    };

    const showTimeoutErrorPage = () => {
      cleanup();
      store.dispatch('navigation/stopLoading');
      errorCode.value = 'timeout';
      showError.value = true;
    };

    const tryAgain = () => {
      store.dispatch('updateLoaderAnimationType', 'default');
      store.dispatch('navigation/startLoading');
      router.go();
    };

    onUnmounted(() => {
      cleanup();
      clearTimeout(firstTimeout);
      clearTimeout(secondTimeout);
    });

    // Start the process
    subscribeToApplications();

    return {
      showError,
      errorCode,
      tryAgain,
    };
  },
};
</script>
