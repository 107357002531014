<template>
  <base-screen>
    <template #main>
      <base-modal v-if="showDialog" @closeModal="showDialog = false">
        <QRCodeModal
          :link="redirectUrl"
          :redirectDocId="redirectDocId"
          :phoneNo="phoneNo"
          :selectedBank="selectedBank"
          @sendSms="sendSms"
        />
      </base-modal>
      <TrueLayerInfo :selectedBank="selectedBank" />
    </template>
    <template #footer>
      <base-button-primary
        v-if="isWebview"
        :disabled="!redirectUrl"
        @clicked="sendValue"
      >
        Connect to {{ bankName }}
      </base-button-primary>
      <base-button-primary
        v-else-if="isDesktop"
        :disabled="!redirectUrl"
        @clicked="handleDesktopClick"
      >
        Connect to {{ bankName }}
      </base-button-primary>

      <a v-else :href="redirectUrl" target="_blank" @click="logEvent">
        <base-button-primary :disabled="!redirectUrl">
          Connect to {{ bankName }}
        </base-button-primary>
      </a>
      <div v-if="!isDesktop" class="consent-info_footer m-t-3">
        <p>
          By tapping you agree to TrueLayer's
          <br />
          <span @click="openLink('terms')">Terms of Service</span>
          and
          <span @click="openLink('policy')">Privacy Policy.</span>
        </p>
      </div>
    </template>
  </base-screen>
</template>

<script>
import BaseModal from '../../modal/BaseModal.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import TrueLayerInfo from '../../truelayer/TrueLayerInfo.vue';
import QRCodeModal from '../../truelayer/QRCodeModal.vue';
import Firestore from '../../../utils/firestore';
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';

export default {
  name: 'ConsentIntro',
  components: {
    BaseScreen,
    BaseButtonPrimary,
    TrueLayerInfo,
    BaseModal,
    QRCodeModal,
  },
  props: {
    redirectUrl: {
      type: String,
    },
    redirectDocId: {
      type: String,
      required: true,
    },
    selectedBank: {
      type: Object,
      required: true,
    },
    phoneNo: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isDesktop: null,
      showDialog: false,
    };
  },
  computed: {
    bankName() {
      return this.selectedBank.display_name;
    },
    isWebview() {
      const webview = localStorage.getItem('webview') == 'true' ? true : false;
      return webview;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener('resize', this.checkScreenWidth);
    });

    // Add an event listener for the resize event
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenWidth);
  },

  methods: {
    logEvent() {
      logFirebaseEvent('tl_connect_selected');
    },
    handleDesktopClick() {
      this.logEvent();
      this.showDialog = true;
    },
    openLink(type) {
      let link;

      switch (type) {
        case 'terms':
          link = 'https://truelayer.com/legal/enduser_tos';
          break;

        case 'policy':
          link = 'https://truelayer.com/legal/privacy';
          break;

        default:
          link = 'https://truelayer.com';
          break;
      }

      const webview = localStorage.getItem('webview') == 'true';

      if (webview) {
        window.OpenBrowser.postMessage(link);
      } else {
        window.open(link, '_blank');
      }
    },
    sendValue() {
      logFirebaseEvent('tl_connect_selected');
      this.$emit('updateModule', 'consentIntro');
    },
    checkScreenWidth() {
      this.isDesktop = window.innerWidth >= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
.consent-info_footer {
  p {
    font-size: 12px !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(78, 85, 84, 0.8);
    padding: 12px;
    padding-top: 0;
    text-align: center;
    margin-bottom: 0;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
  }
}
</style>
