<template>
  <transition v-if="!isLoading" name="fade" appear>
    <base-screen fullWidth>
      <template #main>
        <div class="bg" :style="{ width: isDesktop ? '50%' : '100%' }">
          <div class="title-content">
            <h1 class="header-1 text-centered">
              Select a plan
            </h1>
            <p class="text-centered m-t-4">
              Report your rent to the 3 main UK credit reference
              agencies. Watch your credit score grow. Cancel
              anytime.
            </p>
            <div
              class="bg-image"
              :style="{ width: isDesktop ? '50%' : '100%' }"
            >
              <BaseImage image="payment_plan_bg" />
            </div>
          </div>
        </div>

        <div class="main_content" >
          <div
            class="payment-plan_select_container payment-plan_column content"
          >
            <div class="payment-plan-select_content">
              <p class="payment-plan_subtitle">
                Select your plan
              </p>
              <div
                class="payment-plan_card payment-plan_card__annual m-t-4 m-b-4"
                @click="() => onSelect(plans.annual.id)"
              >
                <div class="flex-row">
                  <label
                    class="transaction-details_tenancy-type_item custom-radio"
                  >
                    <input
                      class="m-t-1"
                      type="radio"
                      :checked="
                        selectedPlan == plans.annual.id
                      "
                      name="selectedBank"
                      @change="selectedPlan = plans.annual.id"
                    />
                    <span></span>
                  </label>

                  <div class="payment-plan_card__annual_content">
                    <div class="flex-row">
                      <h2 class="header-2">
                        Annual
                      </h2>
                      <div class="badge m-l-2">
                        Save 25%
                      </div>
                    </div>

                    <div>
                      <div
                        class="flex-row m-t-2 center-left m-b-1"
                      >
                        <p
                          class="payment-plan_card__annual_price m-0 p-r-1"
                        >
                          £{{ plans.annual.amount }}
                        </p>
                        <p
                          class="payment-plan_card__annual_price_term m-0"
                        >
                          /year
                        </p>
                      </div>
                      <div
                        class="payment-plan_card_description"
                      >
                        <p class="m-0">
                          Which means £{{
                            getMonthlyPrice
                          }}/month
                        </p>
                        <p class="m-0 p-t-1">
                          Cancel anytime, and we will
                          refund the remaining months
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="payment-plan_card__annual_img">
                  <BaseImage image="best-deal" />
                </div>
              </div>
              <div
                class="payment-plan_card payment-plan_card__monthly m-t-2"
                @click="() => onSelect(plans.monthly.id)"
              >
                <div class="flex-row p-v-3">
                  <label
                    class="transaction-details_tenancy-type_item custom-radio"
                  >
                    <input
                      class="m-t-1"
                      type="radio"
                      :checked="
                        selectedPlan == plans.monthly.id
                      "
                      name="selectedBank"
                      @change="
                        selectedPlan == plans.monthly.id
                      "
                    />
                    <span></span>
                  </label>
                  <div class="payment-plan_card__monthly_content">
                    <div class="flex-row center-space-between">
                      <div>
                        <h2 class="header-2">
                          Monthly
                        </h2>
                      </div>
                      <p
                        class="m-0 payment-plan_card__monthly_price"
                      >
                        £{{ plans.monthly.amount }}/month
                      </p>
                    </div>
                    <div class="payment-plan_card_description">
                      <p class="m-0">
                        Pay as you go. Monthly payments
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="withTrial" class="payment-plan_card__annual_img">
                  <BaseImage image="free-trial" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="card-screen-footer">
          <base-button-primary @clicked="sendValue">
            Continue
          </base-button-primary>
        </div>
      </template>
    </base-screen>
  </transition>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

export default {
    components: { BaseScreen, BaseButtonPrimary, BaseImage },
    props: {
        plans: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedPlan: null,
            isDesktop: null,
            isLoading: true,
            withTrial: false
        };
    },
    computed: {
        getMonthlyPrice() {
            return (this.plans.annual.amount / 12).toFixed(2);
        }
    },
    created() {
        this.$store.commit('navigation/UPDATE_ANIMATION_MODE', 'normal');
    },
    mounted() {
        this.checkScreenWidth(); // Initial check when component is mounted
        window.addEventListener('resize', this.checkScreenWidth); // Add listener to window resize
        this.isLoading = false;

        if (this.plans) {
            this.selectedPlan = this.plans.annual.id;
            this.withTrial = this.plans.trialPeriodInDays;
            this.isLoading = false;
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenWidth); // Clean up listener when component is destroyed
    },

    methods: {
        onSelect(type) {
            this.selectedPlan = type;
        },
        sendValue() {
            this.$emit('updateForm', 'paymentPlan', this.selectedPlan);
        },
        checkScreenWidth() {
            this.isDesktop = window.innerWidth >= 1024;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';

.payment-plan_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
}

.payment-plan_container {
    width: 375px;
    height: 100%;
}

.payment-plan_bg_wrap {
    background-color: $blue;
    position: relative;
    height: 45%;
    overflow: hidden;
}

.payment-plan_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/bg/payment_plan_bg.png') no-repeat;
    background-position-y: bottom;
}

.payment-plan_content {
    padding: 20px;
    padding-top: 75px;
}

.payment-plan_select_container {
    padding: 32px 20px 41px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;


}

.payment-plan_subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
    color: $darkestGrey;
}

.payment-plan_card {
    border-radius: 16px;
    cursor: pointer;
}
.payment-plan_card__annual {
    padding: 20px 24px;
    background-color: rgba(122, 159, 248, 0.1);
    position: relative;
}

.payment-plan_card__monthly {
    padding: 16px 24px;
    border: 2px solid $lightestGrey;
    position: relative;
}

.payment-plan_card__annual_img {
    position: absolute;
    top: -27px;
    right: -16px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    background-color: $blue;
    color: #f2f5fe;
    padding: 2px 12px;
    border-radius: 16px;
}

.payment-plan_card__annual_price {
    color: $blue;

    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
}

.payment-plan_card__annual_price_term {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
}

.payment-plan_card_description {
    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
    }
}

.payment-plan_card__monthly_price {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: $darkGrey;
}

.payment-plan_title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
}

.bg {
    background-color: $blue;
    position: absolute;
    z-index: -1;
    top: 0;
}
.content {
    background-color: white;
    margin-top: 300px;

    @media (min-height: 900px ) and (min-width: 1024px) {
        margin-top: 0px;
    }
}

.title-content {
    padding-top: 90px;
    padding-left: 16px;
    padding-right: 16px;
}

.bg-image {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 18px;
}



</style>
