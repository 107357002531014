<template>
  <base-screen contentCentered>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="fail_illustration_list" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <transition
            appear
            enter-active-class="animate__animated animate__fadeIn"
          >
            <h3 class="text-centered">
              Sorry! We can not offer you credit this time
            </h3>
          </transition>
          <p class="text-centered grey m-t-4">
            We suggest reapplying after a month once your circumstances have
            changed.
          </p>
          <p class="text-centered grey m-b-4">
            Based on the information you have shared, we can not offer you a
            credit card now.
          </p>
        </div>
        <div class="buttonIconRow m-t-6">
          <base-button-secondary
            :styles="{
              width: 'auto',
              backgroundColor: buttonClicked ? '#16E89E' : 'inherit',
              borderColor: buttonClicked ? '#16E89E' : 'inherit',
              width: buttonClicked ? 'auto' : 'inherit',
              paddingLeft: '32px',
              paddingRight: '32px',
            }"
            @clicked="setReminder"
          >
            <div class="flex-row buttonIcon">
              <div class="m-r-1">
                <base-icon
                  icon="check"
                  size="md"
                  v-if="buttonClicked"
                ></base-icon>
                <base-icon icon="autopay" size="md" v-else></base-icon>
              </div>
              {{ buttonClicked ? 'Reminder set' : 'Remind me in 30 days' }}
            </div>
          </base-button-secondary>
        </div>
      </div>
    </template>

    <template #footer>
      <base-button-primary @clicked="onPrimaryButtonClick">
        {{ buttonClicked ? 'Okay. Got it!' : 'Go back' }}
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseButtonSecondary from '../../buttons/BaseButtonSecondary.vue';
import BaseIcon from '../../assets/BaseIcon.vue';

export default {
  name: 'OfferError',
  data() {
    return {
      buttonClicked: false,
    };
  },
  components: {
    ContainerSized,
    BaseButtonSecondary,
    BaseIcon,
    BaseButtonPrimary,
    BaseScreen,
    BaseImage,
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
  },
  methods: {
    setReminder() {
      this.buttonClicked = true;
    },
    onPrimaryButtonClick() {
      this.$store.commit('navigation/REQUEST_IN_PROGRESS_UPDATE', true);
      window.location.href = 'https://hellopillar.com/';
    },
  },
};
</script>

<style>
.buttonIcon {
  justify-content: center;
  align-items: center;
}

.buttonIconRow {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
