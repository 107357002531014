<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-title>When do you want to make your payment?</base-title>
      <BaseSelect
        :options="dateOptions"
        placeholder="Select a date"
        @selected="selectDate"
      />
    </template>
    <template #footer>
      <base-button-primary
        :disabled="!selectedDate"
        class="m-b-4"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
      <div
        class="direct-debit-row"
        :style="{ cursor: 'pointer' }"
        @click="showDialog = true"
      >
        <div class="direct-debit-logo-container">
          <BaseImage image="direct-debit" width="100px" />
        </div>
        <div class="direct-debit-text">
          <p class="small">
            <span class="grey">
              Your payments are protected by The
            </span>
            Direct Debit Guarantee
          </p>
        </div>
      </div>
      <base-modal
        v-if="showDialog"
        escape
        @closeModal="showDialog = false"
      >
        <DirectDebitGuarantee />
      </base-modal>
    </template>
  </base-screen>
</template>

<script>
import BaseImage from '../../assets/BaseImage.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import DirectDebitGuarantee from '../../directDebitSetup/directDebitGuarantee.vue';
import BaseSelect from '../../inputs/BaseSelect.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseModal from '../../modal/BaseModal.vue';

export default {
    name: 'SelectInvoiceDayOfMonth',
    components: {
        BaseTitle,
        BaseScreen,
        BaseImage,
        DirectDebitGuarantee,
        BaseModal,
        BaseSelect,
        BaseButtonPrimary,
        BaseImage,
        BaseTitle,
    },
    data() {
        return {
            selectedDate: null,
            showDialog: false,
        };
    },
    computed: {
        dateOptions() {
            return Array.from({ length: 28 }, (_, i) => {
                const day = i + 1;
                let label;
                switch (day) {
                    case 1:
                    case 21:
                        label = `${day}st of each month`;
                        break;
                    case 2:
                    case 22:
                        label = `${day}nd of each month`;
                        break;
                    case 3:
                    case 23:
                        label =`${day}rd of each month`;
                        break;
                    default:
                        label = `${day}th of each month`;
                }
                return { label, value: day };
            });
        },
    },

    methods: {
        sendValue() {
            this.$emit(
                'updateForm',
                'selectInvoiceDayOfMonth',
                this.selectedDate
            );
        },
        selectDate(option) {
            const selectedDateObj = this.dateOptions.find(
                (date) => date.value === option
            );
            if (selectedDateObj) {
                this.selectedDate = selectedDateObj;
            }
        },
    },
};
</script>
