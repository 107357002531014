<template>
  <PaymentSuccess v-if="isRRProduct" />
  <base-screen v-else-if="!isLoading" contentCentered desktopNoBG>
    <template #main>
      <transition name="fade">
        <div class="p-h-5 m-t-7">
          <div class="flex-row center">
            <transition
              appear
              enter-active-class="animate__animated animate__pulse animate__fast"
            >
              <container-sized width="130px">
                <BaseImage image="checkbox_with_background" />
              </container-sized>
            </transition>
          </div>
          <div class="m-t-1">
            <h1 class="header-1 text-centered">
              Congrats!
              <br />
              You're all set
            </h1>
          </div>
          <div class="m-b-5">
            <p
              v-if="device.type == 'desktop'"
              class="text-centered"
            >
              Use the QR-code link to download
              <br />
              the app from your smartphone.
            </p>
            <p v-else class="text-centered">
              Download the app and log in
              <br />
              using your phone number to continue
            </p>
          </div>
          <div class="flex-row center">
            <container-sized width="170px">
              <div v-if="device.type == 'desktop'">
                <qrcode-vue
                  :value="link"
                  size="170"
                  level="H"
                />
              </div>
              <BaseImage
                v-else
                :image="
                  device.os == 'iOS'
                    ? 'app-store'
                    : 'play-store'
                "
                @clicked="storeRedirect"
              />
            </container-sized>
          </div>
          <p
            v-if="device.type == 'desktop'"
            class="backlinkText text-centered"
            @click="goBack"
          >
            Go back to home
          </p>
        </div>
      </transition>
    </template>
  </base-screen>
</template>

<script>
import PaymentSuccess from '../payment/PaymentSuccess.vue';
import QrcodeVue from 'qrcode.vue';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import { detectDevice } from '../../../controllers/services/helpers';
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';

export default {
    name: 'ApplicationCompleted',
    components: {
        BaseScreen,
        ContainerSized,
        BaseImage,
        QrcodeVue,
        PaymentSuccess,
    },
    data() {
        return {
            device: { type: null, os: null },
            link: 'https://hellopillar.page.link/app',
            product: null,
            isLoading: true,
        };
    },

    computed: {
        isRRProduct() {
            const product = localStorage.getItem('productName');

            return (
                product == 'rentReporting' ||
                product == 'rentReportingAndMonitor'
            );
        },
    },

    created() {
        this.$store.dispatch('navigation/hideProgessBar');

        // Send GA event if there ga=true has been passed in the query
        const query = this.$route.query;

        if (query?.GA && query?.GA == 'true') {
            logFirebaseEvent('application_complete');
        }

        const productName = localStorage.getItem('productName');
        if (productName == 'rentReporting') {
            this.product = 'rentReporting';
            return false;
        }

        if (
            productName == 'pillarCardIndia' ||
            productName == 'pillarCardNTC'
        ) {
            localStorage.clear();
        }
        const webview = localStorage.getItem('webview');

        if (webview == 'true') {
            const product = localStorage.getItem('productName');

            if (product == 'pillarMonitor') {
                window.CISComplete.postMessage('Success');
                return false;
            } else if (
                product == 'pillarCard' ||
                product == 'pillarCardClassic' ||
                product == 'pillarCardBuild'
            ) {
                window.PillarBuildComplete.postMessage('Success');
                return false;
            } else {
                window.App.postMessage('Back');
            }
        } else {
            const deviceInfo = detectDevice();
            this.device = deviceInfo;
        }
        this.$store.dispatch('navigation/stopLoading');

        this.isLoading = false;
    },

    methods: {
        goBack() {
            window.location.href = 'https://hellopillar.com/';
        },

        storeRedirect() {
            window.location.href = this.link;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';
.backlinkText {
    margin-top: 72px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: $darkGrey;
    cursor: pointer;
}
</style>
