<template>
  <base-screen>
    <template #main>
      <base-title>{{ title }}</base-title>
      <p>Please provide one of the following:</p>
      <div class="m-t-6">
        <BaseInternationalForm
          :countryCode="countryCode"
          :inputsArray="formInputs"
          @updateForm="updateForm"
        />
        <base-error-message v-if="error">
          {{ error }}
        </base-error-message>
      </div>
    </template>
    <template #footer>
      <base-button-primary
        :disabled="!formIsValid"
        @clicked="sendValue(idDetails)"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import screenMixin from '../../../mixins/screenMixin.js';
import { countryIdNumbersFormInputsAlternative } from '../../../assets/data/countryIdNumbersFormInputs.js';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseInternationalForm from '../../application/baseInternationalForm.vue';
import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';

export default {
    components: {
        BaseScreen,
        BaseInternationalForm,
        BaseButtonPrimary,
        BaseTitle,
        BaseErrorMessage,
    },
    mixins: [screenMixin({ screenName: 'identificationAlternative' })],
    props: {
        countryCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            idDetails: {},
            formIsValid: false,
            error: null,
        };
    },
    computed: {
        title() {
            const titles = {
                IN: "What's your Indian identifier?",
            };
            return titles[this.countryCode] || null;
        },
        formInputs() {
            // Get country specific form inputs
            return (
                countryIdNumbersFormInputsAlternative[this.countryCode] || []
            );
        },
    },

    methods: {
        updateForm(obj, status, validatedObj) {
            // Remove empty values from the object
            this.idDetails = obj;

            // Validate the object
            this.validateIdObject(this.idDetails);

            // Validate the form
            if (status === 'valid' && !this.error) {
                this.formIsValid = true;
            } else if (!this.error && validatedObj) {

                // Check the raw input values for custom validation as we need only one of the identifiers
                const validInputs = validatedObj.filter(
                    (el) => el.validationStatus === 'valid' && el.value !== '' && el.value !== ' '
                );
                this.formIsValid = validInputs.length > 0;
            } else {
                this.formIsValid = false;
            }
        },
        validateIdObject(obj) {

  
            // Check if only one identifier is provided and if not, set the error
            this.error = null;
            if (Object.keys(obj).length > 1) {
                this.error =
                    'Please provide only one of the supported identifiers';
                return false;
            } else if(Object.keys(obj).length === 0) {
                this.error =
                    'Please provide one of the supported identifiers';
                return false;
            }
        },
    },
};
</script>