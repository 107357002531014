<template>
  <ob-fail v-if="error"></ob-fail>
</template>

<script>
import { obBankVerification } from '../../../controllers/ob';
import ObFail from '../../ob/OBFailScreen';

export default {
  components: {
    ObFail,
  },
  data() {
    return {
      moduleName: 'openBankingEBAV',
      loading: true,
      error: false,
      errorMessage: null,
    };
  },
  created() {
    localStorage.removeItem('obsuccess');
  },
  async mounted() {
    // this.$store.dispatch('navigation/moduleLoadingStart');
    this.$store.dispatch(
      'navigation/loadingTextUpdate',
      'Confirming your details...'
    );
    const applicationId = localStorage.getItem('applicationId');
    const webview = localStorage.getItem('webview');
    const token = localStorage.getItem('token');
    let reqParams;

    if (!applicationId && webview) {
      const userId = this.$store.getters['auth/userId'];

      reqParams = {
        userId: userId,
        token: token,
      };

      this.userModule = true;
    } else {
      //Use applicationId for sending request
      reqParams = {
        applicationId: applicationId,
        token: token,
      };
    }

    const response = await obBankVerification(reqParams);

    if (response && response.status == 200) {
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.moduleSuccess();
    } else {
      this.$store.dispatch('navigation/setOBPassedBefore');
      this.$store.dispatch('navigation/moduleLoadingFinish');
      this.error = true;
      return false;
    }
  },
  methods: {
    async moduleSuccess() {
      const applicationId = localStorage.getItem('applicationId');

      //If we have an applicationId, it is a part of application process and we need to call product-config on success
      if (applicationId) {
        this.$store.dispatch('navigation/modulePassed', {
          event: 'open_banking_ebav_successful',
          page_title: this.moduleName,
        });
      } else {
        //If we came from app, we show a succes page and then redirect to app
        this.$store.dispatch('navigation/moduleLoadingFinish');
        this.loading = false;
        window.AddedNewBankAccount.postMessage('Success');
      }
    },
  },
};
</script>

<style></style>
