<template>
  <div v-for="item in formInputs" :key="item.id" class="m-b-2">
    <BaseInput
      :item="item"
      :aria-label="item.label"
      :inputRef="'input' + item.id"
      @onInput="inputChanged"
    />
  </div>
</template>

<script>
import BaseInput from '../inputs/BaseInput.vue';

export default {
    components: { BaseInput },
    props: {
        countryCode: {
            type: String,
            required: true,
        },
        inputsArray: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            formInputs: [],
            addressObj: {},
        };
    },
    created() {
        if (Array.isArray(this.inputsArray)) {
            this.formInputs = [...this.inputsArray]; // set the new object from the props
        }
    },

    methods: {
        inputChanged(id, value, status) {
            const index = this.formInputs.findIndex((el) => el.id === id);
            if (index !== -1) {
                this.formInputs[index].value = value;
                this.formInputs[index].validationStatus = status;

                // Remove country code prefix for the formatted ID

                let formattedId = id;
                if (!id.startsWith('drivingLicenseNumber')) {
                    formattedId = id.replace(
                        new RegExp(this.countryCode + '$'),
                        ''
                    );
                }

                this.addressObj[formattedId] = value;
                
                this.validateForm();
            }
        },

        validateForm() {
            const invalidInputs = this.formInputs.filter(
                (el) => el.validationRequired && el.validationStatus !== 'valid'
            );

            const status = invalidInputs.length === 0 ? 'valid' : 'invalid';

            const cleanObject = this.cleanObject(this.addressObj);
            this.$emit('updateForm', cleanObject, status, this.formInputs); // last argument is optional and used for custom error handling in some components
        },

        cleanObject(obj) {
            // Remove empty values from the object
            return Object.entries(obj)
                .filter(([, value]) => value !== '')
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        },
    },
};
</script>
