<template>
  <base-screen>
    <template #main>
      <base-title>
        What's your previous <br />
        non-UK address?</base-title
      >
      <div>
        <AddressIndia
          type="previous"
          @updateAddress="updateAddress"
          v-bind="$attrs"
        />
      </div>
    </template>
    <template #footer>
      <base-button-primary class="m-t-6" @clicked="sendValue" :disabled="!formIsValid">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import AddressIndia from '../../application/internationalAddress/addressIndia.vue';

export default {
  components: {
    BaseScreen,
    BaseTitle,
    BaseButtonPrimary,
    AddressIndia,
  },
  name: 'internationalAddressPrevious',
  data() {
    return {
      address: null,
      formStatus: 'pending',
      errorMessage: null,
    };
  },
  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateModule', 'internationalAddressPrevious', this.address);
    },

    updateAddress(address, status) {
      this.address = address;
      this.formStatus = status;
    },
  },

  computed: {
    formIsValid() {
      return this.formStatus == 'valid';
    },
  },
};
</script>
