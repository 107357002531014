<template>
  <transition name="fade" appear>
    <base-screen fullWidth>
      <template #main>
        <base-bottom-sheet
          v-if="modalIsActive"
          @closeModal="modalIsActive = false"
        >
          <div class="card-benefits-wrap">
            <p>
              What you get with
              <br />
              <strong>{{ cardProductName }}</strong>
            </p>
            <div class="benefits-list">
              <div
                class="benefits-row"
                v-for="item in selectedBenefits"
                :key="item.icon"
              >
                <div class="benefits-icon">
                  <BaseIcon :icon="item.icon" size="38" />
                </div>
                <div class="benefits-text">
                  <p class="benefits-title">{{ item.title }}</p>
                  <p class="benefits-description">{{ item.description }}</p>
                </div>
              </div>
            </div>

            <base-button-primary @clicked="sendValue">
              Complete my application
            </base-button-primary>
          </div>
        </base-bottom-sheet>
        <div class="card-screen-container-mobile">
          <div class="card-screen-content">
            <div class="card-screen-title">
              <h2>
                You're
                <span v-if="decision == 'refer'">conditionally</span>
                approved for a
              </h2>
              <h2 class="greenText">{{ cardProductName }}</h2>

              <div class="card-screen-desc">
                <p v-if="cardProductType == 'unsecured'">
                  Credit limit of £{{ maxCreditLimitApproved }}.
                  <br />
                  
                </p>
                <p v-else>
                  Our secured card has the benefits of a traditional credit
                  card, and also helps you build your credit history
                </p>

                <div class="badge-row">
                  <span v-if="cardProductType == 'unsecured'" class="badge">
                    {{ apr }}% APR
                  </span>
                  <span v-else class="badge">£{{ monthlyFee }}/monthly</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="card-screen-footer">
          <base-button-primary @clicked="sendValue">
            Complete my application
          </base-button-primary>

          <div class="centered m-b-3 m-t-5">
            <base-button-link @clicked="modalIsActive = true">
              Learn more
            </base-button-link>
          </div>
        </div>
      </template>
    </base-screen>
  </transition>
</template>

<script>
import BaseIcon from '../../../../components/assets/BaseIcon.vue';
import BaseButtonPrimary from '../../../../components/buttons/BaseButtonPrimary.vue';
import BaseScreen from '../../../../components/layout/BaseScreen.vue';
import BaseBottomSheet from '../../../../components/modal/BaseBottomSheet.vue';
import BaseButtonLink from '../../../../components/UI/elements/buttons/BaseButtonLink.vue';
export default {
  components: {
    BaseScreen,
    BaseBottomSheet,
    BaseIcon,
    BaseButtonLink,
    BaseButtonPrimary,
  },
  name: 'CardApprovedMobile',
  props: {
    cardProductType: {
      type: String,
      required: true,
    },
    decision: {
      type: String,
      required: true,
    },
    monthlyFee: {
      type: Number,
      required: true,
    },
    apr: {
      type: Number,
      required: true,
    },
    maxCreditLimitApproved: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalIsActive: false,
      imageLoaded: false,
    };
  },
  methods: {
    sendValue() {
      this.$emit('updateModule', 'offerScreen');
    },
  },

  computed: {
    cardProductName() {
      return this.cardProductType == 'unsecured'
        ? 'Pillar Credit Card '
        : 'Credit Builder Card';
    },
    selectedBenefits() {
      return this.cardProductType === 'unsecured'
        ? this.benefitsUnsecured
        : this.benefitsSecured;
    },
    benefitsUnsecured() {
      return [
        {
          icon: 'transactions',
          title: 'Spread the cost of your purchases ',
          description: `0% APR when paying your statement in full. ${this.apr}% APR if you need more time to pay.`,
        },
        {
          icon: 'no-fees',
          title: 'No fees for spending abroad',
          description:
            'Take your Pillar credit card with you when you travel, and enjoy fee-free usage worldwide.',
        },
        {
          icon: 'scores',
          title: 'Improves your credit score',
          description:
            'Using your Pillar card responsibly and making timely payments will help improve your credit history.',
        },
        {
          icon: 'purchase',
          title: 'Purchase protection',
          description:
            'Section 75 protection on all card purchases above £100.',
        },
      ];
    },
    benefitsSecured() {
      return [
        {
          icon: 'scores',
          title: 'Improves your credit score',
          description:
            'Using your Pillar card responsibly and making timely payments will help improve your credit history.',
        },
        {
          icon: 'no-fees',
          title: 'No fees for spending abroad',
          description:
            'Take your Pillar credit card with you when you travel, and enjoy fee-free usage worldwide.',
        },
        {
          icon: 'purchase',
          title: 'Purchase protection',
          description:
            'Section 75 protection on all card purchases above £100.',
        },
      ];
    },
  },
  mounted() {
    const mainContent = document.querySelector('.main-content');
    mainContent.style.marginBottom = '0px';
    mainContent.style.marginTop = '0px';
  },
};
</script>

<style lang="scss" scoped>
.card-benefits-wrap {
  p {
    text-align: center !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    letter-spacing: -0.4px !important;
    margin-top: -20px;
  }
}

.benefits-list {
  p {
    margin: 0;
  }
  margin-bottom: 24px;

  .benefits-row {
    width: 100%;
    display: flex;
    padding: 8px;
    gap: 12px;
    justify-content: left;

    .benefits-text {
      .benefits-title {
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 19.6px !important;
        letter-spacing: -0.28px !important;
        margin-bottom: 4px !important;
        text-align: left !important;
      }
      .benefits-description {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 140% !important;
        letter-spacing: -0.4px !important;
        margin: 0;
        text-align: left !important;
      }
    }
  }
}

.card-screen-container-mobile {
  background-image: url('../../../../assets/backgrounds/card_mobile.svg') !important;
  height: 630px;
  /* Replace with your image path */
  background-position: 49% bottom !important; /* Horizontally centered and vertically at the bottom */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  overflow: hidden;
  background-size: auto 100%;
  width: 100%;
  min-width: 330px;
  margin-top: -7px;
}

.card-screen-content {
  background-image: url('../../../../assets/img/chevrons.svg');
  background-position: center top 20px;
  min-height: 300px;
  background-repeat: no-repeat;
  width: 100%;
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
}
.card-screen-title {
  padding-top: 8vh;
  padding-left: 48px;
  padding-right: 48px;
  h2,
  span {
    color: white !important;
    text-align: center !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 132% !important; /* 31.68px */
    letter-spacing: -0.72px !important;
  }

  .greenText {
    color: #16e89e !important;
  }

  .card-screen-desc {
    p {
      color: white !important;
      text-align: center !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 160% !important; /* 22.4px */
      letter-spacing: -0.14px !important;
    }
  }

  .badge-row {
    display: flex;
    justify-content: center;
  }
  .badge {
    margin-top: 8px;
    display: inline-block;
    padding-right: 16px;
    padding-left: 16px;
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.2);

    color: #fff !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: -0.28px !important;
  }
}

.card-screen-container-mobile {
  background-image: url('../../../../assets/img/chevrons.svg');
  background-position: center;
  min-height: 300px;
  background-repeat: no-repeat;
  width: 100%;
}
</style>
