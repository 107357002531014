<template>
  <base-screen>
    <template #main>
      <base-title>
        Which bank account do you use to pay your rent with?
      </base-title>
      <p>
        We will report your rent as we see the transactions enter your
        bank account.
      </p>
      <div class="m-t-6">
        <div v-for="account in bankAccounts" :key="account.account_id">
          <div
            class="list-item_wrap"
            @click="
              (event) =>
                account.disabled ? showWarning(event) : null
            "
          >
            <div
              class="list-item_container"
              @click="selectAccount(account.account_id)"
            >
              <div class="list-image">
                <BaseImage
                  :image="account.provider.provider_id"
                  folder="tl-banks"
                />
              </div>
              <div class="list-title-container">
                <p class="list-title">
                  {{ account.accountName }}
                </p>
                <p class="list-subtitle">
                  {{ account.maskedAccountNumber }}
                </p>
              </div>
              <div class="list-icon">
                <BaseIcon
                  icon="arrow-right_lightgrey"
                  size="md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewAccountButton @clicked="addNewAccount" />
    </template>
  </base-screen>
</template>

<script>
import BaseDescription from '../../layout/BaseDescription.vue';
import NewAccountButton from '../../buttons/NewAccountButton.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseList from '../../list/BaseList.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
import BaseModal from '../../modal/BaseModal.vue';
import BaseImage from '../../assets/BaseImage.vue';

export default {
    name: 'ChooseBankAccount',
    components: {
        BaseScreen,
        BaseTitle,
        BaseImage,
        BaseIcon,
        NewAccountButton,
    },
    props: {
        bankAccounts: {
            type: Array,
            required: true,
        },
        selectedId: {
            type: String,
        },
    },
    computed: {
        formIsValid() {
            return this.selectedId;
        },
    },

    methods: {
        selectAccount(id) {
            this.$emit('updateForm', 'chooseBankAccount', id);
        },
        addNewAccount() {
            this.$emit('addNewAccount');
        },
    },
};
</script>
