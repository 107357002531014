
import { http } from '../../config/axios';



export const generateJumioLink = async (reqData) => {

    const url = `/jumio/id-verification`;

    try {
        const response = await http.post(url, reqData);
        return response;

    } catch (err) {
        return err;
    }

};



export const jumioToPassed = async (applicationId) => {

    const url = `/jumio/step-passed/${applicationId}`;
    // TODO: Change to post?

    try {
        const response = await http.get(url);
        return response;

    } catch (err) {
        return err;
    }

};
