<template>
  <button
    type="button"
    :style="styles"
    :class="[
      size ? `button-secondary ${size}` : 'button-secondary',
      isLoading ? 'loading' : '',
    ]"
    @click="click"
  >
    <div class="loader-container" v-if="isLoading">
      <div class="loading-indicator"></div>
    </div>
    <slot v-if="!isLoading" />
  </button>
</template>

<script>
export default {
  name: 'BaseButtonSecondary',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },

    styles: {
      type: Object,
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
  computed: {
    getHeight() {
      switch (this.size) {
        case 'lg':
          return '60px';

        case 'md':
          return '48px';

        case 'sm':
          return '36px';

        default:
          return '60px';
      }
    },
    isLoading() {
      return this.$store.getters['navigation/buttonSecondaryInLoadingState'];
    },
  },
};
</script>

<style lang="scss" scoped>
.button-secondary {
  width: 100%;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  background-color: transparent;
  color: $darkestGrey;
  height: 60px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  transition: 0.3s;
  position: relative;
  cursor: pointer;

  &.md {
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
  }

  &.sm {
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  &:hover,
  &:active {
    border: 1px solid $black;
    color: $black;
  }
}

.loader-container {
  display: flex; /* use flexbox to center the loading indicator */
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loading-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: $lightGrey;
  animation: spin 1s linear infinite;
  z-index: 3;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
