<template>
  <div class="m-b-4">
    <p class="base-description">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseSubtitle',
};
</script>

<style lang="scss" scoped>
p.base-description {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  letter-spacing: -0.02em;
  text-align: left;

  color: $darkGrey !important;
}
</style>
