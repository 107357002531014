import { sendRequest } from "../config/axios/helper";


export const complyAdvantageUrls = {
    COMPLY_ADVANTAGE_SEARCH: {
        url: '/comply-advantage/comply-advantage-search',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    COMPLY_ADVANTAGE_CROSS_CORE: {
        url: '/comply-advantage/comply-advantage-cross-core',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    }

}


export const complyAdvantageSearch = async params => {
    return await sendRequest(complyAdvantageUrls.COMPLY_ADVANTAGE_SEARCH, params)

};

export const complyAdvantageCrossCore = async params => {
    return await sendRequest(complyAdvantageUrls.COMPLY_ADVANTAGE_CROSS_CORE, params)
}




