<template>
  <base-screen contentCentered v-if="!isLoading" noSlideAnimation desktopNoBG>
    <template #main>
      <div class="p-h-5">
        <div class="flex-row center">
          <container-sized width="75%">
            <BaseImage image="fail-illustration" />
          </container-sized>
        </div>
        <div class="m-t-6">
          <h3 class="text-centered">
            We couldn't
            <br />
            verify your address
          </h3>
          <p class="text-centered grey m-t-4 m-b-4">
            We were unable to match you at
            <br />
            <span :style="{ color: '#242424', fontWeight: '600' }">
              {{ address }}.
            </span>

            <span v-if="allowReset">
              Please provide us with an alternative address.
            </span>
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <base-button-primary
        @clicked="addNewAddress"
        black
        class="mb-3 mt-2"
        v-if="allowReset"
      >
        Change address
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseImage from '../../assets/BaseImage.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import { LocalStorage } from '../../../utils/localStorage';
// import { getUserRecord } from '../../../controllers/user';
export default {
  name: 'EquifaxCISFail',
  components: {
    BaseScreen,
    BaseImage,
    BaseButtonPrimary,
    ContainerSized,
  },
  data() {
    return {
      address: null,
      isLoading: true,
      allowReset: true,
    };
  },
  mounted() {
    this.$store.dispatch('navigation/hideProgessBar');
  },
  async created() {
    let applicationData = this.$store.getters['application/applicationData'];

    if (!applicationData) {
      const applicationId = LocalStorage.get('applicationId');

      const applRecordReq = await this.$store.dispatch(
        'application/getApplicationRecord',
        { applicationId }
      );

      if (!applRecordReq.status) {
        this.$router.push('/error');
        return false;
      }

      applicationData = applRecordReq.data;
    }

    const applicationDataStepCount = applicationData.applicationDataStepCount;

    if (applicationDataStepCount && applicationDataStepCount > 0) {
      this.allowReset = false;
    }

    const address = applicationData.address.current;

    const formattedAddress = `${
      address.subBuilding ? address.subBuilding + ' ' : ''
    }${address.buildingName ? address.buildingName + ' ' : ''}${
      address.buildingNo ? address.buildingNo + ' ' : ''
    }${address.streetName ? address.streetName : ''}`;
    this.address = formattedAddress;

    this.isLoading = false;
  },
  methods: {
    addNewAddress() {
      this.$emit('addNewAddress');
    },
  },
};
</script>
