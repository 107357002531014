<template>
    <base-screen contentCentered desktopNoBG>
        <template #main>
            <div class="flex-row center">
                <container-sized width="65%">
                    <BaseImage image="fail-illustration" />
                </container-sized>
            </div>
            <div class="m-t-7">
                <h3 class="text-centered">We could not verify your identity</h3>
                <div class="m-t-6 m-b-4 text-centered">
                    <p class="text-centered grey">
                        Sorry, we can’t verify your identity with answers about
                        your credit report. Please connect a bank account
                        instead.
                    </p>
                </div>
            </div>
        </template>
        <template #footer>
            <base-button-primary
                @clicked="connectBankAccount"
                black
                class="m-b-2"
            >
                Connect a bank account
            </base-button-primary>
        </template>
    </base-screen>
</template>

<script>
import { logFirebaseEvent } from '../../../controllers/analytics/firebase';
import BaseImage from '../../assets/BaseImage.vue';
import ContainerSized from '../../layout/ContainerSized.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';

export default {
    components: {
        BaseScreen,
        ContainerSized,
        BaseImage,
        BaseButtonPrimary,
    },
    name: 'ErrorKBA',

    mounted() {
        logFirebaseEvent('kba_failed');
        this.$store.dispatch('navigation/stopLoading');
    },

    methods: {
        connectBankAccount() {
            // Remove KBA from screens history
            this.$store.dispatch('navigation/startLoading');
            if (this.$route.path == '/CISIdVerification') {
                this.$router.go();
            } else {
                this.$router.push('/');
            }

            // this.$store.state.navigation.history.shift();
            // this.$store.dispatch('navigation/updateScreen', 'consentBankSelect');
        },
    },
};
</script>
