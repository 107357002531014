import { openHttp } from "../config/axios";


const apiKey = process.env.VUE_APP_LOQATE_API_KEY;

export const findAddress = async (searchText, secondFind) => {
    let container = '';

    if (secondFind !== undefined) {
        container = secondFind;
    }

    //Build the params for the search

    const isMiddleware = false;
    const origin = '';
    const countries = 'GBR';
    const limit = '20';
    const language = 'en-gb';
    const url =
        'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws';

    let params = '';
    params += '&Key=' + encodeURIComponent(apiKey);
    params += '&Text=' + encodeURIComponent(searchText);
    params += '&IsMiddleware=' + encodeURIComponent(isMiddleware);
    params += '&Container=' + encodeURIComponent(container);
    params += '&Origin=' + encodeURIComponent(origin);
    params += '&Countries=' + encodeURIComponent(countries);
    params += '&Limit=' + encodeURIComponent(limit);
    params += '&Language=' + encodeURIComponent(language);

    // Make the call to get the address list based on inputted search text
    const response = await openHttp.post(url, params);

    // Return the response from Loqate
    return {
        status: true,
        data: response.data.Items
    };
};

export const retrieveAddress = async (id) => {
    const url = `https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws?Key=${apiKey}&Id=${id}`;
    const response = await openHttp.post(url);
    if (response) {
        const data = response.data.Items[0];
        if (data.Company !== '') {
            data.BuildingNumber = data.BuildingName;
            data.BuildingName = data.Company;
        }

        // Return the filtered address
        return {
            status: true,
            data: {
                buildingNo: data.BuildingNumber,
                buildingName: data.BuildingName,
                subBuilding: data.SubBuilding,
                city: data.City,
                country: data.CountryName,
                streetName: data.Street,
                postCode: data.PostalCode,
            }
        };
    }
};