<template>
  <base-screen>
    <template #main>
      <base-title>
        Now, tell us a bit about the place you are renting
      </base-title>
      <base-modal
        v-if="showDialog && isDesktop"
        @closeModal="showDialog = false"
      >
        <UpdateWarning
          @changeAddress="updateAddress"
          @cancel="showDialog = false"
        />
      </base-modal>

      <base-bottom-sheet
        v-if="showDialog && !isDesktop"
        @closeModal="showDialog = false"
      >
        <UpdateWarning
          @changeAddress="updateAddress"
          @cancel="showDialog = false"
        />
      </base-bottom-sheet>

      <div class="address-confirmation-form_block">
        <div class="header-2">
          Address of the property you are renting
        </div>
        <div class="m-t-5">
          <AddressCard
            type="current"
            customTitle="Rental Address"
            :address="currentAddress"
            @editAddress="showDialog = true"
          />
        </div>
      </div>

      <div class="address-confirmation-form_block_question">
        <div class="flex-row">
          <div class="header-2 m-r-2">
            When did you move in or are planning to move into this
            address?
          </div>

          <div class="popper-block">
            <Popper hover="true" :style="myStyles" placement="bottom-end">
              <BaseIcon icon="question_blue" size="md" />
              <template #content>
                If you are unsure, please give your best approximation
              </template>
            </Popper>
          </div>
        </div>
        <div class="m-t-4">
          <div class="flex-row date_row">
            <BaseInput
              :item="
                formInputs.find((el) => el.id == 'tenancyMonth')
              "
              @onInput="inputChanged"
            />
            <BaseInput
              ref="tenancyYear"
              :item="
                formInputs.find((el) => el.id == 'tenancyYear')
              "
              inputRef="inputTenancyYear"
              @onInput="inputChanged"
            />
          </div>
        </div>
        <base-error-message v-if="tenancyStartError" key="error">
          {{ tenancyStartError }}
        </base-error-message>
      </div>

      <div class="m-t-7 m-b-4">
        <div class="flex-row">
          <div class="header-2 m-r-2">
            What is the tenancy duration as per your contract in months?
          </div>

          <div class="popper-block">
            <Popper hover="true" :style="myStyles" placement="bottom-end">
              <BaseIcon icon="question_blue" size="md" />
              <template #content>
                If you are unsure, please give your best approximation
              </template>
            </Popper>
          </div>
        </div>
        <div class="m-t-4">
          <BaseInput
            ref="tenancyDuration"
            :item="formInputs.find((el) => el.id == 'numberMonths')"
            inputRef="inputTenancyDuration"
            @onInput="inputChanged"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <base-button-primary :disabled="!formIsValid" @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import AddressCard from '../../applicationData/address/AddressCard.vue';
import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import UpdateWarning from '../../applicationData/address/UpdateWarning.vue';
import moment from 'moment';
import BaseModal from '../../modal/BaseModal.vue';
import BaseBottomSheet from '../../modal/BaseBottomSheet.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
import Popper from 'vue3-popper';


export default {
    name: 'AddressConfirmationForm',
    components: {
        BaseScreen,
        AddressCard,
        BaseInput,
        BaseButtonPrimary,
        BaseBottomSheet,
        UpdateWarning,
        BaseTitle,
        BaseErrorMessage,
        BaseModal,
        BaseIcon,
        Popper,
    },
    props: {
        currentAddress: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showDialog: false,
            tenancyStartError: null,
            fullDate: null,
            webview: false,
            isDesktop: null,
            myStyles: {
        '--popper-theme-background-color': '#333333',
        '--popper-theme-background-color-hover': '#333333',
        '--popper-theme-text-color': '#ffffff',
        '--popper-theme-border-width': '0px',
        '--popper-theme-border-style': 'solid',
        '--popper-theme-border-radius': '8px',
        '--popper-theme-padding': '16px',
        '--popper-theme-box-shadow': '0 6px 30px -6px rgba(0, 0, 0, 0.25)',
      },
            formInputs: [
                {
                    id: 'tenancyMonth',
                    value: '',
                    type: 'number',
                    subtype: 'month',
                    label: 'Month',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
                {
                    id: 'tenancyYear',
                    value: '',
                    type: 'number',
                    subtype: 'year',
                    label: 'Year',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
                {
                    id: 'numberMonths',
                    value: '',
                    type: 'number',
                    subtype: 'numberMonths',
                    label: 'Number of Months',
                    validationRequired: true,
                    validationStatus: 'pending',
                },
            ],
            newAddress: null,
        };
    },

    computed: {
        formIsValid() {
            const invalid = this.formInputs.filter(
                (el) => el.validationStatus !== 'valid'
            );

            if (invalid.length !== 0) {
                return false;
            }

            const tenancyStartValid = this.validateTenancyStart();

            return tenancyStartValid;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.checkScreenWidth();
            window.addEventListener('resize', this.checkScreenWidth);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenWidth);
    },
    methods: {
        checkScreenWidth() {
            this.isDesktop = window.innerWidth >= 1024;
        },
        async inputChanged(id, value, status) {
 
          if(id !== 'numberMonths') {
            this.tenancyStartError = null;
          }
            const index = this.formInputs.findIndex((el) => el.id == id);

            this.formInputs[index].value = value;
            this.formInputs[index].validationStatus = status;

            if (id == 'tenancyMonth' && value.length == 2) {
                this.$refs.tenancyYear.$refs.inputTenancyYear.focus();
            }

            const valid =  this.validateTenancyStart()
            if (id == 'tenancyYear' && value.length == 4 && valid) {
                this.$refs.tenancyDuration.$refs.inputTenancyDuration.focus();
            }



        },

        async sendValue() {
            const applicationId = localStorage.getItem('applicationId');

            const applicationData = await this.$store.dispatch(
                'application/getApplicationRecord',
                { applicationId }
            );

            const index = this.formInputs.findIndex(
                (el) => el.id == 'numberMonths'
            );
            const data = {
                tenancyDetails: {
                    ...applicationData.tenancyDetails,
                    tenancyStartDate: this.fullDate,
                    tenancyDuration: +this.formInputs[index].value,
                },
            };
            this.$emit('sendForm', data);
        },

        validateTenancyStart() {
            const moveInMonth = this.formInputs.find(
                (el) => el.id == 'tenancyMonth'
            );
            const moveInYear = this.formInputs.find(
                (el) => el.id == 'tenancyYear'
            );

            if (
                moveInMonth?.value &&
                moveInMonth?.value.length > 1 &&
                moveInYear?.value &&
                moveInYear?.value.length > 3
            ) {
                const date = `01/${moveInMonth?.value}/${moveInYear?.value}`;

                const givenDate = moment(date, 'DD/MM/YYYY');
                const currentDate = moment();
                const diffMonths = currentDate.diff(givenDate, 'months');

                if (diffMonths < -12) {
                    const minDateString = moment()
                        .add(12, 'months')
                        .format('MM/YYYY');
                    this.tenancyStartError = `*To process and report your rent payments, the tenancy start date must be ${minDateString} or earlier.`;
                    return false;
                } else {
                    this.fullDate = `01/${moveInMonth?.value}/${moveInYear?.value}`;
                    return true;
                }

                // const formattedDate = moment(date, 'DD/MM/YYYY', true);

                // // Check if the tenancy started more than 2 months ago
                // const minDate = moment().subtract(2, 'months');
                // console.log('minDate', minDate);
                // const minDateFormatted = moment(minDate).set('date', 1);

                // const minDateString =
                //     moment(minDateFormatted).format('MM/YYYY');

                // const duration = moment.duration(
                //     formattedDate.diff(minDateFormatted)
                // );

                // const fullDays = duration.asDays();
                // console.log(fullDays);

                // if (fullDays <= 0) {
                //     this.fullDate = `01/${moveInMonth?.value}/${moveInYear?.value}`;
                //     return true;
                // } else {
                //     const fieldsValid =
                //         moveInMonth.validationStatus == 'valid' &&
                //         moveInYear.validationStatus == 'valid';

                //     if (fieldsValid) {
                //         this.tenancyStartError = `*To process and report your rent payments, the tenancy start date must be ${minDateString} or earlier.`;
                //     }
                // }
            }

            return false;
        },

        async updateAddress() {
            this.$store.dispatch(
                'navigation/updateScreen',
                'changeAddressScreen'
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';

.date_row {
    gap: 15px;
}

.address-confirmation-form_block_question {
    margin-top: 40px;
}
</style>
