<template>
  <div>
    <div class="manualAddressForm__group">
      <div>
        <BaseSelect
          :options="countryCodes"
          @selected="selectCountryCode"
          placeholder="Country"
          id="country"
          :defaultValue="countrySelectedByDefault"
        />
      </div>

      <div v-if="showAddressForm" class="m-t-2">
        <div
          class="manualAddressForm_item"
          v-for="(item, index) of formInputs.filter(
            (el) => el.inputGroup !== 'postcode'
          )"
          :key="'group' + index"
        >
          <BaseInput :item="item" @onInput="inputChanged" />
        </div>

        <div class="postocde_row flex-row m-t-2">
          <div
            v-for="(item, index) of formInputs.filter(
              (el) => el.inputGroup == 'postcode'
            )"
            :key="'group' + index"
          >
            <BaseInput
              :item="item"
              @onInput="inputChanged"
              noErrorMessage
              :inputRef="'input' + item.id"
              :ref="item.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '../../inputs/BaseInput.vue';
import countryCodes from '../../../json/alpha2codes.json';
import BaseSelect from '../../inputs/BaseSelect.vue';

export default {
  components: { BaseInput, BaseSelect },
  name: 'AddressIndia',
  data() {
    return {
      showAddressForm: false,
      countryCodes,
      formInputs: [
        {
          id: this.type + 'indiaFlat',
          value: '',
          type: 'text',
          label: 'Flat / Apartment Number',
          validationRequired: false,
        },
        {
          id: this.type + 'indiaStreet',
          value: '',
          type: 'text',
          label: 'Street Address*',
          validationRequired: true,
        },
        {
          id: this.type + 'indiaLocality',
          value: '',
          type: 'text',
          label: 'Locality/Area',
          validationRequired: false,
        },
        {
          id: this.type + 'indiaCity',
          value: '',
          type: 'text',
          label: 'City/Town/Village*',
          validationRequired: true,
        },
        {
          id: this.type + 'indiaState',
          value: '',
          type: 'text',
          label: 'State*',
          validationRequired: true,
        },
        {
          id: this.type + 'indiaPinCode',
          value: '',
          type: 'text',
          label: 'PIN Code*',
          validationRequired: true,
        },
      ],
      addressObj: {
        indiaFlat: '',
        indiaStreet: '',
        indiaLocality: '',
        indiaCity: '',
        indiaState: '',
        indiaPinCode: '',
        country: 'India',
      },
      country: null,
      customError: null,
    };
  },

  methods: {
    async inputChanged(id, value, status) {
      //   this.tenancyStartError = null;
      const index = this.formInputs.findIndex((el) => el.id == id);

      const addressGroupField = this.formInputs[index]?.inputGroup;

      if (addressGroupField) {
        this.customError = null;
      }

      this.formInputs[index].value = value;
      this.formInputs[index].validationStatus = status;
      const formattedId = id.replace(this.type, '');
      this.addressObj[formattedId] = value;
      this.formIsValid();
    },

    selectCountryCode(option) {
      if (option == 'IN') {
        this.showAddressForm = true;
      } else {
        this.showAddressForm = false;
      }
    },

    formIsValid() {
      const addressObj = this.addressObj;
      let invalidInputs = [];
      invalidInputs = this.formInputs.filter(
        (el) => el.validationRequired == true && el.validationStatus !== 'valid'
      );

      if (invalidInputs.length > 0) {
        this.$emit('updateAddress', addressObj, 'invalid');
        return false;
      }

      // if (
      //   !addressObj.subBuilding &&
      //   !addressObj.buildingName &&
      //   !addressObj.buildingNo &&
      //   !addressObj.streetName
      // ) {
      //   this.customError =
      //     '*Apartment name, building name, number or street is required';
      //   this.$emit('updateAddress', addressObj, 'invalid');
      //   return false;
      // }

      this.$emit('updateAddress', addressObj, 'valid');
    },
  },
};
</script>

<style lang="scss" scoped>
.manualAddressForm_item {
  margin-bottom: 8px;
}

.postocde_row {
  gap: 8px;
}

.slide-up-item-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-up-item-leave-active {
  transition: all 0.15s ease-in-out;
}

.slide-up-item-enter {
  transform: translateY(50%);
}
.slide-up-item-leave-to {
  opacity: 0;
}

.slide-up-item-enter-active {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-up-item-leave-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

// .manualAddressForm__group {
//   margin-bottom: 32px;
// }
</style>
