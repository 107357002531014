<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <base-subtitle>1. Upload your passport.</base-subtitle>
      <base-description>
        Please provide a copy of your passport or offer letter that
        clearly outlines your income.
      </base-description>
      <FileUploadInput id="passport" title="Passport" @onFileChange="onFileChange" />
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="!selectedFile"
        @clicked="uploadFile"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';
import BaseDescription from '../../layout/BaseDescription.vue';
import BaseScreen from '../../layout/BaseScreen.vue';
import FileUploadInput from '../../inputs/FileUploadInput.vue';

export default {
    name: 'JubioPassport',
    components: {
        BaseScreen,
        BaseSubtitle,
        BaseButtonPrimary,
        BaseDescription,
        FileUploadInput,
    },
    data() {
        return {
            selectedFile: null,
            eventData: null,
        };
    },

    methods: {
        onFileChange(event) {
            const file = event.target.files[0] || null;
            this.selectedFile = file;
            this.eventData = event;
        },

        async uploadFile() {
            // this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
            // this.$store.dispatch('navigation/startLoading');

            const userId = this.$store.getters['auth/userId'];
            const req = await this.$store.dispatch(
                'application/uploadFileToStorage',
                {
                    eventData: this.eventData,
                    docType: 'passport',
                    userId,
                }
            );

            if (!req.status) {
                this.handleError('Something went wrong while uploading the file. Please try again.');
                return;
            }

            const fileInfo = req.data;
            this.$emit(
                'updateForm',
                'jubioPassport',
                fileInfo
            );
        },
    

        handleError(response) {
            this.selectedFile = null;
            this.$store.dispatch('navigation/stopLoading');
            this.$store.dispatch('error/updatePopupErrorStatus', {
                status: true,
                message: response.friendlyMessage,
            });
        },
    },
};
</script>

<style scoped>
.file-path {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
}
</style>
