<template>
  <component
    :is="componentMap[activeScreen]"
    :key="activeScreen"
    @updateModule="updateModule"
  />
</template>

<script>
import PersonalDetails from '../screens/applicationData/PersonalDetails.vue';
import CountrySelect from '../screens/applicationData/CountrySelect.vue';
import { logFirebaseEvent } from '../../controllers/analytics/firebase.js';
import moduleMixin from '../../mixins/moduleMixin.js';

export default {
    name: 'ApplicationDataNTC',
    components: {
        PersonalDetails,
        CountrySelect,
    },
    mixins: [moduleMixin({ defaultScreen: 'personalDetails' })],
    data() {
        return {
            personalDetails: null,
            countryCode: null,
        };
    },

    computed: {
        componentMap() {
            return {
                personalDetails: PersonalDetails,
                countrySelect: CountrySelect,
            };
        },
    },

    created() {
        this.logApplicationStart();
        this.stopLoading();
    },

    methods: {
        logApplicationStart() {
            const product = localStorage.getItem('productName');
            const currentEvent = sessionStorage.getItem('app_event');

            // Check if the event does not exist or if it differs from the current product
            if (!currentEvent || currentEvent !== product) {
                sessionStorage.setItem('app_event', product);
                logFirebaseEvent('application_started', { product });
            }
        },
        updateModule(id, data) {
            // Set the data
            switch (id) {
                case 'personalDetails':
                    this.personalDetails = data;
                    break;
                case 'countrySelect':
                    this.countryCode = data;
                    return this.submitApplication();
                    break;
            }

            // Navigate to the next screen
            const screenTransitions = {
                personalDetails: 'countrySelect',
            };

            const nextScreen = screenTransitions[id];
            if (nextScreen) {
                this.navigateToNextScreen(nextScreen);
            }
        },

        async submitApplication() {
            this.startLoading();

            const applicationData = this.prepareApplicationData();

            // API call to create application
            const applicationResponse = await this.$store.dispatch(
                'application/createApplication',
                applicationData
            );
            if (!applicationResponse.status) {
                this.handleApplicationError(applicationResponse);
                return false;
            }

            this.modulePassed({
                event: 'application_created',
                page_title: 'applicationDataNTC',
            });
        },

        prepareApplicationData() {
            const product = localStorage.getItem('productName');
            const webview = localStorage.getItem('webview') === 'true';

            const data = {
                ...this.personalDetails,
                countryCode: this.countryCode,
                userAgent: navigator.userAgent,
                product,
                webview,
            };

            // Optional fields
            let leadInfo = localStorage.getItem('leadInfo');
            if (leadInfo) {
                leadInfo = JSON.parse(leadInfo);
                data.leadId = leadInfo;
            }

            const userId = this.$store.getters['auth/userId'];
            if (userId) {
                data.userId = userId;
            }
            return data;
        },

        handleApplicationError(response) {
            this.$store.dispatch('navigation/stopLoading');
            this.$store.dispatch('error/updatePopupErrorStatus', {
                status: true,
                message: response.friendlyMessage,
            });
        },
    },
};
</script>
