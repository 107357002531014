<template>
  <component
    :is="componentMap[activeScreen]"
    :key="activeScreen"
    v-bind="currentComponentProps"
    @updateForm="updateForm"
  />
</template>

<script>
import JubioIntro from '../screens/jubio/JubioIntro.vue';
import JubioPassport from '../screens/jubio/JubioPassport.vue';
import JubioSelfie from '../screens/jubio/JubioSelfie.vue';
import moduleMixin from '../../mixins/moduleMixin.js';
import { uploadDocument } from '../../controllers/documents.js';

export default {
    name: 'JubioModule',
    components: {
        JubioIntro,
        JubioPassport,
        JubioSelfie,
    },
    mixins: [moduleMixin({ defaultScreen: 'jubioIntro' })],
    data() {
        return {
            formDetails: {
                jubioPassport: null,
                jubioSelfie: null,
            },
        };
    },
    computed: {
        componentMap() {
            return {
                jubioIntro: JubioIntro,
                jubioPassport: JubioPassport,
                jubioSelfie: JubioSelfie,
            };
        },

        currentComponentProps() {
            return {};
        },
    },
    mounted() {
        this.$store.dispatch('navigation/stopLoading');
    },

    methods: {
        updateForm(id, data) {
            this.formDetails[id] = data;

            if (id === 'jubioSelfie') {
                return this.submitForm();
            }
            // Navigate to the next screen
            const screenTransitions = {
                jubioIntro: 'jubioPassport',
                jubioPassport: 'jubioSelfie',
            };

            const nextScreen = screenTransitions[id];
            if (nextScreen) {
                this.navigateToNextScreen(nextScreen);
            }
        },

        async submitForm() {
            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const payload = {
                userId,
                applicationId,
                stepToPassed: 'jubio',
                fileInfo: [
                    this.formDetails.jubioPassport,
                    this.formDetails.jubioSelfie,
                ],
            };
            const resp = await uploadDocument(payload);
            if (!resp.status) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'jubio_passed',
                page_title: 'jubio',
            });
        },
    },
};
</script>
