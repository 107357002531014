<template>
  <div :class="!noMargin ? 'm-b-6' : null">
    <h1 class="base-title">
      <slot></slot>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'BaseTitle',
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: -0.02em !important;
  text-align: left;
  color: $darkestGrey;
  margin-top: 0px;
}
</style>
