<template>
  <base-screen>
    <template #main>
      <base-title>
        Tell us a bit about your rent payments
      </base-title>
      <div>
        <h2 class="header-2">
          How much is your rent?
        </h2>
        <div class="m-t-4">
          <BaseInput
            shouldAutofocus
            prependSymbol="£"
            :item="formInputs.find((el) => el.id == 'rentPaymentAmount')"
            :min="minValue"
            @onInput="inputChanged"
          />
        </div>
      </div>
      <div class="m-t-6">
        <h2 class="header-2">
          How often do you pay your rent?
        </h2>
        <BaseSelect
          class="m-t-4"
          :options="options"
          :defaultValue="selectedByDefault"
          @selected="selectRentFrequency"
        />
      </div>
      <div class="m-t-6">
        <div class="flex-row">
          <div class="m-r-2">
            <h2 class="header-2">
              When is your next rent payment due?
            </h2>
          </div>

          <div class="popper-block">
            <Popper hover="true" :style="myStyles" placement="bottom-end">
              <BaseIcon icon="question_blue" size="md" />
              <template #content>
                If you are unsure, please give your best approximation
              </template>
            </Popper>
          </div>
        </div>
        <div class="transaction-details-form_row flex-row m-t-4">
          <div
            v-for="(item, index) of formInputs.filter(
              (el) => el.inputGroup == 'nextRentPayment'
            )"
            :key="'group' + index"
            class="transaction-details-form_item"
          >
            <BaseInput
              :ref="item.id"
              :item="item"
              noErrorMessage
              :inputRef="'input' + item.id"
              @onInput="inputChanged"
            />
          </div>
        </div>
        <base-error-message v-if="tenancyStartError">
          {{ tenancyStartError }}
        </base-error-message>
      </div>
    </template>
    <template #footer>
      <base-button-primary
        black
        :disabled="!formIsValid"
        class="m-t-5"
        @clicked="sendValue"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseScreen from '../../layout/BaseScreen.vue';
import BaseErrorMessage from '../../inputs/BaseErrorMessage.vue';
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseTitle from '../../layout/BaseTitle.vue';
import BaseIcon from '../../assets/BaseIcon.vue';
import BaseInput from '../../inputs/BaseInput.vue';

import moment from 'moment';
import BaseSelect from '../../inputs/BaseSelect.vue';
import Popper from 'vue3-popper';

export default {
  name: 'TransactionDetailsForm',
  components: {
    BaseInput,
    BaseTitle,
    BaseButtonPrimary,
    BaseErrorMessage,
    BaseScreen,
    BaseSelect,
    BaseIcon,
    Popper,
  },

  data() {
    return {
      showDialog: false,
      tenancyStartError: null,
      fullDate: null,
      formInputs: [
        {
          id: 'rentPaymentAmount',
          value: '',
          type: 'number',
          subtype: 'rentPaymentAmount',
          label: '',
          validationRequired: true,
          validationStatus: 'pending',
        },

        {
          id: 'rentNextPaymentDay',
          value: '',
          type: 'number',
          subtype: 'day',
          label: 'Day',
          validationRequired: true,
          validationStatus: 'pending',
          inputGroup: 'nextRentPayment',
        },

        {
          id: 'rentNextPaymentMonth',
          value: '',
          type: 'number',
          subtype: 'month',
          label: 'Month',
          validationRequired: true,
          validationStatus: 'pending',
          inputGroup: 'nextRentPayment',
        },

        {
          id: 'rentNextPaymentYear',
          value: '',
          type: 'number',
          subtype: 'year',
          label: 'Year',
          validationRequired: true,
          validationStatus: 'pending',
          inputGroup: 'nextRentPayment',
        },
      ],
      options: [
        { label: 'Monthly', title: 'Monthly',  value: 'monthly' },
        { label: 'Weekly', title: 'Weekly',  value: 'weekly' },
        { label: 'Fortnightly', title: 'Fortnightly', value: 'fortnightly' },
      ],
      rentPaymentFrequency: null,
      selectedByDefault: null,
      fullNextPaymentDate: null,
      myStyles: {
        '--popper-theme-background-color': '#333333',
        '--popper-theme-background-color-hover': '#333333',
        '--popper-theme-text-color': '#ffffff',
        '--popper-theme-border-width': '0px',
        '--popper-theme-border-style': 'solid',
        '--popper-theme-border-radius': '8px',
        '--popper-theme-padding': '16px',
        '--popper-theme-box-shadow': '0 6px 30px -6px rgba(0, 0, 0, 0.25)',
      },
    };
  },

  computed: {
    minValue() {
      if(this.rentPaymentFrequency == 'weekly') {
        return 15;
      } else if(this.rentPaymentFrequency == 'fortnightly') {
        return 30;
      } else {
        return 60;
      }
    },
    formIsValid() {
      const invalid = this.formInputs.filter(
        (el) => el.validationStatus !== 'valid'
      );

      if (invalid.length !== 0) {
        return false;
      }

      if (!this.selectRentFrequency || !this.fullNextPaymentDate) {
        return false;
      }

      const tenancyStartValid = this.validateNextPaymentDate();
      return tenancyStartValid;
    },
  },
  created() {
    this.$store.dispatch('application/updateApplicationStore', {
      rentPaymentAmount: '',
    });

    const applicationData = this.$store.getters['application/applicationData'];

    if (applicationData) {
      const rentPaymentFrequency = applicationData.rentPaymentFrequency;
      if (rentPaymentFrequency) {
        this.selectedByDefault = rentPaymentFrequency;
        this.rentPaymentFrequency = this.options[0].value;
      }
    }


    this.$store.dispatch('application/updateApplicationStore', {
      rentPaymentFrequency: '',
    });
    this.selectedByDefault = this.options[0];
    this.rentPaymentFrequency = this.options[0].value;
    this.formInputs[0].value = ''
  },

  

  methods: {
    async inputChanged(id, value, status) {
      this.tenancyStartError = null;
      const index = this.formInputs.findIndex((el) => el.id == id);
      this.formInputs[index].value = value;
      this.formInputs[index].validationStatus = status;


      if (id == 'rentNextPaymentDay' && value.length == 2) {
        if (this.$refs.rentNextPaymentMonth && this.$refs.rentNextPaymentMonth[0]) {
          this.$refs.rentNextPaymentMonth[0].$refs.inputrentNextPaymentMonth.focus();
        }
      }


      if (id == 'rentNextPaymentMonth' && value.length == 2) {
        if (this.$refs.rentNextPaymentYear && this.$refs.rentNextPaymentYear[0]) {
          this.$refs.rentNextPaymentYear[0].$refs.inputrentNextPaymentYear.focus();
        }
      }

  this.validateNextPaymentDate();
},


    sendValue() {
      const index = this.formInputs.findIndex(
        (el) => el.id == 'rentPaymentAmount'
      );

      const data = {
        // rentId: ,
        // obAccountId: ,
        rentAmount: +this.formInputs[index].value,
        rentFrequency: this.rentPaymentFrequency,
        rentPaymentDueDate: this.fullNextPaymentDate,
      };

      this.$emit('updateForm', 'transactionDetailsForm', data);
    },

    validateNextPaymentDate() {
      this.fullNextPaymentDate = null;
      const day = this.formInputs.find((el) => el.id == 'rentNextPaymentDay');
      const month = this.formInputs.find(
        (el) => el.id == 'rentNextPaymentMonth'
      );
      const year = this.formInputs.find((el) => el.id == 'rentNextPaymentYear');

      if (
        day?.value &&
        day?.value.length > 1 &&
        month?.value &&
        month?.value.length > 1 &&
        year?.value &&
        year?.value.length > 3
      ) {
        const date = `${day?.value}/${month?.value}/${year?.value}`;
        const formattedDate = moment(date, 'DD/MM/YYYY', true);
        if (formattedDate) {
          this.fullNextPaymentDate = date;
        }
        const now = moment();
        const duration = moment.duration(now.diff(formattedDate));
        const durationInDays = duration.asDays();

        if (durationInDays < 1) {
          this.fullDate = date;
          return true;
        } else {
          const fieldsValid =
            day.validationStatus == 'valid' &&
            month.validationStatus == 'valid' &&
            year.validationStatus == 'valid';

          if (fieldsValid) {
            this.tenancyStartError = '*Date cannot be in the past';
          }
        }
      }

      return false;
    },
    selectRentFrequency(option) {
   
      if (option) {
      this.$store.dispatch('application/updateApplicationStore', {
        rentPaymentFrequency: option,
      });

      this.rentPaymentFrequency = option;
    }
    },

    updateAddress() {
      // redirect to app
      window.UpdateAddress.postMessage('Success');
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/app.scss';

.date_row {
  gap: 15px;
}

.address-confirmation-form_block_question {
  margin-top: 40px;
}

.transaction-details-form_row {
  gap: 10px;
}
</style>
