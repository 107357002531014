<template>
  <ChooseBankAccount
    v-if="activeScreen == 'chooseBankAccount' && !isLoading"
    key="chooseBankAccount"
    noConfirmation
    :bankAccounts="accountsList"
    :selectedId="selectedAccountId"
    @onItemSelect="onAccountSelect"
    @addNewAccount="addNewAccount"
    @updateForm="updateForm"
  />
  <TransactionsListScreen
    v-else-if="activeScreen == 'transactionsList' && !isLoading"
    key="transactionsList"
    :transactionsList="transactionsList"
    :selectedBankName="selectedBankName"
    :showManualInputForm="showManualInputForm"
    @changeAccount="changeAccount"
    @addTransactionManually="addTransactionManually"
    @updateForm="updateForm"
  />
  <TransactionDetailsForm
    v-else-if="activeScreen == 'transactionDetailsForm' && !isLoading"
    key="transactionDetailsForm"
    @updateForm="updateForm"
  />
  <TransactionDetails
    v-else-if="activeScreen == 'transactionDetails'"
    key="transactionDetails"
    :amount="rentPaymentAmount"
    :generalRecurrenceType="rentFrequency"
    :showBackToTransactionsOption="showBackToTransactionsOption"
    :showManualInputForm="showManualInputForm"
    @updateForm="updateForm"
    @goBack="goBack"
  />
</template>

<script>
import TransactionDetails from '../screens/rentReportingTransaction/TransactionDetails.vue';
import TransactionsListScreen from '../screens/rentReportingTransaction/TransactionsListScreen.vue';
import _ from 'lodash';
import { addNewAccount, bankAccountsList } from '../../controllers/openBanking';
import ChooseBankAccount from '../screens/rentReportingTransaction/ChooseBankAccount.vue';
import TransactionDetailsForm from '../screens/rentReportingTransaction/TransactionDetailsForm.vue';
import moment from 'moment';
import { getTransactions } from '../../controllers/decision-engine';
import { getBankAccounts } from '../../controllers/truelayer';

const manualTransactionDetails = {
    accountId: null,
    generalRecurrenceType: null,
    manualAmount: null,
    manualPaymentDate: null,
};

const autoTransactionDetails = {
    rentStreamsBlockDocId: null,
    streamId: null,
};
export default {
    name: 'DirectDebitSetup',
    components: {
        ChooseBankAccount,
        TransactionsListScreen,
        TransactionDetails,
        TransactionDetailsForm,
    },
    data() {
        return {
            rentTransactionDetails: {},
            tenancyStartDate: null,
            jointTenancyContribution: null,
            tenancyType: null,
            isLoading: true,
            accountsList: [],
            bankAccounts: [],
            transactionsList: [],
            rentStreamsBlockDocId: null,
            electedAccountId: null,
            selectedTransaction: null,
            transactionDetails: {},
            showManualInputForm: true,
            showBackToTransactionsOption: true,
            selectedAccountId: null,
            rentFrequency: null,
        };
    },
    computed: {
        activeScreen() {
            const activeScreen = this.$store.getters['navigation/activeScreen'];
            if (!activeScreen) {
                return 'chooseBankAccount';
            } else {
                return activeScreen;
            }
        },
        rentPaymentAmount() {
            return (
                this.selectedTransaction?.maxAmount ||
                this.rentTransactionDetails?.manualAmount ||
                null
            );
        },
        animationMode() {
            return this.$store.getters['navigation/animationMode'];
        },
    },

    async created() {
        const userId = this.$store.getters['auth/userId'];

        if (!userId) {
            this.$router.push('/error');
            return false;
        }

        const bankAccountsReq = await getBankAccounts({ userId });

        if (!bankAccountsReq.status) {
            this.$router.push('/error');
            return false;
        }

        const bankAccounts = bankAccountsReq.data;

        const filteredBankAccounts = bankAccounts.filter((el) => {
            let idVerified = el.hasOwnProperty('idVerified')
                ? el.idVerified
                : el.ebavResult == 'pass' || el.nameMatch;
            return (
                el.removed !== true && idVerified && el.consentStatus == 'valid'
            );
        });

        const accountsList = [];

        if (filteredBankAccounts.length > 0) {
            filteredBankAccounts.map((el) => {
                let maskedAccountNumber = null;

                if (el?.account_number?.number) {
                    maskedAccountNumber = `****${el.account_number.number.slice(
                        -4
                    )}`;
                } else if (el.display_name) {
                    maskedAccountNumber = el.display_name;
                }

                const updatedAccount = {
                    ...el,
                    accountName: el.provider.display_name,
                    maskedAccountNumber,
                };

                return accountsList.push(updatedAccount);
            });
        }

        this.accountsList = accountsList;

        setTimeout(() => {
            this.isLoading = false;
        }, 200);

        this.$store.dispatch('navigation/stopLoading');
    },

    methods: {
        async updateForm(id, data) {
            const userId = this.$store.getters['auth/userId'];
            switch (id) {
                case 'chooseBankAccount':
                    this.$store.dispatch(
                        'navigation/updateLoaderAnimationText',
                        'Checking your rent payments...'
                    );
                    this.$store.dispatch('navigation/startLoading');

                    this.onAccountSelect(data);

                    await this.getTransactions(data);
                    break;

                case 'transactionsList':
                    {
                        const transaction = this.transactionsList.find(
                            (el) => el.id == data
                        );

                        this.rentFrequency = transaction.frequency;

                        this.selectedTransaction = transaction;
                        this.showBackToTransactionsOption = true;

                        this.rentTransactionDetails = {
                            ...autoTransactionDetails,
                            rentStreamsBlockDocId: this.rentStreamsBlockDocId,
                            streamId: transaction.id,
                        };
                        this.transactionDetails = transaction;
                        // this.transactionDetails.rentStreamsBlockDocId =
                        //     this.rentStreamsBlockDocId;

                        this.transactionDetails.streamId = transaction.id;
                        this.$store.dispatch(
                            'navigation/updateScreen',
                            'transactionDetails'
                        );
                    }
                    break;
                case 'transactionDetailsForm':
                    this.rentFrequency = data.rentFrequency;
                    {
                        this.rentTransactionDetails = {
                            ...this.manualTransactionDetails,
                            accountId: `${this.selectedAccountId}-${userId}`,
                            generalRecurrenceType: data.rentFrequency,
                            manualAmount: data.rentAmount,
                            manualPaymentDate: data.rentPaymentDueDate,
                        };

                        // this.selectedTransaction = null;
                        // this.transactionDetails.rentStreamsBlockDocId = null;
                        // this.manualTransactionDetails = data;
                        // this.showBackToTransactionsOption = false;

                        // this.rentTransactionDetails = {
                        //     rentStreamsBlockDocId: null,
                        //     id: null,
                        //     minAmount: null,
                        //     maxAmount: data.rentAmount,
                        //     description: null,
                        //     image: null,
                        //     frequency: data.rentFrequency,
                        //     nextPaymentDueDate: data.rentPaymentDueDate,
                        // };

                        this.$store.dispatch(
                            'navigation/updateScreen',
                            'transactionDetails'
                        );
                    }
                    break;
                case 'transactionDetails': {
                    this.tenancyType = data.tenancyType;

                    if (data.jointTenancyContribution) {
                        this.jointTenancyContribution =
                            data.jointTenancyContribution;
                    }
                    this.sendForm(data);
                }
            }

            // if (id == 'chooseBankAccount') {
            //   this.$store.dispatch(
            //     'loadingTextUpdate',
            //     'Checking your rent payments...'
            //   );
            //   this.isLoading = true;

            //   this.getTransactions();
            // } else if (id == 'transactionsList') {
            //   const transaction = this.transactionsList.find((el) => el.id == data);

            //   this.selectedTransaction = transaction;
            //   this.activeScreen = 'transactionDetails';
            // } else if (id == 'transactionDetails') {
            //   this.sendForm(data);
            // } else if (id == 'transactionDetailsForm') {
            //   this.manualTransactionDetails = data;
            //   this.activeScreen = 'transactionDetails';
            // }
        },
        onAccountSelect(id) {
            this.selectedAccountId = id;
            const bankAccount = this.accountsList.find(
                (el) => el.account_id == id
            );
            this.selectedBankName = bankAccount.accountName;
        },

        manualRoute() {
            this.transactionsList = [];
            this.$store.dispatch(
                'navigation/updateScreen',
                'transactionDetailsForm'
            );

            this.$store.dispatch('navigation/stopLoading');
            return false;
        },

        async getTransactions(accountId) {
            const applicationId = localStorage.getItem('applicationId');
            const applicationData = await this.$store.dispatch(
                'application/getApplicationRecord',
                { applicationId }
            );

            if (applicationData.data.forcedManualRoute) {
                return this.manualRoute();
            }

            // We want to check if the tenancyStartDate is less than 60 days from today and if so, we want to skip the transactionsList and go straight to the manual transactionDetailsForm
            const givenDate = moment(
                applicationData.data.tenancyDetails.tenancyStartDate,
                'DD/MM/YYYY'
            );
            const currentDate = moment();
            const diffDays = currentDate.diff(givenDate, 'days');

            if (diffDays < 60) {
                return this.manualRoute();
            }
            const userId = this.$store.getters['auth/userId'];

            if (!userId) {
                this.$router.push('/error');
                return false;
            }

            const data = {
                userId,
                applicationId,
                accountId,
            };

            const transactionsReq = await getTransactions(data);

            if (!transactionsReq.status) {
                this.$router.push('/error');
                return false;
            }

            this.transactionsList = transactionsReq.data?.rentStreams ?? [];
            // this.rentStreamsBlockDocId =
            //     transactionsReq.data.rentStreamsBlockDocId;

            // this.rentReportingDetails = {
            //     ...autoTransactionDetails,
            //     rentStreamsBlockDocId: transactionsReq.data.rentStreamsBlockDocId,
            // };

            this.rentStreamsBlockDocId =
                transactionsReq.data.rentStreamsBlockDocId;

            if (this.transactionsList.length > 0) {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    'transactionsList'
                );
            } else {
                this.$store.dispatch(
                    'navigation/updateScreen',
                    'transactionDetailsForm'
                );
            }

            this.$store.dispatch('navigation/stopLoading');
        },

        changeAccount() {
            this.$store.commit('navigation/UPDATE_ANIMATION_MODE', 'reverse');
            this.$store.dispatch(
                'navigation/updateScreen',
                'chooseBankAccount'
            );
        },

        goBack() {
            this.$store.dispatch('navigation/updateScreen', 'transactionsList');
        },

        async sendForm(tenancyInfo) {
            // this.$store.dispatch('navigation/changeLoaderOpacity', 0.8);
            this.$store.dispatch('navigation/startLoading');

            const applicationId = localStorage.getItem('applicationId');

            const applicationData = await this.$store.dispatch(
                'application/getApplicationRecord',
                { applicationId }
            );

            const payload = {
                tenancyDetails: {
                    ...applicationData.data.tenancyDetails,
                    tenancyType: this.tenancyType,
                },

                rentTransactionDetails: this.rentTransactionDetails,
            };

            if (this.tenancyType === 'joint' && this.jointTenancyContribution) {
                payload.tenancyDetails.jointTenancyContribution =
                    this.jointTenancyContribution;
            }

            // data.rentTransactionDetails.obAccountId = this.selectedAccountId;

            // if (this.selectedTransaction) {
            //   console.log(this.selectedTransaction);
            // }
            // this.$store.dispatch(
            //     'navigation/buttonPrimaryInLoadingState',
            //     true
            // );

            const updateApplicationReq = await this.$store.dispatch(
                'application/updateApplication',
                { data: payload, step: 'rentReportingTransaction' }
            );

            if (!updateApplicationReq.status) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'rr_transaction_selected',
                page_title: 'rentReportingTransaction',
            });
            this.$store.dispatch('navigation/requestFinish');
        },

        async addNewAccount() {
            this.selectedTransaction = null;
            this.transactionDetails.rentStreamsBlockDocId = null;

            const userId = this.$store.getters['auth/userId'];
            const applicationId = localStorage.getItem('applicationId');

            if (!userId || !applicationId) {
                this.$router.push('/error');
                return false;
            }

            this.isLoading = true;
            const addNewReq = await addNewAccount({ applicationId, userId });

            if (!addNewReq.status) {
                this.$router.push('/error');
                return false;
            }

            this.$router.go();
        },
        addTransactionManually() {
            this.selectedTransaction = null;
            this.transactionDetails.rentStreamsBlockDocId = null;
            this.$store.dispatch(
                'navigation/updateScreen',
                'transactionDetailsForm'
            );
        },
    },
};
</script>