<template>
  <base-screen noSlideAnimation>
    <template #main>
      <base-title noMargin>Who do you bank with?</base-title>
      <div class="p-t-2 p-b-6">
        <p class="">Please select your bank from the list below.</p>
      </div>
      <!-- <div key="1" :data-index="1">
          <div class="errorMessage mt-5" v-if="errorText">
            It looks like the bank account you connected may not be your main
            bank account. If you have another one, please connect it now
          </div>
        </div> -->

      <TransitionGroup tag="div" name="accordion" class="transitionComponent">
        <div v-if="popularBanksVisible" key="popularBanks" class="item">
          <PopularAccountsGrid @selectBank="selectBank" />
          <div style="height: 100px"></div>
        </div>
        <div class="item" key="listOfBanks">
          <ListOfBanks @selectBank="selectBank" @searchInput="searchInput" />
        </div>
      </TransitionGroup>
      <div key="3" :data-index="3">
        <div class="noBankOption">
          <v-img
            src="../../assets/icons/no-bank-found.svg"
            contain
            style="width: 120px; margin-left: auto; margin-right: auto"
          />
          <p class="mt-4 bold-text">Couldn't find your bank in the list?</p>
          <p class="mt-4">
            A few banks still don't support Open Banking, so we can't connect to
            them
          </p>
        </div>
      </div>
    </template>
  </base-screen>
</template>

//
<script>
import BaseTitle from '../layout/BaseTitle.vue';
import PopularAccountsGrid from '../ob/listOfBanks/popularAccountsGrid.vue';
import ListOfBanks from '../ob/listOfBanks/listOfBanks.vue';
// import BaseTitle from '../layout/BaseTitle.vue';
import BaseScreen from '../layout/BaseScreen.vue';

export default {
  components: {
    ListOfBanks,
    PopularAccountsGrid,
    // BaseTitle,
    BaseScreen,
    BaseTitle,
  },
  name: 'ChooseBank',
  data() {
    return {
      searchText: '',
    };
  },

  computed: {
    errorText() {
      const product = localStorage.getItem('productName');
      const stepPassedBefore =
        this.$store.getters['navigation/stepPassedBefore'];
      const OBSummaryPassedBefore =
        this.$store.getters['navigation/OBSummaryPassedBefore'];

      if (product == 'pillarCard') {
        if (stepPassedBefore && !OBSummaryPassedBefore) return true;
      }

      return false;
    },

    popularBanksVisible() {
      return !this.searchText;
    },
  },
  methods: {
    selectBank(bankcode) {
      this.$emit('selectBank', bankcode);
    },
    searchInput(text) {
      this.searchText = text;
    },
  },
};
</script>

<style scoped>
.errorMessage {
  padding-top: 0px;
  margin: 10px 0;
  border: 1px solid #a5325e;
  width: 100% !important;
  border-radius: 6px;
  padding: 13px 14px 11px;
  background: #fff6f6;
  border-style: dashed;
  top: 15px !important;
  color: #a5325e !important;
  font-size: 14px;
}

.noBankOption {
  height: 290px;
  background: url('../../assets/bg/diagonal.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
}

.bold-text {
  font-size: 16px;
  font-weight: 500;
}

h1.title {
  font-family: Poppins;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: -0.02em !important;
  text-align: center !important;
}
</style>
