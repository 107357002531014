<template>
    <base-screen contentCentered customTransition="fade">
        <template #main>
            <div class="content">
                <div class="image">
                    <BaseImage image="cis-search" size="200" />
                </div>

                <h1 class="header-1 text-centered m-v-6">
                    Identity documents and passport.
                </h1>
                <p class="text-centered">
                    {{ description }}
                </p>
                <div class="jumio_intro_list">
                    <base-list-item
                        :icon="item.icon"
                        :title="item.title"
                        v-for="(item, index) in listItems"
                        :key="item.icon"
                        :animationDelay="!index ? 0.3 : 0.3 + index * 0.1"
                        :customStyle="listItemStyles"
                    >
                        <p>
                            {{ item.description }}
                        </p>
                    </base-list-item>
                </div>
            </div>
        </template>
        <template #footer>
            <base-button-primary black @clicked="sendValue">
                Okay, I'm ready
            </base-button-primary>
            <div class="footer-text">
                <p>
                    By clicking on the 'Transfer my credit history to UK' button
                    above, I understand that I am sending my CRIF India credit
                    history to Pillar for processing according to its Privacy
                    Policy and Terms of Service.
                </p>
            </div>
        </template>
    </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../../components/buttons/BaseButtonPrimary.vue';
import BaseImage from '../../../components/assets/BaseImage.vue';
import BaseScreen from '../../../components/layout/BaseScreen.vue';
import BaseListItem from '../../../components/list/ListItem.vue';

export default {
    name: 'JumioIntro',
    components: {
        BaseScreen,
        BaseImage,
        BaseButtonPrimary,
        BaseListItem,
    },
    data() {
        return {
            listItems: [
                {
                    icon: 'id_doc_passport',
                    title: "You'll need a passport, driving license or ID card to complete this process",
                },
                {
                    icon: 'id_doc',
                    title: 'Make sure your face is not obstructed and that the image is not blurry',
                },
                {
                    icon: 'settings',
                    title: 'Avoid taking pictures under bright lights to avoid glare',
                },
            ],
            listItemStyles: {
                fontSize: '12px !important',
                fontWeight: '500',
                lineHeight: '16px',
            },
        };
    },

    methods: {
        sendValue() {
            this.$emit('updateForm', 'jubioIntro');
        },
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-weight: 400;
}

.content {
    padding: 0 24px 24px 24px;
}
.image {
    display: flex;
    justify-content: center;
}

.header-1 {
    font-weight: 400;
}

.footer-text {
    margin-top: 26px;

    p {
        color: #727776;
        font-weight: 500;
        font-size: 12px;
        text-align: center;

        span {
            color: black;
            font-weight: 600;
            font-size: 12px;
        }
    }
}

.jumio_intro_list {
    margin-top: 67px;
    margin-bottom: 36px;
}
</style>
