<template>
  <button
    type="button"
    :class="size ? `button-primary ${size}` : 'button-primary'"
    :disabled="disabled"
    @click="addNewAccount"
  >
    <div class="flex-row center">
      <BaseIcon icon="plus_circle" isButton />
      <div :style="{ alignSelf: 'center' }" class="m-l-2">
        Add New Account
      </div>
    </div>
  </button>
</template>

<script>
import { resetTLSteps } from '../../controllers/truelayer.js';
import BaseIcon from '../assets/BaseIcon.vue';

export default {
    name: 'BaseButtonPrimary',
    components: { BaseIcon },
    props: {
        size: {
            type: String,
            default: 'lg',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
        },
    },
    computed: {
        getHeight() {
            switch (this.size) {
                case 'lg':
                    return '60px';
                case 'md':
                    return '48px';
                case 'sm':
                    return '36px';
                default:
                    return '60px';
            }
        },
    },
    methods: {
        async addNewAccount() {
            this.$store.dispatch('navigation/startLoading');

            const applicationId = localStorage.getItem('applicationId');
            const payload = {
                applicationId};
            const paymentProgressReq = await resetTLSteps(payload);

            if (!paymentProgressReq) {
                window.location.href = `${redirectUrl}error`;
                return false;
            }
            
            this.$router.go()
        },
        // click() {
        //   this.$emit('clicked');
        // },
    },
};
</script>

<style lang="scss" scoped>
.button-primary {
    height: 60px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: $lightestGrey;
    color: $darkGrey;
    transition: 0.3s;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    cursor: pointer;

    &.md {
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.01em;
    }

    &.sm {
        height: 36px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.02em;
    }

    i {
        color: white;
    }

    //   &:hover {
    //     background-color: $black;
    //   }

    &:disabled {
        background-color: $lightGrey;
        cursor: not-allowed;
        pointer-events: none;
    }

    //   &:active {
    //     background-color: $black;
    //     box-shadow: 0px 0px 8px rgba(36, 36, 36, 0.6);
    //   }
}
</style>
