<template>
  <base-screen noLeaveAnimation>
    <template #main>
      <div>
        <base-subtitle>
          {{ index }}. How much do you spend monthly on regular expenses?
        </base-subtitle>
        <base-description>
          This might include mortgage/rent, monthly bills such as council,
          commitments, transportation, retail and food/drink.
        </base-description>
        <BaseInput
          prependSymbol="£"
          :item="formInputs.find((el) => el.id == 'monthlyExpenses')"
          autofocus
          @onInput="inputChanged"
        />
      </div>
    </template>
    <template #footer>
      <base-button-primary black :disabled="!formIsValid" @clicked="sendValue">
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseCheckbox from '../../inputs/BaseCheckbox.vue';
import BaseDescription from '../../layout/BaseDescription.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';
export default {
  name: 'ExpensesForm',
  components: {
    BaseScreen,
    BaseSubtitle,
    BaseInput,
    BaseDescription,
    BaseButtonPrimary,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formInputs: [
        {
          id: 'monthlyExpenses',
          value: '',
          type: 'number',
          subtype: 'whole',
          label: '',
          validationRequired: true,
          validationStatus: 'pending',
        }
      ],
    };
  },

  computed: {
    formIsValid() {
      const invalid = this.formInputs.filter(
        (el) => el.validationStatus !== 'valid'
      );

      return invalid.length == 0;
    },
  },

  methods: {
    inputChanged(id, value, status) {
      const fieldIndex = this.formInputs.findIndex((el) => el.id == id);

      if (fieldIndex != -1) {
        this.formInputs[fieldIndex].value = value;
        this.formInputs[fieldIndex].validationStatus = status;
      }
    },

    sendValue() {
      this.$emit('updateForm', 'expenses', this.formInputs[0].value);
    },
  },
};
</script>
