<template>
  <component
    :is="componentMap[activeScreen]"
    :key="activeScreen"
    v-bind="currentComponentProps"
    @updateForm="updateForm"
  />
</template>

<script>
import CompanyName from '../screens/employmentDetails/CompanyName.vue';
import EmploymentStatus from '../screens/employmentDetails/EmploymentStatus.vue';
import BaseIntroScreen from '../layout/BaseIntroScreen.vue';
import moduleMixin from '../../mixins/moduleMixin.js';
import EmploymentStartDate from '../screens/employmentAndExpenditureNTC/EmploymentStartDate.vue';
import EmploymentContract from '../screens/employmentAndExpenditureNTC/EmploymentContract.vue';
import { uploadDocument } from '../../controllers/documents.js';


export default {
    name: 'EmploymentAndExpenditureNTC',
    components: {
        EmploymentStatus,
        CompanyName,
        BaseIntroScreen,
        EmploymentStartDate,
        EmploymentContract,
    },
    mixins: [moduleMixin({ defaultScreen: 'introScreen' })],
    data() {
        return {
            formDetails: {
                employmentStatus: null,
                employerName: null,
                employmentStartDate: null,
                employmentContract: null,
            },
        };
    },

    computed: {
        componentMap() {
            return {
                introScreen: BaseIntroScreen,
                employmentStatus: EmploymentStatus,
                companyName: CompanyName,
                employmentStartDate: EmploymentStartDate,
                employmentContract: EmploymentContract,
            };
        },
        isEmployed() {
            const employedStatuses = [
                'employedFullTime',
                'employedPartTime',
                'selfEmployed',
            ];

            return employedStatuses.includes(this.formDetails.employmentStatus);
        },

        currentComponentProps() {
            switch (this.activeScreen) {
                case 'introScreen':
                    return {
                        description:
                            'To complete your application, please provide a bit of information about your income and expenses.',
                        title: 'Employment and Expenditure',
                    };
                case 'incomeScreen':
                    return { index: this.isEmployed ? 3 : 2 };
                case 'employmentStartDate':
                    return { index: this.isEmployed ? 3 : 2 };
                case 'employmentContract':
                    return {
                        index: this.isEmployed ? 4 : 3
                    };
                default:
                    return {};
            }
        },
    },
    mounted() {
        this.stopLoading();
    },

    methods: {
        updateForm(id, data) {
            this.formDetails[id] = data;
            const updatedData = { [id]: data };
            this.$store.dispatch(
                'application/updateApplicationData',
                updatedData
            );

            if (id === 'employmentContract') {
                return this.submitForm();
            }

            // Navigate to the next screen
            const screenTransitions = {
                introScreen: 'employmentStatus',
                employmentStatus: this.isEmployed
                    ? 'companyName'
                    : 'employmentStartDate',
                employerName: 'employmentStartDate',
                employmentStartDate: 'employmentContract',
            };

            const nextScreen = screenTransitions[id];
            if (nextScreen) {
                this.navigateToNextScreen(nextScreen);
            }
        },

        async submitForm() {
            const applicationId = localStorage.getItem('applicationId');
            const userId = this.$store.getters['auth/userId'];

            const payload = {
                userId,
                applicationId,
                stepToPassed: 'employmentAndExpenditureNTC',
                fileInfo: this.formDetails.employmentContract,
                applicationData: {
                    employmentStatus: this.formDetails.employmentStatus,
                    employerName: this.formDetails.employerName || null,
                    employmentStartDate: this.formDetails.employmentStartDate,
                },
            };
            const resp = await uploadDocument(payload);
            if (!resp.status) {
                this.$router.push('/error');
                return false;
            }

            this.$store.dispatch('navigation/modulePassed', {
                event: 'employment_contract_uploaded',
                page_title: 'employmentAndExpenditureNTC',
            });
        },

    }
};
</script>
