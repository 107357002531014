<template>
  <base-screen>
    <template #main>
      <base-subtitle>
        Enter your Indian Permanent Account Number (PAN)</base-subtitle
      >
      <BaseInput
        class="m-t-5"
        :item="item"
        @onInput="inputChanged"
        shouldAutofocus
      />
    </template>
    <template #footer>
      <base-button-primary
        @clicked="sendValue"
        black
        :disabled="item.validationStatus !== 'valid'"
      >
        Continue
      </base-button-primary>
    </template>
  </base-screen>
</template>

<script>
import BaseButtonPrimary from '../../buttons/BaseButtonPrimary.vue';
import BaseInput from '../../inputs/BaseInput.vue';
import BaseSubtitle from '../../layout/BaseSubtitle.vue';

import BaseScreen from '../../layout/BaseScreen.vue';

export default {
  components: {
    BaseScreen,
    BaseInput,
    BaseSubtitle,
    BaseButtonPrimary,
  },
  name: 'PANScreen',
  data() {
    return {
      item: {
        id: 'PAN',
        value: '',
        type: 'PANCode',
        label: '',
        validationRequired: true,
        validationStatus: 'pending',
      },
    };
  },

  methods: {
    inputChanged(id, value, status) {
      this.item.value = value;
      this.item.validationStatus = status;
    },

    sendValue() {
      this.$emit('updateForm', 'PANScreen', this.item.value);
    },
  },
};
</script>
